import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'

import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import FreelancerPriceCalcs from './FreelancerPriceCalcs'

type TFreelancerCalculator = {
  className?: string
}

const FreelancerCalculator = (props: TFreelancerCalculator) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    )
      return

    setIsDrawerOpen(state)
  }

  return (
    <>
      <div
        className={`lg:flex items-center hidden flex-col cursor-pointer ${props.className ?? ''}`}
        onClick={() => setIsDrawerOpen(true)}
      >
        <FiIcons name='calculator' className='ml-1' />
        <p className='buttontext2-regular text-center'>Cálculos</p>
      </div>
      <div className={`lg:hidden block cursor-pointer`} onClick={() => setIsDrawerOpen(true)}>
        <FiIcons className='w-12 h-12' name='calculator' />
      </div>

      <Drawer variant='persistent' anchor={'bottom'} open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <FreelancerPriceCalcs handleDrawer={toggleDrawer} />
      </Drawer>
    </>
  )
}

export default FreelancerCalculator
