import { useMutation } from 'react-query'
import { IDelete, IPost } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { TEmailVerificationBody, TNewEmailVerificationBody } from '../../models/IEmailVerification'

const endpoints = {
  emailVerification: (params?: string) => `email-verification${params ?? ''}`,
  newEmail: 'email-verification/new-email',
  verifyEmail: 'email-verification/verify-email',
}

export const useNewVerificationMutation = () => {
  return useMutation(({ body }: IPost<TNewEmailVerificationBody>) =>
    apiClient.post<TNewEmailVerificationBody, TNewEmailVerificationBody>({ endpoint: endpoints.newEmail, body })
  )
}

export const useVerifyEmailMutation = () => {
  return useMutation(({ body }: IPost<TEmailVerificationBody>) =>
    apiClient.post<TEmailVerificationBody, TEmailVerificationBody>({ endpoint: endpoints.verifyEmail, body })
  )
}

export const useRemoveEmailMutation = () => {
  return useMutation(({ id }: IDelete) =>
    apiClient.delete<TEmailVerificationBody>({ endpoint: endpoints.emailVerification(`/${id}`) })
  )
}
