import { useMutation, useQuery } from 'react-query'
import { IReactQueryOptions, IResponse, TParams } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'
import { IConcept } from '../../models/IConcept'

const endpoints = {
  concepts: (id?: string) => `concepts${id}`,
}

export const useConceptsQuery = (params: { filters?: unknown } & IReactQueryOptions = {}) => {
  const { filters, reactQueryOptions } = params
  const endpoint = endpoints.concepts(filters ? `?filters=${JSON.stringify(filters)}` : '')
  const item = useQuery(endpoint, () => apiClient.get<IResponse<IConcept>>({ endpoint }), {
    select: (data) => data.data,
    ...reactQueryOptions,
  })
  useSetLoader(item)

  return item
}

export const useConceptsMutation = () => {
  return useMutation((params: TParams<Partial<IConcept>>) =>
    apiClient.mutation<IConcept, Partial<IConcept>>({
      endpoint: endpoints.concepts(params.id ? `/${params.id}` : ''),
      method: params.method,
      body: params.body,
    })
  )
}
