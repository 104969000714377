import { useForm, SubmitHandler } from 'react-hook-form'
import { IFreelancer } from '../../../models/IFreelancer'
import { usePostFreelancerMutation } from '../../../customHooks/request/freelancersQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { cleanCLP, getTimeStamp, loadFile } from '../../../utils/helpers'

import FreelancersForm from '../../../shared/FreelancersForm/FreelancersForm'

import { Box, Button } from '@material-ui/core'

const FreelancerRegister: React.FC = () => {
  const form = useForm()

  const postFreelancerMutation = usePostFreelancerMutation()
  useRequestAlert(postFreelancerMutation, form)

  const createFreelancer: SubmitHandler<IFreelancer> = async (data) => {
    let cv = undefined
    let portfolio = undefined
    let avatar = undefined
    console.log(1)
    if (form.watch('cv.url')?.item(0)?.name && !form.watch('cv.url')?.item(0)?.name.includes('http')) {
      cv = { ...data.cv, url: await loadFile(data.cv.url) }
    }
    if (form.watch('portfolio.url')?.item(0)?.name && !form.watch('portfolio.url')?.item(0)?.name.includes('http')) {
      portfolio = { ...data.portfolio, url: await loadFile(data.portfolio.url) }
    }
    if (form.watch('avatar.url')?.item(0)?.name && !form.watch('avatar.url')?.item(0)?.name.includes('http')) {
      avatar = { ...data.avatar, url: await loadFile(data.avatar.url) }
    }

    const formatedData = {
      ...data,
      createdAt: getTimeStamp(data.createdAt),
      birthdate: getTimeStamp(data.birthdate),
      cv,
      portfolio,
      avatar,
      hour_cost: cleanCLP(data.hour_cost),
      was_invited: true,
    }

    postFreelancerMutation.mutate(formatedData, {
      onSuccess: () => {
        form.reset()
        form.setValue('is_submitted_form', true)
      },
    })
  }

  return (
    <>
      <h3>Registrar nuevo Freelancer</h3>
      <form onSubmit={form.handleSubmit(createFreelancer)}>
        <FreelancersForm form={form} asCpanel />

        <Box display='flex' justifyContent='flex-end'>
          <Button type='submit' variant='contained' color='primary' className='mt-3'>
            Registrar
          </Button>
        </Box>
      </form>
    </>
  )
}

export default FreelancerRegister
