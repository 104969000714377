import { Route, Switch } from 'react-router-dom'
import routes from '../../constants/routes'

import FreelancerRegister from '../../components/Cpanel/Freelancers/FreelancerRegister'
import FreelancersFindie from '../../components/Cpanel/Freelancers/FreelancersFindie'
import FreelancersFindieFeatured from '../../components/Cpanel/Freelancers/FreelancersFindieFeatured'
import FreelancerHomeGallery from '../../components/Cpanel/Freelancers/FreelancerHomeGallery'
import FreelancersDeleted from '../../components/Cpanel/Freelancers/FreelancersDeleted'
import FreelancersPostulations from '../../components/Cpanel/Freelancers/FreelancerPostulations/FreelancersPostulations'

const Freelancers: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.cpanel.freelancers.register} component={FreelancerRegister} />
      <Route path={routes.cpanel.freelancers.postulations} component={FreelancersPostulations} />
      <Route path={routes.cpanel.freelancers.findie} component={FreelancersFindie} />
      <Route path={routes.cpanel.freelancers.findie_featured} component={FreelancersFindieFeatured} />
      <Route path={routes.cpanel.freelancers.home_gallery} component={FreelancerHomeGallery} />
      <Route path={routes.cpanel.freelancers.deleted} component={FreelancersDeleted} />
    </Switch>
  )
}

export default Freelancers
