import { FC } from 'react'
import HelpBook from '../../components/FreelanceUserProfile/FreelancerHelp/HelpBook'
import HelpCalculator from '../../components/FreelanceUserProfile/FreelancerHelp/HelpCalculator'
import HelpPayment from '../../components/FreelanceUserProfile/FreelancerHelp/HelpPayment'
import HelpProjects from '../../components/FreelanceUserProfile/FreelancerHelp/HelpProjects'
import HelpUseManual from '../../components/FreelanceUserProfile/FreelancerHelp/HelpUseManual'
import HelpUserProfile from '../../shared/HelpUserProfile/HelpUserProfile'

const helpList = [
  {
    topic: 'Book',
    content: <HelpBook />,
  },
  {
    topic: 'Proyectos',
    content: <HelpProjects />,
  },
  {
    topic: 'Calculadora',
    content: <HelpCalculator />,
  },
  {
    topic: 'Pagos',
    content: <HelpPayment />,
  },
  {
    topic: 'Manual de uso',
    content: <HelpUseManual />,
  },
]

const FreelancerHelp: FC = () => {
  return <HelpUserProfile helpList={helpList} />
}

export default FreelancerHelp
