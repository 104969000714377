import { useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import endpoints from '../../constants/endpoints'
import { IClient, INewClient, IProjectClient, IProjectClientBody } from '../../models/IClient'
import { IPaginatedResponse, IPost, IPut } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints2 = {
  clients: (id: string) => `clients/${id}`,
  clientsSortedWithFilter: (filters: string) => `clients/sorted?filters=${filters}`,
  clientByEmail: (email: string) => `clients/by-email/${email}`,
  projectClient: 'clients/projectClient',
  newClient: 'clients/newClient',
}

export const useClientsSorted = ({ filters, enabled }: { filters: unknown; enabled?: boolean }) => {
  const endpoint = endpoints2.clientsSortedWithFilter(JSON.stringify(filters))
  const items = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IClient>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled: enabled ?? true,
  })
  useSetLoader(items)
  return items
}

export const useOneClientQuery = ({ id, enabled = true }: { id: string; enabled: boolean }) => {
  const endpoint = endpoints2.clients(id)
  const item = useQuery(endpoint, () => apiClient.get<IClient>({ endpoint }), {
    select: (data) => {
      return data
    },
    enabled,
  })
  useSetLoader(item)
  return item
}

export const useUpdateClient = () => {
  return useMutation(({ body, id }: IPut<Omit<IClient, 'createdAt'>>) =>
    apiClient.put<IClient, Omit<IClient, 'createdAt'>>({ endpoint: endpoints2.clients(id), body })
  )
}

export const useCreateProjectClient = () => {
  return useMutation(({ body }: IPost<IProjectClientBody>) =>
    apiClient.post<IProjectClient, IProjectClientBody>({ endpoint: endpoints2.projectClient, body })
  )
}

export const useCreateNewClient = () => {
  return useMutation(({ body }: IPost<Partial<IClient>>) =>
    apiClient.post<INewClient, Partial<IClient>>({ endpoint: endpoints2.newClient, body })
  )
}

//Old structure..............................................
export const useClientsQuery = (filters: any) => {
  const clientsQuery = useQuery(
    [`all_${endpoints.clients}`, filters],
    () => apiClient.getClientsFiltered(JSON.stringify(filters)),
    {
      cacheTime: 0,
      select: (data) => {
        return { data: data.data, metadata: data.metadata }
      },
    }
  )
  return clientsQuery
}

export const useClientQuery = (id: string) => {
  const clientQuery = useQuery(`one_${endpoints.clients}`, () => apiClient.getClient(id), {
    cacheTime: 0,
  })
  return clientQuery
}

export const usePostClientMutation = () => {
  const mutation = useMutation(`post_${endpoints.clients}`, apiClient.postClient)
  return mutation
}

export const usePutClientMutation = (refresh?: 'refresh') => {
  const mutation = useMutation(`put_${endpoints.clients}`, apiClient.putClient)
  refresh && useRefreshClients(mutation)
  return mutation
}

export const useDeleteClientMutation = (refresh?: 'refresh') => {
  const mutation = useMutation(`delete_${endpoints.clients}`, apiClient.deleteClient)
  refresh && useRefreshClients(mutation)
  return mutation
}

export const useRefreshClients = (mutation: any) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    mutation.isSuccess && queryClient.refetchQueries(`all_${endpoints.clients}`)
  }, [mutation.isSuccess])
}
