import { evaluate } from 'decimal-eval'
import { IExperienceLevel } from '../models/IFreelancer'
import { TProjectStatus } from '../models/IProject'
import { TWeekDay } from '../models/IWorkPlan'
import { IStaff } from '../models/IStaff'
import dayjs from 'dayjs'
import clsx, { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

//Regexs
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const urlRegex = /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/

//Mehods
export const created_at = dayjs().valueOf()
export const CURRENT_YEAR = new Date().getFullYear()
export const getTimeStamp = (date: Date | string | number) => dayjs(date).valueOf()

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

export const experienceLevelDict = (experienceLevel: IExperienceLevel) => {
  const experienceLevelDict: Record<string, { title: string; styles: string }> = {
    junior: { title: 'Junior', styles: 'bg-yellow-bright text-black' },
    semi_senior: { title: 'Mid', styles: 'bg-cyan text-black' },
    senior: { title: 'Senior', styles: 'bg-rose-video text-black' },
    expert: { title: 'Experto', styles: 'bg-light-black text-golden' },
  }
  return experienceLevelDict[experienceLevel]
}

export const projectStatusDict = (projectStatus: TProjectStatus) => {
  const projectStatusDict: Record<string, string> = {
    in_progress: 'En progreso',
    not_approved: 'NO aprobado',
    finished: 'Terminado',
    cancelled: 'Cancelado',
    paused: 'Pausado',
  }
  return projectStatusDict[projectStatus]
}

export const weekDaysDict = (weekday: TWeekDay) => {
  const weekDayDict: Record<TWeekDay, string> = {
    Lun: 'Lunes',
    Mar: 'Martes',
    Mie: 'Miércoles',
    Jue: 'Jueves',
    Vie: 'Viernes',
  }
  return weekDayDict[weekday]
}

export const taxCalculator = (price: number, taxPercent: number, calculateType?: 'without_tax') => {
  const tax = price * (taxPercent / 100)

  return calculateType === 'without_tax' ? price - tax : price + tax
}

export const formatDecimal = (number: number | undefined) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'decimal',
    minimumFractionDigits: Number.isInteger(number) ? 0 : 0,
    maximumFractionDigits: Number.isInteger(number) ? 0 : 0,
  }).format(number ?? 0)
}

export const loadFile = (files: any) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      return resolve(reader.result)
    })
    reader.readAsDataURL(files.item(0))
  })
}

export const loadFiles = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      return resolve(reader.result)
    })
    reader.readAsDataURL(file)
  })
}

export const setFormTheme = (selectedTheme?: string) => {
  const theme: Record<string, any> = {
    blue: {
      containerBgColor: 'bg-sea-blue',
      buttonBgColor: 'bg-soft-blue',
      textColor: 'text-white',
      borderBottom: 'border-b border-white',
    },
    orange: {
      containerBgColor: 'bg-soft-orange',
      buttonBgColor: 'bg-orange',
      textColor: 'text-black',
      borderBottom: 'border-b border-black',
    },
  }

  if (!selectedTheme) return theme.blue
  return theme[selectedTheme]
}

export const sortByString = (a: any, b: any, field: string) => {
  const aToString = a[field].toLowerCase()
  const bToString = b[field].toLowerCase()

  if (aToString < bToString) {
    return -1
  }
  if (aToString > bToString) {
    return 1
  }
  return 0
}

export const getFirstWord = (text: string) => {
  const endSLide = text.indexOf(' ')
  return endSLide > 0 ? text.slice(0, endSLide + 1) : text
}

export const capitalizeFirstLetter = (text: string) => {
  return text.slice(0, 1).toUpperCase()
}

export const uniqueKey = () => Math.round(Math.random() * (1000000 - 1) + 1).toString()

export const operation = (number1: any, operator: string, number2: any) => {
  return Math.ceil(+evaluate(`${number1} ${operator} ${number2}`))
}

export const parseVideoUrl = (url: string) => {
  if (!url.includes('youtu')) return url
  const id = url.slice(17, url.length)
  return `https://www.youtube.com/embed/${id}`
}

export const cleanCLP = (value?: string | number | null) => {
  if (!value) return 0

  value = typeof value === 'number' ? String(value) : value
  return parseFloat(value.replace(/[.]/g, '').replace(/[,]/g, '.'))
}

export const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const cleanString = (string: string) => {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replaceAll(' ', '')
}

export const projectDetailColumns = (isDesktop: boolean, isMobile: boolean) => {
  if (isDesktop) return '250px 1fr'
  if (isMobile) return '1fr'
  return '200px 1fr'
}

export const workPlanPrice = (staff: IStaff) => {
  const selectedVersion = staff.work_plan.versions.find((version) => version.is_selected)
  if (!selectedVersion) return undefined

  return { ...selectedVersion.price }
}

export const progressPercent = (step: number, totalSteps: number) => {
  const percent = (step * 100) / totalSteps
  return Math.ceil(percent)
}
