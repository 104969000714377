import { useState } from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import FindieGifIsotype from '../../shared/Brand/Isotype/FindieGifIsotype'
import { useUFQuery } from '../../customHooks/request/projectsQuery'
import useAuth from '../../customHooks/useAuth'
import { Link } from 'react-router-dom'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import useUser from '../../customHooks/useUser'
import routes from '../../constants/routes'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const RightMenuUserProfile = () => {
  const { data: uf } = useUFQuery()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { logout } = useAuth()
  const { user } = useUser()
  const { t } = useTranslation()

  const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    )
      return

    setIsDrawerOpen(state)
  }

  const helpPath = () => {
    if (!user) return ''
    return user.user_type === 'client' ? routes.clientUserProfile.help : routes.freelancerUserProfile.help
  }

  if (!user) return null

  return (
    <>
      <div className={`cursor-pointer`} onClick={() => setIsDrawerOpen(true)}>
        <MenuIcon fontSize='large' />
      </div>

      <Drawer variant='temporary' anchor={'right'} open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className='flex flex-col h-full justify-between md:p-8 p-4'>
          <IconButton className={`absolute top-2 right-2`} onClick={() => setIsDrawerOpen(false)}>
            <CloseIcon fontSize='large' className='text-black' />
          </IconButton>
          <div className='mt-12'>
            <p className='body2-regular lg:mt-4 mt-2 text-right'>
              {t('GLOBAL.TODAY')}
              {dayjs().format('DD/MM/YYYY')}
            </p>
            <Link to={helpPath()}>
              <div className={`flex justify-end items-center mt-4 gap-2 cursor-pointer`}>
                <FiIcons className='w-9 h-9' name='hearth' />
                <p className='buttontext2-regular text-center'>{t('GLOBAL.HELP')}</p>
              </div>
            </Link>
          </div>

          <div>
            <p className='buttontext2-semibold text-center cursor-pointer mb-8' onClick={logout}>
              Log out
            </p>
            <FindieGifIsotype className='w-20 h-20 block mx-auto' variant='contained-black' />
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default RightMenuUserProfile
