import {
  AddIcon,
  CircleAddIcon,
  BookmarkIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EditIcon,
  EyeIcon,
  LinkIcon,
  SkullIcon,
  StarIcon,
  SuspendedIcon,
  LocationIcon,
  AccountIcon,
  FindieTransparentIcon,
  HomeIcon,
  ProfileIcon,
  GifIcon,
  RepeatIcon,
  RepeatDisabledIcon,
  AddCircleIcon,
  ScoreStarIcon,
  DownloadIcon,
  CalculatorIcon,
  HeartIcon,
  CircleCloseIcon,
  BlueEyeIcon,
  LocationWhiteIcon,
  CircleAddWhiteIcon,
  BookIcon,
  BackArrowIcon,
  StatusIcon,
  HelpIcon,
  UnCheckedSquareIcon,
  CheckedSquareIcon,
  DollarIcon,
  MountainIcon,
  Edit2Icon,
  BlueSkullIcon,
  ArrowRightIcon,
  LinkedinIcon,
  InstagramIcon,
  SpotifyIcon,
  EmailIcon,
  CircleAddBlueIcon,
  CircleCloseBlueIcon,
  CircleCloseBlackIcon,
  SmallStarIcon,
} from './Icons'

export type TCustomIcons = {
  Icon?: any
  fill?: string
  width?: string
  height?: string
  className?: string
  onClick?: (e?: any) => void
}

export const IconsDict = {
  blue_skull: BlueSkullIcon,
  star: StarIcon,
  skull: SkullIcon,
  suspended: SuspendedIcon,
  eye: EyeIcon,
  blue_eye: BlueEyeIcon,
  bookmark: BookmarkIcon,
  chevron_left: ChevronLeftIcon,
  chevron_right: ChevronRightIcon,
  chevron_up: ChevronUpIcon,
  chevron_down: ChevronDownIcon,
  add: AddIcon,
  addCircle: AddCircleIcon,
  circleAdd: CircleAddIcon,
  circle_add_white: CircleAddWhiteIcon,
  circle_add_blue: CircleAddBlueIcon,
  edit: EditIcon,
  link: LinkIcon,
  location: LocationIcon,
  location_white: LocationWhiteIcon,
  account: AccountIcon,
  findieTransparent: FindieTransparentIcon,
  home: HomeIcon,
  profile: ProfileIcon,
  gif: GifIcon,
  repeat: RepeatIcon,
  repeat_disabled: RepeatDisabledIcon,
  score_star: ScoreStarIcon,
  download: DownloadIcon,
  calculator: CalculatorIcon,
  hearth: HeartIcon,
  circle_close: CircleCloseIcon,
  circle_close_black: CircleCloseBlackIcon,
  circle_close_blue: CircleCloseBlueIcon,
  book: BookIcon,
  back_arrow: BackArrowIcon,
  status: StatusIcon,
  help: HelpIcon,
  uncheckedSquare: UnCheckedSquareIcon,
  checkedSquare: CheckedSquareIcon,
  dollar: DollarIcon,
  mountain: MountainIcon,
  edit2: Edit2Icon,
  Linkedin: LinkedinIcon,
  Instagram: InstagramIcon,
  ArrowRight: ArrowRightIcon,
  Spotify: SpotifyIcon,
  Email: EmailIcon,
  smallStar: SmallStarIcon,
}

export type TIconName = {
  name: keyof typeof IconsDict
}

export const FiIcons = (props: TCustomIcons & TIconName) => {
  const Icon = IconsDict[props.name]

  return <Icon {...props} />
}
