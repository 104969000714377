import { useEffect, useState } from 'react'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'

import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import SectionStructure from '../../layout/WebSite/SectionStructure'
import FaqsList from '../../components/WebSite/Faqs/FaqsList'

import FiButton from '../../assets/UIkit/FiButton'
import routes from '../../constants/routes'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { useTranslation } from 'react-i18next'

const Faqs: React.FC = () => {
  const { view, setView } = useWebSiteLayout()
  const [faqSelected, setFaqSelected] = useState<string>('client')
  const { t } = useTranslation()

  const freelancerFaqs: [] = t('faq.freelancerFaqs', { returnObjects: true })
  const clientsFaqsLeft: [] = t('faq.clientsFaqsLeft', { returnObjects: true })
  const clientsFaqsRight: [] = t('faq.clientsFaqsRight', { returnObjects: true })

  const clientsFaqs: [] = t('faq.clientsFaqs', { returnObjects: true })
  const freelancerFaqsLeft: [] = t('faq.freelancerFaqsLeft', { returnObjects: true })
  const freelancerFaqsRight: [] = t('faq.freelancerFaqsRight', { returnObjects: true })

  useEffect(() => {
    setView({ path: 'faqs', bgColor: 'web-bg-white', textColor: 'text-black', textColor2: 'text-white' })
  }, [])

  return (
    <WebSiteStructure>
      <SectionStructure>
        <h1 className={`${view.textColor} mt-12`}>
          {t('faq.title.line1')}
          <br />
          {t('faq.title.line2')}
          <br />
          {t('faq.title.line3')}
        </h1>

        <div className={`md:mt-40 mt-64 flex ${view.textColor}`}>
          <span
            className='buttontext3-bold text-left md:inline block cursor-pointer'
            onClick={() => setFaqSelected('freelancer')}
          >
            FREELANCERS
            {faqSelected === 'freelancer' ? (
              <FiIcons name='chevron_down' className='ml-3 w-5 h-3' />
            ) : (
              <FiIcons name='chevron_up' className='ml-3 w-5 h-3' />
            )}
          </span>
          <h4 className='md:mx-8 mx-4'>/</h4>
          <span className='buttontext3-bold text-left md:inline block cursor-pointer' onClick={() => setFaqSelected('client')}>
            {t('CLIENTES')}
            {faqSelected === 'client' ? (
              <FiIcons name='chevron_down' className='ml-3 w-5 h-3' />
            ) : (
              <FiIcons name='chevron_up' className='ml-3 w-5 h-3' />
            )}
          </span>
        </div>

        {faqSelected === 'freelancer' && (
          <>
            <FaqsList faqs={freelancerFaqs} leftFaqsQuestions={freelancerFaqsLeft} rightFaqsQuestions={freelancerFaqsRight} />
            <div className='total-center mt-28'>
              <p className='formtext2-regular'>{t('faq.questionFreelancer')}</p>
              <div>
                <FiButton
                  asLink
                  to={routes.web_site.freelancer_suscription_form.step_zero}
                  variant='outlined'
                  className='py-2 px-4 ml-6'
                >
                  <p className='buttontext1-medium'>{t('faq.buttonFreelancer')}</p>
                </FiButton>
              </div>
            </div>
          </>
        )}
        {faqSelected === 'client' && (
          <>
            <FaqsList faqs={clientsFaqs} leftFaqsQuestions={clientsFaqsLeft} rightFaqsQuestions={clientsFaqsRight} />
            <div className='total-center mt-28'>
              <p className='formtext2-regular'>{t('faq.questionClient')}</p>
              <div>
                <FiButton
                  asLink
                  to={routes.web_site.client_suscription_form.step_zero}
                  variant='outlined'
                  className='py-2 px-4 ml-6'
                >
                  <p className='buttontext1-medium'>{t('faq.buttonClient')}</p>
                </FiButton>
              </div>
            </div>
          </>
        )}
      </SectionStructure>
    </WebSiteStructure>
  )
}

export default Faqs
