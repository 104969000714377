import { Slide } from '@material-ui/core'
import { FC } from 'react'
import FiButton from '../../assets/UIkit/FiButton'
import routes from '../../constants/routes'
import { useTranslation } from 'react-i18next'

type TScrollToTop = {
  open: boolean
}

const MobileBottomMenu: FC<TScrollToTop> = (props) => {
  const { t } = useTranslation()
  return (
    <Slide direction='up' in={props.open} mountOnEnter unmountOnExit>
      <div className='fixed bottom-0 left-0 md:hidden w-full z-30 total-center gap-8 py-3 bg-black'>
        <div>
          <FiButton
            className='px-4 py-2'
            theme='light'
            variant='outlined'
            to={routes.web_site.freelancer_suscription_form.step_zero}
            asLink
          >
            <p className='buttontext1-medium'>{t('Postula')}</p>
          </FiButton>
        </div>
        <div>
          <FiButton
            className='px-4 py-2'
            theme='light'
            variant='outlined'
            to={routes.web_site.client_suscription_form.step_zero}
            asLink
          >
            <p className='buttontext1-medium'>{t('Contrata')}</p>
          </FiButton>
        </div>
      </div>
    </Slide>
  )
}

export default MobileBottomMenu
