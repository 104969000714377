import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import Tabs from '../../assets/UIkit/Tabs'
import routes from '../../constants/routes'
import useUser from '../../customHooks/useUser'

import BankDataForm from '../../shared/BankDataForm/BankDataForm'
import useRequestAlert from '../../customHooks/useRequestAlert'
import { useUpdateUser } from '../../customHooks/request/userQuery'
import { IUser, TAvatarStyle } from '../../models/IUser'
import FreelancerFormPersonalData from '../../shared/FreelancersForm/FreelancerFormPersonalData'
import { useUpdateFreelancer } from '../../customHooks/request/freelancersQuery'
import { IFreelancer } from '../../models/IFreelancer'
import FreelancerProfilePlan from '../../components/FreelanceUserProfile/FreelancerProfile/FreelancerProfilePlan'
import useAuth from '../../customHooks/useAuth'
import EntityProfileSettings from '../../shared/ProjectUserProfile/EntityProfile/EntityProfileSettings'
import { getTimeStamp } from '../../utils/helpers'

const avatarColors: TAvatarStyle[] = ['yellow', 'strong-rose', 'magenta', 'cyan', 'golden']

const FreelancerProfile: FC = () => {
  const form = useForm()
  const { logout } = useAuth()
  const { push: navigate } = useHistory()
  const { pathname } = useLocation()
  const { user, freelancerProfile, setFreelancerProfile, setUser } = useUser()
  const updateFreelancer = useUpdateFreelancer()
  const updateUser = useUpdateUser()
  useRequestAlert(updateUser)
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState('')
  const password = form.watch('password')
  const password_repeat = form.watch('password_repeat')

  const tabs = [
    {
      id: 1,
      text: `Datos`,
      isSelected: pathname.includes('personales'),
      action: () => tabAction(routes.freelancerUserProfile.profile.personalData),
    },
    {
      id: 2,
      text: `Ajustes`,
      isSelected: pathname.includes('ajustes'),
      action: () => tabAction(routes.freelancerUserProfile.profile.settings),
    },
    {
      id: 3,
      text: `Facturación`,
      isSelected: pathname.includes('facturacion'),
      action: () => tabAction(routes.freelancerUserProfile.profile.billing),
    },
    {
      id: 4,
      text: `Plan`,
      isSelected: pathname.includes('plan'),
      action: () => tabAction(routes.freelancerUserProfile.profile.plan),
    },
  ]

  const tabAction = (path: string) => {
    navigate(path)
    form.reset()
  }

  const updateFreelancerData = async (body: IFreelancer) => {
    if (pathname.includes('ajuste')) {
      const isValidPassword =
        /^.{6,}$/.test(password) && /\d/.test(password) && /[A-Z]/.test(password) && password === password_repeat
      if (password && !isValidPassword) return

      const bodyWithoutPassword = {
        avatar_style: form.watch('avatar_style'),
      }
      const freelancerBody = {
        contact_preference: form.watch('contact_preference'),
      }
      const bodyWithPassword = { ...bodyWithoutPassword, password, has_default_password: false }
      await updateFreelancer.mutate(
        { body: freelancerBody, id: user?.profile_id ?? '' },
        {
          onSuccess: (response) => {
            const { _id, ...rest } = body
            updateUser.mutate(
              { body: { ...rest, last_name: rest.lastName } as unknown as IUser, id: user?._id ?? '' },
              { onSuccess: (response) => setUser(response as IUser) }
            )
            setFreelancerProfile(response)
          },
        }
      )

      await updateUser.mutate(
        { body: password ? bodyWithPassword : bodyWithoutPassword, id: user?._id ?? '' },
        {
          onSuccess: (response) => {
            if (password) {
              setUpdatePasswordMessage('Cambiaste la contraseña!. Ahora te vamos a redirigir para que inicies sesión nuevamente.')
              setTimeout(() => {
                logout()
              }, 2000)
              return
            }
            setUser(response as IUser)
          },
        }
      )
      return
    }
    const { createdAt, ...updateFreelancerBody } = body
    let userBody = { ...updateFreelancerBody }
    if (body.birthdate) {
      userBody = { ...userBody, birthdate: getTimeStamp(body.birthdate) }
    }

    updateFreelancer.mutate(
      { body: userBody, id: user?.profile_id ?? '' },
      {
        onSuccess: (response) => {
          const { _id, ...rest } = body
          updateUser.mutate(
            { body: { ...rest, last_name: rest.lastName } as unknown as IUser, id: user?._id ?? '' },
            { onSuccess: (response) => setUser(response as IUser) }
          )
          setFreelancerProfile(response)
        },
      }
    )
  }

  if (!freelancerProfile || !user) return null
  return (
    <div>
      <Tabs tabs={tabs} variant='default' />
      <form onSubmit={form.handleSubmit(updateFreelancerData)}>
        <div className='flex justify-end md:mt-12 mt-4'>
          {!pathname.includes('plan') && (
            <button type='submit' className='subtitle4-medium border-b border-black'>
              Guardar cambios
            </button>
          )}
        </div>

        <Switch>
          <Route path={routes.freelancerUserProfile.profile.personalData}>
            <FreelancerFormPersonalData form={form} freelancer={freelancerProfile} variant='default' isDisabledFields={false} />
          </Route>
          <Route path={routes.freelancerUserProfile.profile.settings}>
            <h5 className='text-blue'>{updatePasswordMessage}</h5>
            <EntityProfileSettings
              contactPreference={freelancerProfile.contact_preference}
              avatarColors={avatarColors}
              avatarStyle={user.avatar_style}
              form={form}
            />
          </Route>
          <Route path={routes.freelancerUserProfile.profile.billing}>
            <BankDataForm form={form} role={freelancerProfile} isDisabledFields={false} variant='default' />
          </Route>
          <Route path={routes.freelancerUserProfile.profile.plan}>
            <FreelancerProfilePlan {...freelancerProfile} />
          </Route>
        </Switch>
      </form>
    </div>
  )
}

export default FreelancerProfile
