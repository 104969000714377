import { FC, useEffect, useMemo, useState } from 'react'
import ProfilesFreelancerCard from '../../../shared/ProfilesFreelancerCard/ProfilesFreelancerCard'
import {
  audiovisualExpertise1,
  audiovisualExpertise2,
  audiovisualExpertise3,
  designExpertise1,
  designExpertise2,
  designExpertise3,
  designExpertise4,
  developExpertise1,
  developExpertise2,
  marketingExpertise1,
  marketingExpertise2,
  writingExpertise1,
  writingExpertise2,
  writingExpertise3,
  writingExpertise4,
} from '../../../constants/WebSite/FreelancersProfilesConstants'
import { ICategory } from '../../../models/ICategory'
import { IFreelancer } from '../../../models/IFreelancer'

import FiButton from '../../../assets/UIkit/FiButton'
import routes from '../../../constants/routes'
import FindieIsotype from '../../../shared/Brand/Isotype/FindieIsotype'
import SpecialText from '../../../shared/SpecialText/SpecialText'
import { TView } from '../../../context/WebSite/CurrentViewContext'
import PostulationBanner from '../../../shared/PostulationBanner/PostulationBanner'
import { useTranslation } from 'react-i18next'
import { CategoryTabs } from '../../../shared/FreelancersGallery/CategoryTabs'
import { useCategoriesQuery } from '../../../customHooks/request/categoriesQuery'
import { useSetLoader } from '../../../customHooks/useSetLoader'
import { usePublicFreelancersQuery } from '../../../customHooks/request/freelancersQuery'
import useExecuteScroll from '../../../customHooks/useExecuteScroll'
import video from '../../../assets/images/web/video-play.svg'
import { Link } from 'react-router-dom'
import useUser from '../../../customHooks/useUser'
import AppDialog from '../../../assets/UIkit/AppDialog'
import EmailVerification from '../ClientSuscriptionForm/EmailVerification'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import useNewClient from '../../../customHooks/useNewClient'

type TFreelancersProfilesList = {
  view: TView
}

const FreelancersProfilesList: FC<TFreelancersProfilesList> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { data: categoriesQuery } = useCategoriesQuery()
  const scroll = useExecuteScroll()
  const { emailProps, isVerificationModalOpen, setIsVerificationModalOpen } = useNewClient()

  const [categorySelected, setCategorySelected] = useState<ICategory | undefined>()
  const freelancersQuery = usePublicFreelancersQuery({
    filters: { 'featured_status.is_featured': true, category: categorySelected },
    reactQueryOptions: { enabled: Boolean(categorySelected) },
  })
  const { data: profiles } = freelancersQuery
  useSetLoader(freelancersQuery)

  const [showExpertiz, setShowExpertiz] = useState<'' | 'hidden'>('hidden')

  const categories = useMemo(() => {
    if (!categoriesQuery) return []
    return categoriesQuery.filter((category: ICategory) => !category.is_other_category)
  }, [categoriesQuery])

  useEffect(() => {
    if (!categorySelected && categoriesQuery) setCategorySelected(categoriesQuery[0])
  }, [categoriesQuery])

  return (
    <div className='lg:-mx-16 mx-0' ref={scroll.elementRef}>
      <SpecialText styles={props.view} noMarginTop>
        {t('freelancerProfile.FreelancersProfilesList.SpecialText')}
      </SpecialText>

      <CategoryTabs
        categories={categories}
        selectCategory={categorySelected}
        textColor={props.view.textColor}
        className='mt-8'
        setSelectCategory={setCategorySelected}
      />

      <div className='flex justify-between items-center pb-9 border-b border-black'>
        <div className='flex gap-6 items-center'>
          <h3 className='cursor-pointer' onMouseOver={() => setShowExpertiz('')} onMouseOut={() => setShowExpertiz('hidden')}>
            {t(categorySelected?.name ?? '')}
          </h3>
          <div className='flex gap-2 items-center'>
            <img src={video} alt='video' />
            <Link to={routes.web_site.why_findie}>
              <p className='subtitle6-regular'>¿Cómo funciona Findie?</p>
            </Link>
          </div>
        </div>

        <div className='md:flex hidden items-center justify-end'>
          <FindieIsotype variant='golden' className='w-5 h-4' />
          <p className='microcopy'>: {t('freelancerProfile.FreelancersProfilesList.title')}</p>
        </div>
      </div>

      <div className='md:hidden flex items-center justify-end mt-4'>
        <FindieIsotype variant='golden' className='w-5 h-4' />
        <p className='microcopy'>: {t('freelancerProfile.FreelancersProfilesList.title')} </p>
      </div>

      <div className='relative pt-8 md:pb-16 pb-28'>
        <div className='grid grid-cols-12 gap-x-4 lg:gap-y-12 md:gap-y-6 gap-y-4 lg:px-0 md:px-4 px-0'>
          {profiles &&
            profiles.data
              .sort((a, b) => a.featured_status.position - b.featured_status.position)
              .map((freelancer: IFreelancer) => {
                return (
                  <div key={freelancer._id} className='md:col-span-4 col-span-6 mx-1'>
                    <ProfilesFreelancerCard freelancer={freelancer} />
                  </div>
                )
              })}
        </div>

        <div
          className={`absolute -top-14 left-0 lg:w-9/12 w-full bg-white-04 pb-8 pt-16 ${showExpertiz}`}
          onMouseOver={() => setShowExpertiz('')}
          onMouseOut={() => setShowExpertiz('hidden')}
        >
          {/* design */}
          {categorySelected && categorySelected._id === '6168109e817121146f4b149b' && (
            <div className=''>
              <p className='subtitle6-medium'>Áreas de expertiz</p>

              <div className='grid grid-cols-12 md:gap-x-12 gap-x-0'>
                <div className='md:col-span-3 col-span-12'>
                  {designExpertise1.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-3 col-span-12'>
                  {designExpertise2.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-3 col-span-12'>
                  {designExpertise3.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-3 col-span-12'>
                  {designExpertise4.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {/* develop */}
          {categorySelected && categorySelected._id === '616810b4817121146f4b14a1' && (
            <div>
              <p className='subtitle6-medium'>Áreas de expertiz</p>

              <div className='grid grid-cols-12 md:gap-x-12 gap-x-0'>
                <div className='md:col-span-4 col-span-12'>
                  {developExpertise1.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-4 col-span-12'>
                  {developExpertise2.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {/* Writing */}
          {categorySelected && categorySelected._id === '616810d8817121146f4b14ad' && (
            <div>
              <p className='subtitle6-medium'>Áreas de expertiz</p>

              <div className='grid grid-cols-12 md:gap-x-12 gap-x-0'>
                <div className='md:col-span-4 col-span-12'>
                  {writingExpertise1.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-4 col-span-12'>
                  {writingExpertise2.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                  {writingExpertise3.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-4 col-span-12'>
                  {writingExpertise4.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {/* audivisual */}
          {categorySelected && categorySelected._id === '616810cd817121146f4b14a7' && (
            <div>
              <p className='subtitle6-medium'>Áreas de expertiz</p>

              <div className='grid grid-cols-12 md:gap-x-12 gap-x-0'>
                <div className='md:col-span-4 col-span-12'>
                  {audiovisualExpertise1.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-4 col-span-12'>
                  {audiovisualExpertise2.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-4 col-span-12'>
                  {audiovisualExpertise3.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {/* marketing */}
          {categorySelected && categorySelected._id === '651ed4b850b5f7c31a6c342f' && (
            <div>
              <p className='subtitle6-medium'>Áreas de expertiz</p>

              <div className='grid grid-cols-12 md:gap-x-12 gap-x-0'>
                <div className='md:col-span-4 col-span-12'>
                  {marketingExpertise1.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                <div className='md:col-span-4 col-span-12'>
                  {marketingExpertise2.map((iter, index) => {
                    return (
                      <div key={iter.area + index} className='mt-4'>
                        <p className='body2-medium'>{iter.area}</p>
                        {iter.expertises.map((expertise, index) => {
                          return (
                            <p key={expertise + index} className='microcopy'>
                              {expertise}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        {profiles && profiles.data.length > 16 && (
          <div className='total-center flex-col relative z-10 lg:w-1/2 w-5/6 py-12 md:md:-mt-96 -mt-48 mx-auto bg-white-04 lg:px-12 px-4 rounded-md'>
            {user && user.user_type === 'client' ? (
              <div className='mt-4'>
                <h4 className='text-center'> {t('freelancerProfile.moreChart.createAccount')}</h4>
                <FiButton
                  className='block mx-auto px-4 mt-2'
                  variant='contained'
                  theme='secondary'
                  asLink
                  to={routes.clientUserProfile.book}
                >
                  {t('freelancerProfile.moreChart.clickHere')}
                </FiButton>
              </div>
            ) : (
              <div className='mt-4'>
                <h4 className='text-center'> {t('freelancerProfile.moreChart.moreProfiles')}</h4>
                <FiButton
                  className='block mx-auto px-4 mt-2'
                  variant='contained'
                  theme='secondary'
                  onClick={() => setIsVerificationModalOpen(true)}
                >
                  {t('freelancerProfile.moreChart.clickHere')}
                </FiButton>
              </div>
            )}
          </div>
        )}
      </div>

      <div className='lg:ml-0 -ml-8 mt-40'>
        <PostulationBanner title='No esperes más,' className='md:mt-28 mt-36' bgColor='bg-white' textColor='text-black' />
      </div>

      <AppDialog open={isVerificationModalOpen} title='' maxWidth='xl' onClose={() => setIsVerificationModalOpen(false)} noHeader>
        <div className='pt-8 relative overflow-hidden'>
          <EmailVerification {...emailProps} redirectTo={routes.web_site.lite_client_suscription_form} asLiteForm />

          <FiIcons
            name='circle_close_black'
            className='absolute -top-2 -right-2 cursor-pointer'
            onClick={() => setIsVerificationModalOpen(false)}
          />
        </div>
      </AppDialog>
    </div>
  )
}

export default FreelancersProfilesList
