import { FC } from 'react'

const HelpCalculator: FC = () => {
  return (
    <div className='w-10/12'>
      <p className='body2-regular'>
        Podrás ver cuánto cobras y cuánto ganas en tu bolsillo con respecto al % que tengas de membresía Findie. Por el momento a
        los freelancers chilenos se les calcula el monto a escribir en su boleta de prestación de servicios.
      </p>
    </div>
  )
}

export default HelpCalculator
