import { IWebSiteSection } from '../../../models/IWebSiteSection'
import SectionStructure from '../../../layout/WebSite/SectionStructure'
import feat1 from '../../../assets/images/web/clients/feat1.svg'
import feat2 from '../../../assets/images/web/clients/feat2.svg'
import feat3 from '../../../assets/images/web/clients/feat3.svg'
import { useTranslation } from 'react-i18next'

const images = [feat1, feat2, feat3]

const ClientFeatured: React.FC<IWebSiteSection> = (props) => {
  const { t } = useTranslation()
  const clientFeatured: any[] = t('OurClients.ClientFeatured', { returnObjects: true })

  return (
    <SectionStructure className='pt-0' id='client_featured'>
      <div className='grid grid-cols-12 md:gap-16'>
        {clientFeatured.map((iter: any) => {
          return (
            <div className='col-span-12 mt-5 md:col-span-4 md:mt-0 md:text-center'>
              <div className={'md:mx-auto mb-3 w-20 h-20 flex items-end'}>
                <img className={''} src={images[iter.imageIndex]} />
              </div>
              <p className={`subtitle1-medium whitespace-pre-wrap ${props.view.textColor}`}>{iter.title}</p>
            </div>
          )
        })}
      </div>
    </SectionStructure>
  )
}

export default ClientFeatured
