import { UseFormReturn } from 'react-hook-form'
import InputField from '../../../assets/UIkit/Forms/InputField'
import SelectField from '../../../assets/UIkit/Forms/SelectField'
import { budgetList, currencyBudgetList } from '../../../constants/Cpanel/ProjectConstants'
import useConcepts from '../../../customHooks/useConcepts'
import CloseIcon from '@material-ui/icons/Close'
import { IConcept } from '../../../models/IConcept'
import { useEffect } from 'react'
import { IBriefProduct } from '../../../models/IBrief'
import { useUpdateProject } from '../../../customHooks/request/projectsQuery'
import { IProject } from '../../../models/IProject'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import { useTranslation } from 'react-i18next'

type TBriefForm = {
  form: UseFormReturn<any>
  project: IProject
  refetchProject: (
    options?: (RefetchOptions & RefetchQueryFilters<IProject>) | undefined
  ) => Promise<QueryObserverResult<IProject, unknown>>
}

const BriefForm = (props: TBriefForm) => {
  const { t } = useTranslation()
  let NewWhenProjectStarts: any[] = t('GLOBAL.WHEN_PROJECT_STARTS', { returnObjects: true })

  const form = props.form
  const { conceptList, removeConcept } = useConcepts({ conceptName: 'concepts_list', form: form })

  const updateProject = useUpdateProject()

  const currency = form.watch('currency')
  const budgetType = props.project.with_currency ? currencyBudgetList : budgetList

  const updateCurrencyType = (currencyType: 'true' | 'false') => {
    updateProject.mutate(
      {
        body: { with_currency: JSON.parse(currencyType) },
        id: props.project._id,
      },
      {
        onSuccess: () => props.refetchProject(),
      }
    )
  }

  useEffect(() => {
    form.setValue('description', props.project.brief.description)
    form.setValue('when_start', props.project.brief.when_start)
    form.setValue('products', props.project.brief.products)
    form.setValue('currency', JSON.stringify(props.project.with_currency))
  }, [props.project])

  useEffect(() => {
    if (currency && JSON.parse(currency) !== props.project.brief.project.with_currency) updateCurrencyType(currency)
  }, [currency])

  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='2xl:col-span-3 lg:col-span-4 col-span-12'>
        <div className='grid-cols-12'>
          <SelectField
            className='w-full mt-4'
            name='when_start'
            label={`${t('GLOBAL.WHEN_START')}`}
            labelClassName='text-gray-300'
            variant='default'
            inputProps={{ className: 'w-full' }}
            selectOptions={NewWhenProjectStarts}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={form}
          />
        </div>
        <div className='col-span-12 mt-4 border-b border-gray-300 md:pb-0 pb-1'>
          <p className='microcopy mt-4 text-gray-300'>{t('BRIE_FORM.CURRENCY')}</p>
          <div className='flex items-center'>
            <div className='mr-4 flex items-center gap-1'>
              <InputField
                name='currency'
                id='is_hidden_on'
                inputProps={{
                  className: 'no-height',
                  type: 'radio',
                  value: 'false',
                }}
                form={form}
              />
              <label htmlFor='is_hidden_on' className='body2-medium cursor-pointer md:mb-1.5 mb-0'>
                {t('BRIE_FORM.CHILEAN_PESO')}
              </label>
            </div>
            <div className='mr-4 flex items-center gap-1'>
              <InputField
                name='currency'
                id='is_hidden_off'
                inputProps={{
                  className: 'no-height',
                  type: 'radio',
                  value: 'true',
                }}
                form={form}
              />
              <label htmlFor='is_hidden_off' className='body2-medium cursor-pointer md:mb-1.5 mb-0'>
                {t('BRIE_FORM.AMERICAN_DOLAR')}
              </label>
            </div>
          </div>
        </div>
        <SelectField
          className='w-full mt-4'
          name='project_size'
          label={`${t('BRIE_FORM.BUDGET')}`}
          labelClassName='text-gray-300'
          variant='default'
          inputProps={{ className: 'w-full' }}
          selectOptions={budgetType.map((budget) => {
            return { value: budget.value, label: budget.label }
          })}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={form}
        />
        <div>
          <SelectField
            className='w-full mt-4'
            name='selected_concept'
            label={`${t('BRIE_FORM.CONCEPTS')}`}
            labelClassName='text-gray-300'
            variant='default'
            inputProps={{ className: 'w-full' }}
            selectOptions={conceptList.map((concept) => {
              return { value: JSON.stringify(concept), label: concept.name }
            })}
            form={form}
          />
          <div className='overflow-auto pb-2'>
            <div className='flex flex-wrap mt-1'>
              {(form.watch('concepts_list') ?? []).map((concept: IConcept) => {
                return (
                  <span
                    key={concept._id}
                    className={`microcopy flex items-center rounded-2xl px-2 py-0 mr-2 mt-2 border border-black text-black`}
                  >
                    {concept.name}
                    <CloseIcon
                      fontSize='small'
                      className='text-gray ml-1 cursor-pointer'
                      onClick={() => removeConcept(concept)}
                    />
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='2xl:col-span-9 lg:col-span-8 col-span-12'>
        <InputField
          className='w-full mt-4'
          name='title'
          label={`${t('BRIE_FORM.TITLE')}`}
          inputProps={{ className: 'w-full' }}
          labelClassName='text-gray-300'
          variant='default'
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={form}
        />
        <InputField
          className='w-full mt-4'
          name='description'
          label={`${t('BRIE_FORM.INP_DECRYPT.LABEL')}`}
          textareaProps={{ className: 'w-full', rows: 5, placeholder: `${t('BRIE_FORM.INP_DECRYPT.PLACEHOLDER')}` }}
          labelClassName='text-gray-300'
          variant='default'
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={form}
          textarea
        />
        <div>
          <p className='microcopy mt-4 text-gray-300'>{t('BRIE_FORM.DELIVER')}</p>
          <div className='overflow-auto pb-2 border-b border-gray-300'>
            <div className='flex flex-wrap -mt-1'>
              {(form.watch('products') ?? []).map((product: IBriefProduct) => {
                return (
                  <span key={product.name} className={`microcopy rounded-2xl px-2 py-0 mr-2 mt-2 border border-black text-black`}>
                    {product.name}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
        <InputField
          className='w-full mt-4'
          name='supplies'
          label={`${t('BRIE_FORM.INP_SUPPLIES.LABEL')}`}
          inputProps={{ className: 'w-full', placeholder: `${t('BRIE_FORM.INP_SUPPLIES.PLACEHOLDER')}` }}
          labelClassName='text-gray-300'
          variant='default'
          form={form}
        />
        <InputField
          className='w-full mt-4'
          name='competition_referents'
          label={`${t('BRIE_FORM.INP_COMPETITION_REF.LABEL')}`}
          inputProps={{
            className: 'w-full',
            placeholder: `${t('BRIE_FORM.INP_COMPETITION_REF.PLACEHOLDER')}`,
          }}
          labelClassName='text-gray-300'
          variant='default'
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={form}
        />
      </div>
    </div>
  )
}

export default BriefForm
