import { useQuery, useMutation } from 'react-query'
import { IPayment } from '../../models/IPayment'
import { IMutation, IResponse } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  payments: `payments`,
  paymentsFiltered: (params: string) => `payments?filters=${params}`,
  payment: (id: string) => `payments/${id}`,
}

export const usePaymentListQuery = (filters?: unknown) => {
  const endpoint = filters ? endpoints.paymentsFiltered(JSON.stringify(filters)) : endpoints.payments
  const paymentsQuery = useQuery(endpoint, () => apiClient.get<IResponse<IPayment>>({ endpoint }), {
    select: (data) => {
      return data.data
    },
  })
  useSetLoader(paymentsQuery)

  return paymentsQuery
}

export const usePaymentMutation = () => {
  return useMutation(({ body, method, id }: IMutation<IPayment>) =>
    apiClient.mutation<IPayment, IPayment>({ endpoint: endpoints.payment(id ?? ''), body, method })
  )
}
