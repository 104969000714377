import { FC } from 'react'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { IFreelancer } from '../../../models/IFreelancer'
import dayjs from 'dayjs'

const FreelancerProfilePlan: FC<IFreelancer> = (props) => {
  return (
    <div>
      <p className='microcopy text-gray-500 mt-5'>Miembro desde</p>
      <span className='body2-regular'>{dayjs(props?.createdAt ?? 0).format('DD/MM/YYYY')}</span>

      <p className='microcopy text-gray-500 mt-5'>Membresía</p>
      <span className='body2-regular'>{props?.plan?.name}</span>

      {props?.agreement_file?.name && (
        <>
          <p className='microcopy text-gray-500 mt-5'>Acuerdo</p>
          <span className='body2-regular'>
            Descargar acuerdo <FiIcons name='download' className='ml-1' />
          </span>
        </>
      )}
    </div>
  )
}

export default FreelancerProfilePlan
