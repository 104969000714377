import { FC, useState } from 'react'

import ToolbarApp from './ToolbarApp'
import DrawerApp from './DrawerApp'
import CpanelRouter from '../../appRouter/CpanelRouter'

import { Box } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

const CpanelLayout: FC = () => {
  const [open, setOpen] = useState(true)

  const componentsProps = {
    open: open,
    setOpen: setOpen,
  }

  return (
    <div className='flex'>
      <CssBaseline />

      <ToolbarApp {...componentsProps} />
      <DrawerApp {...componentsProps} />

      <Box height='calc(100vh - 64px)' width='100%' className={`pl-4 bg-light-gray mt-16`}>
        <Box height='100%' className='p-8 bg-white'>
          <Box overflow='auto' height='100%' className='px-8 py-12'>
            <CpanelRouter />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CpanelLayout
