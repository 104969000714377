import { makeStyles } from '@material-ui/core'
import { PropsWithChildren, MutableRefObject } from 'react'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'

type TSectionStructure = {
  className?: string
  id?: string
  ref?: MutableRefObject<HTMLDivElement>
}

const useStyles = makeStyles(() => ({
  paddingY: {
    paddingTop: 96,
    paddingBottom: 96,
  },
  paddingX: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  '@media (max-width: 1024px)': {
    paddingY: {
      paddingTop: 64,
      paddingBottom: 64,
    },
    paddingX: {
      paddingLeft: 48,
      paddingRight: 48,
    },
  },
  '@media (max-width: 430px)': {
    paddingX: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
}))

const SectionStructure: React.FC<TSectionStructure> = (props: PropsWithChildren<any>) => {
  const { view } = useWebSiteLayout()
  const classes = useStyles()

  return (
    <div className={`${classes.paddingX} ${props.className} ${classes.paddingY} ${view.textColor}`} id={props.id} ref={props.ref}>
      {props.children}
    </div>
  )
}

export default SectionStructure
