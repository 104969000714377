import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import routes from '../../constants/routes'

import Categories from '../../components/Cpanel/Assets/Categories'
import Tabs from '../../assets/UIkit/Tabs'
import Plans from '../../components/Cpanel/Assets/Plans'
import Skills from '../../components/Cpanel/Assets/Skills'
import PaymentMethods from '../../components/Cpanel/Assets/PaymentMethods'
import useSwitchOnOff from '../../customHooks/useSwitchOnOff'
import Products from '../../components/Cpanel/Assets/Products'
import Concepts from '../../components/Cpanel/Assets/Concepts'

const Assets: React.FC = () => {
  const history = useHistory()
  const pathname = useLocation().pathname
  const switchOnOff = useSwitchOnOff()
  const tabs = [
    {
      id: 1,
      text: `Categorías`,
      isSelected: true,
      action: () => history.push(routes.cpanel.assets.categories),
    },
    {
      id: 2,
      text: `Habilidades`,
      action: () => history.push(routes.cpanel.assets.skills),
    },
    {
      id: 3,
      text: `Planes`,
      action: () => history.push(routes.cpanel.assets.plans),
    },
    {
      id: 4,
      text: `Metodos de pago`,
      action: () => history.push(routes.cpanel.assets.payment_methods),
    },
    {
      id: 5,
      text: `Productos`,
      action: () => history.push(routes.cpanel.assets.products),
    },
    {
      id: 6,
      text: `Conceptos`,
      action: () => history.push(routes.cpanel.assets.concepts),
    },
  ]

  useEffect(() => {
    switchOnOff.setSwitchState(false)
  }, [pathname])

  return (
    <>
      <div className='flex justify-between items-center'>
        <Tabs tabs={tabs} />
        <div className='total-center flex-col w-32'>
          <p className='microcopy text-center'>¿Eliminar assets?</p>
          {switchOnOff.render()}
        </div>
      </div>

      <Switch>
        <Route path={routes.cpanel.assets.skills}>
          <Skills mustDelete={switchOnOff.switchState} />
        </Route>
        <Route path={routes.cpanel.assets.plans}>
          <Plans mustDelete={switchOnOff.switchState} />
        </Route>
        <Route path={routes.cpanel.assets.payment_methods}>
          <PaymentMethods mustDelete={switchOnOff.switchState} />
        </Route>
        <Route path={routes.cpanel.assets.products}>
          <Products mustDelete={switchOnOff.switchState} />
        </Route>
        <Route path={routes.cpanel.assets.concepts}>
          <Concepts mustDelete={switchOnOff.switchState} />
        </Route>
        <Route exact={true}>
          <Categories mustDelete={switchOnOff.switchState} />
        </Route>
      </Switch>
    </>
  )
}

export default Assets
