import { FC, useEffect, useMemo } from 'react'
import { Link, NavLink, Route, Switch, useParams } from 'react-router-dom'
import { useOneProjectQuery } from '../../../customHooks/request/projectsQuery'
import TitleWithUnderline from '../../../shared/TitleWithUnderline/TitleWithUnderline'
import ProjectActivity from '../../../shared/ProjectUserProfile/ProjectActivity'
import { Box, useMediaQuery } from '@material-ui/core'
import FiButton from '../../../assets/UIkit/FiButton'
import { ProjectDetailLayout } from '../../../shared/ProjectUserProfile/ProjectDetailLayout'
import CandidateWorkPlan from '../../../shared/ProjectUserProfile/CandidateWorkPlan'
import ProjectPayments from '../../../shared/ProjectUserProfile/ProjectPayments'
import ProjectFeedback from '../../../shared/ProjectUserProfile/ProjectFeedback/ProjectFeedback'
import routes from '../../../constants/routes'
import { projectDetailColumns } from '../../../utils/helpers'
import BriefDrawer from '../../../shared/ProjectUserProfile/Brief/BriefDrawer'
import useWorkPlanVersion from '../../../customHooks/useWorkPlanVersion'
import scrollTop from '../../../assets/images/web/scroll-top.svg'
import useUser from '../../../customHooks/useUser'

const freelancerProjectDetail: FC = () => {
  const params = useParams<{ projectId: string }>()
  const isMobile = useMediaQuery('(max-width: 430px)')
  const isDesktop = useMediaQuery('(min-width: 825px)')
  const { data: projectQuery, refetch: refetchProject } = useOneProjectQuery(params.projectId)
  const selectedWorkPlanVersion = useWorkPlanVersion(projectQuery?.staff[0].work_plan)
  const { setIsFinishProjectButton } = useUser()

  const goBackPath = useMemo(() => {
    if (!projectQuery) return ''

    return ['cancelled', 'finished'].includes(projectQuery.project_status)
      ? routes.freelancerUserProfile.projects.historical
      : routes.freelancerUserProfile.projects.list
  }, [projectQuery])

  const hasLastPayment = () => {
    if (!projectQuery || !selectedWorkPlanVersion) return false

    const paymentsAmount = projectQuery.payments
      .filter((payment) => payment.entity === 'freelancer')
      .reduce((acum, payment) => {
        return (acum = acum + payment.amount)
      }, 0)

    return paymentsAmount >= selectedWorkPlanVersion.price.after_fee
  }

  useEffect(() => {
    setIsFinishProjectButton(true)
  }, [])

  if (!projectQuery) return null

  const briefProps = {
    ...projectQuery.brief,
    company_size: projectQuery.client.company_size,
    country: projectQuery.client.address.country,
  }
  return (
    <div>
      <Link to={goBackPath}>
        <TitleWithUnderline title={projectQuery.brief.title} />
      </Link>
      <div className='flex justify-end'>
        <a href='mailto:contacto@findie.cl'>
          <FiButton variant='outlined'>
            <p className='buttontext1-medium-fixed text-blue'>Contáctanos</p>
          </FiButton>
        </a>
      </div>

      <Box className='md:mt-12 mt-6' display='grid' gridTemplateColumns='1fr 70px' alignItems='center'>
        <Box display='grid' gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)} className='gap-8' alignItems='center'>
          <div className='border-t border-black pt-2 md:block hidden'>
            <p className='subtitle6-regular text-black'>Cliente</p>
          </div>

          {projectQuery?.staff[0]?.freelancer?._id && (
            <div className='flex gap-12 pt-2 slider hide-scroll-bar'>
              <div className='slider-item'>
                <NavLink
                  to={`${routes.freelancerUserProfile.projects.detail}/${params.projectId}/actividad`}
                  className='subtitle6-regular text-black-300'
                  activeClassName='border-b border-black'
                >
                  Actividad
                </NavLink>
              </div>
              <div className='slider-item'>
                <NavLink
                  to={`${routes.freelancerUserProfile.projects.detail}/${params.projectId}/acuerdo`}
                  className='subtitle6-regular text-black-300'
                  activeClassName='border-b border-black'
                >
                  Acuerdo
                </NavLink>
              </div>
              <div className='slider-item'>
                <NavLink
                  to={`${routes.freelancerUserProfile.projects.detail}/${params.projectId}/pagos`}
                  className='subtitle6-regular text-black-300'
                  activeClassName='border-b border-black'
                >
                  Pagos
                </NavLink>
              </div>
              <div className='slider-item'>
                {hasLastPayment() && (
                  <NavLink
                    to={`${routes.freelancerUserProfile.projects.detail}/${params.projectId}/feedback`}
                    className='subtitle6-regular text-black-300 mr-6'
                    activeClassName='border-b border-black'
                  >
                    Feedback
                  </NavLink>
                )}
              </div>
            </div>
          )}
        </Box>
        <BriefDrawer {...briefProps}>
          <div className='flex gap-2 items-center cursor-pointer '>
            <img className={`w-12 rotated-right`} src={scrollTop} alt='brief' />
            <p className='subtitle6-regular text-black mt-px'>Brief</p>
          </div>
        </BriefDrawer>
      </Box>

      <Switch>
        <ProjectDetailLayout gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)} client={projectQuery.client}>
          <Route path={`${routes.freelancerUserProfile.projects.detail}/:projectId/feedback`}>
            {hasLastPayment() && <ProjectFeedback entity='freelancer' project={projectQuery} refetchProject={refetchProject} />}
          </Route>
          <Route path={`${routes.freelancerUserProfile.projects.detail}/:projectId/pagos`}>
            <ProjectPayments paymentEntity='freelancer' {...projectQuery} />
          </Route>
          <Route path={`${routes.freelancerUserProfile.projects.detail}/:projectId/acuerdo`}>
            <div className='shadow-md md:px-6 px-2 pt-4 pb-10'>
              <CandidateWorkPlan workPlan={projectQuery.staff[0].work_plan} withoutPrice />
            </div>
          </Route>
          <Route path={`${routes.freelancerUserProfile.projects.detail}/:projectId/actividad`}>
            <ProjectActivity entity='freelancer' {...projectQuery} />
          </Route>
        </ProjectDetailLayout>
      </Switch>
    </div>
  )
}

export default freelancerProjectDetail
