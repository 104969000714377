import { Route, Switch } from 'react-router-dom'
import routes from '../constants/routes'

import HomePage from '../views/WebSite/HomePage'
import AboutUs from '../views/WebSite/AboutUs'
import OurFreelancers from '../views/WebSite/OurFreelancers'
import Faqs from '../views/WebSite/Faqs'
import LegalAspects from '../views/WebSite/LegalAspects'
import FreelancersProfiles from '../views/WebSite/FreelancersProfiles'
import FreelancerProfile from '../views/WebSite/FreelancerProfile'
import Error404 from '../pages/error404'
import WhyFindie from '../views/WebSite/WhyFindie'
// import HowWorks from '../views/WebSite/HowWorks'
// import OurClients from '../views/WebSite/OurClients'
// import PaymentPage from '../views/WebSite/PaymentPage'
// import Calculator from '../views/WebSite/Calculator'
// import BookStore from '../views/WebSite/BookStore'

const Redirect = () => {
  window.location.href = 'https://findie.cl'
  return null
}

function WebSiteRouter() {
  return (
    <Switch>
      {/* <Route exact path={routes.web_site.bookStore} component={BookStore} /> */}
      {/* <Route exact path={routes.web_site.calculator} component={Calculator} /> */}
      {/* <Route exact path={`${routes.web_site.freelancer_profile}/:id`} component={FreelancerProfile} /> */}
      <Route exact path={routes.web_site.terms_conditions} component={LegalAspects} />
      <Route exact path={routes.web_site.faqs} component={Faqs} />
      <Route exact path={`${routes.web_site.freelancers_profiles}`} component={FreelancersProfiles} />
      {/* <Route exact path={routes.web_site.our_clients} component={OurClients} /> */}
      <Route exact path={routes.web_site.our_freelancers} component={OurFreelancers} />
      <Route exact path={routes.web_site.why_findie} component={WhyFindie} />
      {/* <Route exact path={routes.web_site.how_works} component={HowWorks} />  */}
      <Route exact path={routes.web_site.about_us} component={AboutUs} />
      <Route exact path={routes.web_site.home} component={Redirect} />
      <Route path={routes.page_not_fount} component={Error404} />
    </Switch>
  )
}

export default WebSiteRouter
