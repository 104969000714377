import { useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import endpoints from '../../constants/endpoints'
import { apiClient } from '../../utils/ApiClient'
import { IPaginatedResponse } from '../../models/IRequest'
import { useSetLoader } from '../useSetLoader'
import { IPaymentMethod } from '../../models/IPaymentMethod'

// export const useProjectFeesQuery = () => {
//   const projectFeesQuery = useQuery(`all_${endpoints.paymentMethods}`, apiClient.getPaymentMethods, {
//     select: (data) => data.data,
//   })
//   return projectFeesQuery
// }

const endpoints2 = {
  paymentMethods: (params: string) => `paymentMethods${params}`,
}

export const useProjectFeesQuery = (params: { filters?: unknown; enabled?: boolean } = {}) => {
  const { filters, enabled = true } = params
  const endpoint = endpoints2.paymentMethods(filters ? `?filters=${JSON.stringify(filters)}` : '')
  const projectsQuery = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IPaymentMethod>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled,
  })
  useSetLoader(projectsQuery)
  return projectsQuery
}

export const useProjectFeeQuery = (id: string) => {
  const projectFeeQuery = useQuery(`one_${endpoints.paymentMethods}`, () => apiClient.getPaymentMethod(id))
  return projectFeeQuery
}

export const usePostProjectFeeMutation = () => {
  const mutation = useMutation(apiClient.postPaymentMethod)
  useRefreshProjectFees(mutation)
  return mutation
}

export const usePutProjectFeeMutation = () => {
  const mutation = useMutation(apiClient.putPaymentMethod)
  useRefreshProjectFees(mutation)
  return mutation
}

export const useDeleteProjectFeeMutation = () => {
  const mutation = useMutation(apiClient.deletePaymentMethod)
  useRefreshProjectFees(mutation)
  return mutation
}

export const useRefreshProjectFees = (mutation: any) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    mutation.isSuccess && queryClient.refetchQueries(`all_${endpoints.paymentMethods}`)
  }, [mutation.isSuccess])
}
