import { useState } from 'react'

export type TToggleActions<D, A> = {
  dialogData?: D
  dialogAction?: A
  defaultData?: D
  defaultAction?: A
}

const useDialog = <D, A = void>({ defaultData = undefined, defaultAction = undefined }: TToggleActions<D, A> = {}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState<D | undefined>(defaultData)
  const [action, setAction] = useState<A | undefined>(defaultAction)

  const toggle = ({ dialogData = undefined, dialogAction = undefined }: TToggleActions<D, A> = {}) => {
    setIsOpen(!isOpen)
    if (dialogData !== data) setData(dialogData)
    if (dialogAction !== action) setAction(dialogAction)
  }

  const updateAction = (dialogAction?: A) => {
    setAction(dialogAction ?? undefined)
  }

  const updateData = (dialogData?: D) => {
    setData(dialogData ?? undefined)
  }

  return { isOpen, data, action, toggle, updateAction, updateData }
}

export default useDialog
