import { FC, useMemo, useState, useEffect } from 'react'
import Tabs, { TTab } from '../../../assets/UIkit/Tabs'
import { IStaff } from '../../../models/IStaff'
import { TProjectSections } from '../Project'
import { Divider, List, ListItem, ListItemSecondaryAction, IconButton, Box } from '@material-ui/core'
import FiButton from '../../../assets/UIkit/FiButton'
import AppDialog from '../../../assets/UIkit/AppDialog'
import { useOfferListQuery, useOffersMutation, useOneOfferQuery } from '../../../customHooks/request/offersQuery'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { IOffer } from '../../../models/IOffer'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import ProjectCandidatesSelection from './ProjectCandidatesSelection'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import useDialog from '../../../customHooks/useDialog'
import CandidateWorkPlan from './CandidateWorkPlan'
import UserProfileDialog from '../../UserProfileDialog/UserProfileDialog'
import DoneAllIcon from '@material-ui/icons/DoneAll'

const ProjectCandidates: FC<TProjectSections> = (props) => {
  const { data: project, refetch: refetchProject } = props.project
  const staffQuery = project?.staff
  const [staff, setStaff] = useState<IStaff>()
  const {
    data: offerQuery,
    refetch: refetchOffer,
    isLoading,
  } = useOfferListQuery({ filters: { project: props.projectId }, enabled: false })
  const updateOffer = useOffersMutation()
  useRequestAlert(updateOffer)
  const dialog = useDialog<IOffer, 'candidates' | 'work_plan'>()
  const oneOfferQuery = useOneOfferQuery({ id: dialog.data?._id ?? '', enabled: Boolean(dialog.data?._id) })

  const tabs: TTab[] = useMemo(() => {
    if (!staffQuery) return []

    setStaff(staffQuery[0])
    return staffQuery.map((staff: IStaff, index: number) => {
      return { id: index + 1, text: staff.category.name, isSelected: index === 0, action: () => setStaff(staff) }
    })
  }, [staffQuery])

  const markAsFavorite = (offer: IOffer) => {
    updateOffer.mutate(
      { body: { is_favorite: !offer.is_favorite }, id: offer._id, method: 'PUT' },
      { onSuccess: () => refetchOffer() }
    )
  }

  const openWorkPlan = async (offer: IOffer) => {
    dialog.toggle({ dialogAction: 'work_plan', dialogData: offer })
  }

  useEffect(() => {
    if (!staff) return
    refetchOffer()
  }, [staff])

  if (project && project.project_status !== 'not_approved') {
    return (
      <UserProfileDialog>
        <h4 className='text-blue'>Los candidatos del proyecto ya fueron seleccionados</h4>
      </UserProfileDialog>
    )
  }
  if (staffQuery && staffQuery.length === 0) {
    return (
      <UserProfileDialog>
        <h4 className='text-blue'>Selecciona los candidatos de cada categoría del proyecto</h4>
        <h4 className='text-center mt-4 text-blue'>Ups, Aun no asignas categorías al proyecto.</h4>
        <h4 className='text-center text-blue'>Puedes hacerlo en la pestaña "Proyecto".</h4>
      </UserProfileDialog>
    )
  }
  return (
    <>
      <h5 className='mt-4'>Selecciona los candidatos de cada categoría del proyecto</h5>
      <Divider className='my-2' />
      <div className='flex justify-between items-center'>
        <Tabs tabs={tabs} variant='default' />
        <div>
          {staff && !staff?.freelancer?._id && (
            <FiButton className='w-48' onClick={() => dialog.toggle({ dialogAction: 'candidates' })}>
              Agregar candidatos
            </FiButton>
          )}
        </div>
      </div>
      {!isLoading && (
        <>
          {!offerQuery || offerQuery.length === 0 ? (
            <h4 className='mt-20 text-center'>
              No hay candidatos <br /> seleccionados...
            </h4>
          ) : (
            <List className='mt-4'>
              {offerQuery
                .filter((offer) => offer.staff._id === staff?._id)
                .map((offer: IOffer) => {
                  return (
                    <ListItem
                      key={offer._id}
                      className={
                        offer.status === 'awarded'
                          ? 'border border-blue-700 rounded-md mt-2 scale-50'
                          : 'border-b border-gray-300'
                      }
                    >
                      <Box display='grid' gridTemplateColumns='3.5rem 1fr' alignItems='center'>
                        <img className='mt-2' style={{ width: 50, height: 49 }} src={offer.freelancer.avatar.url} />
                        <div className='mt-1'>
                          <p className='subtitle5-medium'>{offer.freelancer.name}</p>
                          <p className='body2-regular -mt-1'>{offer.freelancer.college_degree}</p>
                        </div>
                      </Box>

                      <ListItemSecondaryAction>
                        {['postulated', 'available'].includes(offer.status) && (
                          <IconButton disabled={props.isDisabledFields} edge='end' onClick={() => markAsFavorite(offer)}>
                            <FiIcons name='star' className={`${offer.is_favorite && 'svg-orange'} mb-0.1`} />
                          </IconButton>
                        )}
                        {offer?.work_plan?._id && <DoneAllIcon className='text-soft-green ml-4' />}
                        {offer?.is_available_to_client && offer?.work_plan?.versions.length > 0 && (
                          <IconButton disabled={props.isDisabledFields} edge='end' onClick={() => openWorkPlan(offer)}>
                            <WorkOutlineIcon className='text-blue' />
                          </IconButton>
                        )}
                        {offer.status === 'rejected' && <span className='text-red ml-2'>Rechazado</span>}
                        {offer.status === 'declined' && <span className='text-red ml-2'>Declinado</span>}
                        {offer.status === 'awarded' && <span className='text-blue-700 ml-2'>Seleccionado</span>}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </List>
          )}
        </>
      )}

      <AppDialog
        open={dialog.isOpen}
        title={dialog.action === 'candidates' ? 'Selecciona a los candidatos' : 'Plan de trabajo'}
        maxWidth='xl'
      >
        {dialog.action === 'candidates' && (
          <ProjectCandidatesSelection
            isDialogOpen={dialog.isOpen}
            currentStaff={staff}
            offerQuery={offerQuery ?? []}
            refetchOffer={refetchOffer}
            refetchProject={refetchProject}
            setIsDialogOpen={() => dialog.toggle()}
            {...props}
          />
        )}
        {dialog.action === 'work_plan' && <CandidateWorkPlan toggle={dialog.toggle} oneOfferQuery={oneOfferQuery} />}
      </AppDialog>
    </>
  )
}

export default ProjectCandidates
