import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import FreelancerCalculator from '../../components/FreelanceUserProfile/FreelancerCalculator/FreelancerCalculator'
import routes from '../../constants/routes'
import useUser from '../../customHooks/useUser'
import { useTranslation } from 'react-i18next'

export const RightSideBar: FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  const helpPath = () => {
    if (!user) return ''
    return user.user_type === 'client' ? routes.clientUserProfile.help : routes.freelancerUserProfile.help
  }

  if (!user) return null
  return (
    <div className='flex-col justify-end items-end py-14 pr-8 xl:flex hidden'>
      {user.user_type === 'freelancer' && <FreelancerCalculator className='pb-14' />}
      <Link to={helpPath()}>
        <div className={`total-center flex-col cursor-pointer`}>
          <FiIcons name='hearth' />
          <p className='buttontext2-regular text-center'>{t('GLOBAL.HELP')}</p>
        </div>
      </Link>
      <p className='microcopy text-magenta text-right w-full'>{t('RIGHT_SIDE_BAR.BETA_VERSION')}</p>
    </div>
  )
}
