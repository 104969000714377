import { FC, useMemo, useState } from 'react'
import Tabs, { TTab } from '../../assets/UIkit/Tabs'
import { IStaff } from '../../models/IStaff'
import { Divider } from '@material-ui/core'
import { TProjectSections } from './Project'
import { Box } from '@material-ui/core'
import CandidateWorkPlan from '../ProjectUserProfile/CandidateWorkPlan'
import { formatDecimal, workPlanPrice } from '../../utils/helpers'
import UserProfileDialog from '../UserProfileDialog/UserProfileDialog'
import FiButton from '../../assets/UIkit/FiButton'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { useProjectFeesQuery } from '../../customHooks/request/PaymentMethodsQuery'
import { useForm } from 'react-hook-form'
import useWorkPlanVersion from '../../customHooks/useWorkPlanVersion'
import { useUpdatePlatformMethod } from '../../customHooks/request/workPlanQuery'
import useRequestAlert from '../../customHooks/useRequestAlert'
import routes from '../../constants/routes'
import ClientCandidateCard from './ClientCandidateCard'

const ProjectStaff: FC<TProjectSections> = (props) => {
  const form = useForm()
  const { data: project, refetch: refetchProject } = props.project
  const selectedWorkPlanVersion = useWorkPlanVersion(project?.staff[0]?.work_plan)
  const updatePlatformMethod = useUpdatePlatformMethod()
  useRequestAlert(updatePlatformMethod)

  const { data: paymentMethodsQuery } = useProjectFeesQuery()

  const [staff, setStaff] = useState<IStaff>()

  const tabs: TTab[] = useMemo(() => {
    if (!project) return []

    setStaff(project.staff[0])
    return project.staff.map((staff: IStaff, index: number) => {
      return { id: index + 1, text: staff.category.name, isSelected: index === 0, action: () => setStaff(staff) }
    })
  }, [project])

  const updateWorkPlan = () => {
    let body = undefined

    if (form.watch('payment_method')) body = { payment_method: JSON.parse(form.getValues('payment_method')) }
    updatePlatformMethod.mutate({ body, id: project?.staff[0]?.work_plan?._id ?? '' }, { onSuccess: () => refetchProject() })
  }

  const paymentMethods = useMemo(() => {
    if (!paymentMethodsQuery) return []

    return paymentMethodsQuery.data.map((method) => ({ method: method.name, fee: method.fee }))
  }, [paymentMethodsQuery])

  if (!staff?.freelancer?._id || !project) {
    return (
      <UserProfileDialog>
        <h4 className='text-blue'>Los candidatos de proyecto deben ser seleccionados primero</h4>
        <h4 className='text-center mt-4 text-blue'>
          Puedes hacerlo en la pestaña "Candidatos" o el cliente desde su User Profile.
        </h4>
      </UserProfileDialog>
    )
  }
  return (
    <>
      <h5 className='mt-4'>Selecciona los candidatos de cada categoría del proyecto</h5>
      <Divider className='my-2' />
      <Tabs tabs={tabs} variant='default' />

      <Box display='grid' gridTemplateColumns='250px 1fr' className='mt-6 gap-8'>
        <div>
          <ClientCandidateCard
            goToProfile={() => window.open(`${routes.cpanel.freelancers.findie}/${staff.freelancer._id}`)}
            {...staff.freelancer}
            avatarImg={staff.freelancer.avatar.url}
            withFlagIcon
          />
        </div>

        <div>
          <div className='grid grid-cols-12 gap-2'>
            <div className='col-span-9 border border-blue-700 rounded-lg mb-4 p-4'>
              <p className='buttontext4-medium'>
                Plan: <span className='text-blue'>{staff.freelancer.plan.name},</span> tiene un fee de {staff.freelancer.plan.fee}
                %
              </p>
              <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' className='gap-4 mt-2'>
                <div>
                  <p className='microcopy'>Findie paga</p>
                  <p className='body2-regular border-b border-gray-300'>{formatDecimal(workPlanPrice(staff)?.after_fee ?? 0)}</p>
                </div>
                {!project.with_currency && (
                  <div>
                    <p className='microcopy'>Impuesto</p>
                    <p className='body2-regular border-b border-gray-300'>{formatDecimal(workPlanPrice(staff)?.sii_tax ?? 0)}</p>
                  </div>
                )}
                <div>
                  <p className='microcopy'>Total</p>
                  <p className='body2-regular border-b border-gray-300'>{formatDecimal(workPlanPrice(staff)?.total ?? 0)}</p>
                </div>
              </Box>
            </div>
            <form className='col-span-3 flex flex-col gap-2 items-center' onSubmit={form.handleSubmit(updateWorkPlan)}>
              {selectedWorkPlanVersion?.price?.payment_method?.method ? (
                <p className='microcopy text-blue text-center'>
                  Se paga por {selectedWorkPlanVersion?.price?.payment_method?.method}
                </p>
              ) : (
                <SelectField
                  name={`payment_method`}
                  labelClassName='text-red'
                  className='w-full'
                  label='Plataforma de pago'
                  inputProps={{ className: 'w-full' }}
                  selectOptions={paymentMethods.map((payment) => {
                    return { value: JSON.stringify(payment), label: payment.method }
                  })}
                  options={{ required: 'Este campo es requerido' }}
                  form={form}
                />
              )}
              <FiButton type='submit'>Recalcular honorarios</FiButton>
            </form>
          </div>
          <CandidateWorkPlan workPlan={staff.work_plan} withoutPrice />
        </div>
      </Box>
    </>
  )
}

export default ProjectStaff
