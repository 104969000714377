import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useClientQuery } from '../../../customHooks/request/clientsQuery'
import useSwitchOnOff from '../../../customHooks/useSwitchOnOff'
import Project from '../../../shared/Project/Project'

const AllProjectDetail: FC = () => {
  const param = useParams<{ clientId: string }>()
  const switchOnOff = useSwitchOnOff()
  const client = useClientQuery(param.clientId)

  return (
    <div>
      <h3>
        {client.data?.name} {client.data?.lastName}
      </h3>

      <Project />
    </div>
  )
}

export default AllProjectDetail
