import { IBrief } from '../../models/IBrief'
import { TClientProps } from './Brief/BriefDrawer'
import Pillbox from '../Pillbox/Pillbox'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { useTranslation } from 'react-i18next'

type TOfferBrief = {
  brief: IBrief & TClientProps
}
const OfferBrief = (props: TOfferBrief) => {
  const { brief } = props
  const { t } = useTranslation()

  const companySizeStyles = (size: string) => {
    const dispatch: Record<string, { styles: string; title: string }> = {
      'Microempresa (1 a 9 trabajadores)': { styles: 'text-white bg-magenta', title: `${t('GLOBAL.MICRO_SIZE')}` },
      'Micro-sized (1 to 9 workers)': { styles: 'text-white bg-magenta', title: `${t('GLOBAL.MICRO_SIZE')}` },
      'Pequeña (10 a 49 trabajadores)': { styles: 'text-black bg-yellow-bright', title: `${t('GLOBAL.SMALL')}` },
      'Small-sized (10 to 49 workers)': { styles: 'text-black bg-yellow-bright', title: `${t('GLOBAL.SMALL')}` },
      'Mediana (50 a 199 trabajadores)': { styles: 'text-white bg-blue', title: `${t('GLOBAL.MEDIUM')}` },
      'Medium (50 to 199 workers)': { styles: 'text-white bg-blue', title: `${t('GLOBAL.MEDIUM')}` },
      'Gran empresa (200 o más)': { styles: 'text-white bg-black', title: `${t('GLOBAL.LARGE_COMP')}` },
      'Large enterprise (200 or more)': { styles: 'text-white bg-black', title: `${t('GLOBAL.LARGE_COMP')}` },
    }
    return dispatch[size] ? dispatch[size] : { styles: 'text-transparent bg-transparent', title: '' }
  }

  return (
    <div className='grid grid-cols-12 xl:gap-8 gap-4 bg-ice-blue md:p-6 p-4'>
      <div className='xl:col-span-4 col-span-12'>
        <div className='grid grid-cols-12 gap-x-4'>
          <p className='microcopy text-gray-400 col-span-12'> {t('BRIE_FORM.ABOUT_COMPANY')}</p>

          <div className='border-b pb-4 border-gray-300 lg:col-span-12 col-span-12'>
            <p className='body2-regular text-light-black'>
              <FiIcons className='ml-0 mr-1 mb-1' name='location' />
              {brief.country}
            </p>

            <div className='mt-6'>
              <Pillbox className={`px-12 py-1.5  ${companySizeStyles(props.brief.company_size).styles}`}>
                {companySizeStyles(props.brief.company_size).title}
              </Pillbox>
            </div>
          </div>

          <div className='lg:col-span-12 mt-3 col-span-12'>
            <p className='microcopy  text-gray-400'>{t('BRIE_FORM.START-DATE')}</p>
            <p className='body2-regular text-light-black border-b border-gray-300 mt-2'>{brief.when_start}</p>
          </div>

          <div className='lg:col-span-12 mt-3 col-span-12'>
            <p className='microcopy text-gray-400 mt-0'>{t('BRIE_FORM.BUDGET')}</p>
            <p className='body2-regular text-light-black border-b border-gray-300 mt-2'>{brief.project_size}</p>
          </div>

          <div className='lg:col-span-12 mt-3 col-span-12'>
            <p className='microcopy text-gray-400'>{t('BRIE_FORM.CONCEPTS_ONLY')}</p>
            <div className='flex flex-wrap mt-2 gap-1'>
              {brief.concepts_list.map((concept) => {
                return (
                  <Pillbox key={concept.name} className={`px-6 py-0 border border-black text-light-black`}>
                    {concept.name}
                  </Pillbox>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='xl:col-span-8 col-span-12'>
        <div className='overflow-auto text-light-black border-b border-gray-300' style={{ height: 247 }}>
          <p className='microcopy text-gray-400'>{t('GLOBAL.DESCRIPTION')}</p>
          <p className='body2-regular whitespace-pre-wrap'>{brief.description}</p>
        </div>
        {brief.products.length > 0 && (
          <div>
            <p className='microcopy mt-4 text-gray-400'>{t('BRIE_FORM.DELIVER_ITEMS')}</p>
            <div className='overflow-auto'>
              <div className='flex flex-wrap'>
                {brief.products.map((product) => {
                  return (
                    <Pillbox key={product.name} className='px-2 py-0 mr-2 mt-2 border border-black text-light-black'>
                      {product.name}
                    </Pillbox>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {brief.supplies && (
          <>
            <p className='microcopy text-gray-400 mt-5'>{t('BRIE_FORM.AVAILABLE_MATERIALS')}</p>
            <p className='body2-regular text-light-black border-b border-gray-300'>{brief.supplies}</p>
          </>
        )}
        {brief.competition_referents && (
          <>
            <p className='microcopy text-gray-400 mt-5'>{t('BRIE_FORM.REFERENCES')}</p>
            <p className='body2-regular text-light-black border-b border-gray-300'>{brief.competition_referents}</p>
          </>
        )}
      </div>
    </div>
  )
}

export default OfferBrief
