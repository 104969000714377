import { FC, useMemo } from 'react'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import { useUpdateProject } from '../../../customHooks/request/projectsQuery'
import { useStaffMutation } from '../../../customHooks/request/staffQuery'
import { IProject } from '../../../models/IProject'
import { IFeedbackDetail } from '../../../models/IStaff'
import FeedbackScore from './FeedbackScore'

type TProjectFeedback = {
  project: IProject
  entity: 'freelancer' | 'client'
  readOnly?: boolean
  refetchProject?: (
    options?: (RefetchOptions & RefetchQueryFilters<IProject>) | undefined
  ) => Promise<QueryObserverResult<IProject, unknown>>
}

const ProjectFeedback: FC<TProjectFeedback> = (props) => {
  const defaultFeedback =
    props.entity === 'freelancer' ? props.project.staff[0].freelancer_feedback : props.project.staff[0].client_feedback
  const updateStaff = useStaffMutation()
  const updateProject = useUpdateProject()
  const isProjectFinished = ['finished', 'cancelled'].includes(props.project.project_status)
  const readOnly = props.readOnly || isProjectFinished
  const feedbackList = useMemo(() => defaultFeedback.list, [props.project])

  const isFeedbackIncomplete = feedbackList.some((feedback) => feedback.score === 0)

  const sendFeedbackScore = (currentFeedback: IFeedbackDetail) => {
    if (readOnly) return

    const newFeedbackList = feedbackList.map((feedback) => {
      if (feedback._id === currentFeedback._id) return currentFeedback
      return feedback
    })

    const body =
      props.entity === 'freelancer'
        ? { freelancer_feedback: { ...defaultFeedback, list: newFeedbackList } }
        : { client_feedback: { ...defaultFeedback, list: newFeedbackList } }

    updateStaff.mutate(
      { body, method: 'PUT', id: props.project.staff[0]._id },
      { onSuccess: () => props?.refetchProject && props.refetchProject() }
    )
  }

  const sendFeedback = () => {
    const body =
      props.entity === 'freelancer'
        ? { freelancer_feedback: { ...defaultFeedback, is_done: true } }
        : { client_feedback: { ...defaultFeedback, is_done: true } }

    updateStaff.mutate(
      { body, method: 'PUT', id: props.project.staff[0]._id },
      {
        onSuccess: async () => {
          if (props.entity === 'freelancer' && props.project.staff[0].client_feedback.is_done) {
            await updateProject.mutate({ body: { project_status: 'finished' }, id: props.project._id })
          }
          if (props.entity === 'client' && props.project.staff[0].freelancer_feedback.is_done) {
            await updateProject.mutate({ body: { project_status: 'finished' }, id: props.project._id })
          }
          props?.refetchProject && props.refetchProject()
        },
      }
    )
  }

  return (
    <>
      <div className='flex justify-end md:mt-12 mt-4'>
        {!isFeedbackIncomplete && !defaultFeedback.is_done && (
          <button className='subtitle4-medium border-b border-black' onClick={sendFeedback}>
            Enviar feedback
          </button>
        )}
        {defaultFeedback.is_done && <p className='subtitle4-medium  text-blue'> Feedback enviado</p>}
      </div>
      {feedbackList.map((feedback) => {
        return (
          <div key={feedback._id} className='grid grid-cols-12 mt-8'>
            <p className='subtitle4-regular lg:col-span-4 md:col-span-5 col-span-6'>{feedback.title}</p>
            <div className='lg:col-span-3 md:col-span-7 col-span-6'>
              <FeedbackScore
                score={feedback.score}
                readOnly={readOnly}
                onClick={(score: number) => sendFeedbackScore({ ...feedback, score })}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ProjectFeedback
