import { FC, useEffect, useState } from 'react'
import { TFreelancerBookSections } from '../../../views/FreelancerUserProfile/FreelancerBook'
import { Box, Button, Divider, IconButton, Tooltip } from '@material-ui/core'
import { IPorfolioFileDetail } from '../../../models/IFreelancer'
import useDialog from '../../../customHooks/useDialog'
import AppDialog from '../../../assets/UIkit/AppDialog'
import FileDropzone, { TFile } from '../../../shared/FileDropZone/FileDropZone'
import AddIcon from '@material-ui/icons/Add'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  useRemovePortfolioFiles,
  useUpdateDnDPortfolioFiles,
  useUpdatePortfolioFiles,
} from '../../../customHooks/request/freelancersQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

type TColumnFile = {
  column: string
  files: IPorfolioFileDetail[]
}

const FILE_ZONE_ERROR_TITLE = 'Solo se admiten archivos con la extensión jpg, jpeg, png, gif, webp.'
const LIMIT_FILES = 21

const FreelancerPorfolio: FC<TFreelancerBookSections> = (props) => {
  const { data: freelancer, refetch: refetchFreelancer, isLoading: isFreelancerLoading } = props.freelancerQuery
  const dialog = useDialog<any, 'a' | 'b' | 'c' | 'delete'>()

  const updateDnDPortfolio = useUpdateDnDPortfolioFiles()
  const updatePortfolioFiles = useUpdatePortfolioFiles()
  const removePortfolioFile = useRemovePortfolioFiles()
  useRequestAlert(updatePortfolioFiles)
  useRequestAlert(removePortfolioFile)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [portfolioColumns, setPortfolioColumns] = useState<TColumnFile[]>([])
  const [beforeDragAndDrop, setBeforeDragAndDrop] = useState<TColumnFile[]>([])

  const filesAmount = freelancer
    ? freelancer.portfolio_files.reduce((acc, file) => {
        return acc + file.files.length
      }, 0)
    : LIMIT_FILES

  const findColumn = (column: 'a' | 'b' | 'c') => {
    if (!freelancer) return []
    const files = freelancer.portfolio_files.find((portfolio) => portfolio.column === column)
    return files ? files.files : []
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result
    if (!destination) return
    if (source.index === destination.index && source.droppableId === destination.droppableId) return
    if (source.droppableId === destination.droppableId) return singleColumnDnD(source, destination)
    multipleColumnDnD(source, destination)
  }

  const singleColumnDnD = (source: any, destination: any) => {
    if (!freelancer) return

    const findFiles = portfolioColumns.find((portfolio: TColumnFile) => portfolio.column === source.droppableId)
    const filesToChange = findFiles?.files ?? []

    const list = [...filesToChange]
    const [removed] = list.splice(source.index, 1)
    list.splice(destination.index, 0, removed)

    const listEdited = list.map((file: IPorfolioFileDetail, index: number) => {
      return { ...file, position: index + 1 }
    })

    const portfolio_files = portfolioColumns.map((portfolio: TColumnFile) => {
      if (portfolio.column === source.droppableId) {
        return { ...portfolio, files: listEdited }
      }
      return portfolio
    })

    setBeforeDragAndDrop(portfolioColumns)
    setPortfolioColumns(portfolio_files)
    updateDnDPortfolio.mutate({ body: portfolio_files, id: freelancer._id }, { onError: () => afterDndErrorMutate() })
  }

  const multipleColumnDnD = (source: any, destination: any) => {
    if (!freelancer) return

    let fileToMove: unknown = {}
    const findSourceFiles = portfolioColumns.find((portfolio: TColumnFile) => portfolio.column === source.droppableId)
    const sourceFiles = findSourceFiles?.files ?? []
    const findDestinationFiles = portfolioColumns.find((portfolio: TColumnFile) => portfolio.column === destination.droppableId)
    const destinationFiles = findDestinationFiles?.files ?? []

    const sourceList = [...sourceFiles]
    const sourceFilesEdited = sourceList
      .map((file: IPorfolioFileDetail) => {
        if (file.position < source.index + 1) return file

        if (file.position === source.index + 1) {
          fileToMove = { ...file, position: 1000 }
          return { ...file, position: 0 }
        }
        return { ...file, position: file.position - 1 }
      })
      .filter((file: IPorfolioFileDetail) => file.position !== 0)

    destinationFiles.splice(destination.index, 0, fileToMove as IPorfolioFileDetail)
    const destinationFilesEdited = destinationFiles.map((file: IPorfolioFileDetail) => {
      if (file.position < destination.index + 1) return file
      if (file.position === 1000) return { ...file, position: destination.index + 1 }

      return { ...file, position: file.position + 1 }
    })

    const portfolio_files = portfolioColumns.map((portfolio: TColumnFile) => {
      if (portfolio.column === source.droppableId) return { ...portfolio, files: sourceFilesEdited }
      if (portfolio.column === destination.droppableId) return { ...portfolio, files: destinationFilesEdited }

      return portfolio
    })

    setBeforeDragAndDrop(portfolioColumns)
    setPortfolioColumns(portfolio_files)
    updateDnDPortfolio.mutate({ body: portfolio_files, id: freelancer._id }, { onError: () => afterDndErrorMutate() })
  }

  function afterDndErrorMutate() {
    setPortfolioColumns(beforeDragAndDrop)
  }

  const savePorfolioImages = async (fileList: TFile[] | TFile) => {
    if (!freelancer) return
    if (filesAmount + (fileList as TFile[]).length >= LIMIT_FILES) return setIsModalOpen(true)

    const body = { column: dialog.data, files: fileList as TFile[] }
    updatePortfolioFiles.mutate(
      { body, id: freelancer._id },
      {
        onSuccess: async () => {
          await refetchFreelancer()
          dialog.toggle()
        },
      }
    )
  }

  const removeFile = () => {
    if (!freelancer) return

    const body = { column: dialog.data.column, file: dialog.data.file }
    removePortfolioFile.mutate(
      { body, id: freelancer._id },
      {
        onSuccess: async () => {
          await refetchFreelancer()
          dialog.toggle()
        },
      }
    )
  }

  useEffect(() => {
    if (!freelancer) return setPortfolioColumns([])

    const portfolio = ['a', 'b', 'c'].map((column: string) => {
      return { column, files: findColumn(column as 'a' | 'b' | 'c') }
    })
    setPortfolioColumns(portfolio)
  }, [freelancer])

  useEffect(() => {
    return () => {
      refetchFreelancer()
    }
  }, [])

  if (isFreelancerLoading) return null
  return (
    <div>
      <p className='microcopy'>648 pixeles de ancho con altura variable • 150 ppi • PNG o GIF • Máximo 8 MB</p>

      <div className='grid grid-cols-12 gap-2 mt-4'>
        {['a', 'b', 'c'].map((column: string) => {
          return (
            <div key={column} className='col-span-4  border border-gray-700 p-2'>
              <div className='flex justify-between'>
                <p className='subtitle4-medium'>Columna {column.toUpperCase()}</p>
                {filesAmount < LIMIT_FILES && (
                  <div>
                    <IconButton className='p-0 mr-2' onClick={() => dialog.toggle({ dialogData: column })}>
                      <AddIcon className='text-black' />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className='grid grid-cols-12 gap-2'>
          {portfolioColumns.map((portfolio: TColumnFile) => {
            return (
              <Droppable key={portfolio.column} droppableId={portfolio.column} type='TASK'>
                {(provided) => (
                  <div className='col-span-4' ref={provided.innerRef} {...provided.droppableProps}>
                    {portfolio.files
                      .sort((a, b) => a.position - b.position)
                      .map((file: IPorfolioFileDetail, index: number) => {
                        return (
                          <Draggable
                            key={`${portfolio.column}-${file.position}`}
                            draggableId={`${portfolio.column}-${file.position}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className={`${file.link ? 'pt-10' : ''} mt-2`}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div className='relative'>
                                  <img src={file.url} alt={file.name} className='w-full' />
                                  {file.url !== freelancer?.portfolio_avatar && (
                                    <Tooltip title='Eliminar' placement='top-start'>
                                      <IconButton
                                        className='p-1 absolute top-1 right-1'
                                        onClick={() =>
                                          dialog.toggle({
                                            dialogData: { column: portfolio.column, file },
                                            dialogAction: 'delete',
                                          })
                                        }
                                      >
                                        <HighlightOffIcon className='text-red' />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )
          })}
        </div>
      </DragDropContext>

      <AppDialog open={dialog.isOpen} maxWidth='xl' title='Imágenes/links' noHeader>
        <Box width='50vw'>
          {dialog.action === 'delete' ? (
            <>
              <span className='subtitle4-medium mb-4 block'>
                Estas seguro que deseas eliminar el archivo: {dialog?.data?.file?.name} de la columna{' '}
                <span className='uppercase'> "{dialog?.data?.column}"</span>
              </span>

              <Divider className='mt-4' />
              <Box display='flex' justifyContent='flex-end' mt={2}>
                <Button type='reset' variant='contained' className='mr-4' onClick={() => dialog.toggle()}>
                  Cancelar
                </Button>
                <Button variant='contained' color='primary' onClick={removeFile}>
                  Aceptar
                </Button>
              </Box>
            </>
          ) : (
            <>
              <span className='subtitle4-medium mb-4 block'>
                Sube una o varias imágenes en la columna <span className='uppercase'> "{dialog?.data}"</span>.
              </span>

              {dialog.isOpen && (
                <FileDropzone
                  errorTitle={FILE_ZONE_ERROR_TITLE}
                  acceptedFileTypes='.png, .jpg, .jpeg, .gif'
                  onSubmit={(files) => savePorfolioImages(files)}
                  onClose={dialog.toggle}
                />
              )}
            </>
          )}
        </Box>
      </AppDialog>

      <AppDialog open={isModalOpen} title='Limite de archivos' noHeader>
        <span className='subtitle4-medium mb-4 block'>Has llegado al limite de 21 archivos. Borra algunos</span>
        <div className='flex justify-end mt-4 pt-2'>
          <Button variant='outlined' color='primary' type='button' onClick={() => setIsModalOpen(false)}>
            Cerrar
          </Button>
        </div>
      </AppDialog>
    </div>
  )
}

export default FreelancerPorfolio
