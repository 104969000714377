import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useTranslation } from 'react-i18next'

const HelpNewProject: FC = () => {
  const { t } = useTranslation()
  const project_Topics: any = t('CLIENT_HELP.PROJECT_TOPICS', { returnObjects: true })
  return (
    <div className='w-10/12'>
      <p className='body2-regular'>
        {t('CLIENT_HELP.2_CREATE_A_PRO')}{' '}
        <Link to={routes.clientUserProfile.projects.new} className='text-blue'>
          {t('CLIENT_HELP.CREATE_SINGLE_PRO')}{' '}
        </Link>{' '}
        {t('CLIENT_HELP.WE_EXPLAIN_POINTS')}
      </p>
      {project_Topics.map((topic: any, index: any) => {
        return (
          <div key={index} className='flex gap-2 ml-8 mt-4'>
            <p className='body2-regular'>•</p>
            <p className='body2-regular whitespace-pre-wrap'>
              <span className='body2-medium'>{topic.title} </span>
              {topic.subtitle}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default HelpNewProject
