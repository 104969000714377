import { ReactComponent as Star } from '../../images/icons/star-icon.svg'
import { ReactComponent as Skull } from '../../images/icons/skull-icon.svg'
import { ReactComponent as Suspended } from '../../images/icons/suspended-icon.svg'
import { ReactComponent as Eye } from '../../images/icons/eye-icon.svg'
import { ReactComponent as BlueEye } from '../../images/icons/blue-eye-icon.svg'
import { ReactComponent as Bookmark } from '../../images/icons/bookmark-icon.svg'
import { ReactComponent as ChevronLeft } from '../../images/icons/chevron-left-icon.svg'
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right-icon.svg'
import { ReactComponent as ChevronUp } from '../../images/icons/chevron-up-icon.svg'
import { ReactComponent as ChevronDown } from '../../images/icons/chevron-down-icon.svg'
import { ReactComponent as Add } from '../../images/icons/add-icon.svg'
import { ReactComponent as AddCircle } from '../../images/icons/add-circle-icon.svg'
import { ReactComponent as CircleAdd } from '../../images/icons/circle-add-icon.svg'
import { ReactComponent as CircleAddWhite } from '../../images/icons/circle-add-white-icon.svg'
import { ReactComponent as CircleAddBlue } from '../../images/icons/circle-add-blue-icon.svg'
import { ReactComponent as Link } from '../../images/icons/link-icon.svg'
import { ReactComponent as Edit } from '../../images/icons/edit-icon.svg'
import { ReactComponent as Location } from '../../images/icons/location-icon.svg'
import { ReactComponent as LocationWhite } from '../../images/icons/location-white-icon.svg'
import { ReactComponent as FindieTransparent } from '../../images/icons/findie-transparent-icon.svg'
import { ReactComponent as Account } from '../../images/icons/account-icon.svg'
import { ReactComponent as Home } from '../../images/icons/home-icon.svg'
import { ReactComponent as Profile } from '../../images/icons/profile-icon.svg'
import { ReactComponent as Gif } from '../../images/icons/gif-icon.svg'
import { ReactComponent as Repeat } from '../../images/icons/repeat-icon.svg'
import { ReactComponent as RepeatDisabled } from '../../images/icons/repeat-disabled-icon.svg'
import { ReactComponent as ScoreStar } from '../../images/icons/score-star-icon.svg'
import { ReactComponent as Download } from '../../images/icons/download-icon.svg'
import { ReactComponent as Heart } from '../../images/icons/heart-icon.svg'
import { ReactComponent as Calculator } from '../../images/icons/calculator-icon.svg'
import { ReactComponent as CircleClose } from '../../images/icons/circle-close-icon.svg'
import { ReactComponent as CircleCloseBlack } from '../../images/icons/circle-close-black-icon.svg'
import { ReactComponent as CircleCloseBlue } from '../../images/icons/circle-close-blue-icon.svg'
import { ReactComponent as Book } from '../../images/icons/book-icon.svg'
import { ReactComponent as BackArrow } from '../../images/icons/back-arrow-icon.svg'
import { ReactComponent as Status } from '../../images/icons/status-icon.svg'
import { ReactComponent as Help } from '../../images/icons/help-icon.svg'
import { ReactComponent as UnCheckedSquare } from '../../images/icons/unchecked-square-icon.svg'
import { ReactComponent as CheckedSquare } from '../../images/icons/checked-square-icon.svg'
import { ReactComponent as Edit2 } from '../../images/icons/edit2-icon.svg'
import { ReactComponent as Dollar } from '../../images/icons/dollar-icon.svg'
import { ReactComponent as Mountain } from '../../images/icons/mountain-icon.svg'
import { ReactComponent as BlueSkull } from '../../images/icons/blue-skull-icon.svg'
import { ReactComponent as ArrowRight } from '../../images/icons/arrow-right-icon.svg'
import { ReactComponent as Linkedin } from '../../images/icons/linkedin-icon.svg'
import { ReactComponent as Instagram } from '../../images/icons/instagram-icon.svg'
import { ReactComponent as Spotify } from '../../images/icons/spotify-icon.svg'
import { ReactComponent as Email } from '../../images/icons/email-icon.svg'
import { ReactComponent as SmallStar } from '../../images/icons/small-star-icon.svg'

import { TCustomIcons } from './FiIcons'

export const LinkedinIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Linkedin} {...props} />
}

export const InstagramIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Instagram} {...props} />
}

export const SpotifyIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Spotify} {...props} />
}

export const EmailIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Email} {...props} />
}

export const ArrowRightIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={ArrowRight} {...props} />
}

export const BlueSkullIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={BlueSkull} {...props} />
}

export const Edit2Icon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Edit2} {...props} />
}

export const DollarIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Dollar} {...props} />
}

export const MountainIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Mountain} {...props} />
}

export const StarIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Star} {...props} />
}

export const SkullIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Skull} {...props} />
}

export const SuspendedIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Suspended} {...props} />
}

export const EyeIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Eye} {...props} />
}

export const BlueEyeIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={BlueEye} {...props} />
}

export const BookmarkIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Bookmark} {...props} />
}

export const ChevronLeftIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={ChevronLeft} {...props} />
}

export const ChevronRightIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={ChevronRight} {...props} />
}

export const ChevronUpIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={ChevronUp} {...props} />
}

export const ChevronDownIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={ChevronDown} {...props} />
}

export const AddIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Add} {...props} />
}

export const AddCircleIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={AddCircle} {...props} />
}

export const CircleAddIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CircleAdd} {...props} />
}

export const CircleAddWhiteIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CircleAddWhite} {...props} />
}

export const CircleAddBlueIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CircleAddBlue} {...props} />
}

export const LinkIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Link} {...props} />
}

export const EditIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Edit} {...props} />
}

export const LocationIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Location} {...props} />
}

export const LocationWhiteIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={LocationWhite} {...props} />
}

export const AccountIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Account} {...props} />
}

export const FindieTransparentIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={FindieTransparent} {...props} />
}

export const HomeIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Home} {...props} />
}

export const ProfileIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Profile} {...props} />
}

export const GifIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Gif} {...props} />
}

export const RepeatIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Repeat} {...props} />
}

export const RepeatDisabledIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={RepeatDisabled} {...props} />
}

export const ScoreStarIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={ScoreStar} {...props} />
}

export const SmallStarIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={SmallStar} {...props} />
}

export const DownloadIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Download} {...props} />
}

export const HeartIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Heart} {...props} />
}

export const CalculatorIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Calculator} {...props} />
}

export const CircleCloseIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CircleClose} {...props} />
}

export const CircleCloseBlackIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CircleCloseBlack} {...props} />
}

export const CircleCloseBlueIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CircleCloseBlue} {...props} />
}

export const BookIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Book} {...props} />
}

export const BackArrowIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={BackArrow} {...props} />
}

export const StatusIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Status} {...props} />
}

export const HelpIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={Help} {...props} />
}

export const CheckedSquareIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={CheckedSquare} {...props} />
}

export const UnCheckedSquareIcon: React.FC<TCustomIcons> = (props) => {
  return <DefaultIcon Icon={UnCheckedSquare} {...props} />
}

const DefaultIcon: React.FC<TCustomIcons> = (props) => {
  return (
    <props.Icon
      className={`${props.className} inline-block ${!props.className?.includes('svg') && 'svg-transparent'}`}
      onClick={props.onClick}
    />
  )
}
