import { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useFreelancerProfileQuery } from '../../customHooks/request/freelancersQuery'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'
import { useSetLoader } from '../../customHooks/useSetLoader'

import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import WebFreelancerProfile from '../../components/WebSite/WebFreelancerProfile/WebFreelancerProfile'
import routes from '../../constants/routes'
import UserProfileLayout from '../../layout/UserProfile/UserProfileLayout'
import { TSidebarOptions } from '../../layout/UserProfile/ClientUserProfileLayout'
import { useTranslation } from 'react-i18next'

const FreelancerProfile: React.FC = () => {
  const { t } = useTranslation()
  const param = useParams<{ id: string }>()
  const { setView } = useWebSiteLayout()
  const freelancersQuery = useFreelancerProfileQuery(param.id)
  const sidebarOptions: TSidebarOptions[] = [
    { title: 'Home', icon: 'home', to: routes.clientUserProfile.home },
    { title: `${t('SIDE_BAR.PROJECTS')}`, icon: 'findieTransparent', to: routes.clientUserProfile.projects.index },
    { title: `${t('SIDE_BAR.CATALOGUE')}`, icon: 'book', to: routes.clientUserProfile.book },
    { title: `${t('SIDE_BAR.PROFILE')}`, icon: 'account', to: routes.clientUserProfile.profile.personalData },
  ]
  useSetLoader(freelancersQuery)

  if (freelancersQuery.isLoading) return null
  if (freelancersQuery.isSuccess && freelancersQuery.data.is_hidden) return <Redirect to='/*' />
  return (
    <UserProfileLayout sidebarOptions={sidebarOptions}>
      {freelancersQuery.isSuccess && (
        <WebFreelancerProfile
          freelancer={freelancersQuery.data}
          contactButtonLink={`${routes.web_site.client_suscription_form.index}/${freelancersQuery.data._id}`}
          asWebSite
        />
      )}
    </UserProfileLayout>
  )
}

export default FreelancerProfile
