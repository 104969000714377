import { PropsWithChildren } from 'react'

const FormStepContainer: React.FC = (props: PropsWithChildren<any>) => {
  return (
    <div className='form-content-height flex justify-center overflow-auto md:pt-32 pt-10'>
      <div className='lg:w-1/2 md:w-9/12 w-9/12'>{props.children}</div>
    </div>
  )
}

export default FormStepContainer
