import { FC } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import frame from '../../../assets/images/userProfile/frame.png'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import BlueBorderCard from '../../../shared/ProjectOptions/BlueBorderCard'
import BlueWavesCard from '../../../shared/ProjectOptions/BlueWavesCard'

const FreelancerProjectOptions: FC = () => {
  const isDesktop = useMediaQuery('(min-width:825px)')

  return (
    <Box maxWidth={isDesktop ? '620px' : '100%'} className='relative pb-8'>
      <BlueBorderCard title='Ofertas' path={routes.freelancerUserProfile.projects.offers.list} />

      <div className='grid grid-cols-12 mt-4 gap-4'>
        <BlueWavesCard
          className='md:col-span-6 col-span-12 justify-self-start'
          title='Proyectos activos'
          path={routes.freelancerUserProfile.projects.list}
        />

        <Link className='md:col-span-6 col-span-12 md:h-full h-44 pt-4' to={routes.freelancerUserProfile.projects.historical}>
          <div className='relative h-full cursor-pointer'>
            <p
              className='formtext1-regular z-10'
              style={{ position: 'absolute', top: '46%', left: '46%', transform: 'translate(-50%, -50%)' }}
            >
              Históricos
            </p>
            <img className='absolute w-full h-full z-0 ' src={frame} alt='marco' />
          </div>
        </Link>
      </div>
    </Box>
  )
}

export default FreelancerProjectOptions
