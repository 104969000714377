import { FC, useMemo, useEffect } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import SelectField from '../../../assets/UIkit/Forms/SelectField'
import { useFreelancersFindieQuery } from '../../../customHooks/request/freelancersQuery'
import useCategories from '../../../customHooks/useCategories'
import FreelancerProfileCard from '../../../shared/ProjectUserProfile/FreelancerProfileCard'
import WebFreelancerProfile from '../../WebSite/WebFreelancerProfile/WebFreelancerProfile'
import useDialog from '../../../customHooks/useDialog'
import { IFreelancer } from '../../../models/IFreelancer'
import CloseIcon from '@material-ui/icons/Close'
import routes from '../../../constants/routes'

const ClientBook: FC = () => {
  const form = useForm()
  const isTablet = useMediaQuery('(max-width: 825px)')
  const categoryId = form.watch('category')
  const { data: categoriesQuery } = useCategories()
  const { data: freelancerQuery } = useFreelancersFindieQuery({
    filters: {
      $and: [{ $or: [{ freelancer_status: 'available' }] }],
      category: categoryId,
    },
    enabled: Boolean(categoryId),
  })
  const dialog = useDialog<IFreelancer, 'list' | 'detail'>({ defaultAction: 'list' })

  const categories = useMemo(() => {
    if (!categoriesQuery) return []

    return categoriesQuery
      .filter((category) => category.is_available)
      .map((category) => ({ label: category.name, value: category._id }))
  }, [categoriesQuery])

  useEffect(() => {
    if (!categories.length) return

    form.setValue('category', categories[0].value)
  }, [categories])

  useEffect(() => {
    scrollTo(0, 0)
  }, [dialog.action])

  return (
    <Box display='grid' gridTemplateColumns={isTablet ? '1fr' : '200px 1fr'} className='mt-6 gap-8'>
      <div>
        <div className='border-t border-b border-black'>
          <SelectField
            className='w-full'
            name='category'
            variant='transparent'
            inputProps={{ className: 'w-full' }}
            selectOptions={categories}
            form={form}
          />
        </div>
      </div>
      <div>
        {dialog.action === 'list' ? (
          <div className='grid grid-cols-12 gap-6'>
            {freelancerQuery &&
              freelancerQuery.data.map((freelancer) => (
                <div
                  key={freelancer._id}
                  className='md:col-span-4 col-span-12 cursor-pointer mx-auto'
                  onClick={() => dialog.toggle({ dialogData: freelancer, dialogAction: 'detail' })}
                >
                  <FreelancerProfileCard {...freelancer} />
                </div>
              ))}
          </div>
        ) : (
          <>
            {dialog.data && (
              <div className='relative'>
                <CloseIcon
                  className='text-white top-6 right-6 cursor-pointer absolute'
                  fontSize='large'
                  onClick={() => dialog.toggle({ dialogAction: 'list' })}
                />
                <WebFreelancerProfile
                  freelancer={dialog.data as IFreelancer}
                  theme='black'
                  contactButtonLink={`${routes.clientUserProfile.projects.new}/1`}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  )
}

export default ClientBook
