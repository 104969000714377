import { Box, useMediaQuery } from '@material-ui/core'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import ClpField from '../../../assets/UIkit/Forms/ClpField'
import useUser from '../../../customHooks/useUser'
import { siiTaxesValues, taxCalculator, ticketTaxCalculator } from '../../../utils/CalculationsHelper'
import { cleanCLP, formatDecimal } from '../../../utils/helpers'

type TFreelancerPriceCalcs = {
  handleDrawer: (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
}

const FreelancerPriceCalcs: FC<TFreelancerPriceCalcs> = (props) => {
  const { freelancerProfile: freelancer } = useUser()
  const planFee = freelancer && freelancer.plan ? freelancer.plan.fee : 0
  const isMobile = useMediaQuery('(max-width: 430px)')
  const form = useForm()
  const freelancerAmount = cleanCLP(form.watch('freelancer_amount'))
  const freelancerTotal = taxCalculator(freelancerAmount, planFee).withOutTax
  const siiTax = ticketTaxCalculator(freelancerTotal, siiTaxesValues('ticket').tax, siiTaxesValues('ticket').constant).tax
  const ticketMount = ticketTaxCalculator(
    freelancerTotal,
    siiTaxesValues('ticket').tax,
    siiTaxesValues('ticket').constant
  ).withTax

  if (!freelancer) return null
  return (
    <div className='md:p-8 p-4'>
      <div className='flex md:flex-row flex-col justify-between'>
        <h5>Simulador de boleta</h5>
        <div className='md:flex hidden justify-end'>
          <button className=' border-blue py-2 px-8 mr-4' onClick={() => form.reset()}>
            <p className='buttontext2-medium text-blue'>Limpiar</p>
          </button>
          <button className=' bg-blue border-blue py-2 px-8' onClick={props.handleDrawer(false)}>
            <p className='buttontext2-medium text-white'>Ocultar</p>
          </button>
        </div>
      </div>

      <div className='grid grid-cols-12 gap-8 mt-8 w-full overflow-x-hidden'>
        <div className='lg:col-span-4 md:col-span-6 col-span-12'>
          <Box
            className='border-b border-gray-500 h-12 items-center'
            display='grid'
            gridTemplateColumns={isMobile ? '150px 1fr' : '200px 1fr'}
          >
            <p className='subtitle4-regular'>Monto a calcular</p>
            <div className='flex items-center gap-5'>
              <p className='subtitle4-regular'>$</p>
              <ClpField
                name='freelancer_amount'
                className='w-full'
                inputProps={{ className: 'bg-rose border-none text-red subtitle4-regular w-full' }}
                form={form}
              />
            </div>
          </Box>
          <Box
            className='border-b border-gray-500 h-12 pb-1 items-center mt-1'
            display='grid'
            gridTemplateColumns={isMobile ? '150px 1fr' : '200px 1fr'}
          >
            <p className='subtitle4-regular'>Retención SII</p>
            <div className='flex items-center gap-5 h-full'>
              <p className='subtitle4-regular'>$</p>
              <p className='subtitle4-regular w-full h-full flex items-center pl-4'>{formatDecimal(siiTax)}</p>
            </div>
          </Box>
          <Box
            className='border-b border-gray-500 h-12 pb-1 items-center mt-1'
            display='grid'
            gridTemplateColumns={isMobile ? '150px 1fr' : '200px 1fr'}
          >
            <p className='subtitle4-regular'>% Findie</p>
            <div className='flex items-center gap-5 h-full'>
              <p className='subtitle4-regular'>%</p>
              <p className='subtitle4-regular bg-rose w-full h-full text-red flex items-center pl-4'>{planFee}</p>
            </div>
          </Box>
        </div>
        <div className='lg:col-span-4 md:col-span-6 col-span-12'>
          <Box className='h-12 pb-1 items-center mt-1' display='grid' gridTemplateColumns={isMobile ? '150px 1fr' : '200px 1fr'}>
            <p className='subtitle4-regular'>Recibes</p>
            <div className='flex items-center gap-5 h-full'>
              <p className='subtitle4-regular'>$</p>
              <p className='subtitle4-regular bg-ice-blue w-full h-full text-blue flex items-center pl-4'>
                {formatDecimal(freelancerTotal)}
              </p>
            </div>
          </Box>
          <Box className='h-12 pb-1 items-center mt-1' display='grid' gridTemplateColumns={isMobile ? '150px 1fr' : '200px 1fr'}>
            <p className='subtitle4-regular'>Hacer boleta por</p>
            <div className='flex items-center gap-5 h-full'>
              <p className='subtitle4-regular'>$</p>
              <p className='subtitle4-regular bg-ice-blue w-full h-full text-blue flex items-center pl-4'>
                {formatDecimal(ticketMount)}
              </p>
            </div>
          </Box>
        </div>
      </div>

      <div className='md:hidden flex justify-end mt-6'>
        <button className='border-blue py-2 px-8 mr-4' onClick={() => form.reset()}>
          <p className='text-blue'>Limpiar</p>
        </button>
        <button className='bg-blue border-blue py-2 px-8' onClick={props.handleDrawer(false)}>
          <p className='text-white'>Ocultar</p>
        </button>
      </div>
    </div>
  )
}

export default FreelancerPriceCalcs
