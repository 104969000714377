import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSkillListQuery } from '../../customHooks/request/skillsQuery'
import { IFormStep } from '../../models/IFormStep'
import { ISkill } from '../../models/ISkill'
import SkillsMultiSelect from '../SkillsMultiSelect/SkillsMultiSelect'
import { useTranslation } from 'react-i18next'

const SkillsStep: React.FC<IFormStep & { skillsLimit?: number; whitOutBorder?: boolean }> = (props) => {
  const { t } = useTranslation()
  const { slide } = useParams<{ slide: string }>()
  const storage = props.storage
  const category = storage?.item?.categorySelected?.is_other_category ? undefined : storage?.item?.category
  const { data: skillsList } = useSkillListQuery({
    filters: { category },
    enabled: Boolean(storage?.item?.category),
  })
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([])

  useEffect(() => {
    if (slide !== props.currentStep) return
    if (!skillsList || !storage.item.skills) return

    const skills = storage.item.skills.reduce((acc: ISkill[], skill: string) => {
      const searchSkill = skillsList.find((currentSkill) => currentSkill._id === skill)
      return searchSkill ? [...acc, searchSkill] : acc
    }, [])

    setSelectedSkills(skills)
  }, [slide, skillsList])

  useEffect(() => {
    if (slide !== props.currentStep) return
    props.setIsButtonDisabled(Boolean(!selectedSkills.length))

    if (!selectedSkills.length) {
      storage.setItem({ ...storage.item, skills: [] })
      return
    }
    const skills = selectedSkills.map((skill: ISkill) => skill._id)
    storage.setItem({ ...storage.item, skills })
  }, [selectedSkills, slide])

  useEffect(() => {
    setSelectedSkills([])
  }, [storage.item.category])

  if (!skillsList) return null
  return (
    <div className='h-96'>
      <SkillsMultiSelect
        skills={skillsList}
        selectedSkills={selectedSkills}
        setSelectedSkills={setSelectedSkills}
        whitOutBorder={props.whitOutBorder}
        skillsLimit={props.skillsLimit}
        className='border-b-blue'
        label={
          <h4>
            {t('clientSubscriptionForm.SkillsStep.skills')}{' '}
            {props.skillsLimit && <span className='text-gray-300'>Max. {props.skillsLimit}</span>}
          </h4>
        }
      />
    </div>
  )
}

export default SkillsStep
