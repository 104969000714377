import { useEffect, useContext, useState, useMemo, FC } from 'react'
import { Link } from 'react-router-dom'
import { useFreelancersSorted } from '../../../customHooks/request/freelancersQuery'
import { IFreelancer } from '../../../models/IFreelancer'
import { CategoriesContext } from '../../../context/Cpanel/CategoriesContext'
import usePagination from '../../../customHooks/usePagination'

import { Box, Chip, List, ListItem, ListItemSecondaryAction } from '@material-ui/core'
import routes from '../../../constants/routes'

import CustomPagination from '../../../assets/UIkit/CustomPagination'
import FreelancerShortCuts from '../../../shared/FreelancerShortCuts/FreelancerShortCuts'
import Tabs, { TTab } from '../../../assets/UIkit/Tabs'
import { ICategory } from '../../../models/ICategory'
import useGroupAlphabetList from '../../../customHooks/useGroupAlphabetList'
import SelectField from '../../../assets/UIkit/Forms/SelectField'
import { ISkill } from '../../../models/ISkill'
import { useForm } from 'react-hook-form'
import { useSkillsQuery } from '../../../customHooks/request/skillsQuery'
import useQueryParams from '../../../customHooks/useQueryParams'
import { paginationLimit } from '../../../utils/configs'
import InputField from '../../../assets/UIkit/Forms/InputField'
import useDebounce from '../../../customHooks/useDebounce'

const limit = paginationLimit

const FreelancersFindieList: FC = () => {
  const form = useForm()
  const query = useQueryParams()
  const word = useDebounce(form.watch('word'), 600)
  const categoryParam = query.get('category')
  const pageParam = query.get('page')
  const pagination = usePagination(Number(['0', ''].includes(pageParam ?? '') ? '1' : pageParam))
  const { categoriesQuery } = useContext(CategoriesContext)

  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([])
  const [categoryId, setCategoryId] = useState<string | undefined>(categoryParam ?? undefined)
  const freelancersQuery = useFreelancersSorted({
    filters: {
      $and: [
        { $or: [{ freelancer_status: 'available' }, { freelancer_status: 'suspended' }] },
        {
          $or: [
            { name: word ? { $regex: word, $options: 'i' } : undefined },
            { lastName: word ? { $regex: word, $options: 'i' } : undefined },
          ],
        },
      ],
      isShortResponse: true,
      category: categoryId,
      skills: selectedSkills.map((skill: ISkill) => skill._id),
      page: pagination.page,
      limit,
    },
    enabled: Boolean(categoryId),
  })
  const freelancers = useGroupAlphabetList<IFreelancer>(freelancersQuery)
  const skillQuery = useSkillsQuery({ category: categoryId ?? undefined }, false)
  const [tabs, setTabs] = useState<TTab[]>([])
  const selectedSkill = form.watch('skills_options')

  const skillsList = useMemo(() => {
    if (!skillQuery.data) return []

    return skillQuery.data
      .filter((skill: ISkill) => {
        const findSkill = selectedSkills.some((chosenSkill: ISkill) => chosenSkill._id === skill._id)
        return !findSkill
      })
      .map((skill: ISkill) => {
        return { value: JSON.stringify(skill), label: skill.name }
      })
  }, [skillQuery.data, selectedSkills])

  const setNewSkill = () => {
    if (!selectedSkill) return

    const parsedSkill = JSON.parse(selectedSkill)
    setSelectedSkills([...selectedSkills, parsedSkill])
  }

  const removeSkill = (skillToDelete: ISkill) => {
    const removeSkill = selectedSkills.filter((skill: ISkill) => skill._id !== skillToDelete._id)
    setSelectedSkills(removeSkill)
  }

  const handlePage = (value: number) => {
    pagination.handlePage(value)
    query.set('category', categoryId ?? '')
    query.set('page', String(value))
  }

  useEffect(() => {
    if (categoriesQuery.isSuccess) {
      const categories = categoriesQuery.data
        .filter((category: ICategory) => !category.is_other_category)
        .map((category: ICategory, index: number) => {
          const tab: TTab = {
            id: index,
            text: category.name,
            isSelected: categoryParam ? categoryParam === category._id : index === 0,
            action: () => {
              pagination.handlePage(1)
              setCategoryId(category._id)
              query.set('category', category._id)
              query.set('page', '1')
              setSelectedSkills([])
              form.setValue('word', '')
            },
          }
          return tab
        })
      setTabs(categories)
      if (!categoryParam) {
        setCategoryId(categoriesQuery.data[0]?._id ?? undefined)
        query.set('category', categoriesQuery.data[0]?._id ?? '')
        query.set('page', String(pagination.page))
      }
    }
  }, [categoriesQuery.isSuccess])

  const cleanFilters = () => {
    setSelectedSkills([])
    form.setValue('word', '')
  }

  useEffect(() => {
    if (categoryId) {
      freelancersQuery.refetch()
      skillQuery.refetch()
    }
  }, [categoryId])

  useEffect(() => {
    setNewSkill()
  }, [selectedSkill])

  useEffect(() => {
    pagination.page > 0 && categoryId && freelancersQuery.refetch()
  }, [pagination.page])

  useEffect(() => {
    freelancersQuery.refetch()
  }, [word, selectedSkills])

  return (
    <>
      <h3>Freelancers Findie</h3>
      <Box display='grid' gridTemplateColumns='1fr 20px' className='mt-4'>
        {tabs.length > 0 && <Tabs tabs={tabs} />}
        <p className='col-span-1 buttontext4-regular text-blue text-right mt-2'>
          {freelancersQuery.isSuccess && `${freelancersQuery.data?.metadata?.count}`}
        </p>
      </Box>
      <div className='grid grid-cols-12 gap-4 mt-2'>
        <div className='col-span-6'>
          <InputField
            name='word'
            label='Filtra por nombre'
            inputProps={{ className: 'w-full mr-2 border border-gray-300', placeholder: 'Filtrar por nombre' }}
            options={{ required: 'Este campo es requerido' }}
            form={form}
          />
          {freelancersQuery.isSuccess && freelancersQuery.data.data.length === 0 && word && (
            <span className='subtitle3-regular text-red block'>No se encontraron resultados de la búsqueda</span>
          )}
        </div>
        <div className='col-span-6'>
          <SelectField
            name='skills_options'
            label='Filtra por habilidad'
            inputProps={{ className: 'w-full border border-gray-300' }}
            form={form}
            selectOptions={skillsList}
          />
        </div>
      </div>

      <div className='flex flex-wrap border border-300 overflow-y-auto h-14 mt-2 p-2'>
        {selectedSkills.map((skill: ISkill) => {
          return (
            <Chip
              key={skill._id}
              label={skill.name}
              color='primary'
              size='small'
              onDelete={() => removeSkill(skill)}
              className='mr-2 mt-2 buttontext2-regular'
            />
          )
        })}
      </div>
      <p className='text-red microcopy text-right cursor-pointer h-6' onClick={cleanFilters}>
        {(selectedSkills.length > 0 || word) && 'Limpiar filtros'}
      </p>

      {freelancers.map((structure, index) => {
        return (
          <div key={structure.letter}>
            {structure.list.length > 0 && (
              <>
                <p className='subtitle2-bold'>{structure.letter}</p>
                <List className='mt-2 border-t border-r border-l border-gray-300 py-0'>
                  {structure.list.map((freelancer: IFreelancer) => {
                    return (
                      <div key={freelancer._id} className='border-b border-gray-300'>
                        <ListItem button>
                          <Link to={`${routes.cpanel.freelancers.findie}/${freelancer._id}`} className='w-full'>
                            <div className='grid grid-cols-12'>
                              <span className='col-span-3 buttontext4-regular'>
                                {freelancer?.name}, {freelancer?.lastName}
                              </span>
                              <span className='col-span-5 buttontext4-regular'>{freelancer?.college_degree}</span>
                            </div>
                          </Link>
                          <ListItemSecondaryAction>
                            <FreelancerShortCuts
                              freelancer={freelancer}
                              page={pagination.page}
                              freelancersQuery={freelancersQuery}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    )
                  })}
                </List>
              </>
            )}
          </div>
        )
      })}

      {freelancersQuery.isSuccess && (
        <div className='flex justify-center'>
          <CustomPagination
            pages={freelancersQuery.data.metadata.pages}
            page={pagination.page}
            onChange={(event, value) => handlePage(value)}
            position={'center'}
            className='mt-4'
          />
        </div>
      )}
    </>
  )
}

export default FreelancersFindieList
