import { FC } from 'react'
import FiButton from '../../assets/UIkit/FiButton'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import newOffer from '../../assets/images/userProfile/new.png'
import { IFreelancer } from '../../models/IFreelancer'
import { experienceLevelDict } from '../../utils/helpers'
import Pillbox from '../Pillbox/Pillbox'

type TClientCandidateCard = {
  className?: string
  avatarClassName?: string
  isFavorite?: boolean
  avatarImg?: string
  hasNegotiation?: boolean
  withFlagIcon?: boolean
  goToProfile?: () => void
}

const ClientCandidateCard: FC<IFreelancer & TClientCandidateCard> = (props) => {
  return (
    <div className={`relative p-2 grid grid-cols-12 ${props.className ?? ''}`}>
      {props.isFavorite && <FiIcons name='star' className='svg-orange absolute top-4 md:left-40 left-32' />}
      {props.hasNegotiation && <img src={newOffer} className='absolute top-0 left-0 w-10 h-10' alt='nuevo' />}
      <img
        className={`${
          props.avatarClassName ?? ''
        } md:col-span-12 col-span-6 md:justify-self-start justify-self-center md:w-40 w-28 md:h-40 h-28 object-cover`}
        src={props.portfolio_avatar ?? props.category.image}
        alt='avatar'
      />

      <div className='md:col-span-12 col-span-6'>
        <p className='subtitle5-medium md:mt-4 mt-0'>
          {props.name} {props.lastName.slice(0, 1).toUpperCase()}. {props.withFlagIcon ? (props.is_foreign ? '🌎' : '🇨🇱') : ''}
        </p>
        <p className='body2-regular'>{props.college_degree}</p>
        <div className='flex lg:flex-row flex-col gap-4 mt-4'>
          <div>
            <Pillbox className={`py-2 ${experienceLevelDict(props.experience_level).styles}`}>
              {experienceLevelDict(props.experience_level).title}
            </Pillbox>
          </div>

          {props?.goToProfile && (
            <div className='flex items-center gap-4 -ml-4'>
              <FiButton variant='default' onClick={props.goToProfile}>
                <FiIcons name='blue_eye' className='mr-1' />
                <p className='body2-regular cursor-pointer text-blue'>VER PERFIL</p>
              </FiButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientCandidateCard
