import { FC } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

type TTitleWithUnderline = {
  title: string
  className?: string
}

const TitleWithUnderline: FC<TTitleWithUnderline> = (props) => {
  return (
    <p className={`microcopy ${props.className}`}>
      <span className='pr-4 border-b border-black pb-2 mr-4'>
        <ArrowBackIcon fontSize='small' className='-ml-1' />
      </span>
      {props.title}
    </p>
  )
}

export default TitleWithUnderline
