import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useProjectListQuery } from '../../../customHooks/request/projectsQuery'
import useUser from '../../../customHooks/useUser'
import TitleWithUnderline from '../../../shared/TitleWithUnderline/TitleWithUnderline'
import ProjectItemCard from '../../../shared/ProjectUserProfile/ProjectItemCard'
import UserProfileDialog from '../../../shared/UserProfileDialog/UserProfileDialog'

const ClientHistoricProjectList: FC = () => {
  const { user } = useUser()
  const { data: projectQuery } = useProjectListQuery({
    filters: {
      client: user?.profile_id ?? '',
      $or: [{ project_status: 'finished' }, { project_status: 'cancelled' }],
    },
    enabled: Boolean(user?.profile_id),
  })

  return (
    <div>
      <Link to={routes.clientUserProfile.projects.options}>
        <TitleWithUnderline title='Menu Proyectos' />
      </Link>

      <div className='mt-12'>
        {projectQuery && (
          <>
            {projectQuery.data.length > 0 ? (
              projectQuery.data.map((project) => {
                return <ProjectItemCard key={project._id} {...project} entity='client' isHistoric />
              })
            ) : (
              <UserProfileDialog>
                <h4 className='text-blue text-center'>¡Mmmm! aún no tienes proyectos históricos.</h4>
              </UserProfileDialog>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ClientHistoricProjectList
