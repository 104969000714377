import { TView } from '../../context/WebSite/CurrentViewContext'

type TTextSlider = {
  text: string
  view?: TView
  className?: string
}

const TextSlider = (props: TTextSlider) => {
  return (
    <div className={`animation-container ${props.className ?? ''}`}>
      <div className={`moving-text body2-medium ${props.view?.textColor ?? ''}`}>{props.text}</div>
    </div>
  )
}

export default TextSlider
