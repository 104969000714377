import { Divider, Box } from '@material-ui/core'

type TClientPrices = {
  prices: any
}

const ClientPrices = (props: TClientPrices) => {
  return (
    <div className='mt-16'>
      {props.prices.map((plan: any, index: number) => {
        return (
          <div key={plan.planName}>
            <Box className='flex md:flex-row flex-col items-center'>
              <p
                className={`rounded-full px-4 py-1 total-center text-white ws-family-medium md:w-96 w-full`}
                style={{ backgroundColor: plan.color, fontSize: 30 }}
              >
                {plan.planName}
              </p>
              <p className='w-full subtitle4-regular md:mt-0 mt-4 pl-12'>{plan.description}</p>
            </Box>
            {index < props.prices.length - 1 && <Divider className='my-8 col-span-12 bg-white' />}
          </div>
        )
      })}
    </div>
  )
}

export default ClientPrices
