import { useState } from 'react'

import { Theme, Tooltip, withStyles } from '@material-ui/core'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'

type THelpTooltip = {
  title: string
  className?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const HelpTooltip = (props: THelpTooltip) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <span className={`xl:inline hidden ${props.className ?? ''}`}>
        <LightTooltip title={props.title} placement={props.placement ?? 'top'}>
          <span>
            <FiIcons name='help' />
          </span>
        </LightTooltip>
      </span>
      <span className={`xl:hidden inline ${props.className ?? ''}`}>
        <LightTooltip
          title={props.title}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          placement={props.placement ?? 'top'}
        >
          <span>
            <FiIcons name='help' />
          </span>
        </LightTooltip>
      </span>
    </>
  )
}

export default HelpTooltip

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'var(--ice-blue)',
    border: '1px solid var(--blue)',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    color: 'var(--blue)',
    fontFamily: 'WorkSans-Regular',
    fontSize: 14,
    maxWidth: 367,
    padding: '.5rem 1rem',
  },
}))(Tooltip)
