import { FC } from 'react'
import { IClient } from '../../models/IClient'

type TClientCard = {
  className?: string
  avatarClassName?: string
  avatarImg?: string
}

const ClientCard: FC<IClient & TClientCard> = (props) => {
  return (
    <div className={`relative ${props.className ?? ''}`}>
      <div className={`${props.avatarClassName ?? ''} w-36 h-36 rounded-full bg-magenta`} />
      <p className='subtitle5-medium mt-4'>
        {props.name} {props.lastName.slice(0, 1).toUpperCase()}.
      </p>
      <p className='body2-regular'>
        {props.address.city}, {props.address.country}
      </p>
    </div>
  )
}

export default ClientCard
