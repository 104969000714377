import { useForm } from 'react-hook-form'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { IStorage } from '../../models/IFormStep'
import { IClientProduct } from '../../models/IClient'
import { allProducts, defaultProducts } from '../../constants/WebSite/ClientSubscriptionForm'
import InputField from '../../assets/UIkit/Forms/InputField'
import { cleanString } from '../../utils/helpers'
import PillBoxWithActions from '../Pillbox/PillBoxWithActions'
import { useTranslation } from 'react-i18next'

type TProjectProductList = {
  selectedProducts: IClientProduct[]
  storage: IStorage
  productsLimit?: number
  setSelectedProducts: Dispatch<SetStateAction<IClientProduct[]>>
}

const ProjectProductList = (props: TProjectProductList) => {
  const { t } = useTranslation()

  const { productsLimit = 1000 } = props
  const storage = props.storage
  const form = useForm()

  const [defaultProductList, setDefaultProductList] = useState<IClientProduct[]>(defaultProducts)
  const [allProductList, setAllProductList] = useState<IClientProduct[]>(allProducts)

  const filterWord = form.watch('filter_word')

  const addProduct = (product: IClientProduct) => {
    const products = [...props.selectedProducts, product]
    props.setSelectedProducts(products)
    storage.setItem({ ...storage.item, products })
  }

  const removeProduct = (product: IClientProduct) => {
    const products = props.selectedProducts.filter((currentProduct) => currentProduct.name !== product.name)
    props.setSelectedProducts(products)
    storage.setItem({ ...storage.item, products })

    if (product.isDefault) return setDefaultProductList([...defaultProductList, product])
    setAllProductList([...allProductList, product])
  }

  useEffect(() => {
    if (filterWord) {
      const products = allProductList.filter((product) =>
        props.selectedProducts.length
          ? !props.selectedProducts.some((selectedProduct) => selectedProduct.name === product.name)
          : true
      )
      setAllProductList(products)
      return
    }
    const products = defaultProductList.filter((product) =>
      props.selectedProducts.length
        ? !props.selectedProducts.some((selectedProduct) => selectedProduct.name === product.name)
        : true
    )
    setDefaultProductList(products)
  }, [props.selectedProducts])

  useEffect(() => {
    props.setSelectedProducts(storage.item?.products ?? [])
  }, [storage.item.products])

  return (
    <>
      <div>
        <div className='w-full flex flex-wrap gap-x-4 gap-y-2 mb-4'>
          {props.selectedProducts.map((product) => (
            <PillBoxWithActions key={product.name} title={product.name} handleRemove={() => removeProduct(product)} />
          ))}
        </div>

        {props.selectedProducts.length < productsLimit ? (
          <InputField
            className='w-full border-b-blue mb-4'
            name='filter_word'
            variant='transparent'
            inputProps={{
              placeholder: `${t('GLOBAL.WRITE_HERE')}`,
              className: 'w-full formtext2-regular',
            }}
            form={form}
          />
        ) : (
          <p className='text-red microcopy w-full border-b-blue'>Llegaste al límite de {productsLimit} productos</p>
        )}
      </div>

      <div className='md:h-80 h-40 overflow-auto'>
        {props.selectedProducts.length < productsLimit && (
          <>
            <p className='microcopy text-blue mt-12'>Top picks</p>
            <div className='w-full flex flex-wrap gap-x-4 gap-y-2 mt-2'>
              {filterWord ? (
                <>
                  {allProductList
                    .filter((product) => {
                      const categories = product.categories.toString()
                      const keyWords = product.keyWords.toString()
                      const productKey = `${categories}${keyWords}${product.name}`

                      return cleanString(productKey).includes(cleanString(filterWord))
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((product) => (
                      <PillBoxWithActions key={product.name} title={product.name} handleClick={() => addProduct(product)} />
                    ))}
                </>
              ) : (
                <>
                  {defaultProductList
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((product) => (
                      <PillBoxWithActions key={product.name} title={product.name} handleClick={() => addProduct(product)} />
                    ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ProjectProductList
