import { TGenericInput } from './GenericInput'

export type selectOptions = { value: any; label: string | number | undefined }
import { useTranslation } from 'react-i18next'

export type TGenericSelect = {
  selectOptions?: selectOptions[]
  withoutDefaultValue?: boolean
  label?: string | any
  optionsClassName?: string
  inputProps?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
}

const GenericSelect: React.FC<TGenericSelect & TGenericInput> = (props) => {
  const { t } = useTranslation()
  const disabledStyle = props?.inputProps?.disabled ? 'bg-black-005 border-none px-4' : inputStyle()

  function inputStyle() {
    if (props.variant === 'transparent') return 'px-4'
    if (props.variant === 'default') return 'border-b border-gray-300 pl-1 pr-4'
    return 'border border-black px-4'
  }

  return (
    <select
      {...props.form.register(props.name, props.options)}
      className={`md:h-10 h-8 no-focus custom-select ${props.inputProps?.className ?? ''} ${disabledStyle}`}
      disabled={props?.inputProps?.disabled}
    >
      {!props.withoutDefaultValue && <option value=''>{t('GLOBAL.SELECT_1_OPT')}</option>}
      {props.selectOptions?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export default GenericSelect
