import { FC } from 'react'
import { IWebSiteSection } from '../../../models/IWebSiteSection'
import SectionStructure from '../../../layout/WebSite/SectionStructure'
import Arrow from '../../../assets/UIkit/Arrow'
import TypingAnimation from '../../../shared/TypingAnimation/TypingAnimation'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from '../../../constants/routes'

const WhyFindieIntro: FC<IWebSiteSection> = (props) => {
  const navigate = useHistory().push
  const { t } = useTranslation()

  let words: string[] = t('OurClients.OurClientsIntro.words', { returnObjects: true })

  return (
    <SectionStructure className='mt-14 pb-0 clients-degrade max-w-screen-lg flex justify-center'>
      <div className='intro-contain lg:w-full md:w-11/12 w-full'>
        <div className='lg:w-8/12 md:w-11/12 w-full'>
          <h1 className={`${props.view.textColor} 2xl:pr-4 lg:pr-10 pr-0 whitespace-pre-wrap`}>
            {t('OurClients.OurClientsIntro.part1')}
            <TypingAnimation words={words} />
            {t('OurClients.OurClientsIntro.part2')}
          </h1>
          <p className={`subtitle5-regular mt-6 md:w-4/6 lg:w-full ${props.view.textColor}`}>
            {t('OurClients.OurClientsIntro.paragraph')}
          </p>
        </div>
        <div
          className={`lg:mt-8 md:mt-72 mt-36 cursor-pointer text-white`}
          onClick={() => navigate(routes.web_site.client_suscription_form.step_zero)}
        >
          <span className='buttontext3-bold text-left mr-4 md:inline block'>
            {t('OurClients.OurClientsIntro.findFreelancer')}
          </span>
          <Arrow direction='horizontal' className='md:inline block' />
        </div>
      </div>
    </SectionStructure>
  )
}

export default WhyFindieIntro
