import Pillbox from '../Pillbox/Pillbox'
import { TProjectEvaluationStatus, TProjectStatus } from '../../models/IProject'

type TProjectStatusPillBox = {
  projectStatus: TProjectStatus
  evaluationStatus: TProjectEvaluationStatus
  variant?: 'contained' | 'outlined'
  className?: string
}

const ProjectStatusPillBox = (props: TProjectStatusPillBox) => {
  const { variant = 'contained' } = props

  const setProjectStatus = () => {
    if (props.projectStatus === 'in_progress') {
      return { title: 'En progreso', styles: variant === 'contained' ? 'bg-magenta' : 'border-magenta text-magenta' }
    }
    if (props.projectStatus === 'finished') {
      return { title: 'Completado', styles: variant === 'contained' ? 'bg-black' : 'border border-black text-black' }
    }
    if (props.projectStatus === 'cancelled') {
      return { title: 'Cancelado', styles: variant === 'contained' ? 'bg-light-gray text-black' : 'border-light-gray text-black' }
    }

    if (props.projectStatus === 'paused') {
      return { title: 'Pausado', styles: variant === 'contained' ? 'bg-light-gray text-black' : 'border-light-gray text-black' }
    }
    if (props.evaluationStatus === 'step_one') {
      return { title: 'En revisión', styles: variant === 'contained' ? 'bg-golden' : 'border-golden text-golden' }
    }
    if (props.evaluationStatus === 'step_two') {
      return { title: 'En búsqueda', styles: variant === 'contained' ? 'bg-black' : 'border border-black text-black' }
    }
    if (props.evaluationStatus === 'step_three')
      return { title: 'Candidatos', styles: variant === 'contained' ? 'bg-blue' : 'border-blue text-blue' }

    return { title: '', bgColor: 'bg-transparent text-transparent' }
  }

  return (
    <Pillbox className={`py-0 inline-block ${props.className} ${setProjectStatus().styles}`}>{setProjectStatus().title}</Pillbox>
  )
}

export default ProjectStatusPillBox
