import { SubmitHandler, useForm } from 'react-hook-form'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { IClient } from '../../../models/IClient'

import ClientsForm from '../../../shared/ClientsForm/ClientsForm'

import { Button } from '@material-ui/core'

type TClientPersonalData = {
  clientId: string
  client: UseQueryResult<IClient, unknown>
  isInputsDisabled: boolean
  putClientMutation: UseMutationResult<{ data: IClient }, unknown, { body: Partial<IClient>; _id: string }, unknown>
}

const ClientPersonalData: React.FC<TClientPersonalData> = (props) => {
  const form = useForm()
  const { data: client } = props.client

  const editClient: SubmitHandler<IClient> = (data) => {
    if (!client) return

    const formatedData = {
      ...data,
      createdAt: client.createdAt,
    }
    props.putClientMutation.mutate({ body: formatedData, _id: props.clientId })
  }

  return (
    <form onSubmit={form.handleSubmit(editClient)}>
      <div className='flex justify-end'>
        <Button variant='contained' color='primary' className='mt-3' type='submit' disabled={props.isInputsDisabled}>
          Guardar cambios
        </Button>
      </div>

      <ClientsForm form={form} client={client} isDisabledFields={props.isInputsDisabled} asCpanel />
    </form>
  )
}

export default ClientPersonalData
