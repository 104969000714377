import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import Tabs from '../../assets/UIkit/Tabs'
import routes from '../../constants/routes'
import { useUpdateClient } from '../../customHooks/request/clientsQuery'
import useUser from '../../customHooks/useUser'

import { IClient } from '../../models/IClient'
import ClientsForm from '../../shared/ClientsForm/ClientsForm'
import useRequestAlert from '../../customHooks/useRequestAlert'
import { useUpdateUser } from '../../customHooks/request/userQuery'
import { IUser, TAvatarStyle } from '../../models/IUser'
import useAuth from '../../customHooks/useAuth'
import EntityProfileSettings from '../../shared/ProjectUserProfile/EntityProfile/EntityProfileSettings'
import ClientBankDataForm from '../../shared/BankDataForm/ClientBankDataForm'
import { useTranslation } from 'react-i18next'

const avatarColors: TAvatarStyle[] = ['black', 'magenta', 'orange', 'cyan', 'sea-blue']

const ClientProfile: FC = () => {
  const { t } = useTranslation()

  const form = useForm()
  const { logout } = useAuth()
  const { push: navigate } = useHistory()
  const { pathname } = useLocation()
  const { user, clientProfile, setClientProfile, setUser } = useUser()
  const updateClient = useUpdateClient()
  const updateUser = useUpdateUser()
  useRequestAlert(updateUser)
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState('')
  const password = form.watch('password')
  const password_repeat = form.watch('password_repeat')

  const tabs = [
    {
      id: 1,
      text: `${t('GLOBAL.SETTINGS.TAB.DATA')}`,
      isSelected: pathname.includes('personales'),
      action: () => tabAction(routes.clientUserProfile.profile.personalData),
    },
    {
      id: 2,
      text: `${t('GLOBAL.SETTINGS.TAB.SET')}`,
      isSelected: pathname.includes('ajuste'),
      action: () => tabAction(routes.clientUserProfile.profile.settings),
    },
    {
      id: 3,
      text: `${t('GLOBAL.SETTINGS.TAB.PAY')}`,
      isSelected: pathname.includes('facturacion'),
      action: () => tabAction(routes.clientUserProfile.profile.billing),
    },
  ]

  const tabAction = (path: string) => {
    navigate(path)
    form.reset()
  }

  const updateClientData = async (data: IClient) => {
    if (pathname.includes('ajuste')) {
      const isValidPassword =
        /^.{6,}$/.test(password) && /\d/.test(password) && /[A-Z]/.test(password) && password === password_repeat
      if (password && !isValidPassword) return

      const bodyWithoutPassword = { avatar_style: form.watch('avatar_style') }
      const clientBody = { contact_preference: form.watch('contact_preference') }
      const bodyWithPassword = { ...bodyWithoutPassword, password, has_default_password: false }

      await updateClient.mutate(
        { body: clientBody as IClient, id: clientProfile?._id ?? '' },
        {
          onSuccess: (response) => {
            const { _id, ...rest } = body
            updateUser.mutate(
              { body: rest as unknown as IUser, id: user?._id ?? '' },
              { onSuccess: (userResponse) => setUser(userResponse as IUser) }
            )
            setClientProfile(response)
          },
        }
      )
      await updateUser.mutate(
        { body: password ? bodyWithPassword : bodyWithoutPassword, id: user?._id ?? '' },
        {
          onSuccess: (response) => {
            if (password) {
              setUpdatePasswordMessage('Cambiaste la contraseña!. Ahora te vamos a redirigir para que inicies sesión nuevamente.')
              setTimeout(() => {
                logout()
              }, 2000)
              return
            }
            setUser(response as IUser)
          },
        }
      )
      return
    }
    const body = data
    const { createdAt, ...updateClientBody } = body
    updateClient.mutate(
      { body: updateClientBody, id: clientProfile?._id ?? '' },
      {
        onSuccess: (response) => {
          const { _id, ...rest } = body
          updateUser.mutate(
            { body: rest as unknown as IUser, id: user?._id ?? '' },
            { onSuccess: (userResponse) => setUser(userResponse as IUser) }
          )
          setClientProfile(response)
        },
      }
    )
  }

  if (!user || !clientProfile) return null
  return (
    <div>
      <Tabs tabs={tabs} variant='default' />
      <form onSubmit={form.handleSubmit(updateClientData)}>
        <div className='flex justify-end md:mt-12 mt-4 mb-4'>
          <button type='submit' className='subtitle4-medium border-b border-black'>
            {t('GLOBAL.SETTINGS.SAVE_CHANGES')}
          </button>
        </div>

        <Switch>
          <Route path={routes.clientUserProfile.profile.personalData}>
            <ClientsForm client={clientProfile} variant='default' isDisabledFields={false} form={form} />
          </Route>
          <Route path={routes.clientUserProfile.profile.settings}>
            <h5 className='text-blue'>{updatePasswordMessage}</h5>
            <EntityProfileSettings
              contactPreference={clientProfile.contact_preference}
              avatarStyle={user.avatar_style}
              avatarColors={avatarColors}
              form={form}
            />
          </Route>
          <Route path={routes.clientUserProfile.profile.billing}>
            <ClientBankDataForm client={clientProfile} isDisabledFields={false} variant='default' form={form} />
          </Route>
        </Switch>
      </form>
    </div>
  )
}

export default ClientProfile
