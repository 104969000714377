import { FC, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { useOneOfferQuery } from '../../../../../customHooks/request/offersQuery'
import TitleWithUnderline from '../../../../../shared/TitleWithUnderline/TitleWithUnderline'
import WebFreelancerProfile from '../../../../WebSite/WebFreelancerProfile/WebFreelancerProfile'
import CloseIcon from '@material-ui/icons/Close'
import { UseQueryResult } from 'react-query'
import { IProject } from '../../../../../models/IProject'
import { Box, makeStyles } from '@material-ui/core'
import OfferWorkPlan from '../../../../../shared/ProjectUserProfile/OfferWorkplan/OfferWorkPlan'
import useChooseCandidateContext from '../../../../../customHooks/useChooseCandidateContext'
import { FiIcons } from '../../../../../assets/UIkit/Icons/FiIcons'
import ProjectBreakDown from './ProjectBreakDown/ProjectBreakDown'
import ClientCandidateCard from '../../../../../shared/Project/ClientCandidateCard'

type TClientProjectCandidatesDetail = {
  projectQuery: UseQueryResult<IProject, unknown>
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '250px 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '200px 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}))

const ClientProjectCandidatesDetail: FC<TClientProjectCandidatesDetail> = (props) => {
  const classes = useStyles()
  const params = useParams<{ offerId: string; projectId: string }>()
  const oneOfferQuery = useOneOfferQuery({ id: params.offerId, refetchOnWindowFocus: false })
  const { data: offerQuery } = oneOfferQuery

  const dialog = useChooseCandidateContext()

  useEffect(() => {
    dialog.toggle({ dialogAction: 'work_plan' })
  }, [])

  if (!offerQuery) return null
  if (dialog.action === 'breakdown') return <ProjectBreakDown projectQuery={props.projectQuery} params={params} />
  if (dialog.action === 'finish_process') {
    return (
      <Box height='60vh' className='bg-ice-blue total-center flex-col rounded-md shadow-md'>
        <h4 className='text-blue text-center'>¡Hurra! has elegido a tu freelancer</h4>
      </Box>
    )
  }
  return (
    <>
      <Link className='mt-4' to={`${routes.clientUserProfile.projects.detail}/${params.projectId}/candidatos/lista`}>
        <TitleWithUnderline className='inline-block' title='Volver a candidatos' />
      </Link>

      <Box className={`mt-6 gap-8 ${classes.container}`}>
        <div>
          <ClientCandidateCard
            goToProfile={() => dialog.toggle({ dialogAction: 'profile' })}
            {...offerQuery.freelancer}
            isFavorite={offerQuery.is_favorite}
          />
        </div>

        <div>
          {dialog.action === 'work_plan' && offerQuery.work_plan && <OfferWorkPlan offerQuery={oneOfferQuery} asClient />}
          {dialog.action === 'profile' && (
            <div className='relative'>
              <CloseIcon
                className='text-white top-6 right-6 cursor-pointer absolute'
                fontSize='large'
                onClick={() => dialog.toggle({ dialogAction: 'work_plan' })}
              />
              <WebFreelancerProfile
                freelancer={offerQuery.freelancer}
                theme='black'
                contactButtonLink={routes.clientUserProfile.projects.new}
                noContractButton
              />
            </div>
          )}
        </div>
      </Box>
    </>
  )
}

export default ClientProjectCandidatesDetail
