import { FC } from 'react'
import useUser from '../../../customHooks/useUser'
import card from '../../../assets/images/userProfile/card.svg'
import smallCard from '../../../assets/images/userProfile/small-card.svg'
import { Box, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import BlueBorderCard from '../../../shared/ProjectOptions/BlueBorderCard'
import BlueWavesCard from '../../../shared/ProjectOptions/BlueWavesCard'
import { useTranslation } from 'react-i18next'

const ClientHomeStructure: FC = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery('(min-width:825px)')

  return (
    <Box maxWidth={isDesktop ? '620px' : '100%'} className='relative pb-8'>
      <h2>
        {t('GLOBAL.HI')} {user?.name},
        <br />
        {t('HOME_PAGE.WHAT_TO_DO')}
      </h2>
      <div className='md:flex hidden justify-between mt-8 items-center shadow-black-005 px-4 py-2'>
        <p className='subtitle6-regular w-96 pr-12'>{t('HOME_PAGE.GREETINGS')}</p>
        <img className='pr-8' src={card} alt='sobre' />
      </div>

      <div className='grid grid-cols-12 gap-2 mt-4 gap-y-4'>
        <BlueBorderCard
          className='md:col-span-6 col-span-12'
          title={t('GLOBAL.CREATE_PROJECT')}
          path={`${routes.clientUserProfile.projects.new}/1`}
        />
        <BlueWavesCard
          className='md:col-span-6 col-span-12'
          title={t('GLOBAL.ACTIVE_PROJECTS')}
          path={routes.clientUserProfile.projects.list}
        />
      </div>

      <div className='md:grid hidden grid-cols-12 gap-2 mt-4'>
        <div className='col-span-3 shadow-black-005 p-4 flex justify-between flex-col cursor-pointer'>
          <a href='mailto:contacto@findie.cl'>
            <p className='body2-medium mb-4'>{t('GLOBAL.INBOX')}</p>
            <img className='ml-auto' src={smallCard} alt='estrella' />
          </a>
        </div>
        <div className='col-span-6 shadow-black-005 total-center py-8 cursor-pointer'>
          <Link to={routes.clientUserProfile.book}>
            <p className='formtext1-regular'>{t('HOME_PAGE.CATALOGUE')}</p>
          </Link>
        </div>
      </div>
      <div className='col-span-6 shadow-black-005 md:hidden flex justify-center items-center py-8 mt-4 cursor-pointer'>
        <Link to={routes.clientUserProfile.book}>
          <p className='formtext1-regular'>{t('HOME_PAGE.CATALOGUE')}</p>
        </Link>
      </div>
    </Box>
  )
}

export default ClientHomeStructure
