import { useForm } from 'react-hook-form'
import InputField from '../../../../assets/UIkit/Forms/InputField'
import { IWorkPlanVersion, TVersion } from '../../../../models/IWorkPlan'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { IOffer } from '../../../../models/IOffer'
import useChooseCandidateContext from '../../../../customHooks/useChooseCandidateContext'
import useTotalWorkPlanPrice from '../../../../customHooks/useTotalWorkPlanPrice'

type TClientVersion = {
  offerQuery: IOffer
  hasThisVersion: IWorkPlanVersion | undefined
  is_negotiable: boolean
  version: TVersion
  negotiationVersion: TVersion
  hasVersionSelected: boolean
  handleVersion: (currentVersion: TVersion) => void
  selectWorkPlanVersion: (params: { version: TVersion; isSelected?: boolean }) => Promise<void>
  setNegotiationVersion: Dispatch<SetStateAction<TVersion>>
}

const ClientVersion = (props: TClientVersion) => {
  const form = useForm()
  const dialog = useChooseCandidateContext()
  const { total } = useTotalWorkPlanPrice({
    offer: props.offerQuery,
    selectedProducts: [],
    selectedWorkPlanVersion: props.hasThisVersion,
  })

  useEffect(() => {
    if (props.hasThisVersion) form.setValue('deliverables', props.hasThisVersion.deliverables)
  }, [props.hasThisVersion])

  if (!props.hasThisVersion) return null
  return (
    <div className='md:col-span-4 col-span-12 mr-4' onClick={() => props.handleVersion(props.version)}>
      <div className='col-span-12 mb-4 flex items-center gap-3'>
        <p className='subtitle3-20'>
          {props.version}
          {props.hasThisVersion?.is_negotiated_version && <span className='text-magenta ml-1'>New!</span>}
        </p>
      </div>

      <p className={`microcopy ${props.hasThisVersion?.is_negotiated_version ? 'text-magenta' : 'text-blue'}`}>Entregables</p>
      <InputField
        name='deliverables'
        className='w-full'
        variant='transparent'
        textareaProps={{
          disabled: true,
          className: 'w-full bg-transparent',
          rows: 8,
        }}
        textarea
        form={form}
      />

      <div className='grid grid-cols-12 gap-2 mt-4'>
        <div className='col-span-6'>
          <p className={`microcopy ${props.hasThisVersion?.is_negotiated_version ? 'text-magenta' : 'text-blue'}`}>Precio</p>
          <p className='body2-regular border-b-blue'>{total}</p>
        </div>
        <div className='col-span-6'>
          <p className={`microcopy ${props.hasThisVersion?.is_negotiated_version ? 'text-magenta' : 'text-blue'}`}>
            Correcciones
          </p>
          <p className='body2-regular border-b-blue'>{props.hasThisVersion.iterations}</p>
        </div>
      </div>

      <button
        className={`col-span-12 buttontext2-medium w-full cursor-pointer py-1 mt-2 text-white bg-blue border-blue`}
        onClick={() => dialog.toggle({ dialogAction: 'breakdown', dialogData: props.version })}
      >
        Continuar
      </button>
    </div>
  )
}

export default ClientVersion
