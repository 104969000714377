import { ReactElement, useEffect } from 'react'
import { Route, useLocation, useHistory } from 'react-router-dom'
import useAuth from '../customHooks/useAuth'
import { useSetLoader } from '../customHooks/useSetLoader'
import useUser from '../customHooks/useUser'

type TPrivateRoute = {
  exact: boolean
  path: string
  userType?: string[]
  component: ReactElement<any, string | React.JSXElementConstructor<any>>
}

const PrivateRoute: React.FC<TPrivateRoute> = (props) => {
  const location = useLocation()
  const { isUserAllowed } = useAuth()
  const { isUserLogged } = useUser()
  const navigate = useHistory().push
  const loader = useSetLoader()

  useEffect(() => {
    if (!isUserLogged) return

    loader.setIsOpen(false)
    if (!isUserAllowed(props?.userType)) return navigate({ pathname: '/*', state: { from: location } })
  }, [isUserLogged])

  return (
    <Route exact={props.exact} path={props.path}>
      {props.component}
    </Route>
  )
}

export default PrivateRoute
