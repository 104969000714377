import LabeField from './LabeField'
import FormError from './FormError'
import { inputStyle, TGenericInput } from './GenericInput'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

const ClpField: React.FC<TGenericInput & { isCurrency?: boolean }> = (props) => {
  const disabledStyle = props?.inputProps?.disabled ? 'bg-black-005 border-none px-4' : inputStyle(props.variant ?? 'outlined')
  const { showErrorMessage = true } = props

  return (
    <div className={props.className}>
      <LabeField {...props} />
      <Controller
        control={props.form.control}
        name={props.name}
        rules={props.options}
        defaultValue=''
        render={({ field: { onChange, name, value } }) => {
          return (
            <NumberFormat
              className={`md:h-10 h-8 no-focus body2-regular ${disabledStyle} ${props.inputProps?.className ?? ''}`}
              style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
              disabled={props.inputProps?.disabled}
              placeholder={props.inputProps?.placeholder}
              thousandSeparator={props.isCurrency ? false : '.'}
              decimalSeparator={','}
              name={name}
              value={value ?? 0}
              onChange={onChange}
            />
          )
        }}
      />
      {showErrorMessage && <FormError name={props.name} errors={props.form?.formState?.errors} />}
    </div>
  )
}

export default ClpField
