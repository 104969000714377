import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IFormStepStructure } from '../../../../models/IFormStep'
import NewProjectStepLayout from './NewProjectStepLayout'
import { IClientProduct } from '../../../../models/IClient'
import ProjectProductList from '../../../../shared/ProductList/ProjectProductList'
import { useTranslation } from 'react-i18next'
import InputField from '../../../../assets/UIkit/Forms/InputField'
import { useForm } from 'react-hook-form'

export const NewProjectStepProduct: FC<IFormStepStructure> = (props) => {
  const { t } = useTranslation()
  const { slide } = useParams<{ slide: string }>()
  const storage = props.storage
  const form = useForm()
  const { Title } = props

  useEffect(() => {
    form.setValue('products', storage?.item?.products?.at(0)?.name ?? '')
  }, [])

  useEffect(() => {
    if (slide === `${props.position}`) props.setIsButtonDisabled(Boolean(!storage?.item?.products?.at(0)?.name?.length))
  }, [storage.item.products, slide, form.watch('products')])

  useEffect(() => {
    storage.setItem({
      ...storage.item,
      products: [
        {
          name: form.watch('products'),
          categories: [],
          keyWords: [],
          isDefault: false,
        },
      ] as IClientProduct[],
    })
  }, [form.watch('products')])

  return (
    <>
      <Title>{t('UP_NEW_PROJECT.PRODUCTS_NEEDS?')}</Title>

      <NewProjectStepLayout>
        <div className='pt-3'>
          <InputField
            name='products'
            form={form}
            variant='transparent'
            className='w-full border-b-blue'
            textareaProps={{
              rows: 4,
              className: 'w-full formtext2-regular',
              placeholder: `${t('UP_NEW_PROJECT.PRODUCTS_DESCRIBE')}`,
            }}
            textarea
          />
        </div>
      </NewProjectStepLayout>
    </>
  )
}
