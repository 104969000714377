import { useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useConceptsQuery } from './request/conceptsQuery'
import { IConcept } from '../models/IConcept'

const useConcepts = ({ form, conceptName = 'concepts_list' }: { conceptName?: string; form: UseFormReturn<any> }) => {
  const { data: conceptsQuery } = useConceptsQuery({ filters: { is_available: true } })
  const selectedConcept = form.watch('selected_concept')
  const parsedSelectedConcept = selectedConcept ? JSON.parse(selectedConcept) : undefined
  const selectedConcepts = form.watch(conceptName) ?? []

  const conceptList: IConcept[] = useMemo(() => {
    if (!conceptsQuery) return []

    if (selectedConcepts && selectedConcepts.length) {
      const filteredConceptList = selectedConcepts.reduce((acum: IConcept[], skill: IConcept) => {
        return acum.filter((iter: IConcept) => iter._id !== skill._id)
      }, conceptsQuery)
      return filteredConceptList
    }
    return conceptsQuery
  }, [conceptsQuery, selectedConcepts])

  const addConcept = () => {
    form.setValue(conceptName, [...selectedConcepts, parsedSelectedConcept])
  }

  const removeConcept = (currentConcept: IConcept) => {
    const removeConcepts = selectedConcepts.filter((concept: IConcept) => concept._id !== currentConcept._id)
    form.setValue(conceptName, removeConcepts)
  }

  useEffect(() => {
    if (selectedConcept) addConcept()
  }, [selectedConcept])

  return { conceptList, removeConcept }
}

export default useConcepts
