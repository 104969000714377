import { createContext } from 'react'
import useDialog, { TToggleActions } from '../customHooks/useDialog'
import { TVersion } from '../models/IWorkPlan'

export type TCandidateDialogActions = 'work_plan' | 'profile' | 'breakdown' | 'finish_process'

type TDialog = {
  isOpen: boolean
  data: TVersion | undefined
  action: TCandidateDialogActions | undefined
  toggle: (params?: TToggleActions<TVersion, TCandidateDialogActions>) => void
  updateAction: (dialogAction?: TCandidateDialogActions | undefined) => void
  updateData: (dialogData?: TVersion | undefined) => void
}

const ChooseCandidateContext: React.Context<TDialog> = createContext<TDialog>({
  isOpen: false,
  data: undefined,
  action: undefined,
  toggle: () => null,
  updateAction: () => null,
  updateData: () => null,
})

const ChooseCandidateContextProvider: React.FC = (props) => {
  const dialog = useDialog<TVersion, TCandidateDialogActions>()

  return <ChooseCandidateContext.Provider value={{ ...dialog }}>{props.children}</ChooseCandidateContext.Provider>
}

export { ChooseCandidateContext, ChooseCandidateContextProvider }
