import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const HelpPayment: FC = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className='w-10/12'>
      <p className='body2-regular'>{t('CLIENT_HELP.PAY_FOR_NOW')} </p>
      {i18n.language === 'es' && (
        <>
          <p className='body2-regular mt-6'>{t('CLIENT_HELP.BY_WIRE_TRANSFER')}</p>
          <p className='body2-regular'>
            {t('CLIENT_HELP.COMP_FINDIE_NAME')}
            <br />
            {t('CLIENT_HELP.BCI_BANK')}
            <br />
            {t('CLIENT_HELP.BCI_ACCOUNT_NUMBER')}
            <br />
            {t('CLIENT_HELP.FINDIE_RUT')}
            <br />
            {t('CLIENT_HELP.CONTACT_FINDIE')}
          </p>
          <p className='body2-regular mt-6'>{t('CLIENT_HELP.2_PAYPAL')}</p>
          <p className='body2-regular'>{t('CLIENT_HELP.CONTACT_FINDIE')}</p>
        </>
      )}
      {i18n.language === 'en' && (
        <>
          <p className='body2-regular'>
            <br />
            {t('CLIENT_HELP.PAY_BY_KHIPU')}
            <br />
            {t('CLIENT_HELP.2_PAYPAL')}
            <br />
            {t('CLIENT_HELP.PAY_BY_BITPAY')}
          </p>
          <p className='body2-regular mt-6'>
            {t('CLIENT_HELP.FOR_REQUEST')}
            <a href='mailto:contacto@findie.cl' className='text-blue-500'>
              {t('CLIENT_HELP.PLEASE_CONTACT')}
            </a>
          </p>
          <p></p>
        </>
      )}
    </div>
  )
}

export default HelpPayment
