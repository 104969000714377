import { FC } from 'react'
import { Link } from 'react-router-dom'
import blueWaves from '../../assets/images/userProfile/blue-waves.svg'

type TBlueWavesCard = {
  title: string
  path: string
  className?: string
}

const BlueWavesCard: FC<TBlueWavesCard> = (props) => {
  return (
    <Link className={props.className} to={props.path}>
      <div className='black-white-filter p-0.5 shadow-black-005 cursor-pointer'>
        <img className='w-full' src={blueWaves} alt='olas' />
        <p className='formtext1-regular my-4 text-center'>{props.title}</p>
        <img className='w-full' src={blueWaves} alt='olas' />
      </div>
    </Link>
  )
}

export default BlueWavesCard
