import { useEffect } from 'react'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { useForm } from 'react-hook-form'
import { meetingsDays, yesOrNotOptions } from '../../../constants/Cpanel/ProjectConstants'
import { Divider } from '@material-ui/core'
import FiButton from '../../../assets/UIkit/FiButton'
import { useUpdateWorkPlan } from '../../../customHooks/request/workPlanQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { UseQueryResult } from 'react-query'
import { IOffer } from '../../../models/IOffer'
import dayjs from 'dayjs'
import { getTimeStamp } from '../../../utils/helpers'
import CandidateWorkPlan from '../CandidateWorkPlan'

type TWorkPlanData = {
  isDisabledFields: boolean
  offerQuery: UseQueryResult<IOffer, unknown>
  asClient?: boolean
}

const WorkPlanData = (props: TWorkPlanData) => {
  const { data: offerQuery, refetch: refetchOffer } = props.offerQuery
  const form = useForm()
  const updateWorkPlan = useUpdateWorkPlan()
  useRequestAlert(updateWorkPlan)

  const handleWorkPlan = (data: any) => {
    const start_date = getTimeStamp(data.start_date)
    const end_date = getTimeStamp(data.end_date)
    const is_negotiable = JSON.parse(data.is_negotiable)

    const body = {
      ...data,
      start_date,
      end_date,
      is_negotiable,
    }

    updateWorkPlan.mutate({ body, id: offerQuery?.work_plan._id ?? '' }, { onSuccess: () => refetchOffer() })
  }

  const setMeetingDays = () => {
    let meetingList: string[] = []
    meetingsDays.forEach((day: string) => {
      meetDay(day) && meetingList.push(day)
    })
    form.setValue('availability.days', meetingList)
  }

  const meetDay = (key: string) => form.watch(key)

  useEffect(() => {
    if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return

    const workPlan = Object.entries(offerQuery.work_plan)
    workPlan.forEach(([key, value]) => {
      if (!['start_date', 'end_date', 'is_negotiable', 'availability', 'description', 'considerations'].includes(key)) return
      if (key === 'availability') {
        form.setValue('availability.time_from', value.time_from)
        form.setValue('availability.time_to', value.time_to)
        form.setValue('availability.time_zone', value.time_zone)
        value.days.forEach((day: string) => {
          form.setValue(day, true)
        })
        return
      }
      if (['start_date', 'end_date'].includes(key)) return form.setValue(key, dayjs(value).format('YYYY-MM-DD'))
      if (key === 'is_negotiable') return form.setValue('is_negotiable', JSON.stringify(value))
      form.setValue(key, value)
    })
  }, [offerQuery])

  useEffect(() => {
    setMeetingDays()
  }, [meetDay('Lun'), meetDay('Mar'), meetDay('Mie'), meetDay('Jue'), meetDay('Vie')])

  if (props.asClient && offerQuery) return <CandidateWorkPlan workPlan={offerQuery.work_plan} withoutPrice />
  return (
    <form className='grid grid-cols-12 gap-4 pz-4' onSubmit={form.handleSubmit(handleWorkPlan)}>
      <div className='flex justify-end col-span-12 mb-4 gap-2'>
        <a href='mailto:contacto@findie.cl'>
          <FiButton variant='outlined' theme='golden'>
            <p className='buttontext1-medium-fixed'>Contact us</p>
          </FiButton>
        </a>
        {!props.asClient && !props.isDisabledFields && (
          <button className={` cursor-pointer py-1  text-blue mr-1 px-4 border-blue`} type='submit'>
            <p className='buttontext1-medium-fixed text-blue'>Guardar</p>
          </button>
        )}
      </div>

      <InputField
        className='col-span-3'
        name='start_date'
        label='Fecha de inicio'
        inputProps={{ disabled: props.asClient || props.asClient || props.isDisabledFields, type: 'date', className: 'w-full' }}
        labelClassName='text-blue'
        variant='default'
        options={{ required: 'Este campo es requerido' }}
        form={form}
      />
      <InputField
        className='col-span-3'
        name='end_date'
        label='Fecha de entrega'
        inputProps={{ disabled: props.asClient || props.isDisabledFields, type: 'date', className: 'w-full' }}
        labelClassName='text-blue'
        variant='default'
        options={{ required: 'Este campo es requerido' }}
        form={form}
      />
      <div className='col-span-3'>
        <p className='microcopy text-blue'>Disponibilidad</p>
        <div className='flex justify-around mt-2'>
          {meetingsDays.map((day: string, index: number) => {
            return (
              <div key={day} className='total-center flex-col'>
                <input
                  id={`${day}-${index}`}
                  {...form.register(day)}
                  type='checkbox'
                  disabled={props.asClient || props.isDisabledFields}
                />
                <label htmlFor={`${day}-${index}`} className='microcopy'>
                  {day}
                </label>
              </div>
            )
          })}
        </div>
      </div>
      <div className='flex gap-2 flex-col items-center col-span-3'>
        <p className='microcopy text-blue'>Negociable</p>
        <div className='flex items-center'>
          {yesOrNotOptions.map((iter, index) => {
            return (
              <div key={iter.label} className='mr-4 flex items-center'>
                <input
                  id={`${index}`}
                  {...form.register('is_negotiable')}
                  value={iter.value}
                  type='radio'
                  disabled={props.asClient || props.isDisabledFields}
                />
                <label htmlFor={`${index}`} className='microcopy ml-1'>
                  {iter.label}
                </label>
              </div>
            )
          })}
        </div>
      </div>
      <div className='col-span-3'>
        <div className='flex items-center gap-2'>
          <p className='microcopy text-blue'>Entre</p>
          <InputField
            className='col-span-4'
            name='availability.time_from'
            inputProps={{
              disabled: props.asClient || props.isDisabledFields,
              placeholder: '00:00',
              className: 'w-full border border-gray-300',
            }}
            labelClassName='text-blue'
            variant='outlined'
            form={form}
          />
          <InputField
            className='col-span-4'
            name='availability.time_to'
            inputProps={{
              disabled: props.asClient || props.isDisabledFields,
              placeholder: '00:00',
              className: 'w-full border border-gray-300',
            }}
            labelClassName='text-blue'
            variant='outlined'
            form={form}
          />
        </div>
      </div>
      <div className='col-span-4 flex items-center gap-2'>
        <p className='microcopy text-blue w-32'>Zona Horaria</p>
        <InputField
          className='w-full'
          name='availability.time_zone'
          inputProps={{
            disabled: props.asClient || props.isDisabledFields,
            className: 'w-full border border-gray-300',
            placeholder: 'Ubicación',
          }}
          labelClassName='text-blue'
          variant='outlined'
          form={form}
        />
      </div>
      <InputField
        className='col-span-12'
        name='description'
        label='Descripción propuesta (máx 800 caracteres)'
        options={{ maxLength: { value: 800, message: 'Máximo 800  caracteres.' } }}
        textareaProps={{
          disabled: props.asClient || props.isDisabledFields,
          className: 'w-full',
          rows: 7,
          maxLength: 800,
          placeholder: 'Describe el servicio a realizar. Acá te conquistas al cliente.',
        }}
        labelClassName='text-blue'
        variant='default'
        textarea
        form={form}
      />
      <Divider className='bg-black my-2 col-span-12' />
      <InputField
        className='col-span-12'
        name='considerations'
        label='Planificación (máx 400 caracteres)'
        options={{ maxLength: { value: 400, message: 'Máximo 400 caracteres.' } }}
        textareaProps={{
          disabled: props.asClient || props.isDisabledFields,
          className: 'w-full',
          rows: 7,
          placeholder:
            'Detalla cómo vas a organizar el proyecto. Hitos y estado de avance. Puedes indicar el % hasta completar el 100% que sería la entrega.',
          maxLength: 400,
        }}
        labelClassName='text-blue'
        variant='default'
        textarea
        form={form}
      />
    </form>
  )
}

export default WorkPlanData
