import { FC, useState } from 'react'
import FiButton from '../../../assets/UIkit/FiButton'
import { Divider } from '@material-ui/core'
import { IOffer } from '../../../models/IOffer'
import { UseQueryResult } from 'react-query'
import OfferWorkPlan from '../../ProjectUserProfile/OfferWorkplan/OfferWorkPlan'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles, Theme } from '@material-ui/core/styles'
import { useUpdateWorkPlan } from '../../../customHooks/request/workPlanQuery'

type TCandidateWorkPlan = {
  oneOfferQuery: UseQueryResult<IOffer, unknown>
  toggle: () => void
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const CandidateWorkPlan: FC<TCandidateWorkPlan> = (props) => {
  const updateWorkPlan = useUpdateWorkPlan()
  const [isChecked, setIsChecked] = useState(props.oneOfferQuery.data?.work_plan.can_edit ?? false)

  const handleEditWorkPlan = () => {
    updateWorkPlan.mutate(
      { body: { can_edit: !isChecked }, id: props.oneOfferQuery.data?.work_plan?._id ?? '' },
      {
        onSuccess: () => {
          setIsChecked((prev) => !prev)
          props.oneOfferQuery.refetch()
        },
      }
    )
  }

  return (
    <div>
      <div className='flex justify-between items-center'>
        <h5>
          {props.oneOfferQuery.data?.freelancer.name} {props.oneOfferQuery.data?.freelancer.lastName}
        </h5>
        {props.oneOfferQuery?.data?.status === 'postulated' && (
          <div className='inline'>
            <Switch checked={isChecked} onChange={handleEditWorkPlan} color='primary' />
            <LightTooltip placement='top' title={`Le permite al freelancer editar el plan de trabajo que envió al cliente.`}>
              <span className='microcopy text-soft-green'>Editar plan de trabajo</span>
            </LightTooltip>
          </div>
        )}
      </div>

      <OfferWorkPlan offerQuery={props.oneOfferQuery} asCpanel />

      <div className='flex justify-end mt-4 pt-2 border-t border-gray-300'>
        <Divider />
        <FiButton variant='outlined' theme='secondary' className='mr-4' onClick={props.toggle}>
          Cerrar
        </FiButton>
      </div>
    </div>
  )
}

export default CandidateWorkPlan
