import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'

import FreelancersProfilesList from '../../components/WebSite/FreelancersProfiles/FreelancersProfilesList'
import SectionStructure from '../../layout/WebSite/SectionStructure'
import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import useCategories from '../../customHooks/useCategories'

const FreelancersProfiles: React.FC = () => {
  const param = useParams<{ categoryId: string }>()
  const { view, setView } = useWebSiteLayout()
  const { data: categories } = useCategories()

  useEffect(() => {
    setView({ path: 'freelancer_profiles', bgColor: 'web-bg-white', textColor: 'text-black', textColor2: 'text-white' })
  }, [])

  return (
    <WebSiteStructure>
      <SectionStructure>
        <FreelancersProfilesList view={view} />
      </SectionStructure>
    </WebSiteStructure>
  )
}

export default FreelancersProfiles
