import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { TSidebarOptions } from './FreelancerUserProfileLayout'

type TBottomMenuUserProfile = {
  sidebarOptions: TSidebarOptions[]
}

const BottomMenuUserProfile: FC<TBottomMenuUserProfile> = (props) => {
  return (
    <div className='flex justify-evenly pt-4 shadow-black-025'>
      {props.sidebarOptions.map((option: TSidebarOptions) => {
        return (
          <div className='md:px-8 px-0' key={option.title}>
            <NavLink
              className={`flex items-center justify-between flex-col cursor-pointer h-16`}
              activeClassName='border-b-blue'
              to={option.to}
            >
              <FiIcons name={option.icon} />
              <p className='buttontext2-regular text-center'>{option.title}</p>
            </NavLink>
          </div>
        )
      })}
    </div>
  )
}

export default BottomMenuUserProfile
