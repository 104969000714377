import { FC } from 'react'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import Pillbox from '../../../shared/Pillbox/Pillbox'

const HelpProjects: FC = () => {
  return (
    <>
      <div className='w-10/12'>
        <p className='body2-regular'>En el menú proyectos podrás ver:</p>
        <p className='body2-regular mt-6'>
          <span className='body2-medium'>Ofertas: </span> acá llegan los proyectos a los cuales puedes postular. Verás el brief
          del cliente y tendrás que hacer un presupuesto/propuesta de trabajo.
        </p>
        <p className='body2-regular mt-6'>
          <span className='body2-medium'>Proyectos activos: </span> acá encuentras los proyectos que están en desarrollo.
        </p>
        <p className='body2-regular mt-6'>
          <span className='body2-medium'>Históricos: </span> todo proyecto finalizado o cancelado se encontrará en esta sección.
        </p>
        <p className='body2-regular mt-6'>
          <span className='body2-medium'>Calculadora: </span> herramienta para facilitar el que puedas ver cuánto dinero cobras /
          recibes.
        </p>
      </div>

      <div className='flex pl-4 items-center gap-8 py-8 border-t border-gray-300 mt-6'>
        <Pillbox className='bg-black'>Postulado</Pillbox>
        <p className='body2-regular'>Cuando postulas a una oferta.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-8 border-t border-gray-300'>
        <Pillbox className='bg-blue'>Adjudicado</Pillbox>
        <p className='body2-regular'>Cuando ganas el proyecto.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-8 border-t border-gray-300'>
        <Pillbox className='bg-soft-gray'>No ganado</Pillbox>
        <p className='body2-regular'>Cuando no ganas el proyecto.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-4 border-t border-gray-300'>
        <p className='body2-regular text-blue'>
          <FiIcons name='circle_close' /> Rechazar
        </p>
        <p className='body2-regular'>Para rechazar la oferta.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-6 border-t border-gray-300'>
        <p className='subtitle4-regular text-blue'>00:00:00:00</p>
        <p className='body2-regular'>Tiempo restante para enviar tu presupuesto.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-8 border-t border-gray-300'>
        <Pillbox className='border border-black text-black'>Todo en orden, éxito!</Pillbox>
        <p className='body2-regular'>Proyecto en desarrollo normal.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-8 border-t border-gray-300'>
        <Pillbox className='bg-magenta'>Esperando depósito inicial</Pillbox>
        <p className='body2-regular'>Aún no se ha hecho el depósito inicial.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-8 border-t border-gray-300'>
        <Pillbox className='bg-magenta'>Pago pendiente</Pillbox>
        <p className='body2-regular'>Aún no se ha hecho el pago final.</p>
      </div>
      <div className='flex pl-4 items-center gap-8 py-8 border-t border-b border-gray-300'>
        <Pillbox className='border-magenta text-magenta'>Paused</Pillbox>
        <p className='body2-regular'>Proyecto en pausa.</p>
      </div>
    </>
  )
}

export default HelpProjects
