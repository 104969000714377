import { useEffect, useState } from 'react'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'

import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import SectionStructure from '../../layout/WebSite/SectionStructure'
import TermsAndConditions from '../../components/WebSite/LegalAspects/TermsAndConditions'
import PrivacyPolicies from '../../components/WebSite/LegalAspects/PrivacyPolicies'

import { Collapse } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'

const LegalAspects: React.FC = () => {
  const { view, setView } = useWebSiteLayout()
  const [optionSelected, setOptionSelected] = useState<number>(0)
  const { t } = useTranslation()
  const SetLegalOption = (option: number) => {
    if (optionSelected === option) return setOptionSelected(0)
    setOptionSelected(option)
  }

  useEffect(() => {
    setView({ path: 'legal_aspects', bgColor: 'web-bg-light-gray', textColor: 'text-black', textColor2: 'text-light-gray' })
  }, [])

  return (
    <WebSiteStructure className='min-screen-height'>
      <SectionStructure>
        <h1 className={`${view.textColor} mt-12`}>
          {t('legalAspect.title.line1')} <br /> {t('legalAspect.title.line2')}
        </h1>
        <p className='subtitle5-regular mt-6'>{t('legalAspect.subtitle')}</p>

        <div className={`lg:mt-40 md:mt-72 mt-28 ${view.textColor}`}>
          <h5 className='text-left cursor-pointer' onClick={() => SetLegalOption(1)}>
            1. {t('legalAspect.termCondition')}
            {optionSelected === 1 ? (
              <KeyboardArrowDownIcon fontSize='large' className='ml-1' />
            ) : (
              <KeyboardArrowUpIcon fontSize='large' className='ml-1' />
            )}
          </h5>
          <Collapse in={optionSelected === 1}>
            <TermsAndConditions />
          </Collapse>

          <h5 className='text-left cursor-pointer mt-4' onClick={() => SetLegalOption(2)}>
            2. {t('legalAspect.privacyPolicy')}
            {optionSelected === 2 ? (
              <KeyboardArrowDownIcon fontSize='large' className='ml-1' />
            ) : (
              <KeyboardArrowUpIcon fontSize='large' className='ml-1' />
            )}
          </h5>
          <Collapse in={optionSelected === 2}>
            <PrivacyPolicies />
          </Collapse>
        </div>
      </SectionStructure>
    </WebSiteStructure>
  )
}

export default LegalAspects
