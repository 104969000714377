import { FC } from 'react'
import { IFreelancer, IPorfolioFileDetail, IPortfolioFile } from '../../../models/IFreelancer'
import PictureLayout from '../../../shared/AvatarProfile/PictureLayout'

type TFreelancerProfilePortfolio = {
  freelancer: IFreelancer
}

const FreelancerProfilePortfolio: FC<TFreelancerProfilePortfolio> = (props) => {
  const hasPortfolioFiles = props.freelancer.portfolio_files.length > 0

  return (
    <div className='relative mt-6'>
      {props.freelancer.portfolio_video && (
        <div>
          <iframe
            className='w-full mt-4 h-600-to-unset'
            src={props.freelancer.portfolio_video}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>
      )}
      {hasPortfolioFiles && (
        <div className='grid grid-cols-12 gap-x-2'>
          {['a', 'b', 'c'].map((column: string) => {
            return (
              <div className='md:col-span-4 col-span-12'>
                {props.freelancer.portfolio_files
                  .filter((portfolio: IPortfolioFile) => portfolio.column === column)
                  .map((portfolio: IPortfolioFile) => {
                    return (
                      <div key={portfolio.column}>
                        {portfolio.files
                          .sort((a, b) => a.position - b.position)
                          .map((file: IPorfolioFileDetail) => {
                            return (
                              <div key={file.position} className='relative'>
                                {file.link ? (
                                  <iframe
                                    className='h-64 w-full mt-2'
                                    src={file.link}
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                    allowFullScreen
                                  />
                                ) : (
                                  <img src={file.url} alt={file.name} className='w-full mt-2' />
                                )}
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
      )}

      <PictureLayout className='relative z-10 ml-auto mr-4 -mt-6' avatar={props.freelancer.avatar.url} width={64} height={64} />
    </div>
  )
}

export default FreelancerProfilePortfolio
