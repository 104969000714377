import { useState } from 'react'

const usePagination = (defaultPage?: number) => {
  const [page, setPage] = useState(defaultPage ?? 1)

  const handlePage = (value: number) => {
    setPage(value)
  }

  return { page, handlePage }
}

export default usePagination
