import { FC, useEffect, useMemo } from 'react'
import { Link, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useOneProjectQuery } from '../../../customHooks/request/projectsQuery'
import TitleWithUnderline from '../../../shared/TitleWithUnderline/TitleWithUnderline'
import { ClientProjectCandidates } from './ClientProjectDetail/ClientProjectCandidates/ClientProjectCandidates'
import ProjectFeedback from '../../../shared/ProjectUserProfile/ProjectFeedback/ProjectFeedback'
import ProjectPayments from '../../../shared/ProjectUserProfile/ProjectPayments'
import { Box, useMediaQuery } from '@material-ui/core'
import FiButton from '../../../assets/UIkit/FiButton'
import { ProjectDetailLayout } from '../../../shared/ProjectUserProfile/ProjectDetailLayout'
import ProjectActivity from '../../../shared/ProjectUserProfile/ProjectActivity'
import CandidateWorkPlan from '../../../shared/ProjectUserProfile/CandidateWorkPlan'
import { projectDetailColumns } from '../../../utils/helpers'
import BriefDrawer from '../../../shared/ProjectUserProfile/Brief/BriefDrawer'
import useWorkPlanVersion from '../../../customHooks/useWorkPlanVersion'
import scrollTop from '../../../assets/images/web/scroll-top.svg'
import UpdateBrief from './ClientProjectDetail/UpdateBrief'
import { useTranslation } from 'react-i18next'

const ClientProjectDetail: FC = () => {
  const { t } = useTranslation()
  const params = useParams<{ projectId: string }>()
  const navigate = useHistory().push
  const isMobile = useMediaQuery('(max-width: 430px)')
  const isDesktop = useMediaQuery('(min-width: 825px)')

  const projectQuery = useOneProjectQuery(params.projectId)
  const { data: project, refetch: refetchProject, isSuccess } = projectQuery
  const workPlan = project && project.staff.length > 0 ? project?.staff[0].work_plan : undefined
  const selectedWorkPlanVersion = useWorkPlanVersion(workPlan)

  const isApproved = Boolean(
    project && project.staff.length && project.staff[0]?.work_plan?._id && project.staff[0]?.freelancer?._id
  )

  const goBackPath = useMemo(() => {
    if (!project) return ''

    return ['cancelled', 'finished'].includes(project.project_status)
      ? routes.clientUserProfile.projects.historical
      : routes.clientUserProfile.projects.list
  }, [project])

  const hasLastPayment = () => {
    if (!project || !selectedWorkPlanVersion) return

    const paymentsAmount = project.payments
      .filter((payment) => payment.entity === 'freelancer')
      .reduce((acum, payment) => {
        return (acum = acum + payment.amount)
      }, 0)

    return paymentsAmount >= selectedWorkPlanVersion.price.after_fee
  }

  const defaultProjectPath = () => {
    if (project && project.is_brief_incomplete) return `${routes.clientUserProfile.projects.detail}/${params.projectId}/brief`
    return isApproved
      ? `${routes.clientUserProfile.projects.detail}/${params.projectId}/actividad`
      : `${routes.clientUserProfile.projects.detail}/${params.projectId}/candidatos/lista`
  }

  useEffect(() => {
    if (isSuccess) navigate(defaultProjectPath())
  }, [isSuccess])

  if (!project) return null

  const briefProps = { ...project.brief, company_size: project.client.company_size, country: project.client.address.country }
  return (
    <div>
      <Link to={goBackPath}>
        <TitleWithUnderline title={project.brief.title} />
      </Link>
      {!project.is_brief_incomplete && (
        <div className='flex justify-end'>
          <a href='mailto:contacto@findie.cl'>
            <FiButton variant='outlined'>
              <p className='buttontext1-medium-fixed text-blue'>{t('PROJECT_DETAILS.CONTACT_US')}</p>
            </FiButton>
          </a>
        </div>
      )}

      <Box className='md:mt-12 mt-6' display='grid' gridTemplateColumns='1fr 120px' alignItems='center'>
        <Box display='grid' gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)} className='gap-8' alignItems='center'>
          {!project.is_brief_incomplete && (
            <div className='border-t border-black pt-2 md:block hidden'>
              {!isApproved && (
                <NavLink
                  to={`${routes.clientUserProfile.projects.detail}/${params.projectId}/candidatos/lista`}
                  className='subtitle6-regular text-black'
                  activeClassName='border-b border-black'
                >
                  {t('PROJECT_DETAILS.CANDIDATES')}
                </NavLink>
              )}
            </div>
          )}

          {isApproved && (
            <div className={`flex gap-12 pt-2 hide-scroll-bar slider`}>
              <div className='slider-item'>
                <NavLink
                  to={`${routes.clientUserProfile.projects.detail}/${params.projectId}/actividad`}
                  className='subtitle6-regular text-black'
                  activeClassName='border-b border-black'
                >
                  {t('PROJECT_DETAILS.ACTIVITY')}
                </NavLink>
              </div>
              <div className='slider-item'>
                <NavLink
                  to={`${routes.clientUserProfile.projects.detail}/${params.projectId}/acuerdo`}
                  className='subtitle6-regular text-black'
                  activeClassName='border-b border-black'
                >
                  {t('PROJECT_DETAILS.AGREEMENT')}
                </NavLink>
              </div>
              <div className='slider-item'>
                <NavLink
                  to={`${routes.clientUserProfile.projects.detail}/${params.projectId}/pagos`}
                  className='subtitle6-regular text-black'
                  activeClassName='border-b border-black'
                >
                  {t('PROJECT_DETAILS.PAYMENTS')}
                </NavLink>
              </div>
              <div className='slider-item'>
                {hasLastPayment() && (
                  <NavLink
                    to={`${routes.clientUserProfile.projects.detail}/${params.projectId}/feedback`}
                    className='subtitle6-regular text-black mr-6'
                    activeClassName='border-b border-black'
                  >
                    Feedback
                  </NavLink>
                )}
              </div>
            </div>
          )}
        </Box>
        {!project.is_brief_incomplete && (
          <BriefDrawer {...briefProps}>
            <div className='flex gap-2 items-center cursor-pointer '>
              <img className={`w-12 rotated-right`} src={scrollTop} alt='brief' />
              <p className='subtitle6-regular text-black mt-px'>Brief</p>
            </div>
          </BriefDrawer>
        )}
      </Box>

      {isApproved && (
        <Switch>
          <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/feedback`}>
            <ProjectDetailLayout
              gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)}
              freelancer={project.staff[0]?.freelancer}
            >
              <ProjectFeedback project={project} entity='client' refetchProject={refetchProject} />
            </ProjectDetailLayout>
          </Route>
          <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/pagos`}>
            <ProjectDetailLayout
              gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)}
              freelancer={project.staff[0]?.freelancer}
            >
              <ProjectPayments paymentEntity='client' {...project} />
            </ProjectDetailLayout>
          </Route>
          <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/acuerdo`}>
            <ProjectDetailLayout
              gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)}
              freelancer={project.staff[0]?.freelancer}
            >
              <div className='shadow-md md:px-6 px-2 pt-4 md:pb-10 pb-4'>
                <CandidateWorkPlan workPlan={project.staff[0].work_plan} withoutPrice />
              </div>
            </ProjectDetailLayout>
          </Route>
          <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/actividad`}>
            <ProjectDetailLayout
              gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)}
              freelancer={project.staff[0]?.freelancer}
            >
              <ProjectActivity entity='client' {...project} />
            </ProjectDetailLayout>
          </Route>
        </Switch>
      )}
      <Switch>
        <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/candidatos`}>
          <ClientProjectCandidates projectQuery={projectQuery} />
        </Route>
        <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/brief`}>
          <UpdateBrief projectQuery={projectQuery} />
        </Route>
      </Switch>
    </div>
  )
}

export default ClientProjectDetail
