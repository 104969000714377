import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IFormStepStructure } from '../../../../models/IFormStep'
import NewProjectStepLayout from './NewProjectStepLayout'
import SectionItem from './SectionItem'
import { budgetList, currencyBudgetList } from '../../../../constants/Cpanel/ProjectConstants'
import useUser from '../../../../customHooks/useUser'
import { useTranslation } from 'react-i18next'

export const NewProjectStepBudget: FC<IFormStepStructure> = (props) => {
  const { t, i18n } = useTranslation()
  const { slide } = useParams<{ slide: string }>()
  const { clientProfile } = useUser()
  const storage = props.storage
  const [budgetType] = useState(() => {
    const isSpanish = i18n.language.match(/es.*/)
    return isSpanish ? budgetList : currencyBudgetList
  })
  const { Title } = props

  useEffect(() => {
    slide === `${props.position}` && props.setIsButtonDisabled(Boolean(!storage?.item?.project_size))
  }, [slide, storage.item])

  useEffect(() => {
    if (clientProfile) storage.setItem({ ...storage.item, project_size: '', with_currency: clientProfile.is_foreign })
  }, [clientProfile])

  return (
    <>
      <Title>{t('UP_NEW_PROJECT.WHAT_BUDGET')}</Title>

      <NewProjectStepLayout className='total-center'>
        <div>
          {budgetType.map((iter) => {
            return (
              <SectionItem
                key={iter.label}
                className='mt-4'
                title={iter.label}
                direction='row'
                isActive={storage?.item?.project_size === iter.label}
                onClick={() =>
                  storage.setItem({ ...storage.item, project_size: iter.value, with_currency: clientProfile?.is_foreign })
                }
              />
            )
          })}
        </div>
      </NewProjectStepLayout>
    </>
  )
}
