import { FC } from 'react'
import { IWorkPlan } from '../../models/IWorkPlan'
import { formatDecimal, weekDaysDict } from '../../utils/helpers'
import useWorkPlanVersion from '../../customHooks/useWorkPlanVersion'
import dayjs from 'dayjs'

type TCandidateWorkPlan = {
  workPlan: IWorkPlan
  withoutPrice?: boolean
}

const CandidateWorkPlan: FC<TCandidateWorkPlan> = (props) => {
  const { workPlan } = props
  const selectedWorkPlanVersion = useWorkPlanVersion(workPlan)

  return (
    <div className='grid grid-cols-12 gap-8'>
      <div className='md:col-span-4 col-span-12'>
        <p className='microcopy text-gray-400'>Fecha de inicio</p>
        <p className='body2-regular border-b border-gray-400'>{dayjs(workPlan.start_date ?? 0).format('DD/MM/YYYY')}</p>

        <p className='microcopy text-gray-400 mt-5'>Fecha de entrega</p>
        <p className='body2-regular border-b border-gray-400'>{dayjs(workPlan.end_date ?? 0).format('DD/MM/YYYY')}</p>

        {!props.withoutPrice && (
          <>
            <p className='microcopy text-gray-400 mt-5'>Honorarios</p>
            <p className='body2-regular border-b border-gray-400'>{formatDecimal(selectedWorkPlanVersion?.price.total ?? 0)}</p>
          </>
        )}

        <p className='microcopy text-gray-400 mt-5'>Disponibilidad</p>
        <p className='body2-regular'>
          {workPlan.availability.days
            .map((day) => weekDaysDict(day))
            .toString()
            .replaceAll(',', ', ')}
        </p>
        <p className='body2-regular border-b border-gray-400'>
          {workPlan.availability.time_from} - {workPlan.availability.time_to} - {workPlan.availability.time_zone}
        </p>

        {selectedWorkPlanVersion?.iterations && (
          <>
            <p className='microcopy text-gray-400 mt-5'>Correcciones </p>
            <p className='body2-regular border-b border-gray-400'>{selectedWorkPlanVersion?.iterations}</p>
          </>
        )}
      </div>

      <div className='md:col-span-8 col-span-12'>
        <p className='microcopy text-gray-400'>Descripción propuesta</p>
        <textarea
          className='body2-regular border-b border-gray-400 bg-transparent w-full'
          value={workPlan.description}
          rows={4}
          disabled
        />

        <p className='microcopy text-gray-400 mt-5'>Planificación</p>
        <textarea
          className='body2-regular border-b border-gray-400 bg-transparent w-full'
          value={workPlan.considerations}
          rows={4}
          disabled
        />

        {selectedWorkPlanVersion?.iterations && (
          <>
            <p className='microcopy text-gray-400 mt-5'>Entregables</p>
            <textarea
              className='body2-regular border-b border-gray-400 bg-transparent w-full'
              value={selectedWorkPlanVersion?.deliverables}
              rows={4}
              disabled
            />
          </>
        )}
      </div>
    </div>
  )
}

export default CandidateWorkPlan
