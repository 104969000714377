import { useEffect, useState } from 'react'
import { IFormStep } from '../models/IFormStep'
import useLocalStorage from './useLocalStorage'

const useNewClient = () => {
  const storage = useLocalStorage('new_client', undefined)
  const emailProps = { storage } as IFormStep
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)

  useEffect(() => {
    if (!isVerificationModalOpen) storage.removeItem()
  }, [isVerificationModalOpen])

  return { storage, emailProps, isVerificationModalOpen, setIsVerificationModalOpen }
}

export default useNewClient
