import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useProjectListWithOfferQuery } from '../../../customHooks/request/projectsQuery'
import useUser from '../../../customHooks/useUser'
import ProjectItemCard from '../../../shared/ProjectUserProfile/ProjectItemCard'
import TitleWithUnderline from '../../../shared/TitleWithUnderline/TitleWithUnderline'
import UserProfileDialog from '../../../shared/UserProfileDialog/UserProfileDialog'

const ClientProjectList: FC = () => {
  const { user } = useUser()
  const { data: projectQuery } = useProjectListWithOfferQuery({
    filters: {
      client: user?.profile_id ?? '',
      $or: [{ project_status: 'not_approved' }, { project_status: 'in_progress' }, { project_status: 'paused' }],
    },
    enabled: Boolean(user?.profile_id),
  })

  return (
    <div>
      <Link to={routes.clientUserProfile.projects.options}>
        <TitleWithUnderline title='Proyectos' />
      </Link>

      <div className='mt-12'>
        {projectQuery && (
          <>
            {projectQuery.data.length > 0 ? (
              projectQuery.data.map((project) => {
                return <ProjectItemCard key={project._id} {...project} entity='client' />
              })
            ) : (
              <UserProfileDialog>
                <h4 className='text-blue text-center'>¡Mmmm! aún no tienes proyectos activos.</h4>
              </UserProfileDialog>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ClientProjectList
