type TProgressBar = {
  percent: number
  className?: string
}

const ProgressBar = (props: TProgressBar) => {
  return (
    <div className={`new-project-steps w-full transition-all duration-200 ease-in-out ${props.className ?? ''}`}>
      <div
        className='h-full ml-auto bg-white transition-all duration-200 ease-in-out'
        style={{ width: `${100 - props.percent}%` }}
      />
    </div>
  )
}

export default ProgressBar
