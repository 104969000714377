import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { IconButton } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import InputField from '../../assets/UIkit/Forms/InputField'
import { useTranslation } from 'react-i18next'

type TUpdatePasswordForm = {
  form: UseFormReturn<any>
}

const UpdatePasswordForm: FC<TUpdatePasswordForm> = (props) => {
  const { t } = useTranslation()
  const [isShowPassword, setIsShowPassword] = useState(false)
  const password = props.form.watch('password')
  const password_repeat = props.form.watch('password_repeat')

  useEffect(() => {
    props.form.setValue('password_repeat', password_repeat ? password_repeat.trim() : '')
    props.form.setValue('password', password ? password.trim() : '')
  }, [password_repeat, password])

  return (
    <div className='mt-4'>
      <div className='grid grid-cols-12 lg:gap-6 gap-x-0 gap-y-4'>
        <div className='lg:col-span-6 col-span-12'>
          <div className='relative'>
            <InputField
              name='password'
              label={`${t('GLOBAL.PASSWORD')}`}
              labelClassName='text-gray-500'
              className='w-full'
              variant='default'
              inputProps={{ className: 'w-full', type: isShowPassword ? 'text' : 'password' }}
              form={props.form}
            />
            {isShowPassword ? (
              <IconButton
                className='absolute right-4 top-4 z-10'
                style={{ top: 'calc(50% - 12px)' }}
                onClick={() => setIsShowPassword(false)}
              >
                <VisibilityOffIcon className='text-gray-300' />
              </IconButton>
            ) : (
              <IconButton className='absolute right-4 z-10 top-4' onClick={() => setIsShowPassword(true)}>
                <VisibilityIcon className='text-gray-300' />
              </IconButton>
            )}
          </div>
          <div className='relative'>
            <InputField
              name='password_repeat'
              label={`${t('GLOBAL.CONFIRM_PW')}`}
              labelClassName='text-gray-500'
              className='w-full'
              variant='default'
              inputProps={{ className: 'w-full', type: isShowPassword ? 'text' : 'password' }}
              form={props.form}
            />
            {isShowPassword ? (
              <IconButton
                className='absolute right-4 top-4 z-10'
                style={{ top: 'calc(50% - 12px)' }}
                onClick={() => setIsShowPassword(false)}
              >
                <VisibilityOffIcon className='text-gray-300' />
              </IconButton>
            ) : (
              <IconButton className='absolute right-4 top-4 z-10' onClick={() => setIsShowPassword(true)}>
                <VisibilityIcon className='text-gray-300' />
              </IconButton>
            )}
          </div>
        </div>
        <div className='lg:col-span-6 col-span-12 flex items-end md:px-4 px-0 md:mt-0 mt-4'>
          <div className='border border-black w-full'>
            <div className='p-4'>
              <div className='flex items-center'>
                {/^.{6,}$/.test(password) ? (
                  <CheckRoundedIcon className='text-soft-green mr-2' />
                ) : (
                  <CloseRoundedIcon className='text-red-400 mr-2' />
                )}
                <p className='subtitle4-regular'>{t('GLOBAL.PW_PARAMETERS.SIX_CHARACTERS')}</p>
              </div>
              <div className='flex items-center'>
                {/\d/.test(password) ? (
                  <CheckRoundedIcon className='text-soft-green mr-2' />
                ) : (
                  <CloseRoundedIcon className='text-red-400 mr-2' />
                )}
                <p className='subtitle4-regular'>{t('GLOBAL.PW_PARAMETERS.ONE_NUMBER')}</p>
              </div>
              <div className='flex items-center'>
                {/[A-Z]/.test(password) ? (
                  <CheckRoundedIcon className='text-soft-green mr-2' />
                ) : (
                  <CloseRoundedIcon className='text-red-400 mr-2' />
                )}
                <p className='subtitle4-regular'>{t('GLOBAL.PW_PARAMETERS.ONE_CAP')}</p>
              </div>
              <div className='flex items-center'>
                {/^.{6,}$/.test(password) && password === password_repeat ? (
                  <CheckRoundedIcon className='text-soft-green mr-2' />
                ) : (
                  <CloseRoundedIcon className='text-red-400 mr-2' />
                )}
                <p className='subtitle4-regular'>{t('GLOBAL.PW_PARAMETERS.BE_SAME')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdatePasswordForm
