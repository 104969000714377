import { UseQueryResult } from 'react-query'
import ClpField from '../../../../assets/UIkit/Forms/ClpField'
import InputField from '../../../../assets/UIkit/Forms/InputField'
import SelectField from '../../../../assets/UIkit/Forms/SelectField'
import { FiIcons } from '../../../../assets/UIkit/Icons/FiIcons'
import { siiTax } from '../../../../constants/Cpanel/ProjectConstants'
import { IWorkPlanVersion, TVersion } from '../../../../models/IWorkPlan'
import { cleanCLP, cleanString, formatDecimal } from '../../../../utils/helpers'
import { IOffer } from '../../../../models/IOffer'
import { useUpdateWorkPlan } from '../../../../customHooks/request/workPlanQuery'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import { useProjectFeesQuery } from '../../../../customHooks/request/PaymentMethodsQuery'
import { useMemo, useState } from 'react'
import useTotalWorkPlanPrice from '../../../../customHooks/useTotalWorkPlanPrice'

type TFreelancerVersion = {
  offerQuery: UseQueryResult<IOffer, unknown>
  hasThisVersion: IWorkPlanVersion | undefined
  isSelectedVersion: boolean
  isDisabledFields: boolean
  form: any
  is_negotiable: boolean
  negotiationVersion: TVersion
  version: TVersion
  hasStandardVersion: boolean
  asCpanel?: boolean
  handleVersion: (currentVersion: TVersion) => void
}

type THandleWorkPlan = {
  version: TVersion
  formVersion?: TVersion
  isFreelancerNegotiating?: boolean
  isSelected?: boolean
}

const DEFAULT_COUNTRY = 'chile'

const FreelancerVersion = (props: TFreelancerVersion) => {
  const { data: paymentMethodsQuery } = useProjectFeesQuery()
  const { data: offerQuery, refetch: refetchOffer } = props.offerQuery
  const updateFreelancerVersion = useUpdateWorkPlan()
  useRequestAlert(
    updateFreelancerVersion,
    undefined,
    undefined,
    undefined,
    `Tu propuesta fue guardada como borrador. Para enviar, haz click en "Enviar propuesta".`,
    undefined,
    9000
  )
  const { total } = useTotalWorkPlanPrice({
    offer: offerQuery,
    selectedProducts: [],
    selectedWorkPlanVersion: props.hasThisVersion,
  })

  const [versionError, setVersionError] = useState('')

  const isDisabledFields = props.isDisabledFields || !props.isSelectedVersion
  const isLocal = offerQuery ? cleanString(offerQuery?.freelancer.address.country).includes(DEFAULT_COUNTRY) : true

  const paymentMethods = useMemo(() => {
    if (!paymentMethodsQuery || !offerQuery) return []

    return paymentMethodsQuery.data.map((method) => ({ method: method.name, fee: method.fee }))
  }, [paymentMethodsQuery, offerQuery])

  const handlePlanVersion = () => {
    if (!props.hasStandardVersion) {
      setVersionError('Llena versión Standard primero.')
      setTimeout(() => {
        setVersionError('')
      }, 3000)
      return
    }
    props.handleVersion(props.version)
  }

  const handleWorkPlan = async (params: THandleWorkPlan) => {
    if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return

    await props.form.trigger()
    const formErrorsList = Object.keys(props.form.formState.errors)
    if (formErrorsList.length > 0) return

    const subtotal = cleanCLP(props.form.getValues(`subtotal-${params.formVersion ?? params.version}`))
    const sii =
      isLocal && !offerQuery.project.with_currency
        ? JSON.parse(props.form.getValues(`sii-${params.formVersion ?? params.version}`))
        : { name: 'none', type: 'none', tax: 0, ticket_constant: 0 }

    const payment_method = JSON.parse(props.form.getValues(`payment_method-${params.formVersion ?? params.version}`))
    const iterations = props.form.getValues(`iterations-${params.formVersion ?? params.version}`)
    const deliverables = props.form.getValues(`deliverables-${params.formVersion ?? params.version}`)
    const newVersion = {
      name: params.version,
      iterations,
      deliverables,
      is_selected: Boolean(params.isSelected),
      is_negotiated_version: Boolean(params.isFreelancerNegotiating),
      price: { subtotal, sii, payment_method },
    }

    let versions = offerQuery.work_plan.versions

    const hasThisVersion = versions.some((thisVersion) => thisVersion.name === params.version)
    if (hasThisVersion) {
      versions = versions.map((thisVersion) => {
        if (thisVersion.name === params.version) return { ...thisVersion, ...newVersion } as IWorkPlanVersion
        return thisVersion
      })
    } else {
      versions = [...versions, newVersion] as IWorkPlanVersion[]
    }

    const body = {
      versions,
      freelancerEmail: offerQuery.freelancer.email,
      projectName: offerQuery.brief.title,
      projectPlan: offerQuery.project.plan,
      freelancerPlan: offerQuery.freelancer.plan,
      isProjectWithCurrency: offerQuery.project.with_currency,
    }
    updateFreelancerVersion.mutate(
      { body, id: offerQuery.work_plan._id },
      {
        onSuccess: async () => refetchOffer(),
      }
    )
  }

  return (
    <div className={`md:col-span-4 col-span-12 p-4 cursor-pointer`} onClick={handlePlanVersion}>
      <div className='col-span-12 mb-2 flex justify-between'>
        <p className='subtitle3-20'>
          {props.version}
          {props.hasThisVersion?.is_negotiated_version && <span className='text-rose-video ml-1'>New!</span>}
        </p>

        {props.version === 'Standard' ? (
          <>{props.hasStandardVersion && <FiIcons className='cursor-pointer' name='checkedSquare' />}</>
        ) : (
          <FiIcons className='cursor-pointer' name={props.hasThisVersion ? 'checkedSquare' : 'uncheckedSquare'} />
        )}
      </div>
      {props.version !== 'Standard' && <p className='text-red microcopy col-span-12'>{versionError}</p>}

      {isLocal && !offerQuery?.project.with_currency && (
        <SelectField
          name={`sii-${props.version}`}
          label='Documento de pago'
          labelClassName={props.hasThisVersion?.is_negotiated_version ? 'text-rose-video' : 'text-blue'}
          className='col-span-12'
          variant='default'
          showErrorMessage={props.isSelectedVersion || props.version === 'Standard'}
          inputProps={{ disabled: isDisabledFields, className: 'w-full' }}
          selectOptions={siiTax.map((sii) => {
            return { value: JSON.stringify(sii), label: sii.name }
          })}
          options={{
            required: props.isSelectedVersion || props.version === 'Standard' ? 'Este campo es requerido' : false,
          }}
          form={props.form}
        />
      )}
      <SelectField
        name={`payment_method-${props.version}`}
        label='Plataforma de pago'
        labelClassName={props.hasThisVersion?.is_negotiated_version ? 'text-rose-video' : 'text-blue'}
        className='col-span-12'
        variant='default'
        showErrorMessage={props.isSelectedVersion || props.version === 'Standard'}
        inputProps={{ disabled: isDisabledFields, className: 'w-full' }}
        selectOptions={paymentMethods.map((payment) => {
          return { value: JSON.stringify(payment), label: payment.method }
        })}
        options={{
          required: props.isSelectedVersion || props.version === 'Standard' ? 'Este campo es requerido' : false,
        }}
        form={props.form}
      />
      {props.asCpanel && (
        <div className='col-span-12'>
          <p className={`microcopy text-blue`}>Precio</p>
          <p className='body2-regular border-b-blue'>{total}</p>
        </div>
      )}
      <ClpField
        name={`subtotal-${props.version}`}
        isCurrency={offerQuery?.project.with_currency}
        label={`Costo ${
          props.form.watch(`after_fee-${props.version}`)
            ? `(Recibes ${!offerQuery?.project.with_currency ? 'CLP' : ''} ${formatDecimal(
                Number(props.form.watch(`after_fee-${props.version}`))
              )} ${offerQuery?.project.with_currency ? 'USD' : ''})`
            : ''
        }`}
        labelClassName={props.hasThisVersion?.is_negotiated_version ? 'text-rose-video' : 'text-blue'}
        className='col-span-12 mt-1'
        variant='default'
        showErrorMessage={props.isSelectedVersion || props.version === 'Standard'}
        inputProps={{
          disabled: isDisabledFields,
          className: 'w-full',
          placeholder: offerQuery?.project.with_currency ? 'USD 100' : '$0.000',
          step: 'any',
          type: 'number',
        }}
        options={{
          required: props.isSelectedVersion || props.version === 'Standard' ? 'Ingresa un número' : false,
        }}
        form={props.form}
      />
      <InputField
        className='col-span-12 mt-1'
        name={`iterations-${props.version}`}
        label='Correcciones'
        showErrorMessage={props.isSelectedVersion || props.version === 'Standard'}
        inputProps={{
          disabled: isDisabledFields,
          className: 'w-full',
          type: 'number',
        }}
        labelClassName={props.hasThisVersion?.is_negotiated_version ? 'text-rose-video' : 'text-blue'}
        variant='default'
        options={{
          required: props.isSelectedVersion || props.version === 'Standard' ? 'Este campo es requerido' : false,
        }}
        form={props.form}
      />
      <InputField
        className='col-span-12 mt-1'
        name={`deliverables-${props.version}`}
        label='Entregables'
        showErrorMessage={props.isSelectedVersion || props.version === 'Standard'}
        textareaProps={{
          disabled: isDisabledFields,
          className: 'w-full',
          rows: 5,
        }}
        labelClassName={props.hasThisVersion?.is_negotiated_version ? 'text-rose-video' : 'text-blue'}
        variant='default'
        textarea
        options={{
          required: props.isSelectedVersion || props.version === 'Standard' ? 'Este campo es requerido' : false,
        }}
        form={props.form}
      />

      {props.isSelectedVersion && props.negotiationVersion === 'none' && !props.isDisabledFields && (
        <button
          className={`col-span-12 w-full py-1 cursor-pointer mt-2 border-blue text-blue`}
          onClick={() =>
            handleWorkPlan({
              version: props.version,
            })
          }
        >
          Guardar
        </button>
      )}
      {props.isSelectedVersion && props.negotiationVersion !== 'none' && !props.isDisabledFields && (
        <button
          className={`col-span-12 w-full py-1 cursor-pointer mt-2 border-blue text-blue`}
          onClick={() =>
            handleWorkPlan({
              version: props.version,
              isFreelancerNegotiating: true,
            })
          }
        >
          Guardar contra oferta
        </button>
      )}
    </div>
  )
}

export default FreelancerVersion
