import { IWebSiteSection } from '../../../models/IWebSiteSection'

import SectionStructure from '../../../layout/WebSite/SectionStructure'
import Arrow from '../../../assets/UIkit/Arrow'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useTranslation } from 'react-i18next'
const AboutUsIntro: React.FC<IWebSiteSection & { executeScroll: (e: string) => void }> = (props) => {
  const { t } = useTranslation()
  return (
    <SectionStructure className='mt-14'>
      <div className='about-us-degrade 2xl:w-11/12 lg:w-full md:w-11/12 w-full'>
        <div className='lg:w-7/12 md:w-11/12 w-full'>
          <h1> {t('ABOUTFINDIE.AboutUsIntro.title')}</h1>
          <p className='subtitle5-regular mt-6 md:w-4/6 lg:w-full'>{t('ABOUTFINDIE.AboutUsIntro.subtitle')}</p>
        </div>
        <div className={`lg:mt-8 md:mt-72 mt-36 cursor-pointer ${props.view.textColor}`}>
          <Link to={routes.web_site.freelancers_profiles}>
            <span className={`buttontext3-bold text-left mr-4 md:inline block ${props.view.textColor}`}>
              {t('ABOUTFINDIE.AboutUsIntro.link')}
            </span>
            <Arrow direction='horizontal' className='md:inline block' />
          </Link>
        </div>
      </div>
    </SectionStructure>
  )
}

export default AboutUsIntro
