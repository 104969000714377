import { useEffect } from 'react'
import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import ProductsSlider from '../../shared/TextSlider/ProductsSlider'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'
import HowWorksSections from '../../components/WebSite/HowWorks/HowWorksSections'
import PostulationBanner from '../../shared/PostulationBanner/PostulationBanner'
import smallFindie from '../../assets/images/web/about-us/small-findie.svg'
import smallBlackWhite from '../../assets/images/web/about-us/small-black-white.svg'
import FeaturedFreelancers from '../../components/WebSite/HomePage/FeaturedFreelancers'
import WhyFindieIntro from '../../components/WebSite/WhyFindie/WhyFindieIntro'
import ClientFeatured from '../../components/WebSite/WhyFindie/ClientFeatured'
import ReactPlayer from 'react-player'
// @ts-ignore
import myVideo from '../../assets/video/FIndie.mp4'
import screenshot from '../../assets/images/web/how-works/screenshot-video.png'
import { useTranslation } from 'react-i18next'

const WhyFindie = () => {
  const { t } = useTranslation()
  const { view, setView } = useWebSiteLayout()

  useEffect(() => {
    setView({ path: 'why_findie', bgColor: 'web-bg-sea-blue', textColor: 'text-white', textColor2: 'text-sea-blue' })
  }, [])

  return (
    <>
      <WebSiteStructure>
        <WhyFindieIntro view={view} />
        <ClientFeatured view={view} />
        <HowWorksSections view={view} />
      </WebSiteStructure>

      <FeaturedFreelancers view={view} />
      <ProductsSlider view={view} />

      <WebSiteStructure>
        <div className={`video-container w-full mt-20 text-white`}>
          <ReactPlayer
            width='100%'
            height='100%'
            controls
            autoplay={true}
            url={myVideo}
            light={<img src={screenshot} alt='Thumbnail' width='100%' height='100%' />}
          />
          <div className={`grid grid-cols-12 gap-8 mt-6`}>
            <p className='subtitle5-regular text-white lg:col-span-7 md:col-span-6 col-span-12'>
              {t('HOWWORKS.HowWorksIntro.disclaimer')}
            </p>
            <p className='microcopy whitespace-pre-wrap text-right text-white lg:col-span-5 md:col-span-6 col-span-12'>
              {t('HOWWORKS.HowWorksIntro.credits')}{' '}
            </p>
          </div>
        </div>
        <PostulationBanner title='No esperes más,' bgColor='bg-sea-blue' textColor='text-white' freelancerWithoutBorder />

        <div className={'bg-sea-blue'}>
          <div className={'grid grid-cols-12 px-0 py-0 lg:w-4/5 mx-auto'}>
            <div className={'col-span-6'}>
              <img src={smallFindie} width='100%' />
            </div>
            <div className={'col-span-6'}>
              <img src={smallBlackWhite} width='100%' />
            </div>
          </div>
        </div>
      </WebSiteStructure>
    </>
  )
}

export default WhyFindie
