import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'
import routes from '../constants/routes'

import FiButton from '../assets/UIkit/FiButton'
import InputField from '../assets/UIkit/Forms/InputField'
import FindieGifIsotype from '../shared/Brand/Isotype/FindieGifIsotype'
import { IAuth } from '../models/IAuth'
import { useForgotPasswordMutation } from '../customHooks/request/authQuery'
import dayjs from 'dayjs'

const ForgotPassword: React.FC = () => {
  const form = useForm()
  const forgotPasswordMutation = useForgotPasswordMutation()
  const [requestMessage, setRequestMessage] = useState<string>('')

  const sendEmail: SubmitHandler<IAuth> = async (body) => {
    forgotPasswordMutation.mutate(
      { body, method: 'POST' },
      {
        onSuccess: (resp) => {
          if (resp.status === 'not found') return setRequestMessage('Este correo no existe en nuestra base de datos.')
          setRequestMessage('Revisa tu bandeja de correo electrónico, para restablecer tu contraseña')
        },
        onError: () => setRequestMessage('Ups, hubo un error, inténtalo  nuevamente.'),
      }
    )
  }

  return (
    <div className='h-small-screen w-screen flex flex-col justify-center items-center'>
      <form className='lg:w-1/3 md:w-4/5 w-full px-4' onSubmit={form.handleSubmit(sendEmail)}>
        <Link to={routes.web_site.home}>
          <FindieGifIsotype className='block mx-auto w-28 h-28' />
        </Link>

        <h2 className='mt-12 text-center'>Hola, ingresa tu correo para que hagamos el reset de tu contraseña</h2>

        <div className='h-32'>
          <InputField
            name='email'
            form={form}
            options={{ required: 'Este campo es requerido' }}
            inputProps={{
              className: 'mt-4 border border-gray-300 lg:w-9/12 w-full mx-auto block',
              placeholder: 'Ingresa tu correo',
            }}
          />
          <p className='body2-medium mt-2 text-center'>{requestMessage}</p>
        </div>

        <FiButton type='submit' theme='secondary' variant='outlined' className='block mx-auto px-8'>
          Enviar email
        </FiButton>
        <p className='microcopy text-center mt-16'>® Findie SpA {dayjs().format('YYYY')}</p>
      </form>
    </div>
  )
}

export default ForgotPassword
