import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQueryParams = () => {
  const { search: params, pathname } = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(params), [params])

  const get = (param: string) => {
    return queryParams.get(param)
  }

  const set = (key: string, param: string) => {
    queryParams.set(key, param)
    window.history.replaceState({}, '', `${pathname}?${queryParams}`)
  }

  return { params, get, set }
}

export default useQueryParams
