import { Box } from '@material-ui/core'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import routes from '../../constants/routes'
import { CurrentViewContext } from '../../context/WebSite/CurrentViewContext'
import { IFreelancer } from '../../models/IFreelancer'

import FindieIsotype from '../Brand/Isotype/FindieIsotype'

import { withStyles } from '@material-ui/core/styles'
import { ISkill } from '../../models/ISkill'
import SingleAvatarProfile from '../AvatarProfile/SingleAvatarProfile'
import WebAvatarProfile from '../AvatarProfile/WebAvatarProfile'
import { capitalizeFirstLetter } from '../../utils/helpers'
import PillBoxSkills from '../PillBoxSkills/PillBoxSkills'

type TProfilesFreelancerCard = {
  freelancer: IFreelancer
}

const StyledMenu = withStyles({
  paper: {
    border: 'none',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const ProfilesFreelancerCard: React.FC<TProfilesFreelancerCard> = (props) => {
  const { view } = useContext(CurrentViewContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useHistory().push
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goToProfile = (path: string) => {
    handleClose()
    navigate(path)
  }

  return (
    <>
      {/* Desktop/tablet card */}
      <Link to={`${routes.web_site.freelancer_profile}/${props.freelancer._id}`} className='md:block hidden'>
        <WebAvatarProfile {...props.freelancer} featuredicon={props.freelancer.featured_status.has_feartued_icon} noSkills />
      </Link>

      {/* Mobile card */}
      <button onClick={handleClick} className='md:hidden block relative'>
        <SingleAvatarProfile
          portfolioImage={props.freelancer.portfolio_avatar}
          avatarImage={props.freelancer.avatar.url}
          categoryImage={props.freelancer.category.image}
          asSquare
          withoutRemoveIcon
        />
        {props.freelancer.featured_status.has_feartued_icon && (
          <FindieIsotype variant='golden' className='absolute bottom-1 right-2' />
        )}
      </button>
      <StyledMenu id='customized-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className='px-4 py-2 w-72'>
          <div className='flex items-center gap-1.5'>
            <p className={`subtitle5-medium ${view.textColor}`}>
              {props.freelancer.name} {capitalizeFirstLetter(props.freelancer.lastName)}.
            </p>
          </div>
          <p className={`body2-regular mt-2 ${view.textColor}`}>{props.freelancer.college_degree}</p>
          <PillBoxSkills skills={props.freelancer.skills} limitSkill={4} />
          <p
            className='microcopy mt-2 text-blue text-right cursor-pointer'
            onClick={() => goToProfile(`${routes.web_site.freelancer_profile}/${props.freelancer._id}`)}
          >
            Ir al perfil
          </p>
        </div>
      </StyledMenu>
    </>
  )
}

export default ProfilesFreelancerCard
