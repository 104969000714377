import Pillbox from '../Pillbox/Pillbox'

type TProjectStatusPillBox = {
  postulationStatus: 'step_one' | 'step_two' | 'step_three'
  variant?: 'contained' | 'outlined'
  className?: string
}

const FreelancerEvaluationStatusPillBox = (props: TProjectStatusPillBox) => {
  const { variant = 'contained' } = props

  const setProjectStatus = () => {
    if (props.postulationStatus === 'step_one') {
      return { title: 'Formulario recibido', styles: variant === 'contained' ? 'bg-magenta' : 'border-magenta text-magenta' }
    }
    if (props.postulationStatus === 'step_two') {
      return { title: 'Entrevista', styles: variant === 'contained' ? 'bg-soft-green' : 'border-soft-green text-soft-green' }
    }
    if (props.postulationStatus === 'step_three')
      return { title: 'Registrar', styles: variant === 'contained' ? 'bg-black' : 'border border-black text-black' }

    return { title: '', bgColor: 'bg-transparent text-transparent' }
  }

  return (
    <Pillbox className={`py-0 inline-block ${props.className} ${setProjectStatus().styles}`}>{setProjectStatus().title}</Pillbox>
  )
}

export default FreelancerEvaluationStatusPillBox
