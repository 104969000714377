import { IWebSiteSection } from '../../../models/IWebSiteSection'

import { useEffect, useState } from 'react'
import SectionStructure from '../../../layout/WebSite/SectionStructure'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FindieGifIsotype from '../../../shared/Brand/Isotype/FindieGifIsotype'

const OurHistory: React.FC<IWebSiteSection> = (props) => {
  const { t, i18n } = useTranslation()
  let newHistoryOptions: any[] = t('ABOUTFINDIE.OurHistory.historyOptions', { returnObjects: true })
  const [selectedHistory, setSelectedHistory] = useState(0)
  const contentWidth = newHistoryOptions[selectedHistory].isComponents ? 'w-full' : 'lg:w-7/12 md:w-10/12 w-full'

  return (
    <SectionStructure id='our_history'>
      <div className='flex justify-between items-center lg:mt-4 mt-8'>
        {newHistoryOptions.map((option, index) => {
          const isSelectedStyle = option.title === newHistoryOptions[selectedHistory].title ? 'border-b border-white' : ''
          return (
            <div key={option.title} className='cursor-pointer' onClick={() => setSelectedHistory(index)}>
              <span className={`subtitle2-bold w-auto mx-auto pb-2 ${isSelectedStyle} ${props.view.textColor}`}>
                {option.title}
              </span>
            </div>
          )
        })}
      </div>
      {newHistoryOptions[selectedHistory].isComponents && (
        <Box minHeight={465} className={`mt-8 ${contentWidth}`}>
          <p className={`formtext1-regular whitespace-pre-wrap`}>
            {newHistoryOptions[selectedHistory].content.map((iter: string) => {
              return (
                <Box display='grid' gridTemplateColumns='56px 1fr' key={iter} className={`-ml-4 md:mt-0 mt-4`}>
                  <div>
                    <FindieGifIsotype variant='transparent-white' className='w-14' />
                  </div>
                  <p className='formtext1-regular mt-1 mb-1 self-center'>{iter}</p>
                </Box>
              )
            })}
          </p>
        </Box>
      )}
      {!newHistoryOptions[selectedHistory].isComponents && (
        <Box minHeight={465} className={`mt-8 ${contentWidth}`}>
          <p className={`formtext1-regular whitespace-pre-wrap`}>{newHistoryOptions[selectedHistory].content}</p>
        </Box>
      )}
    </SectionStructure>
  )
}

export default OurHistory
