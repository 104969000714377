import { FC } from 'react'

const HelpBook: FC = () => {
  return (
    <div className='w-10/12'>
      <p className='body2-regular'>
        El book es un catálogo digital donde los clientes miran los perfiles de los freelancers. Recuerda que debes usar un tono
        profesional y elocuente.
      </p>

      <p className='body2-regular mt-6'>
        <span className='body2-medium'>Avatar: </span> imagen en blanco y negro de tu rostro o isotipo. Plano medio o corto, 648
        pixeles de ancho, 150 ppi, formato PNG.
      </p>

      <p className='body2-regular mt-6'>
        <span className='body2-medium'>Introducción: </span> qué has hecho, dónde, por cuánto tiempo, cuáles son tus fuertes, qué
        tipo de proyectos te interesan; en 280 caracteres.
      </p>

      <p className='body2-regular mt-6'>
        <span className='body2-medium'>Habilidades: </span> qué dominas y en qué eres experto.
      </p>

      <p className='body2-regular mt-6'>
        <span className='body2-medium'>XP destacadas: </span> experiencias destacadas (premios, concursos, exhibiciones,
        publicaciones, trabajos, docencia, estudios).
      </p>

      <p className='body2-regular mt-6'>
        <span className='body2-medium'>Educación: </span> puede ser en una institución formal, como puede ser un workshop, o un
        curso online. 648 pixeles de ancho (3:2) 150 ppi Formato PNG Formato GIF tamaño máximo de 8 MB. También puedes subir 2
        videos máximo de 8 MB.
      </p>

      <p className='body2-regular mt-6'>
        <span className='body2-medium'>Portafolio: </span> 9 a 21 imágenes de trabajos (9 pueden ser gifs) sin referencia a tu
        sitio personal, IG, Behance. 648 pixeles de ancho y largo variable, 150 ppi Formato PNG Formato GIF, tamaño máximo de 12
        MB. También puedes subir 1 video máximo de 8 MB.
      </p>
    </div>
  )
}

export default HelpBook
