import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ISkill } from '../models/ISkill'
import { useSetLoader } from './useSetLoader'
import { useSkillListQuery } from './request/skillsQuery'
import { sortByString } from '../utils/helpers'
import useCategories from './useCategories'
import { ICategory } from '../models/ICategory'

const useCategoryAndSkills = ({
  form,
  selectedSkillsName = 'skills',
  selectedCategoryName = 'category',
}: {
  form: UseFormReturn<any>
  selectedSkillsName?: string
  selectedCategoryName?: string
}) => {
  const selectedSkill = form.watch('skills_options')
  const formSelectedSkills = form.watch(selectedSkillsName) ?? []
  const formSelectedCategory = form.watch(selectedCategoryName) ?? ''
  const selectedCategory = form.watch('category_selected')
  const parsedSelectedCategory = selectedCategory ? JSON.parse(selectedCategory) : undefined
  const { data: categoriesQuery } = useCategories()
  const { data: skillsQuery, refetch: refetchSkills } = useSkillListQuery({
    filters: { category: parsedSelectedCategory?._id },
    enabled: false,
  })
  const [skillsList, setSkillsList] = useState<ISkill[]>([])
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>(formSelectedSkills)
  const [categorySelected, setCategorySelected] = useState<ICategory>()
  const loader = useSetLoader()

  const getSkills = async () => {
    if (!parsedSelectedCategory) return
    formSelectedCategory !== parsedSelectedCategory._id && setSkillsList([])
    formSelectedCategory !== parsedSelectedCategory._id && form.setValue(selectedSkillsName, [])
    formSelectedCategory !== parsedSelectedCategory._id && setSelectedSkills([])

    form.setValue(selectedCategoryName, parsedSelectedCategory._id)
    loader.setIsOpen(true)
    await refetchSkills()
    loader.setIsOpen(false)
  }

  const setSkills = () => {
    if (!skillsQuery) return

    if (formSelectedSkills.length) {
      const removeSkills = formSelectedSkills.reduce((acum: ISkill[], skill: ISkill) => {
        return acum.filter((iter: ISkill) => iter._id !== skill._id)
      }, skillsQuery)
      setSkillsList(removeSkills)
      return
    }
    setSkillsList(skillsQuery)
  }

  const addSkill = () => {
    const skillSelected = JSON.parse(selectedSkill)
    form.setValue(selectedSkillsName, [...formSelectedSkills, skillSelected])
    setSelectedSkills([...formSelectedSkills, skillSelected])
    setSkillsList(skillsList.filter((iter: ISkill) => iter._id !== skillSelected._id))
  }

  const removeSkill = (skill: ISkill) => {
    const skills = formSelectedSkills.filter((iter: ISkill) => iter._id !== skill._id)
    form.setValue(selectedSkillsName, skills)
    setSelectedSkills(skills)

    const skillsSorted = [...skillsList, skill].sort((a, b) => sortByString(a, b, 'name'))
    setSkillsList(skillsSorted)
  }

  useEffect(() => {
    setCategorySelected(parsedSelectedCategory)
    getSkills()
  }, [selectedCategory])

  useEffect(() => {
    setSkills()
  }, [skillsQuery])

  useEffect(() => {
    selectedSkill && addSkill()
  }, [selectedSkill])

  return { skillsList, categoriesQuery, categorySelected, selectedSkills, removeSkill, setSelectedSkills }
}

export default useCategoryAndSkills
