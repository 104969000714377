import { useForm } from 'react-hook-form'
import { ICategory } from '../../models/ICategory'
import { ISkill } from '../../models/ISkill'

import { Box, Divider, List, ListItemSecondaryAction, IconButton, ListItem } from '@material-ui/core'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { TProjectSections } from './Project'
import FiButton from '../../assets/UIkit/FiButton'
import useCategoryAndSkills from '../../customHooks/useCategoryAndSkills'
// import PillBoxSkills from '../../AvatarProfile/PillBoxSkills'
import { IStaff } from '../../models/IStaff'
import AppDialog from '../../assets/UIkit/AppDialog'
import useDialog from '../../customHooks/useDialog'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { created_at } from '../../utils/helpers'
import { useStaffMutation } from '../../customHooks/request/staffQuery'
import useRequestAlert from '../../customHooks/useRequestAlert'
import InputField from '../../assets/UIkit/Forms/InputField'
import UserProfileDialog from '../UserProfileDialog/UserProfileDialog'

type TModalActions = 'create' | 'update' | 'delete'

const ProjectCategories: React.FC<TProjectSections> = (props) => {
  const { data: project, refetch: refetchProject } = props.project
  const staffQuery = project?.staff
  const form = useForm()
  const dialog = useDialog<IStaff, TModalActions>()
  const { categoriesQuery } = useCategoryAndSkills({
    form: form,
    selectedSkillsName: 'skills',
    selectedCategoryName: 'category',
  })
  const staffMutation = useStaffMutation()
  useRequestAlert(staffMutation, undefined, afterMutate)
  const skills: ISkill[] = form.watch('skills')
  const category: string = form.watch('category')
  const specialty: string = form.watch('specialty')
  const isProjectSuccessOrCancelled = ['cancelled', 'finished', undefined].includes(props.project?.data?.project_status)

  const hasNoProjectInfo = () => {
    if (!project) return true
    if (project.with_currency)
      return !project?.price?.payment_method?.method || project?.price?.payment_method?.method === 'none' || !project?.plan?.fee
    return (
      !project?.price?.payment_method?.method ||
      project?.price?.payment_method?.method === 'none' ||
      !project?.price?.sii?.name ||
      project?.price?.sii?.name === 'none' ||
      !project?.plan?.fee
    )
  }

  const updateStaffContent = (staff: IStaff) => {
    form.setValue('category', JSON.stringify(staff.category))
    form.setValue('category_selected', JSON.stringify(staff.category))
    form.setValue('skills', staff.skills)
    form.setValue('specialty', staff.specialty)
    dialog.updateAction('update')
    dialog.updateData(staff)
  }

  const handleStaff = (categoryAction?: 'create' | 'update') => {
    const body = { specialty, category, project: props.projectId, client: props.clientId }
    categoryAction === 'create' && staffMutation.mutate({ body: { ...body, created_at }, method: 'POST' })
    categoryAction === 'update' && staffMutation.mutate({ body, id: dialog.data?._id ?? '', method: 'PUT' })
    dialog.action === 'delete' && staffMutation.mutate({ id: `${dialog.data?._id ?? ''}/${props.projectId}`, method: 'DELETE' })
  }

  function afterMutate() {
    refetchProject()
    dialog.action === 'delete' && dialog.toggle()
    clearStaff()
  }

  const clearStaff = () => {
    form.reset()
    form.setValue('category_selected', '')
    form.setValue('specialty', '')
    dialog.updateAction()
    dialog.updateData()
  }

  if (!project) return null
  if (hasNoProjectInfo()) {
    return (
      <UserProfileDialog>
        <h4 className='text-blue'>Selecciona las categorías de trabajo</h4>
        <h4 className='text-center mt-4 text-blue'>
          Para habilitarlas, el proyecto debe tener: plan, método de pago y forma de pago asignados.
        </h4>
        <h4 className='text-center text-blue'>Puedes hacerlo en la sección "Información del proyecto" que está arriba.</h4>
      </UserProfileDialog>
    )
  }

  return (
    <>
      {!isProjectSuccessOrCancelled && (
        <>
          <h5>
            Selecciona las categorías del proyecto
            <span className='text-red ml-2'>(En esta primera etapa, solo puedes agregar una categoría)</span>
          </h5>
          <div className='grid grid-cols-12'>
            <div className='col-span-12 grid grid-cols-12 gap-2 items-center'>
              {categoriesQuery && (
                <SelectField
                  name='category_selected'
                  label='Categorías'
                  className='col-span-5'
                  inputProps={{
                    className: 'w-full',
                    disabled: props.isDisabledFields || dialog.action === 'update' || project.price.total > 0,
                  }}
                  selectOptions={categoriesQuery
                    .filter((category: ICategory) => !category.is_other_category && category.is_available)
                    .map((category: ICategory) => {
                      return { value: JSON.stringify(category), label: category.name }
                    })}
                  options={{ required: 'Este campo es requerido' }}
                  form={form}
                />
              )}
              <InputField
                className='col-span-5'
                name='specialty'
                label='Especialidad'
                inputProps={{ className: 'w-full', disabled: props.isDisabledFields || project.price.total > 0 }}
                form={form}
              />
              {/* <SelectField
                name='skills_options'
                label='Habilidades'
                className='col-span-5'
                inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
                selectOptions={skillsList.map((skill: ISkill) => {
                  return { value: JSON.stringify(skill), label: skill.name }
                })}
                form={form}
              /> */}
              <div className='col-span-2 self-end'>
                <FiButton
                  theme='secondary'
                  disabled={!category || props.isDisabledFields}
                  onClick={() => handleStaff(dialog.action === 'update' ? 'update' : 'create')}
                  className='w-full'
                >
                  {dialog.action === 'update' ? 'Editar' : 'Crear'} categoría
                </FiButton>
                {dialog.action === 'update' && (
                  <FiButton variant='outlined' theme='secondary' onClick={clearStaff} className='w-full py-0.5 mt-1'>
                    Cancelar
                  </FiButton>
                )}
              </div>
            </div>
            {/* <div className='col-span-10 overflow-auto px-4 py-2 h-24 mt-2 border border-gray-300'>
              <PillBoxSkills skills={form.watch('skills') ?? []} onRemove={(skill) => removeSkill(skill)} withRemoveIcon />
            </div> */}
          </div>
        </>
      )}
      <p className='subtitle4-medium mt-4'>Categorías seleccionadas</p>
      <List className='border-t border-r border-l border-gray-300 py-0 mt-4'>
        {staffQuery &&
          staffQuery.map((staff: IStaff) => {
            return (
              <ListItem key={staff._id} className='border-b border-gray-300'>
                <span className='subtitle3-regular'>{staff.category.name}</span>

                <ListItemSecondaryAction>
                  {staff.has_leave_project ? (
                    <span className='subtitle3-regular text-red'>Stack retirado</span>
                  ) : (
                    <>
                      {!isProjectSuccessOrCancelled && (
                        <>
                          {!project.has_offers && (
                            <>
                              <IconButton edge='end' disabled={props.isDisabledFields} onClick={() => updateStaffContent(staff)}>
                                <FiIcons name='edit' />
                              </IconButton>
                              <IconButton
                                edge='end'
                                disabled={props.isDisabledFields}
                                onClick={() => dialog.toggle({ dialogData: staff, dialogAction: 'delete' })}
                              >
                                <FiIcons name='skull' />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
      </List>
      <AppDialog open={dialog.isOpen} title='Acciones de la categoría'>
        <p className='body1-medium mb-4'>
          ¿Estás seguro que deseas{' '}
          {
            <>
              {dialog.action === 'create' && 'crear'}
              {dialog.action === 'update' && 'editar'}
              {dialog.action === 'delete' && 'eliminar'}
            </>
          }{' '}
          la categoría para {form.watch('category_selected') && JSON.parse(form.watch('category_selected')).name}?
        </p>

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Divider />
          <FiButton variant='outlined' theme='secondary' className='mr-4' onClick={dialog.toggle}>
            Cancelar
          </FiButton>
          <FiButton variant='contained' theme='secondary' onClick={handleStaff}>
            Aceptar
          </FiButton>
        </Box>
      </AppDialog>
    </>
  )
}

export default ProjectCategories
