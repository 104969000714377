import { TAvatarStyle } from '../../models/IUser'

type TCpanelAvatar = {
  variant: TAvatarStyle
  borderRadius?: 'rounded' | 'circle'
  name?: string
  lastName?: string
  isSelected?: boolean
  size?: 'micro' | 'small' | 'medium' | 'large'
  className?: string
  onClick?: () => void
}

const LayoutAvatar: React.FC<TCpanelAvatar> = (props) => {
  const { size = 'medium', variant = 'black' } = props

  const name = () => {
    if (!props.name || !props.lastName) return ''

    const splicedName = props.name.split('')
    const splicedLastName = props.lastName.split('')
    return `${splicedName[0]}${splicedLastName[0].toLowerCase()}`
  }

  const styleCondition = () => {
    const dispatch = {
      large: { size: 'lg:w-60 w-56 lg:h-60 h-56', text: 'h1-plus' },
      medium: { size: 'md:w-24 w-16 md:h-24 h-16', text: 'h1-special' },
      small: { size: 'w-11 h-11', text: 'buttontext2-regular' },
      micro: { size: 'w-7 h-7', text: 'buttontext2-regular' },
    }
    return dispatch[size]
  }

  const theme = () => {
    const dispatch: Record<TAvatarStyle, string> = {
      magenta: 'bg-magenta text-white',
      'strong-rose': 'bg-strong-rose text-white',
      'sea-blue': 'bg-sea-blue text-white',
      cyan: 'bg-cyan text-white',
      yellow: 'bg-yellow text-white',
      orange: 'bg-orange text-white',
      golden: 'bg-golden text-white',
      black: 'bg-light-black text-white',
    }
    return dispatch[variant] ? dispatch[variant] : dispatch.black
  }

  return (
    <div className={`${styleCondition().size} ${props.className} relative cursor-pointer`} onClick={props.onClick}>
      <div className={`w-fill h-full total-center rounded-full ${theme()}`}>
        <h1 className={`${styleCondition().text} `} style={{ lineHeight: 0 }}>
          {name()}
        </h1>
      </div>
      {props.isSelected && <div className={props.isSelected ? 'h-0.5 w-8/12 mx-auto mt-2 bg-gray-700' : ''} />}
    </div>
  )
}

export default LayoutAvatar
