import { FC } from 'react'
import FiButton from '../../assets/UIkit/FiButton'
import routes from '../../constants/routes'
import SectionStructure from '../../layout/WebSite/SectionStructure'
import { useTranslation } from 'react-i18next'

type TPostulationBanner = {
  title: string
  bgColor: string
  textColor: string
  className?: string
  freelancerAsTransparent?: boolean
  freelancerWithoutBorder?: boolean
}

const PostulationBanner: FC<TPostulationBanner> = (props) => {
  const { t } = useTranslation()

  return (
    <SectionStructure className={`grid grid-cols-12 lg:gap-8 gap-4 md:py-16 px-0 py-12  ${props.bgColor} ${props.className}`}>
      <h2 className={`col-span-12 md:text-center text-left lg:mb-0 mb-8 w-2/3 md:w-full mx-auto ${props.textColor}`}>
        {t(props.title)}
      </h2>
      <div className='md:col-span-6 col-span-12 md:justify-self-end justify-self-center'>
        <FiButton
          className={`px-4 py-2 ${props.freelancerAsTransparent ? 'bg-transparent' : ''} ${
            props.freelancerWithoutBorder ? 'border-none' : ''
          }`}
          theme='light'
          to={routes.web_site.freelancer_suscription_form.step_zero}
          asLink
        >
          <p className='buttontext1-medium'>{t('Postula como freelancer')}</p>
        </FiButton>
      </div>
      <div className='md:col-span-6 col-span-12 md:justify-self-start justify-self-center mt-4 md:mt-0'>
        <FiButton className='px-4 py-2' theme='secondary' to={routes.web_site.client_suscription_form.step_zero} asLink>
          <p className='buttontext1-medium'> {t('Encuentra a tu freelancer')}</p>
        </FiButton>
      </div>
    </SectionStructure>
  )
}

export default PostulationBanner
