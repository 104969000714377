import { FC, useMemo, useState } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { useOneOfferQuery, useSendClientOffer } from '../../../../customHooks/request/offersQuery'
import { Link, useHistory, useParams } from 'react-router-dom'
import { UseQueryResult } from 'react-query'
import { IOffer } from '../../../../models/IOffer'
import TitleWithUnderline from '../../../../shared/TitleWithUnderline/TitleWithUnderline'
import routes from '../../../../constants/routes'
import OfferWorkPlan from '../../../../shared/ProjectUserProfile/OfferWorkplan/OfferWorkPlan'
import BriefDrawer from '../../../../shared/ProjectUserProfile/Brief/BriefDrawer'
import UserProfileDialog from '../../../../shared/UserProfileDialog/UserProfileDialog'
import useRequestAlert from '../../../../customHooks/useRequestAlert'

export type TOfferView = {
  offerQuery: UseQueryResult<IOffer, unknown>
}

const tabStyles = 'subtitle4-regular cursor-pointer py-2'

export const FreelancerOfferDetail: FC = () => {
  const params = useParams<{ id: string }>()
  const isMobile = useMediaQuery('(max-width: 430px)')
  const navigate = useHistory().push

  const offerQuery = useOneOfferQuery({ id: params.id, refetchOnWindowFocus: false })
  const sendClientOffer = useSendClientOffer()
  useRequestAlert(sendClientOffer)

  const [section, setSection] = useState<'form' | 'are_you_sure' | 'finish_process'>('form')

  const shouldHide = useMemo(() => {
    if (!offerQuery.data || (offerQuery.data && !offerQuery.data.work_plan)) return false

    const workPlan = offerQuery.data.work_plan
    return workPlan.end_date && workPlan.start_date && workPlan.versions.length && workPlan.can_edit
  }, [offerQuery.data])

  const handleOffer = () => {
    if (!offerQuery.data || (offerQuery.data && !offerQuery.data.work_plan)) return false

    const body = {
      workPlan: offerQuery.data.work_plan,
      freelancerEmail: offerQuery.data.freelancer.email,
      clientEmail: offerQuery.data.project.client.email,
      clientName: `${offerQuery.data.project.client.name} ${offerQuery.data.project.client.lastName}`,
    }

    sendClientOffer.mutate(
      { body, id: offerQuery.data._id },
      {
        onSuccess: () => {
          offerQuery.refetch()
          setSection('finish_process')
          setTimeout(() => {
            navigate(routes.freelancerUserProfile.projects.offers.list)
          }, 4000)
        },
      }
    )
  }

  if (!offerQuery.data) return null

  const briefProps = {
    ...offerQuery.data.brief,
    company_size: offerQuery.data.project.client.company_size,
    country: offerQuery.data.project.client.address.country,
  }
  return (
    <>
      <Link to={routes.freelancerUserProfile.projects.offers.list}>
        <TitleWithUnderline title={offerQuery.data?.brief.title ?? ''} className='inline' />
      </Link>

      {section === 'finish_process' && (
        <UserProfileDialog className='mt-6'>
          <h4 className='text-blue text-center'>¡Hurra! has enviado la propuesta</h4>
          <h4 className='text-blue text-center'>Ahora te redirigimos a la lista de ofertas para que continúes postulando.</h4>
        </UserProfileDialog>
      )}
      {section === 'are_you_sure' && (
        <UserProfileDialog className='mt-6'>
          <h4 className='text-blue text-center'>¿Seguro que quieres enviar la propuesta?</h4>
          <div className='flex md:flex-row flex-col md:gap-20 gap-4 mt-8'>
            <button className='border border-black py-2 lg:px-20 px-14' onClick={() => setSection('form')}>
              <p className='text-black'>No</p>
            </button>
            <button className='bg-blue py-2 lg:px-20 px-14 md:mt-0 mt-2' onClick={handleOffer}>
              <p className='text-white'>Si</p>
            </button>
          </div>
        </UserProfileDialog>
      )}
      {section === 'form' && (
        <Box display='grid' gridTemplateColumns={isMobile ? '1fr' : '200px 1fr'} className='md:gap-8 gap-2 mt-8'>
          <div className='border-t border-black border-b md:border-b-0'>
            <BriefDrawer {...briefProps} isOpen={true}>
              <p className={tabStyles}>Brief</p>
            </BriefDrawer>

            {!['rejected', 'declined'].includes(offerQuery.data.status) && <p className={`${tabStyles} text-blue`}>Postular</p>}

            <button
              className={`buttontext2-medium-fixed w-full py-1 cursor-pointer mt-12  ${
                Boolean(shouldHide) ? 'bg-blue text-white' : 'bg-gray-200 text-gray-400'
              }`}
              disabled={!Boolean(shouldHide)}
              onClick={() => setSection('are_you_sure')}
            >
              Enviar propuesta
            </button>
          </div>

          <div>
            {offerQuery.data.status !== 'rejected' ? (
              <OfferWorkPlan offerQuery={offerQuery} />
            ) : (
              <UserProfileDialog>
                <h4 className='text-blue text-center'>Esta oferta ha caducado</h4>
              </UserProfileDialog>
            )}
          </div>
        </Box>
      )}
    </>
  )
}
