//TODO split this component
import { UseFormReturn } from 'react-hook-form'

import { Box } from '@material-ui/core'
import InputField from '../../assets/UIkit/Forms/InputField'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { budgetList, currencyBudgetList } from '../../constants/Cpanel/ProjectConstants'
import { ICategory } from '../../models/ICategory'
import { ISkill } from '../../models/ISkill'
import PillBoxSkills from '../PillBoxSkills/PillBoxSkills'
import useCategoryAndSkills from '../../customHooks/useCategoryAndSkills'
import { TInputProps } from './Project'
import CloseIcon from '@material-ui/icons/Close'
import useConcepts from '../../customHooks/useConcepts'
import { IConcept } from '../../models/IConcept'
import { IBriefProduct } from '../../models/IBrief'

type TProjectForm = {
  form: UseFormReturn<any>
  isDisabledFields?: boolean
  isDirectPlan?: boolean
  selectedSkills?: ISkill[]
  className?: string
  withCurrency?: boolean
}

const ProjectForm: React.FC<TProjectForm> = (props) => {
  const { skillsList, categoriesQuery, removeSkill } = useCategoryAndSkills({
    form: props.form,
    selectedSkillsName: 'brief.skills',
    selectedCategoryName: 'brief.category',
  })
  const { conceptList, removeConcept } = useConcepts({ conceptName: 'brief.concepts_list', form: props.form })
  const budgetType = props.withCurrency ? currencyBudgetList : budgetList
  const values = props.form.getValues()
  console.log(values)
  const setInputDefaultProps = (param: TInputProps) => {
    return {
      name: param.name,
      label: param.label ?? '',
      className: param.className ?? '',
      inputProps: {
        className: `w-full ${param.inputClassName ?? ''}`,
        disabled: props.isDisabledFields,
        placeholder: param.pholder ?? '',
        type: param.type ?? 'text',
      },
      form: props.form,
    }
  }
  const transformString = (str: string) => {
    if (!str) return ''

    // Transformar los números con tres ceros
    let transformed = str.replace(/\$\s?(\d+)\.000\s?-\s?(\d+)\.000/, 'US$ $1 - $2')

    // Transformar los números con un punto decimal
    transformed = transformed.replace(/\$\s?(\d+)\.(\d{3})\s?-\s?(\d+)\.(\d{3})/, (match, p1, p2, p3, p4) => {
      return `US$ ${p1}.${p2} - ${p3}.${p4}`
    })

    // Eliminar tres ceros finales si están presentes
    transformed = transformed.replace(/(\d+)\.000/g, '$1')

    return transformed
  }

  return (
    <>
      <div className={`grid grid-cols-12 border-t border-b border-black ${props.className}`}>
        {props.isDirectPlan && (
          <Box
            display='grid'
            gridTemplateColumns='100px 1fr'
            alignItems='center'
            className='col-span-12 border-b border-black px-4'
          >
            <p className='body2-medium'>
              Freelancers <span className='text-blue'>*</span>
            </p>
            <InputField
              {...setInputDefaultProps({ name: 'brief.freelancers', pholder: 'Escribe aquí', className: 'w-full' })}
              variant='transparent'
              options={{ required: 'Este campo es requerido' }}
            />
          </Box>
        )}
        <Box
          display='grid'
          gridTemplateColumns='55px 1fr'
          alignItems='center'
          className='col-span-6 border-b border-r border-black px-4'
        >
          <p className='body2-medium'>
            Titulo <span className='text-blue'>*</span>
          </p>
          <InputField
            {...setInputDefaultProps({ name: 'brief.title', pholder: 'Escribe aquí', className: 'w-full' })}
            variant='transparent'
            options={{ required: 'Este campo es requerido' }}
          />
        </Box>
        <Box display='grid' gridTemplateColumns='80px 1fr' alignItems='center' className='col-span-6 border-b  border-black px-4'>
          <p className='body2-medium'>
            Categoría <span className='text-blue'>*</span>
          </p>
          {categoriesQuery && (
            <SelectField
              variant='transparent'
              {...setInputDefaultProps({ name: 'category_selected' })}
              selectOptions={categoriesQuery
                .filter((category: ICategory) => !category.is_other_category && category.is_available)
                .map((category: ICategory) => {
                  return { value: JSON.stringify(category), label: category.name }
                })}
              options={{ required: 'Este campo es requerido' }}
            />
          )}
        </Box>

        <Box className='col-span-6 border-b border-r border-black px-4'>
          <p className='body2-medium mt-2'>Descripción</p>
          <InputField
            {...setInputDefaultProps({ name: 'brief.description' })}
            textareaProps={{ rows: 4, className: 'w-full' }}
            textarea
            variant='transparent'
          />
        </Box>
        <Box display='grid' gridTemplateRows='40px 1fr' className='col-span-6 border-b border-black px-4'>
          <Box display='grid' gridTemplateColumns='120px 1fr' alignItems='center'>
            <p className='body2-medium'>
              Habilidades <span className='text-blue'>*</span>
            </p>
            <SelectField
              variant='transparent'
              {...setInputDefaultProps({ name: 'skills_options' })}
              selectOptions={skillsList.map((skill: ISkill) => {
                return { value: JSON.stringify(skill), label: skill.name }
              })}
            />
          </Box>
          <Box className='overflow-auto pb-2'>
            <PillBoxSkills skills={props.form.watch('brief.skills') ?? []} onRemove={(skill) => removeSkill(skill)} />
          </Box>
        </Box>

        <Box
          display='grid'
          gridTemplateColumns='120px 1fr'
          alignItems='center'
          className='col-span-12 border-b border-black px-4'
        >
          <p className='body2-medium'>
            Fecha de inicio <span className='text-blue'>*</span>
          </p>
          {props.form.watch('brief.start_date') && (
            <InputField {...setInputDefaultProps({ name: 'brief.start_date', type: 'date' })} variant='transparent' />
          )}
        </Box>

        <Box
          display='grid'
          gridTemplateColumns='130px 1fr'
          alignItems='center'
          className='col-span-6 border-b border-r border-black px-4'
        >
          <p className='body2-medium'>
            Publico objetivo <span className='text-blue'>*</span>
          </p>
          <InputField
            {...setInputDefaultProps({ name: 'brief.public_objective' })}
            variant='transparent'
            options={{ required: 'Este campo es requerido' }}
          />
        </Box>

        <Box display='grid' gridTemplateColumns='120px 1fr' alignItems='center' className='col-span-6 border-b border-black px-4'>
          <p className='body2-medium'>Consideraciones</p>
          <InputField {...setInputDefaultProps({ name: 'brief.considerations' })} variant='transparent' />
        </Box>

        <Box display='grid' gridTemplateColumns='150px 1fr' alignItems='center' className='col-span-6 border-b border-black px-4'>
          <p className='body2-medium'>
            Cuando comienza <span className='text-blue'>*</span>
          </p>
          <SelectField
            {...setInputDefaultProps({ name: 'brief.when_start' })}
            selectOptions={[
              { value: 'Lo antes posible', label: 'Lo antes posible' },
              { value: 'As soon as possible', label: 'As soon as possible' },
              { value: 'Dentro de 1 semana', label: 'Dentro de 1 semana' },
              { value: 'One week from now', label: 'One week from now' },
              { value: 'Tengo tiempo', label: 'Tengo tiempo' },
              { value: 'I have time', label: 'I have time' },
            ]}
            variant='transparent'
            options={{ required: 'Este campo es requerido' }}
          />
        </Box>

        <Box display='grid' gridTemplateColumns='150px 1fr' alignItems='center' className='col-span-6 border-b border-black px-4'>
          <p className='body2-medium'>Tiempo del proyecto</p>
          <InputField {...setInputDefaultProps({ name: 'brief.project_period' })} variant='transparent' />
        </Box>
        <Box
          display='grid'
          gridTemplateColumns='155px 1fr'
          alignItems='center'
          className='col-span-6 border-b border-r border-black px-4'
        >
          <p className='body2-medium'>Nivel de compromiso</p>
          <InputField {...setInputDefaultProps({ name: 'brief.commitment_level' })} variant='transparent' />
        </Box>

        <Box display='grid' gridTemplateColumns='155px 1fr' alignItems='center' className='col-span-6 border-b border-black px-4'>
          <p className='body2-medium'>Presupuesto</p>

          <SelectField
            variant='transparent'
            {...setInputDefaultProps({ name: 'brief.project_size' })}
            selectOptions={budgetType.map((budget) => {
              return {
                value: budget.value,
                label: budget.label,
                selected: transformString(values?.brief?.project_size) === budget.value ? 'selected' : '',
              }
            })}
            options={{ required: 'Este campo es requerido' }}
          />
        </Box>
        <Box
          display='grid'
          gridTemplateColumns='210px 1fr'
          alignItems='center'
          className='col-span-6 border-b border-r border-black px-4'
        >
          <p className='body2-medium'>
            Competencia y/o referentes <span className='text-blue'>*</span>
          </p>
          <InputField {...setInputDefaultProps({ name: 'brief.competition_referents' })} variant='transparent' />
        </Box>

        <div className='col-span-12 border-b border-black pb-2'>
          <p className='microcopy mt-4 text-gray-400'>Entregables</p>
          <div className='overflow-auto'>
            <div className='flex flex-wrap -mt-1'>
              {(props.form.watch('brief.products') ?? []).map((product: IBriefProduct) => {
                return (
                  <span key={product.name} className={`microcopy rounded-2xl px-2 py-0 mr-2 mt-2 border border-black text-black`}>
                    {product.name}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
        <div className='col-span-12 border-b border-black'>
          <SelectField
            className='w-full mt-4'
            name='selected_concept'
            label='Conceptos'
            variant='default'
            inputProps={{ className: 'w-full' }}
            selectOptions={conceptList.map((concept) => {
              return { value: JSON.stringify(concept), label: concept.name }
            })}
            form={props.form}
          />
          <div className='overflow-auto pb-2'>
            <div className='flex flex-wrap mt-1'>
              {(props.form.watch('brief.concepts_list') ?? []).map((concept: IConcept) => {
                return (
                  <span
                    key={concept._id}
                    className={`microcopy flex items-center rounded-2xl px-2 py-0 mr-2 mt-2 border border-black text-black`}
                  >
                    {concept.name}
                    <CloseIcon
                      fontSize='small'
                      className='text-gray ml-1 cursor-pointer'
                      onClick={() => removeConcept(concept)}
                    />
                  </span>
                )
              })}
            </div>
          </div>
        </div>

        <Box className='col-span-12 border-b border-black px-4'>
          <p className='body2-medium mt-2'>Insumos</p>
          <InputField
            {...setInputDefaultProps({ name: 'brief.supplies' })}
            textareaProps={{ rows: 4, className: 'w-full' }}
            textarea
            variant='transparent'
          />
        </Box>

        <Box className='col-span-12 border-b border-black px-4'>
          <p className='body2-medium mt-2'>Notas</p>
          <InputField
            {...setInputDefaultProps({ name: 'brief.notes' })}
            textareaProps={{ rows: 4, className: 'w-full' }}
            textarea
            variant='transparent'
          />
        </Box>

        <Box className='col-span-12 px-4'>
          <p className='body2-medium mt-2'>Consideraciones de pago</p>
          <InputField
            {...setInputDefaultProps({ name: 'brief.payment_considerations' })}
            textareaProps={{ rows: 4, className: 'w-full' }}
            textarea
            variant='transparent'
          />
        </Box>
      </div>
    </>
  )
}

export default ProjectForm
