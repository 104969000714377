import { Divider } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { useSkillListQuery } from '../../../customHooks/request/skillsQuery'
import { ISkill } from '../../../models/ISkill'
import SkillsMultiSelect from '../../../shared/SkillsMultiSelect/SkillsMultiSelect'
import { TFreelancerBookSections } from '../../../views/FreelancerUserProfile/FreelancerBook'

const FreelancerBio: FC<TFreelancerBookSections> = (props) => {
  const { data: freelancer, refetch: freelancerRefetch } = props.freelancerQuery

  const form = useForm()
  const { data: skillsQuery } = useSkillListQuery({
    filters: { category: freelancer?.category._id ?? '' },
    enabled: Boolean(freelancer?.category._id),
  })
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([])

  const saveChanges = (data: FieldValues) => {
    const body = { skills: selectedSkills, biography: data.biography }
    props.updateFreelancer.mutate({ body, id: freelancer?._id ?? '' }, { onSuccess: () => freelancerRefetch() })
  }

  useEffect(() => {
    if (!freelancer) return
    form.setValue('biography', freelancer.biography)
    setSelectedSkills(freelancer.skills)
  }, [freelancer])

  if (!skillsQuery) return null
  return (
    <form onSubmit={form.handleSubmit(saveChanges)}>
      <button className='subtitle4-medium text-right ml-auto block cursor-pointer' type='submit'>
        Guardar
      </button>

      <p className='subtitle6-regular'>
        Introducción <span className='text-gray-400'>Mín. 330 - Máx. 900 caracteres </span>
      </p>
      <InputField
        name='biography'
        variant='transparent'
        className='w-full'
        textareaProps={{
          className: 'w-full',
          placeholder:
            'Cuéntale al mundo qué has hecho, dónde, por cuánto tiempo, cuáles son tus fuertes, qué tipo de proyectos te interesan; en 280 caracteres (como Twitter).',
          rows: 4,
          maxLength: 900,
          minLength: 330,
        }}
        options={{
          minLength: { value: 330, message: 'Mínimo 330 caracteres.' },
          maxLength: { value: 900, message: 'Máximo 900 caracteres.' },
        }}
        form={form}
        textarea
      />

      <Divider className='my-0' />

      <SkillsMultiSelect
        skills={skillsQuery}
        selectedSkills={selectedSkills}
        setSelectedSkills={setSelectedSkills}
        skillsLimit={10}
        label={
          <p className='subtitle6-regular'>
            Detalla qué dominas y en qué eres experto. <span className='text-gray-300'>Max. 10</span>
          </p>
        }
      />
    </form>
  )
}

export default FreelancerBio
