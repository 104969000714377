import { IClientProduct } from '../../models/IClient'

const products = [
  {
    name: 'Impresión',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Diseño de folletos', 'Carteles', 'Diseño editorial', 'Publicidad impresa'],
  },
  {
    name: 'Envases',
    categories: ['Diseño', 'Diseño Industrial'],
    keyWords: ['Diseño de envases', 'Embalaje', 'Diseño de productos', 'Envases de marca'],
  },
  {
    name: 'Ilustraciones',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Ilustraciones', 'Dibujos', 'Arte digital', 'Ilustraciones personalizadas'],
  },
  {
    name: 'Presentaciones',
    categories: ['Diseño', 'Negocios'],
    keyWords: ['Diseño de diapositivas', 'Presentaciones efectivas', 'Diseño de PowerPoint', 'Pitches'],
  },
  {
    name: 'Investigación de Mercado',
    categories: ['Marketing', 'Investigación'],
    keyWords: ['Análisis de mercado', 'Estudios de mercado', 'Investigación de consumidores'],
  },
  {
    name: 'Estrategia de Marca',
    categories: ['Marketing', 'Branding'],
    keyWords: ['Posicionamiento de marca', 'Identidad de marca', 'Estrategia de marketing'],
  },
  {
    name: 'Naming',
    categories: ['Diseño', 'Branding'],
    keyWords: ['Nombre de marca', 'Nomenclatura', 'Nombres creativos'],
  },
  {
    name: 'Isotipo',
    categories: ['Diseño', 'Branding'],
    keyWords: ['Identidad visual', 'Símbolo de marca', 'Logotipo gráfico'],
  },
  {
    name: 'Monograma',
    categories: ['Diseño', 'Branding'],
    keyWords: ['Diseño monograma', 'Iniciales de marca', 'Marcas personales'],
  },
  {
    name: 'Rebranding',
    categories: ['Diseño', 'Branding'],
    keyWords: ['Renovación de marca', 'Cambio de identidad', 'Reposicionamiento'],
  },
  {
    name: 'Template de RRSS',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Plantilla redes sociales', 'Diseño para redes', 'Posts predefinidos'],
  },
  {
    name: 'Tarjeta de Presentación',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Tarjeta de visita', 'Contacto profesional', 'Networking'],
  },
  {
    name: 'Manual de Marca',
    categories: ['Diseño', 'Branding'],
    keyWords: ['Guía de marca', 'Normas visuales', 'Identidad corporativa'],
  },
  {
    name: 'Merchandising',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Productos promocionales', 'Regalos de marca', 'Artículos publicitarios'],
  },
  {
    name: 'Fondo de Pantalla',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Wallpapers', 'Fondos de pantalla personalizados', 'Diseño de escritorio'],
  },
  {
    name: 'Volante',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Flyers', 'Publicidad impresa', 'Distribución de información'],
  },
  {
    name: 'Díptico',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Folletos dípticos', 'Diseño editorial', 'Información doble cara'],
  },
  {
    name: 'Tríptico',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Folletos trípticos', 'Información triple cara', 'Promoción de eventos'],
  },
  {
    name: 'Brochure',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Folletos informativos', 'Catálogos', 'Material promocional'],
  },
  {
    name: 'Aviso',
    categories: ['Diseño', 'Publicidad'],
    keyWords: ['Anuncios', 'Publicidad impresa', 'Avisos comerciales'],
  },
  {
    name: 'Afiche',
    categories: ['Diseño', 'Publicidad'],
    keyWords: ['Carteles publicitarios', 'Pósters', 'Anuncios de eventos'],
  },
  {
    name: 'Papelería',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Material de oficina', 'Papelería corporativa', 'Elementos de marca'],
  },
  {
    name: 'Calendario',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Calendarios personalizados', 'Planificación de eventos', 'Regalos corporativos'],
  },
  {
    name: 'Infografía',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Información visual', 'Datos gráficos', 'Comunicación efectiva'],
  },
  {
    name: 'Pendón',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Banners', 'Publicidad en eventos', 'Exhibición de marca'],
  },
  {
    name: 'Carátula',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Portadas', 'Diseño de carátulas', 'Presentación visual'],
  },
  {
    name: 'Newsletter',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Boletines informativos', 'Correo electrónico', 'Comunicación con clientes'],
  },
  {
    name: 'Cartones Streaming',
    categories: ['Diseño', 'Entretenimiento'],
    keyWords: ['Gráficos para transmisiones', 'Streaming en vivo', 'Diseño para streamers'],
  },
  {
    name: 'Stickers',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Etiquetas adhesivas', 'Promoción con pegatinas', 'Elementos de marca'],
  },
  {
    name: 'Señalética',
    categories: ['Diseño', 'Arquitectura'],
    keyWords: ['Señales informativas', 'Indicaciones', 'Direcciones'],
  },
  {
    name: 'Tipografía',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Fuentes', 'Diseño de letras', 'Estilos tipográficos'],
  },
  {
    name: 'Iconos',
    categories: ['Diseño', 'Digital'],
    keyWords: ['Iconografía', 'Símbolos', 'Diseño de iconos'],
  },
  {
    name: 'Lettering',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Diseño de letras a mano', 'Caligrafía', 'Lettering personalizado'],
  },
  {
    name: 'Etiqueta',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Etiquetas personalizadas', 'Etiquetas de productos', 'Identificación'],
  },
  {
    name: 'Pattern',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Patrones gráficos', 'Diseño de patrones', 'Texturas repetitivas'],
  },
  {
    name: 'Diseño Textil',
    categories: ['Diseño', 'Moda'],
    keyWords: ['Diseño de telas', 'Textiles', 'Patrones de moda'],
  },
  {
    name: 'Ilustración Editorial',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Ilustración para libros', 'Ilustración editorial', 'Diseño de portadas'],
  },
  {
    name: 'Ilustración Infantil',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Ilustraciones para niños', 'Cuentos ilustrados', 'Libros infantiles'],
  },
  {
    name: 'Ilustración Científica',
    categories: ['Diseño', 'Ciencia'],
    keyWords: ['Ilustración científica', 'Diagramas científicos', 'Ilustración de especies'],
  },
  {
    name: 'Ilustración de Moda',
    categories: ['Diseño', 'Moda'],
    keyWords: ['Ilustración de moda', 'Diseño de vestimenta', 'Dibujos de estilo'],
  },
  {
    name: 'Comic Book',
    categories: ['Diseño', 'Entretenimiento'],
    keyWords: ['Cómics', 'Novela gráfica', 'Historietas'],
  },
  {
    name: 'Personaje',
    categories: ['Diseño', 'Entretenimiento'],
    keyWords: ['Diseño de personajes', 'Personajes ficticios', 'Caracterización'],
  },
  {
    name: 'Concept Art',
    categories: ['Diseño', 'Entretenimiento'],
    keyWords: ['Arte conceptual', 'Diseño de escenarios', 'Conceptos visuales'],
  },
  {
    name: 'Storyboard',
    categories: ['Diseño', 'Entretenimiento'],
    keyWords: ['Guión gráfico', 'Narrativa visual', 'Secuencia de imágenes'],
  },
  {
    name: 'Logo Ilustrado',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Logotipo ilustrado', 'Logos creativos', 'Ilustración de marca'],
  },
  {
    name: 'Mural',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Murales artísticos', 'Pintura mural', 'Arte en paredes'],
  },
  {
    name: 'Ilustración 3D',
    categories: ['Diseño', 'Arte'],
    keyWords: ['Ilustración tridimensional', 'Modelado 3D', 'Arte digital 3D'],
  },
  {
    name: 'Papel Mural',
    categories: ['Diseño', 'Decoración'],
    keyWords: ['Papel tapiz', 'Decoración de pared', 'Diseño de interiores'],
  },
  {
    name: 'Papel de Regalo',
    categories: ['Diseño', 'Regalos'],
    keyWords: ['Envoltura de regalos', 'Papel decorativo', 'Diseño de empaques'],
  },
  {
    name: 'Animatic',
    categories: ['Animación', 'Producción'],
    keyWords: ['Animatics', 'Previsualización animada', 'Storyboard animado'],
  },
  {
    name: 'Animación 2D',
    categories: ['Animación', 'Digital'],
    keyWords: ['Animación tradicional', 'Dibujos animados', '2D animation'],
  },
  {
    name: 'Animación 3D',
    categories: ['Animación', 'Digital'],
    keyWords: ['Animación tridimensional', 'Modelado 3D', '3D animation'],
  },
  {
    name: 'Animación Stop Motion',
    categories: ['Animación', 'Cine'],
    keyWords: ['Stop-motion', 'Animación de cuadros', 'Puppet animation'],
  },
  {
    name: 'Motion Graphics',
    categories: ['Animación', 'Digital'],
    keyWords: ['Gráficos en movimiento', 'Diseño animado', 'Motion design'],
  },
  {
    name: 'Video Explainer',
    categories: ['Animación', 'Marketing'],
    keyWords: ['Videos explicativos', 'Videos informativos', 'Explicación visual'],
  },
  {
    name: 'Personaje Animado',
    categories: ['Animación', 'Entretenimiento'],
    keyWords: ['Personajes animados', 'Diseño de personajes', 'Animación de figuras'],
  },
  {
    name: 'Producto Animado',
    categories: ['Animación', 'Marketing'],
    keyWords: ['Productos animados', 'Animación de productos', 'Demostración animada'],
  },
  {
    name: 'Logotipo Animado',
    categories: ['Animación', 'Branding'],
    keyWords: ['Logos animados', 'Animación de logotipos', 'Introducciones animadas'],
  },
  {
    name: 'Infografía Animada',
    categories: ['Animación', 'Marketing'],
    keyWords: ['Infografías animadas', 'Datos gráficos animados', 'Visualización animada'],
  },
  {
    name: 'Tipografía Animada',
    categories: ['Animación', 'Arte'],
    keyWords: ['Fuentes animadas', 'Texto en movimiento', 'Animación de letras'],
  },
  {
    name: 'Efectos Visuales',
    categories: ['Animación', 'Cine'],
    keyWords: ['VFX', 'Efectos especiales', 'Mejoras visuales'],
  },
  {
    name: 'Realidad Virtual',
    categories: ['Tecnología', 'Entretenimiento'],
    keyWords: ['VR', 'Experiencias inmersivas', 'Mundo virtual'],
  },
  {
    name: 'Realidad Aumentada',
    categories: ['Tecnología', 'Entretenimiento'],
    keyWords: ['AR', 'Interacción digital', 'Combinación de mundo real y virtual'],
  },
  {
    name: 'Animación Interactiva',
    categories: ['Animación', 'Tecnología'],
    keyWords: ['Interactividad animada', 'Animación con respuesta', 'Experiencias interactivas'],
  },
  {
    name: 'Animación para E-learning',
    categories: ['Animación', 'Educación'],
    keyWords: ['E-learning', 'Cursos en línea', 'Animación educativa'],
  },
  {
    name: 'Animación GIF',
    categories: ['Animación', 'Digital'],
    keyWords: ['Imágenes GIF animadas', 'GIFs personalizados', 'Animaciones cortas'],
  },
  {
    name: 'Intro Video',
    categories: ['Animación', 'Digital'],
    keyWords: ['Introducción de video', 'Video de presentación', 'Video de inicio'],
  },
  {
    name: 'Outro Video',
    categories: ['Animación', 'Digital'],
    keyWords: ['Final de video', 'Cierre de video', 'Créditos finales'],
  },
]

const moreProducts = [
  {
    name: 'Diagramación de Página',
    categories: ['Diseño', 'Editorial'],
    keyWords: ['Diseño de maquetación', 'Diagramación de revistas', 'Diseño de páginas'],
  },
  {
    name: 'Diseño de Libro',
    categories: ['Diseño', 'Editorial'],
    keyWords: ['Maquetación de libros', 'Diseño editorial', 'Libros personalizados'],
  },
  {
    name: 'Portada de Publicación',
    categories: ['Diseño', 'Editorial'],
    keyWords: ['Portadas de libros', 'Portadas de revistas', 'Diseño de cubiertas'],
  },
  {
    name: 'Invitación',
    categories: ['Diseño', 'Eventos'],
    keyWords: ['Tarjetas de invitación', 'Diseño de invitaciones', 'Invitaciones personalizadas'],
  },
  {
    name: 'Folleto',
    categories: ['Diseño', 'Impresión'],
    keyWords: ['Diseño de folletos', 'Folletos informativos', 'Publicidad impresa'],
  },
  {
    name: 'Catálogo',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Catálogos de productos', 'Listado de productos', 'Catálogo digital'],
  },
  {
    name: 'Memoria Corporativa',
    categories: ['Diseño', 'Negocios'],
    keyWords: ['Informes corporativos', 'Memoria anual', 'Documentación corporativa'],
  },
  {
    name: 'Investigación y Análisis de Usuario',
    categories: ['Diseño', 'Investigación'],
    keyWords: ['Investigación de usuarios', 'Análisis de usuarios', 'Feedback de usuarios'],
  },
  {
    name: 'Personas y Escenarios de Usuario',
    categories: ['Diseño', 'Investigación'],
    keyWords: ['Perfiles de usuarios', 'Casos de uso', 'Escenarios de uso'],
  },
  {
    name: 'Arquitectura de la Información',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Organización de contenido', 'Jerarquía de información', 'Estructura de sitio web'],
  },
  {
    name: 'Wireframes',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Esquemas de diseño', 'Diseño de página web', 'Bocetos iniciales'],
  },
  {
    name: 'Prototipos',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Prototipado de diseño', 'Modelado interactivo', 'Diseño de experiencia de usuario'],
  },
  {
    name: 'Diseño UI',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Interfaz de usuario', 'Diseño de interfaz', 'UI design'],
  },
  {
    name: 'Diseño UX',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Experiencia de usuario', 'Diseño de experiencia de usuario', 'UX design'],
  },
  {
    name: 'Diseño Responsive',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Diseño adaptable', 'Diseño para dispositivos móviles', 'Responsive web design'],
  },
  {
    name: 'Style Guide',
    categories: ['Diseño', 'Branding'],
    keyWords: ['Guía de estilo', 'Directrices de diseño', 'Manual de estilo'],
  },
  {
    name: 'Design System',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Sistema de diseño', 'Componentes de diseño', 'Ecosistema de diseño'],
  },
  {
    name: 'Test de Usabilidad',
    categories: ['Diseño', 'Investigación'],
    keyWords: ['Pruebas de usuario', 'Evaluación de usabilidad', 'Feedback de usuarios'],
  },
  {
    name: 'E-commerce',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Comercio electrónico', 'Tiendas en línea', 'Plataformas de venta en línea'],
  },
  {
    name: 'Landing Page',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Página de destino', 'Página de captura', 'Páginas de aterrizaje'],
  },
  {
    name: 'Rediseño Web',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Reestructuración de sitios web', 'Mejora de diseño web', 'Actualización de sitios'],
  },
  {
    name: 'Mailing',
    categories: ['Diseño', 'Marketing'],
    keyWords: ['Correo electrónico', 'Campañas de correo', 'Boletines electrónicos'],
  },
  {
    name: 'Botones',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Diseño de botones', 'Botones de llamada a la acción', 'Elementos interactivos'],
  },
  {
    name: 'Banner',
    categories: ['Diseño', 'Publicidad'],
    keyWords: ['Banners publicitarios', 'Publicidad en línea', 'Anuncios web'],
  },
  {
    name: 'Favicon',
    categories: ['Diseño', 'Tecnología'],
    keyWords: ['Icono de sitio web', 'Icono de pestaña', 'Identidad visual web'],
  },
  {
    name: 'Asesoría en Diseño',
    categories: ['Diseño', 'Consultoría'],
    keyWords: ['Consultoría de diseño', 'Asesoramiento de diseño', 'Consultor de diseño'],
  },
]

const videoAndPhotographyProducts = [
  {
    name: 'Producción de Video',
    categories: ['Video', 'Producción'],
    keyWords: ['Filmación de videos', 'Producción audiovisual', 'Contenido videográfico'],
  },
  {
    name: 'Edición de Video y Audio',
    categories: ['Video', 'Edición'],
    keyWords: ['Montaje de videos', 'Edición de sonido', 'Postproducción audiovisual'],
  },
  {
    name: 'Video Interactivo',
    categories: ['Video', 'Tecnología'],
    keyWords: ['Videos con interacción', 'Contenido multimedia interactivo', 'Experiencias de video interactivas'],
  },
  {
    name: 'Producción de Video 360',
    categories: ['Video', 'Tecnología'],
    keyWords: ['Videos en 360 grados', 'Contenido de realidad virtual', 'Experiencias inmersivas'],
  },
  {
    name: 'Webinar',
    categories: ['Video', 'Educación'],
    keyWords: ['Seminarios web', 'Eventos en línea', 'Capacitación en video'],
  },
  {
    name: 'Proyección Mapping',
    categories: ['Video', 'Arquitectura'],
    keyWords: ['Mapping proyectivo', 'Proyección visual', 'Instalaciones visuales'],
  },
  {
    name: 'Producción de Video Comercial',
    categories: ['Video', 'Marketing'],
    keyWords: ['Comerciales de TV', 'Videos publicitarios', 'Promoción de productos'],
  },
  {
    name: 'Producción de Video Promocional',
    categories: ['Video', 'Marketing'],
    keyWords: ['Videos promocionales', 'Anuncios promocionales', 'Promoción de servicios'],
  },
  {
    name: 'Producción de Video Explicativo',
    categories: ['Video', 'Marketing'],
    keyWords: ['Videos explicativos', 'Videos informativos', 'Explicación visual'],
  },
  {
    name: 'Producción de Video Testimonial',
    categories: ['Video', 'Marketing'],
    keyWords: ['Videos de testimonios', 'Entrevistas en video', 'Historias de éxito'],
  },
  {
    name: 'Video para RRSS',
    categories: ['Video', 'Marketing'],
    keyWords: ['Contenido para redes sociales', 'Videos virales', 'Publicidad en redes'],
  },
  {
    name: 'Producción de Video Corporativo',
    categories: ['Video', 'Negocios'],
    keyWords: ['Videos corporativos', 'Presentación de empresa', 'Comunicación interna'],
  },
  {
    name: 'Producción de Video de Evento',
    categories: ['Video', 'Eventos'],
    keyWords: ['Cobertura de eventos', 'Videos de conferencias', 'Resumen de eventos'],
  },
  {
    name: 'Video para Publicidad',
    categories: ['Video', 'Marketing'],
    keyWords: ['Anuncios en video', 'Publicidad en línea', 'Video promocional'],
  },
  {
    name: 'Estrategia de Contenido de Video',
    categories: ['Video', 'Marketing'],
    keyWords: ['Planificación de contenido en video', 'Campañas de video', 'Estrategia de video'],
  },
  {
    name: 'Guión y Storyboard',
    categories: ['Video', 'Producción'],
    keyWords: ['Creación de guiones', 'Narrativa visual', 'Preparación de producciones'],
  },
  {
    name: 'Post-Producción',
    categories: ['Video', 'Edición'],
    keyWords: ['Procesamiento posterior', 'Etalonaje', 'Finalización de producciones'],
  },
  {
    name: 'Corrección de Color',
    categories: ['Video', 'Edición'],
    keyWords: ['Corrección de color', 'Grading de video', 'Mejora visual'],
  },
  {
    name: 'Diseño de Sonido',
    categories: ['Audio', 'Diseño'],
    keyWords: ['Creación de efectos de sonido', 'Mezcla de audio', 'Diseño sonoro'],
  },
  {
    name: 'Fotografía de Archivo',
    categories: ['Fotografía'],
    keyWords: ['Fotografías de stock', 'Imágenes de archivo', 'Fotos de alta resolución'],
  },
  {
    name: 'Fotografía de Moda',
    categories: ['Fotografía', 'Moda'],
    keyWords: ['Sesiones de fotos de moda', 'Editoriales de moda', 'Fotografía de pasarela'],
  },
  {
    name: 'Fotografía de Producto',
    categories: ['Fotografía', 'Comercio Electrónico'],
    keyWords: ['Fotografía de productos', 'Fotos de catálogo', 'Fotografía de productos en línea'],
  },
  {
    name: 'Retoque de Fotografía',
    categories: ['Fotografía', 'Edición'],
    keyWords: ['Edición de imágenes', 'Mejora de fotos', 'Retoque de fotografías'],
  },
]

const writingProducts = [
  {
    name: 'Redacción de Contenido',
    categories: ['Redacción', 'Escritura'],
    keyWords: ['Redacción de artículos', 'Contenido web', 'Escritura creativa'],
  },
  {
    name: 'Edición de Texto',
    categories: ['Redacción', 'Edición'],
    keyWords: ['Edición de manuscritos', 'Revisión de texto', 'Corrección de estilo'],
  },
  {
    name: 'Creación de Blog Posts',
    categories: ['Redacción', 'Blogging'],
    keyWords: ['Artículos de blog', 'Entradas de blog', 'Contenido para blogs'],
  },
  {
    name: 'Guiones para Videos',
    categories: ['Redacción', 'Video'],
    keyWords: ['Guiones para videos', 'Guión audiovisual', 'Narrativa para videos'],
  },
  {
    name: 'Redacción de Discursos',
    categories: ['Redacción', 'Oratoria'],
    keyWords: ['Discursos públicos', 'Palabras para presentaciones', 'Oratoria impactante'],
  },
  {
    name: 'Redacción de Ficción',
    categories: ['Redacción', 'Escritura Creativa'],
    keyWords: ['Relatos cortos', 'Cuentos literarios', 'Ficción creativa'],
  },
  {
    name: 'Creación de Poemas',
    categories: ['Redacción', 'Poesía'],
    keyWords: ['Poemas líricos', 'Versos poéticos', 'Poesía creativa'],
  },
  {
    name: 'Redacción de Contenido Técnico',
    categories: ['Redacción', 'Tecnología'],
    keyWords: ['Documentación técnica', 'Manuales de usuario', 'Contenido técnico'],
  },
  {
    name: 'Redacción de Contenido Científico',
    categories: ['Redacción', 'Ciencia'],
    keyWords: ['Artículos científicos', 'Divulgación científica', 'Contenido de investigación'],
  },
  {
    name: 'Redacción de Contenido de Viajes',
    categories: ['Redacción', 'Viajes'],
    keyWords: ['Artículos de viajes', 'Guías turísticas', 'Contenido de destinos'],
  },
  {
    name: 'Redacción de Contenido de Moda',
    categories: ['Redacción', 'Moda'],
    keyWords: ['Artículos de moda', 'Tendencias de moda', 'Contenido de estilo'],
  },
  {
    name: 'Redacción de Contenido de Salud',
    categories: ['Redacción', 'Salud'],
    keyWords: ['Artículos de salud', 'Consejos de bienestar', 'Contenido médico'],
  },
  {
    name: 'Redacción de Contenido de Alimentación',
    categories: ['Redacción', 'Alimentación'],
    keyWords: ['Artículos de alimentación', 'Recetas culinarias', 'Contenido gastronómico'],
  },
  {
    name: 'Redacción de Contenido de Negocios',
    categories: ['Redacción', 'Negocios'],
    keyWords: ['Artículos de negocios', 'Contenido empresarial', 'Información corporativa'],
  },
  {
    name: 'Redacción de Contenido de Marketing',
    categories: ['Redacción', 'Marketing'],
    keyWords: ['Contenido de marketing', 'Campañas publicitarias', 'Copywriting persuasivo'],
  },
  {
    name: 'Redacción de Contenido de Educación',
    categories: ['Redacción', 'Educación'],
    keyWords: ['Artículos educativos', 'Contenido académico', 'Materiales educativos'],
  },
  {
    name: 'Redacción de Contenido de Entretenimiento',
    categories: ['Redacción', 'Entretenimiento'],
    keyWords: ['Artículos de entretenimiento', 'Noticias de entretenimiento', 'Reseñas de películas'],
  },
  {
    name: 'Redacción de Contenido de Estilo de Vida',
    categories: ['Redacción', 'Estilo de Vida'],
    keyWords: ['Contenido de estilo de vida', 'Consejos de vida saludable', 'Bienestar personal'],
  },
  {
    name: 'Estrategia Comunicacional',
    categories: ['Redacción', 'Comunicación'],
    keyWords: ['Planificación de comunicación', 'Estrategia de mensajes', 'Comunicación corporativa'],
  },
  {
    name: 'Campaña',
    categories: ['Redacción', 'Marketing'],
    keyWords: ['Planificación de campañas', 'Ejecución de campañas', 'Campañas publicitarias'],
  },
  {
    name: 'Difusión en Prensa',
    categories: ['Redacción', 'Relaciones Públicas'],
    keyWords: ['Relaciones con los medios', 'Comunicados de prensa', 'Difusión de noticias'],
  },
  {
    name: 'Grilla',
    categories: ['Redacción', 'Planificación'],
    keyWords: ['Planificación editorial', 'Calendario de contenidos', 'Programación de publicaciones'],
  },
  {
    name: 'Posicionamiento',
    categories: ['Redacción', 'Marketing'],
    keyWords: ['Posicionamiento en buscadores', 'SEO', 'Mejora de visibilidad en línea'],
  },
]

const communityManagerProducts = [
  {
    name: 'Gestión de Redes Sociales',
    categories: ['Community Manager', 'Social Media'],
    keyWords: ['Manejo de perfiles sociales', 'Publicaciones en redes', 'Interacción con seguidores'],
  },
  {
    name: 'Creación de Contenido',
    categories: ['Community Manager', 'Contenido'],
    keyWords: ['Generación de contenido', 'Diseño de imágenes', 'Redacción de publicaciones'],
  },
  {
    name: 'Plan de Contenido',
    categories: ['Community Manager', 'Planificación'],
    keyWords: ['Planificación editorial', 'Calendario de contenidos', 'Estrategia de contenido'],
  },
  {
    name: 'Estrategia de Redes Sociales',
    categories: ['Community Manager', 'Estrategia'],
    keyWords: ['Desarrollo de estrategias', 'Objetivos en redes', 'Crecimiento de audiencia'],
  },
  {
    name: 'Gestión de Comunidad',
    categories: ['Community Manager', 'Interacción'],
    keyWords: ['Moderación de comentarios', 'Respuesta a consultas', 'Fomento de participación'],
  },
  {
    name: 'Análisis de Métricas',
    categories: ['Community Manager', 'Analítica'],
    keyWords: ['Seguimiento de KPIs', 'Informes de rendimiento', 'Mejora de resultados'],
  },
  {
    name: 'Campañas Publicitarias en Redes',
    categories: ['Community Manager', 'Publicidad'],
    keyWords: ['Creación de anuncios', 'Segmentación de audiencia', 'Optimización de campañas'],
  },
  {
    name: 'Colaboración con Influencers',
    categories: ['Community Manager', 'Marketing'],
    keyWords: ['Relaciones con influencers', 'Campañas de colaboración', 'Promoción de productos'],
  },
  {
    name: 'Monitorización de Competidores',
    categories: ['Community Manager', 'Competencia'],
    keyWords: ['Análisis de competidores', 'Benchmarking en redes', 'Identificación de oportunidades'],
  },
  {
    name: 'Entrenamiento y Capacitación',
    categories: ['Community Manager', 'Educación'],
    keyWords: ['Formación en redes', 'Desarrollo de habilidades', 'Actualización constante'],
  },
  {
    name: 'Reporte de Crisis en Redes',
    categories: ['Community Manager', 'Crisis'],
    keyWords: ['Gestión de crisis', 'Comunicación de crisis', 'Reparación de reputación'],
  },
]

const consultingProducts = [
  {
    name: 'Consultoría en Diseño',
    categories: ['Consultoría', 'Diseño'],
    keyWords: ['Asesoramiento en diseño', 'Estrategia de diseño', 'Optimización de diseño'],
  },
  {
    name: 'Consultoría en Animación',
    categories: ['Consultoría', 'Animación'],
    keyWords: ['Asesoramiento en animación', 'Estrategia de animación', 'Optimización de animación'],
  },
  {
    name: 'Consultoría en Audiovisual',
    categories: ['Consultoría', 'Audiovisual'],
    keyWords: ['Asesoramiento en producción audiovisual', 'Estrategia audiovisual', 'Optimización de contenido audiovisual'],
  },
  {
    name: 'Consultoría en Marketing',
    categories: ['Consultoría', 'Marketing'],
    keyWords: ['Asesoramiento en marketing', 'Estrategia de marketing', 'Optimización de estrategias de marketing'],
  },
  {
    name: 'Consultoría en Comunicaciones',
    categories: ['Consultoría', 'Comunicaciones'],
    keyWords: ['Asesoramiento en comunicaciones', 'Estrategia de comunicación', 'Optimización de comunicación corporativa'],
  },
  {
    name: 'Consultoría en Redes Sociales',
    categories: ['Consultoría', 'Social Media'],
    keyWords: ['Asesoramiento en redes sociales', 'Estrategia en redes sociales', 'Optimización de presencia en redes'],
  },
  {
    name: 'Consultoría en SEO',
    categories: ['Consultoría', 'SEO'],
    keyWords: ['Asesoramiento en SEO', 'Estrategia de optimización para motores de búsqueda', 'Mejora de visibilidad en línea'],
  },
  {
    name: 'Consultoría en E-commerce',
    categories: ['Consultoría', 'Negocios'],
    keyWords: ['Asesoramiento en comercio electrónico', 'Estrategia de ventas en línea', 'Optimización de tiendas en línea'],
  },
  {
    name: 'Consultoría en Estrategia Empresarial',
    categories: ['Consultoría', 'Negocios'],
    keyWords: ['Asesoramiento en estrategia empresarial', 'Planificación estratégica', 'Optimización de operaciones'],
  },
  {
    name: 'Consultoría en Desarrollo Web',
    categories: ['Consultoría', 'Tecnología'],
    keyWords: ['Asesoramiento en desarrollo web', 'Estrategia de presencia en línea', 'Optimización de sitios web'],
  },
  {
    name: 'Consultoría en Innovación',
    categories: ['Consultoría', 'Negocios'],
    keyWords: ['Asesoramiento en innovación', 'Estrategia de innovación', 'Optimización de procesos creativos'],
  },
]

export const allProducts: IClientProduct[] = [
  ...products,
  ...moreProducts,
  ...videoAndPhotographyProducts,
  ...writingProducts,
  ...communityManagerProducts,
  ...consultingProducts,
]

export const defaultProducts: IClientProduct[] = [
  {
    name: 'Logotipos',
    categories: ['Diseño', 'Audiovisual'],
    keyWords: ['Logos', 'Arte', 'Digital', 'empresas'],
    isDefault: true,
  },
  {
    name: 'Branding',
    categories: ['Diseño', 'Redacción'],
    keyWords: ['SEO', 'Arte', 'Logos', 'comics'],
    isDefault: true,
  },
  {
    name: 'Web',
    categories: ['Diseño', 'Desarrollo Web'],
    keyWords: ['Diseño web', 'Desarrollo', 'Diseño de interfaz', 'Front-end'],
    isDefault: true,
  },
  {
    name: 'Video Animado',
    categories: ['Animación', 'Marketing'],
    keyWords: ['Videos explicativos', 'Videos informativos', 'Explicación visual'],
    isDefault: true,
  },
]
