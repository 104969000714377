import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { FC } from 'react'
import PictureLayout from './PictureLayout'

interface IAvatarStructure {
  avatarImage: string
  portfolioImage: string
  categoryImage: string
}

interface IAvatarProfile extends IAvatarStructure {
  withoutRemoveIcon?: boolean
  asSquare?: boolean
  removeImage?: () => void
}

const SingleAvatarProfile: FC<IAvatarProfile> = (props) => {
  return (
    <div className='relative'>
      <div className={props.asSquare ? 'square-porfolio-avatar' : 'porfolio-avatar'}>
        <img
          className='w-full h-full object-cover'
          src={!props.portfolioImage || props.portfolioImage === '' ? props.categoryImage : props.portfolioImage}
        />
      </div>
      <PictureLayout className='small-avatar' avatar={props.avatarImage} />

      {!props.withoutRemoveIcon && props.portfolioImage && (
        <DeleteOutlineIcon
          fontSize='medium'
          className='text-red ml-1 absolute top-1 right-8 z-10 cursor-pointer'
          onClick={props.removeImage}
        />
      )}
    </div>
  )
}

export default SingleAvatarProfile
