import { FC, useState } from 'react'
import { Box } from '@material-ui/core'
import { TProjectSections } from './Project'
import ProjectFeedback from '../ProjectUserProfile/ProjectFeedback/ProjectFeedback'
import UserProfileDialog from '../UserProfileDialog/UserProfileDialog'

const tabStyles = 'subtitle4-regular cursor-pointer py-2'

const ProjectFeedbackSection: FC<TProjectSections> = (props) => {
  const { data: project } = props.project
  const [view, setView] = useState<'client' | 'freelancer'>('client')

  if (!project || project.staff.length === 0) {
    return (
      <UserProfileDialog>
        <h4 className='text-blue text-center'>Los candidatos de proyecto deben ser seleccionados primero</h4>
        <h4 className='text-center mt-4 text-blue'>
          Puedes hacerlo en la pestaña "Candidatos" o el cliente desde su User Profile.
        </h4>
      </UserProfileDialog>
    )
  }
  if (!project.staff[0].freelancer_feedback.list || !project.staff[0].client_feedback.list) {
    //Eliminar cuando se restaure la estructura de datos del proyecto
    return (
      <UserProfileDialog>
        <h4 className='text-blue text-center'>Proyecto con formato de feedback antiguo, no es compatible</h4>
      </UserProfileDialog>
    )
  }
  return (
    <Box display='grid' gridTemplateColumns='150px 1fr' className='gap-8 mt-8'>
      <div>
        <p
          className={`${tabStyles} ${view === 'client' ? 'subtitle4-medium border-b border-blue-700 text-blue-700' : ''}`}
          onClick={() => setView('client')}
        >
          Cliente
        </p>
        <p
          className={`${tabStyles} ${view === 'freelancer' ? 'subtitle4-medium border-b border-yellow-600 text-yellow-700' : ''}`}
          onClick={() => setView('freelancer')}
        >
          Freelancer
        </p>
      </div>

      <div>
        {view === 'freelancer' && <ProjectFeedback entity='freelancer' project={project} readOnly />}
        {view === 'client' && <ProjectFeedback entity='client' project={project} readOnly />}
      </div>
    </Box>
  )
}

export default ProjectFeedbackSection
