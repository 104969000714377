import { useMutation, useQuery } from 'react-query'
import { IBrief } from '../../models/IBrief'
import { IPaginatedResponse, IPut } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  briefs: (params: string) => `briefs${params}`,
  briefByProject: (projectId: string) => `briefs/by_project/${projectId}`,
  firstBriefUpdate: (id: string) => `briefs/first-update/${id}`,
}

export const useBrieftListQuery = (filters?: unknown) => {
  const endpoint = endpoints.briefs(`?filters=${JSON.stringify(filters)}`)
  const briefQuery = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IBrief>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
  })
  useSetLoader(briefQuery)

  return briefQuery
}

export const useBriefByProjectQuery = (projectId: string) => {
  const endpoint = endpoints.briefByProject(projectId)

  const briefQuery = useQuery(endpoint, () => apiClient.get<IBrief>({ endpoint }))
  useSetLoader(briefQuery)
  return briefQuery
}

export const useUpdateBrief = () => {
  return useMutation(({ body, id }: IPut<IBrief>) =>
    apiClient.put<IBrief, IBrief>({ endpoint: endpoints.briefs(`/${id}`), body })
  )
}

export const useFirstUpdateBrief = () => {
  return useMutation(({ body, id }: IPut<IBrief>) =>
    apiClient.put<IBrief, IBrief>({ endpoint: endpoints.firstBriefUpdate(id), body })
  )
}
