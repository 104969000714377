import { FC, ReactElement, useState } from 'react'

import { Box, Collapse } from '@material-ui/core'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'

type THelpUserProfile = {
  helpList: { topic: string; content: ReactElement }[]
}

type THelpItem = { topic: string; content: ReactElement }

const HelpUserProfile: FC<THelpUserProfile> = (props) => {
  const [selectedContent, setSelectedContent] = useState<THelpItem | undefined>(props.helpList[0])

  const handleItem = (item: THelpItem) => {
    if (item.topic === selectedContent?.topic) return setSelectedContent(undefined)
    return setSelectedContent(item)
  }

  return (
    <>
      <div className='md:block hidden'>
        <Box display='grid' gridTemplateColumns='250px 1fr' maxWidth={850} className='gap-12'>
          <div>
            {props.helpList.map((iter, index) => {
              return (
                <div
                  key={`${iter.topic}-${index}`}
                  className={`flex items-center justify-between cursor-pointer ${index !== 0 ? 'mt-8' : ''}`}
                  onClick={() => setSelectedContent(iter)}
                >
                  <p className={`${selectedContent?.topic === iter.topic ? 'subtitle4-medium' : 'subtitle4-regular'}`}>
                    {iter.topic}
                  </p>
                  {selectedContent?.topic === iter.topic && <FiIcons name='back_arrow' className='-ml-1' />}
                </div>
              )
            })}
          </div>
          <div>{selectedContent?.content}</div>
        </Box>
      </div>
      <div className='md:hidden block'>
        {props.helpList.map((iter, index) => {
          return (
            <div key={`${iter.topic}-${index}`}>
              <div className={`flex items-center cursor-pointer ${index !== 0 ? 'mt-8' : ''}`}>
                <p className='subtitle4-medium' onClick={() => handleItem(iter)}>
                  {iter.topic}
                </p>
                {selectedContent?.topic === iter.topic && <FiIcons name='back_arrow' className='ml-2' />}
              </div>
              <Collapse in={selectedContent?.topic === iter.topic}>{iter.content}</Collapse>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default HelpUserProfile
