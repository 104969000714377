type TFormError = {
  name: string
  errors: any
}

const FormError: React.FC<TFormError> = (props) => {
  const errors = () => {
    if (props.name.includes('.')) {
      const name = props.name.split('.')
      return props.errors[name[0]]?.[name[1]]
    }
    return props?.errors[props.name]
  }

  return <p className='body2-regular text-magenta'>{errors()?.message}</p>
}

export default FormError
