import { Link } from 'react-router-dom'
import routes from '../../constants/routes'
import FindieLogo from '../../shared/Brand/Logo/FindieLogo'
import FiButton from '../../assets/UIkit/FiButton'
import payment from '../../assets/images/web/payment.svg'
import paymentDesktop from '../../assets/images/web/payment-bg-desktop.svg'
import paymentTablet from '../../assets/images/web/payment-bg-tablet.svg'
import paymentMobile from '../../assets/images/web/payment-bg-mobile.svg'
import { Box } from '@material-ui/core'
import ProductsSlider from '../../shared/TextSlider/ProductsSlider'

const PaymentPage = () => {
  return (
    <Box maxWidth='1400px' className='mx-auto md:p-8 p-4 relative'>
      <Box display='grid' gridTemplateColumns='135px 1fr' className='gap-4 items-end'>
        <div>
          <Link to={routes.web_site.home}>
            <FindieLogo className='md:w-32 md:h-8 w-20 h-5' color='black' />
          </Link>
        </div>

        <ProductsSlider className='mt-6' />
      </Box>

      <Box height='calc(100svh - 100px)' className='relative total-center w-full mt-4 md:px-12 px-8'>
        <img className='w-full h-full absolute xl:block hidden' src={paymentDesktop} alt='pago' />
        <img className='w-full h-full absolute xl:hidden md:block hidden' src={paymentTablet} alt='pago' />
        <img className='w-full h-full absolute md:hidden block ' src={paymentMobile} alt='pago' />

        <div className='relative z-10 '>
          <img className='block mx-auto' src={payment} alt='pago' />
          <h3 className='text-center mt-4'>Thank you for your payment!</h3>
          <p className='buttontext4-regular text-center mt-3'>
            Hemos recibido tu pago. Revisa tu correo para seguir al siguiente paso.
          </p>

          <Link to={routes.clientUserProfile.home}>
            <FiButton variant='outlined' theme='secondary' size='large' className='mt-6 block mx-auto px-12'>
              Back to profile
            </FiButton>
          </Link>
        </div>
      </Box>
    </Box>
  )
}

export default PaymentPage
