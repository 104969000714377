import { FC, useMemo } from 'react'
import { TProjectSections } from './Project'
import { formatDecimal, operation, workPlanPrice } from '../../utils/helpers'
import { IStaff } from '../../models/IStaff'
import { IPlan } from '../../models/IPlan'
import UserProfileDialog from '../UserProfileDialog/UserProfileDialog'
import FiButton from '../../assets/UIkit/FiButton'
import { useRecalculateProjectPrice } from '../../customHooks/request/projectsQuery'
import { ICandidateBody } from '../../models/IProject'
import useRequestAlert from '../../customHooks/useRequestAlert'

const ProjectCalculator: FC<TProjectSections> = (props) => {
  const { data: project, refetch: refetchProject } = props.project
  const recalculateProject = useRecalculateProjectPrice()
  useRequestAlert(recalculateProject)

  const totalFreelancersPrice = useMemo(() => {
    if (!project) return 0

    return project.staff.reduce((acum, staff) => {
      if (!staff.work_plan) return acum
      return (acum = operation(acum, '+', workPlanPrice(staff)?.after_fee ?? 0))
    }, 0)
  }, [project])

  const setPriceType = (plan: IPlan | undefined) => {
    if (!plan) return

    const dispatch = {
      uf: `UF${plan.price}`,
      percent: `${plan.price}%`,
      cash: `$${plan.price}`,
    }
    return dispatch[plan.price_type]
  }

  const recalculateProjectPrice = () => {
    if (!project) return

    const body = {
      project,
      work_plan: project.staff[0].work_plan,
      products: project.price.products,
    } as ICandidateBody

    recalculateProject.mutate({ body, id: project?._id ?? '' }, { onSuccess: () => refetchProject() })
  }

  if (!project) return null
  if (project.project_status === 'not_approved') {
    return (
      <UserProfileDialog>
        <h4 className='text-blue'>Es necesario que el proyecto sea aprobado.</h4>
        <h4 className='text-center mt-4 text-blue'>
          Cuando un candidato es seleccionado en la pestaña "Candidatos" o hecho en el user profile, podrás ver los cálculos del
          proyecto en forma automática
        </h4>
      </UserProfileDialog>
    )
  }
  return (
    <div>
      <div className='flex justify-between items-center mt-8'>
        <p className='buttontext4-medium'>
          Valor UF acordada: <span className='text-blue'>{formatDecimal(project.uf)}</span>
        </p>

        <FiButton onClick={recalculateProjectPrice}>Recalcular costos</FiButton>
      </div>

      {project.staff.map((staff: IStaff) => {
        const isRemovedStyle = staff.has_leave_project ? 'bg-red-005 text-red' : ''
        return (
          <div
            key={staff._id}
            className={`grid grid-cols-12 border-t border-l border-r border-black px-4 mt-4 ${isRemovedStyle}`}
          >
            <p className='col-span-4 body1-medium border-r border-black'>
              {staff.category.name} - {staff.freelancer.name}
            </p>
            <div className='col-span-8 flex justify-between'>
              <p className='body1-medium ml-4'>
                (
                {staff.freelancer.plan.is_price_affect_project
                  ? `${staff.freelancer.plan.name} ${staff.freelancer.plan.fee}%`
                  : 'Plan que no afecta al proyecto'}
                ) {workPlanPrice(staff)?.has_promo_ticket ?? (0 && '- Ticket (2%)')}
              </p>
              <p className='body1-medium text-strong-rose'>${formatDecimal(workPlanPrice(staff)?.after_fee ?? 0)}</p>
            </div>
          </div>
        )
      })}
      <div className='grid grid-cols-12 border border-black px-4'>
        <p className='col-span-4 body1-medium border-r border-black'>Total</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(totalFreelancersPrice)}
        </p>
      </div>

      <div className='grid grid-cols-12 border-t border-l border-r border-black bg-gray-026 px-4 mt-6'>
        <p className='col-span-4 body1-medium border-r border-black'>Subtotal</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.subtotal)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-black px-4'>
        <p className='col-span-4 body1-medium border-r border-black'>
          {project.plan.name} fee ({project.plan.fee}%)
        </p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.findie_fee)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-black px-4'>
        <p className='col-span-4 body1-medium border-r border-black'>
          {project.plan.name} ({setPriceType(project.plan)})
        </p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.search_amount)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-black px-4'>
        <p className='col-span-4 body1-medium border-r text-magenta border-black'>Productos</p>
        <p className='col-span-8 body1-medium text-magenta text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.products.total)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-black px-4'>
        <p className='col-span-4 body1-medium border-r border-black'>IVA (19%)</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.sii_tax)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-black px-4'>
        <p className='col-span-4 body1-medium border-r border-black'>
          {project.price.payment_method.method} ({project.price.payment_method.fee}%)
        </p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.external_fee)}
        </p>
      </div>
      <div className='grid grid-cols-12 border border-black px-4 bg-gray-026'>
        <p className='col-span-4 body1-medium border-r border-black'>Total proyecto</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.total)}
        </p>
      </div>

      <div className='grid grid-cols-12 border-t border-l border-r border-blue-500 px-4 mt-6'>
        <p className='col-span-4 body1-medium border-r border-blue-500'>Utilidad cliente</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.profits.client)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-blue-500 px-4'>
        <p className='col-span-4 body1-medium border-r border-blue-500'>Utilidad freelancers</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.profits.freelancers)}
        </p>
      </div>
      <div className='grid grid-cols-12 border-t border-l border-r border-blue-500 px-4'>
        <p className='col-span-4 body1-medium border-r border-blue-500'>Utilidad productos</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.price.products.total)}
        </p>
      </div>
      <div className='grid grid-cols-12 border border-blue-500 px-4 bg-blue-100'>
        <p className='col-span-4 body1-medium border-r border-blue-500'>Total utilidades</p>
        <p className='col-span-8 body1-medium text-right'>
          {project.with_currency ? 'USD' : '$'} {formatDecimal(project.profits.total)}
        </p>
      </div>
    </div>
  )
}

export default ProjectCalculator
