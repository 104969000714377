import { UseFormReturn } from 'react-hook-form'
import { UseQueryResult } from 'react-query'
import { IFreelancer } from '../../models/IFreelancer'

import FreelancerFormPersonalData from './FreelancerFormPersonalData'
import FreelancerFormPlan from './FreelancerFormPlan'
import FreelancersFormCategoriesAndSkills from './FreelancersFormCategoriesAndSkills'
import FreelancersFormFiles from './FreelancersFormFiles'

type TFreelancersForm = {
  form: UseFormReturn<any>
  freelancer?: UseQueryResult<IFreelancer, unknown>
  isDisabledFields?: boolean
  asFreelancerFindie?: boolean
  asPostulation?: boolean
  asCpanel?: boolean
  variant?: 'outlined' | 'default'
}

const FreelancersForm: React.FC<TFreelancersForm> = (props) => {
  return (
    <>
      <FreelancerFormPersonalData
        freelancer={props?.freelancer?.data}
        variant={props.variant}
        isDisabledFields={props.isDisabledFields}
        asCpanel={props.asCpanel}
        asFreelancerFindie={props.asFreelancerFindie}
        asPostulation={props.asPostulation}
        form={props.form}
      />
      <FreelancersFormCategoriesAndSkills
        freelancer={props?.freelancer?.data}
        isDisabledFields={props.isDisabledFields}
        form={props.form}
        asPostulation={props.asPostulation}
      />
      {props.asFreelancerFindie && <FreelancerFormPlan freelancer={props.freelancer} isDisabledFields={props.isDisabledFields} />}
      <FreelancersFormFiles
        freelancer={props?.freelancer?.data}
        isDisabledFields={props.isDisabledFields}
        asFreelancerFindie={props.asFreelancerFindie}
        asPostulation={props.asPostulation}
        form={props.form}
      />
    </>
  )
}

export default FreelancersForm
