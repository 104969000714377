import { FC, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { loadFiles } from '../../../utils/helpers'
import { TFreelancerBookSections } from '../../../views/FreelancerUserProfile/FreelancerBook'
import camera from '../../../assets/images/userProfile/avatar-camera.svg'

const FreelancerAvatar: FC<TFreelancerBookSections> = (props) => {
  const { data: freelancer } = props.freelancerQuery

  const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
    accept: '.png, .jpg, .jpeg',
    noClick: true,
    noKeyboard: true,
    multiple: false,
  })

  const saveAvatarImage = async () => {
    if (!freelancer) return
    const file = acceptedFiles[0]
    const base64File = await loadFiles(file)
    const avatar = { file_name: file.name, url: base64File as string }
    props.updateFreelancer.mutate(
      {
        body: { avatar },
        id: freelancer._id,
      },
      {
        onSuccess: () => {
          location.reload()
        },
      }
    )
  }

  useEffect(() => {
    if (!acceptedFiles || !acceptedFiles.length) return
    saveAvatarImage()
  }, [acceptedFiles])

  if (!freelancer) return null
  return (
    <div className='grid grid-cols-12'>
      <div className='md:col-span-12 col-span-7 relative' {...getRootProps()}>
        <input {...getInputProps()} />

        {freelancer.avatar.url ? (
          <>
            <img className='w-40 h-40 cursor-pointer rounded-full' src={freelancer.avatar.url} alt='avatar' />
            <img style={{ position: 'absolute', bottom: 0, left: 120 }} src={camera} alt='camera' onClick={open} />
          </>
        ) : (
          <div className='w-40 h-40 rounded-full bg-ice-blue total-center'>
            <FiIcons name='circleAdd' className='w-12 h-12 cursor-pointer' onClick={open} />
          </div>
        )}
      </div>

      <div className='md:col-span-12 col-span-5'>
        <p className='subtitle5-medium mt-4'>
          {freelancer.name} {freelancer.lastName.slice(0, 1).toUpperCase()}.
        </p>
        <p className='body2-regular'>{freelancer.college_degree}</p>
        <a className='microcopy text-blue' href={`${window.location.origin}/perfil/${freelancer._id}`} target='_blank'>
          <FiIcons name='blue_eye' className='mr-1 mb-0.5' />
          VER PERFIL
        </a>
      </div>
    </div>
  )
}

export default FreelancerAvatar
