import { useState, Dispatch, SetStateAction } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import AppDialog from '../../assets/UIkit/AppDialog'
import { CALENDLY_URL } from '../../constants/Cpanel/ProjectConstants'

type TVideoCallModal = {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const VideoCallModal = (props: TVideoCallModal) => {
  return (
    <AppDialog title='' noHeader open={props.isModalOpen} handleClose={() => props.setIsModalOpen(false)} maxWidth='xl'>
      <div className='relative'>
        <iframe
          style={{ minWidth: '70vw', height: '80svh' }}
          src={CALENDLY_URL}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
        <CloseIcon
          className='text-black top-2 right-2 cursor-pointer absolute z-50'
          fontSize='large'
          onClick={() => props.setIsModalOpen(false)}
        />
      </div>
    </AppDialog>
  )
}

export default VideoCallModal
