import { FC, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { TAvatarStyle } from '../../../models/IUser'
import LayoutAvatar from '../../LayoutAvatar/LayoutAvatar'
import UpdatePasswordForm from '../../UpdatePasswordForm/UpdatePasswordForm'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { useTranslation } from 'react-i18next'

type TEntityProfileSettings = {
  avatarStyle: TAvatarStyle
  contactPreference: string
  form: UseFormReturn<any>
  avatarColors: TAvatarStyle[]
}

const EntityProfileSettings: FC<TEntityProfileSettings> = (props) => {
  const { t } = useTranslation()
  useEffect(() => {
    props.form.setValue('avatar_style', props.avatarStyle)
    props.form.setValue('contact_preference', props.contactPreference)
  }, [])

  return (
    <>
      <UpdatePasswordForm form={props.form} />

      <p className='microcopy mt-5 text-gray-400'>{t('GLOBAL.CONT_PREFERENCE')}</p>
      <div className='flex items-center md:flex-row flex-col gap-4'>
        <div className='flex items-center gap-2'>
          <label htmlFor='contact_preference_m' className='body2-medium cursor-pointer block text-gray-400'>
            {t('GLOBAL.MESSAGE')}
          </label>
          <InputField
            id='contact_preference_m'
            name='contact_preference'
            className='mt-2'
            inputProps={{
              className: 'no-height',
              type: 'radio',
              value: 'Mensajería',
            }}
            form={props.form}
          />
        </div>
        <div className='flex items-center gap-2'>
          <label htmlFor='contact_preference_c' className='body2-medium cursor-pointer block text-gray-400'>
            {t('GLOBAL.EMAIL')}
          </label>
          <InputField
            id='contact_preference_c'
            name='contact_preference'
            className='mt-2'
            inputProps={{
              className: 'no-height',
              type: 'radio',
              value: 'Correo',
            }}
            form={props.form}
          />
        </div>
      </div>

      <p className='microcopy mt-5 text-gray-400'>{t('GLOBAL.AVATAR_COLOR')}</p>
      <div className='flex gap-2 mt-2'>
        {props.avatarColors.map((color) => {
          return (
            <LayoutAvatar
              key={color}
              variant={color}
              size='micro'
              isSelected={props.form.watch('avatar_style') === color}
              onClick={() => props.form.setValue('avatar_style', color)}
            />
          )
        })}
      </div>
    </>
  )
}

export default EntityProfileSettings
