import { useForm } from 'react-hook-form'
import { UseQueryResult } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useCreateProject } from '../../../customHooks/request/projectsQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { IFreelancer } from '../../../models/IFreelancer'
import { IMetadata } from '../../../models/IMetadata'
import { cleanCLP, getTimeStamp } from '../../../utils/helpers'
import { usePlansQuery } from '../../../customHooks/request/plansQuery'

import ProjectForm from '../../../shared/Project/ProjectForm'

import { Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IPlan } from '../../../models/IPlan'
import SelectField from '../../../assets/UIkit/Forms/SelectField'
import { created_at } from '../../../utils/helpers'
import { INewProjectBody } from '../../../models/IProject'
import useUf from '../../../customHooks/useUf'

type TClientNewProject = {
  clientId: string
  isDisabledFields?: boolean
  freelancersQuery: UseQueryResult<{ data: IFreelancer[]; metadata: IMetadata }>
}

const ClientNewProject: React.FC<TClientNewProject> = (props) => {
  const form = useForm()
  const navigate = useHistory().push
  const { data: uf } = useUf()
  const { data: plansQuery } = usePlansQuery({ entity: 'client', is_available: true })
  const createNewProject = useCreateProject()
  useRequestAlert(createNewProject)
  const plan: IPlan = form.watch('project.plan') ? JSON.parse(form.watch('project.plan')) : form.watch('project.plan')
  const isDirectPlan = plan ? plan.name.toLowerCase().includes('direct') : false

  const createProject = (data: any) => {
    if (!data.brief.skills.length) return form.setError('skills_options', { message: 'Debes agregar habilidades a tu proyecto' })

    const start_date = getTimeStamp(data.brief.start_date)
    const end_date = getTimeStamp(data.brief.end_date)
    const proposed_budget = cleanCLP(data.brief.proposed_budget)
    const brief = { ...data.brief, created_at, start_date, end_date, proposed_budget }
    const project = { ...data.project, plan, client: props.clientId, created_at, uf }
    const body: INewProjectBody = { brief, project }
    createNewProject.mutate(
      { body },
      {
        onSuccess: () => {
          form.reset()
          navigate(`${routes.cpanel.clients.findie}/${props.clientId}/proyectos`)
        },
      }
    )
  }

  return (
    <form onSubmit={form.handleSubmit(createProject)}>
      <div className='flex justify-end mt-6'>
        <Link
          to={`${routes.cpanel.clients.findie}/${props.clientId}/proyectos`}
          className='body1-medium flex items-center cursor-pointer'
        >
          <ArrowBackIcon /> Volver
        </Link>
      </div>

      <h4>Selecciona el plan del proyecto</h4>
      {plansQuery && (
        <SelectField
          name='project.plan'
          selectOptions={plansQuery.map((plan: IPlan) => {
            return { value: JSON.stringify(plan), label: plan.name }
          })}
          options={{ required: 'Este campo es requerido' }}
          className='mb-4'
          form={form}
        />
      )}

      <h4>{plan?.name}</h4>

      {plan && (
        <>
          <ProjectForm isDisabledFields={props.isDisabledFields} isDirectPlan={isDirectPlan} form={form} />
          <div className='flex justify-end mt-4'>
            <Button variant='contained' color='primary' type='submit' disabled={props.isDisabledFields}>
              Crear proyecto
            </Button>
          </div>
        </>
      )}
    </form>
  )
}

export default ClientNewProject
