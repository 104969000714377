import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../../../constants/routes'
import FreelancerOffersList from './FreelancerOfferList'
import { FreelancerOfferDetail } from './FreelancerOfferDetail'

const FreelancerOffers: FC = () => {
  return (
    <Switch>
      <Route path={`${routes.freelancerUserProfile.projects.offers.detail}/:id`}>
        <FreelancerOfferDetail />
      </Route>
      <Route exact={true}>
        <FreelancerOffersList />
      </Route>
    </Switch>
  )
}

export default FreelancerOffers
