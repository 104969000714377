import { Box } from '@material-ui/core'
import { FC } from 'react'
import { IClient } from '../../models/IClient'
import { IFreelancer } from '../../models/IFreelancer'
import ClientCard from './ClientCard'
import ClientCandidateCard from '../Project/ClientCandidateCard'

type TProjectDetailLayout = {
  gridTemplateColumns: string
  freelancer?: IFreelancer
  client?: IClient
}

export const ProjectDetailLayout: FC<TProjectDetailLayout> = (props) => {
  return (
    <Box display='grid' gridTemplateColumns={props.gridTemplateColumns} className='mt-6 gap-8'>
      <div className='md:block hidden'>
        {props.freelancer && (
          <ClientCandidateCard
            goToProfile={() => window.open(`${window.location.origin}/perfil/${props?.freelancer?._id ?? ''}`)}
            {...props.freelancer}
          />
        )}
        {props.client && <ClientCard {...props.client} />}
      </div>
      <div>{props.children}</div>
    </Box>
  )
}
