import { FC } from 'react'
import useUser from '../../../customHooks/useUser'
import card from '../../../assets/images/userProfile/card.svg'
import smallCard from '../../../assets/images/userProfile/small-card.svg'
import orangeStar from '../../../assets/images/userProfile/orange-star.svg'
import { Box, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import BlueBorderCard from '../../../shared/ProjectOptions/BlueBorderCard'
import BlueWavesCard from '../../../shared/ProjectOptions/BlueWavesCard'

const FreelancerHomeStructure: FC = () => {
  const { user } = useUser()
  const isDesktop = useMediaQuery('(min-width:825px)')

  return (
    <Box maxWidth={isDesktop ? '620px' : '100%'} className='relative pb-8'>
      <h2>
        Hola {user?.name}, <br /> ¿qué te gustaría hacer?
      </h2>
      <div className='md:flex hidden justify-between mt-8 items-center shadow-black-005 px-4 py-2'>
        <p className='subtitle6-regular w-96 pr-12'>
          Te damos la bienvenida a tu user profile. Acá podrás ver tus proyectos, recibir ofertas y explorar Findie.
        </p>
        <img className='pr-8' src={card} alt='sobre' />
      </div>

      <div className='grid grid-cols-12 gap-2 mt-4 gap-y-4'>
        <BlueBorderCard
          className='md:col-span-6 col-span-12'
          title='Mis proyectos'
          path={routes.freelancerUserProfile.projects.list}
        />
        <BlueWavesCard
          className='md:col-span-6 col-span-12'
          title='Ofertas'
          path={routes.freelancerUserProfile.projects.offers.list}
        />
      </div>

      <div className='md:grid hidden grid-cols-12 gap-2 mt-4'>
        {/* <div className='col-span-3 shadow-black-005 p-4 flex justify-between flex-col cursor-pointer'>
          <p className='body2-medium'>Mensajes</p>
          <img className='ml-auto' src={smallCard} alt='sobre' />
        </div>
        <div className='col-span-3 shadow-black-005 p-4 flex justify-between flex-col cursor-pointer'>
          <p className='body2-medium'>Invita y gana hasta $100 usd</p>
          <img className='ml-auto' src={orangeStar} alt='estrella' />
        </div> */}
        <div className='col-span-6 shadow-black-005 total-center py-8 cursor-pointer'>
          <Link to={routes.freelancerUserProfile.book.bio}>
            <p className='formtext1-regular'>Perfil book</p>
          </Link>
        </div>
      </div>
      <div className='col-span-6 shadow-black-005 md:hidden flex justify-center items-center py-8 mt-4 cursor-pointer'>
        <Link to={routes.freelancerUserProfile.book.bio}>
          <p className='formtext1-regular'>Perfil book</p>
        </Link>
      </div>
    </Box>
  )
}

export default FreelancerHomeStructure
