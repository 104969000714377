import { FC } from 'react'
import manual from '../../../assets/images/userProfile/user-manual.png'

const HelpUseManual: FC = () => {
  return (
    <div className='w-10/12'>
      <p className='body2-regular'>Explicación del funcionamiento de Findie</p>
      <p className='body2-regular mt-4'>
        Descárgalo{' '}
        <a href='https://files-findie.s3.amazonaws.com/Documents/ManualdeusoFindie_022023.pdf' target='_blank'>
          <img src={manual} alt='manual' />
        </a>
      </p>
    </div>
  )
}

export default HelpUseManual
