import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link, useHistory, useParams } from 'react-router-dom'
import routes from '../constants/routes'

import FiButton from '../assets/UIkit/FiButton'
import InputField from '../assets/UIkit/Forms/InputField'
import FindieGifIsotype from '../shared/Brand/Isotype/FindieGifIsotype'
import useLocalStorage from '../customHooks/useLocalStorage'
import { useRecoverPasswordMutation } from '../customHooks/request/authQuery'
import { IAuth } from '../models/IAuth'

const RecoverPassword: React.FC = () => {
  const form = useForm()
  const { token } = useParams<{ token: string }>()
  const storage = useLocalStorage('locale', undefined)
  const navigate = useHistory().push
  const recoverPasswordMutation = useRecoverPasswordMutation()
  const [requestMessage, setRequestMessage] = useState<string>('')
  const formField = form.watch

  const editAdminProfile: SubmitHandler<IAuth> = (body) => {
    recoverPasswordMutation.mutate(
      { body, method: 'POST', customHeader: { Authorization: token } },
      {
        onSuccess: () => {
          setRequestMessage('Se cambió la contraseña exitosamente, te vamos a redirigir al inicio')
          storage.removeItem
          setTimeout(() => {
            navigate(routes.auth.login)
          }, 3000)
        },
        onError: () => setRequestMessage('Ups, hubo un error, inténtalo  nuevamente.'),
      }
    )
  }

  return (
    <div className='h-small-screen w-screen flex flex-col justify-center items-center'>
      <form className='lg:w-1/3 md:w-4/5 w-full px-4' onSubmit={form.handleSubmit(editAdminProfile)}>
        <Link to={routes.web_site.home}>
          <FindieGifIsotype className='block mx-auto w-28 h-28' />
        </Link>

        <h2 className='mt-12 text-center'>¡Es hora de renovarse! Crea una nueva clave secreta.</h2>

        <div className='grod grid-cols-12 h-40'>
          <div className='col-span-6'>
            <InputField
              name='password'
              label='Contraseña'
              inputProps={{
                type: 'password',
                className: 'w-full',
                placeholder: 'Ingresa tu nueva contraseña',
              }}
              options={{
                required: 'Este campo es requerido',
                validate: () => {
                  if (formField('repeat_password') !== formField('password')) {
                    return 'Las contraseñas deben ser iguales'
                  }
                },
              }}
              form={form}
            />
          </div>
          <div className='col-span-6'>
            <InputField
              name='repeat_password'
              label='Confirmar contraseña'
              inputProps={{
                type: 'password',
                className: 'w-full',
                placeholder: 'Conforma tu contraseña',
              }}
              options={{
                required: 'Este campo es requerido',
                validate: () => {
                  if (formField('repeat_password') !== formField('password')) {
                    return 'Las contraseñas deben ser iguales'
                  }
                },
              }}
              form={form}
            />
          </div>
          <p className='col-span-12 body2-medium mt-2 text-center'>{requestMessage}</p>
        </div>

        <FiButton type='submit' theme='secondary' variant='outlined' className='block mx-auto px-8'>
          Enviar contraseña
        </FiButton>
        <p className='microcopy text-center mt-16'>® Findie SpA 2021</p>
      </form>
    </div>
  )
}

export default RecoverPassword
