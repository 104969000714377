import { useForm } from 'react-hook-form'
import { IconButton, useMediaQuery } from '@material-ui/core'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { IFormStep } from '../../../models/IFormStep'
import { useEffect } from 'react'

import VerificationInput from 'react-verification-input'
import {
  useNewVerificationMutation,
  useRemoveEmailMutation,
  useVerifyEmailMutation,
} from '../../../customHooks/request/emailVerificationQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import dayjs from 'dayjs'
import FiButton from '../../../assets/UIkit/FiButton'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import { useHistory } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useTranslation } from 'react-i18next'

type TEmailVerification = {
  redirectTo: string
  asLiteForm?: boolean
}

const EmailVerification: React.FC<IFormStep & TEmailVerification> = (props) => {
  const { t } = useTranslation()
  const form = useForm()
  const history = useHistory()
  const storage = props.storage
  const isMobile = useMediaQuery('(max-width: 430px)')
  const sendEmailVerification = useNewVerificationMutation()
  const removeEmailMutation = useRemoveEmailMutation()
  const verifyEmailMutation = useVerifyEmailMutation()
  useRequestAlert(sendEmailVerification, undefined, undefined, undefined, `${t('GLOBAL.EMAIL_VERIFY.WILL_SEND')}`)
  useRequestAlert(verifyEmailMutation)

  const sendEmail = (data: { email: string }) => {
    const today = dayjs()
    const expirationDate = today.add(30, 'minute')

    const created_at = today.valueOf()
    const expiration_date = expirationDate.valueOf()

    const body = {
      created_at,
      expiration_date,
      email: data.email,
    }
    sendEmailVerification.mutate(
      { body },
      {
        onSuccess: () => {
          storage.setItem({ ...storage.item, email: data.email, isEmailVerified: false })
          form.setValue('code', '')
        },
      }
    )
  }

  const editEmail = () => {
    removeEmailMutation.mutate(
      { id: storage.item.email },
      {
        onSuccess: () => {
          form.setValue('email', '')
          form.setValue('code', '')
          storage.setItem({ ...storage.item, email: '', isEmailVerified: false })
        },
      }
    )
  }

  const verifyEmail = () => {
    const body = {
      expiration_date: dayjs().valueOf(),
      email: form.watch('email'),
      code: form.watch('code'),
    }

    verifyEmailMutation.mutate(
      { body },
      {
        onSuccess: () => {
          storage.setItem({ ...storage.item, isEmailVerified: true })
          history.push(props.redirectTo)
        },
      }
    )
  }

  useEffect(() => {
    form.setValue('email', storage.item?.email)
  }, [])

  return (
    <div className='w-full'>
      <h4 className='mb-4 md:mr-56 mr-0 md:mt-0 mt-4'>{t('GLOBAL.EMAIL_VERIFY.ACCOUNT_CREATE')} </h4>

      <div className='md:h-72 h-fit'>
        {Boolean(storage.item?.email) ? (
          <div className='h-full flex flex-col justify-between'>
            <div>
              <p className='formtext2-regular'>{t('GLOBAL.EMAIL_VERIFY.INSERT_CODE_SEND')} </p>
              <p className='formtext2-regular'>{storage.item?.email}</p>

              <div className='md:w-7/12 w-full'>
                <VerificationInput
                  passwordMode={true}
                  length={5}
                  autoFocus
                  onChange={(e) => form.setValue('code', e)}
                  classNames={{
                    container: 'mt-4',
                    character: 'h-24 text-black total-center border border-black',
                    characterSelected: 'border border-black outline-none',
                    characterInactive: 'bg-transparent',
                  }}
                />
              </div>
            </div>

            <div className='flex md:flex-row flex-col md:justify-between justify-center gap-6 md:mt-12 mt-4 items-center'>
              <div className='flex md:flex-row flex-col md:mt-0 mt-12 items-center gap-4'>
                <p className='buttontext2-regular cursor-pointer' onClick={() => sendEmail({ email: form.watch('email') })}>
                  {t('GLOBAL.EMAIL_VERIFY.DID_NOT_RECEIVE')}
                </p>
                <FiButton className='rounded-full border border-black' theme='secondary' variant='outlined' onClick={editEmail}>
                  <p className='microcopy'>{t('GLOBAL.EMAIL_VERIFY.MAIL_EDIT')}</p>
                </FiButton>
              </div>

              <IconButton
                size={isMobile ? 'small' : 'medium'}
                onClick={verifyEmail}
                className={`ml-auto border border-black block bg-black`}
              >
                <CheckRoundedIcon className='text-white' />
              </IconButton>
            </div>
          </div>
        ) : (
          <form className='w-full h-full flex flex-col justify-between pb-4' onSubmit={form.handleSubmit(sendEmail)}>
            <div>
              <div className='flex gap-4 border-b border-black pb-2 md:mr-12 mr-4'>
                <div className='self-end' style={{ width: '90%' }}>
                  <InputField
                    name='email'
                    className='w-full'
                    variant='transparent'
                    inputProps={{
                      className: `formtext2-regular text-black bg-transparent w-full`,
                      placeholder: `${t('GLOBAL.EMAIL_VERIFY.ENTER_MAIL')}`,
                      type: 'email',
                    }}
                    options={{ required: `${t('GLOBAL.EMAIL_VERIFY.REQUERID_FIELD')}` }}
                    form={form}
                  />
                </div>

                <div style={{ width: '10%' }}>
                  <IconButton
                    size={isMobile ? 'small' : 'medium'}
                    type='submit'
                    className={`ml-auto border border-black block bg-black`}
                  >
                    <ArrowForwardIosRoundedIcon className='text-white' />
                  </IconButton>
                </div>
              </div>
              {/* <p className='buttontext2-regular'>{t('GLOBAL.EMAIL_VERIFY.WILL_SEND')}</p> */}
            </div>

            <div className='md:mt-0  mt-16'>
              <p className='microcopy mt-4'>{t('GLOBAL.EMAIL_VERIFY.CREATING_AFFIRM')}</p>
              <p className='microcopy -mt-2'>{t('GLOBAL.EMAIL_VERIFY.TERMS_AND')}</p>

              {props.asLiteForm && (
                <div className='flex md:flex-row flex-col md:mt-2 mt-0 items-center gap-4'>
                  <p className='buttontext2-regular cursor-pointer' onClick={() => sendEmail({ email: form.watch('email') })}>
                    {t('GLOBAL.EMAIL_VERIFY.APPLY_AS_FREE')}
                  </p>
                  <FiButton
                    className='rounded-full border border-black'
                    theme='secondary'
                    variant='outlined'
                    asLink
                    to={routes.web_site.freelancer_suscription_form.step_zero}
                  >
                    <p className='microcopy'>{t('GLOBAL.CLICK')}</p>
                  </FiButton>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default EmailVerification
