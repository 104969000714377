import { Link } from 'react-router-dom'
import { IWebSiteSection } from '../../../models/IWebSiteSection'

import Arrow from '../../../assets/UIkit/Arrow'
import SectionStructure from '../../../layout/WebSite/SectionStructure'
import routes from '../../../constants/routes'
import { useTranslation } from 'react-i18next'

const OurFreelancersIntro: React.FC<IWebSiteSection> = (props) => {
  const { t } = useTranslation()

  return (
    <SectionStructure className='relative pb-0 mt-14'>
      <div className='freelancers-degrade 2xl:w-11/12 lg:w-full md:w-11/12 w-full'>
        <div className='2xl:w-7/12 lg:w-8/12 md:w-10/12 w-full'>
          <h1 className={`${props.view.textColor}`}>{t('OurFreelancers.OurFreelancersIntro.title')}</h1>
          <p className={`subtitle5-regular mt-6 2xl:pr-44 lg:pr-40 md:pr-0 mb-4 ${props.view.textColor}`}>
            {t('OurFreelancers.OurFreelancersIntro.subtitle')}
          </p>
          <div className={`lg:mt-8 md:mt-72 mt-52 cursor-pointer ${props.view.textColor}`}>
            <Link to={routes.web_site.freelancer_suscription_form.step_zero}>
              <span className='buttontext3-bold text-left mr-4 md:inline block'>
                {t('OurFreelancers.OurFreelancersIntro.applyHere')}
              </span>
              <Arrow direction='horizontal' className='md:inline block' />
            </Link>
          </div>
        </div>
      </div>
    </SectionStructure>
  )
}

export default OurFreelancersIntro
