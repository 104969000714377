import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { IPaymentMethod } from '../../../models/IPaymentMethod'

import AppDialog from '../../../assets/UIkit/AppDialog'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { created_at } from '../../../utils/helpers'

import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction } from '@material-ui/core'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { useConceptsMutation, useConceptsQuery } from '../../../customHooks/request/conceptsQuery'
import useDialog from '../../../customHooks/useDialog'
import { IConcept } from '../../../models/IConcept'

const Concepts: FC<{ mustDelete: boolean }> = (props) => {
  const dialog = useDialog<IConcept, 'create' | 'update' | 'suspend' | 'delete'>({ defaultAction: 'create' })
  const createForm = useForm<IPaymentMethod>()
  const updateForm = useForm<IPaymentMethod>()
  const { data: conceptsQuery, refetch: refetchConcepts } = useConceptsQuery()
  const conceptsMutation = useConceptsMutation()
  useRequestAlert(conceptsMutation, undefined, afterMutation)

  const handleConcepts = (data: IConcept) => {
    const body = { name: data.name }
    const id = dialog.data?._id ?? ''
    const is_available = !dialog.data?.is_available

    if (dialog.action === 'create') return conceptsMutation.mutate({ body: { ...body, created_at }, method: 'POST' })
    if (dialog.action === 'update') return conceptsMutation.mutate({ body, id, method: 'PUT' })
    if (dialog.action === 'suspend') return conceptsMutation.mutate({ body: { ...body, is_available }, id, method: 'PUT' })
    if (dialog.action === 'delete') return conceptsMutation.mutate({ body, id, method: 'DELETE' })
  }

  async function afterMutation() {
    await refetchConcepts()
    createForm.reset()
    if (dialog.action === 'create') return
    dialog.toggle({ dialogData: undefined, dialogAction: 'create' })
    updateForm.reset()
  }

  useEffect(() => {
    if (dialog.data?.name) updateForm.setValue('name', dialog.data.name)
  }, [dialog.data])

  if (!conceptsQuery) return null
  return (
    <>
      <h3 className='mt-6'>Agrega un concepto</h3>
      <form onSubmit={createForm.handleSubmit(handleConcepts)}>
        <Grid container spacing={2}>
          <Grid item xs={3} className='mt-4'>
            <InputField
              name='name'
              label='Nombre'
              inputProps={{ className: 'w-full', placeholder: 'Acogedor' }}
              options={{ required: 'Este campo es requerido' }}
              form={createForm}
            />
          </Grid>
          <Grid item xs={12} className='flex justify-end lg:pt-0 pt-2'>
            <Button type='submit' variant='contained' color='primary'>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>

      <h3 className='mb-1'>Conceptos</h3>
      <List className='border-t border-r border-l border-gray-300 py-0 mt-4'>
        {conceptsQuery.map((concept) => {
          return (
            <ListItem key={concept._id} className='border-b border-gray-300'>
              <span className='subtitle3-regular'>{concept.name}</span>

              <ListItemSecondaryAction>
                <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: concept, dialogAction: 'update' })}>
                  <FiIcons name='edit' />
                </IconButton>
                <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: concept, dialogAction: 'suspend' })}>
                  <FiIcons name='eye' className={`${concept.is_available ? '' : 'svg-white'}`} />
                </IconButton>
                {props.mustDelete && (
                  <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: concept, dialogAction: 'delete' })}>
                    <FiIcons name='skull' />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <AppDialog
        open={dialog.isOpen}
        title={`${['update', 'suspend'].includes(dialog.action ?? '') ? 'Editar' : 'Eliminar'} concepto`}
        handleClose={() => dialog.toggle({ dialogAction: 'create' })}
      >
        <form onSubmit={updateForm.handleSubmit(handleConcepts)}>
          {dialog.action === 'update' && (
            <>
              <span className='subtitle4-medium mb-2 block'>El nombre del concepto a editar es: {dialog.data?.name}</span>
              <InputField
                name='name'
                label='Nombre'
                className='mt-2'
                inputProps={{ className: 'w-full', placeholder: 'Acogedor' }}
                options={{ required: 'Este campo es requerido' }}
                form={updateForm}
              />
            </>
          )}
          {dialog.action === 'suspend' && (
            <>
              <span className='subtitle4-medium mb-4 block'>
                ¿Estas seguro que deseas {dialog?.data?.is_available ? 'suspender el concepto' : 'activar el concepto'}
                {dialog?.data?.name}?
              </span>
            </>
          )}
          {dialog.action === 'delete' && (
            <>
              <span className='subtitle4-medium mb-4 block'>
                ¿Estas seguro de eliminar el concepto {dialog?.data?.name}? <br />
              </span>
            </>
          )}
          <Divider className='mt-4' />

          <Box display='flex' justifyContent='flex-end' mt={2}>
            <Button variant='contained' className='mr-4' onClick={() => dialog.toggle({ dialogAction: 'create' })}>
              Cancelar
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              Aceptar
            </Button>
          </Box>
        </form>
      </AppDialog>
    </>
  )
}

export default Concepts
