import { FC } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import Pillbox from '../../../../shared/Pillbox/Pillbox'
import { IOffer } from '../../../../models/IOffer'
import { useTimer } from 'react-timer-hook'
import { created_at as today } from '../../../../utils/helpers'
import { Link } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { FiIcons } from '../../../../assets/UIkit/Icons/FiIcons'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import newOffer from '../../../../assets/images/userProfile/new.png'
import dayjs from 'dayjs'

type TFreelancerOfferCard = {
  declineOffer: () => void
  refetchOffer: (
    options?: (RefetchOptions & RefetchQueryFilters<IOffer[]>) | undefined
  ) => Promise<QueryObserverResult<IOffer[], unknown>>
}

const setPillboxColor = (status: string) => {
  if (status === 'postulated') return { title: 'Postulado', bgColor: 'bg-black' }

  const dispatch: Record<string, Record<string, string>> = {
    awarded: { title: 'Adjudicado', bgColor: 'bg-blue' },
    postulated: { title: 'Postulado', bgColor: 'bg-black' },
    declined: { title: 'Rechazado', bgColor: 'bg-soft-gray' },
    available: { title: '', bgColor: 'bg-transparent' },
  }
  return dispatch[status] ? dispatch[status] : dispatch.available
}

const FreelancerOfferCard: FC<IOffer & TFreelancerOfferCard> = (props) => {
  const isMobile = useMediaQuery('(max-width: 430px)')
  const isTablet = useMediaQuery('(max-width: 825px)')
  const time = new Date(props.project.offer_expiration_date)
  const { days } = useTimer({
    expiryTimestamp: time,
  })

  const gridColumns = () => {
    if (isTablet) return '35px 300px 45px  1fr 100px 100px'
    if (isMobile) return '35px 160px 45px  1fr'
    return '40px 350px 50px 15% 10% 15% 1fr'
  }

  return (
    <Box
      display='grid'
      height={60}
      gridTemplateColumns={gridColumns()}
      className={`items-center border-b border-gray-200 px-2 ${!true ? 'bg-gray-100' : ''}`}
    >
      {!['rejected', 'declined'].includes(props.status) ? (
        <>
          <Link to={`${routes.freelancerUserProfile.projects.offers.detail}/${props._id}`}>
            <FiIcons name='addCircle' />
          </Link>

          <Link to={`${routes.freelancerUserProfile.projects.offers.detail}/${props._id}`}>
            <p className='subtitle4-regular truncate'>{props.brief.title}</p>
          </Link>
        </>
      ) : (
        <>
          <p className='subtitle4-regular truncate' />
          <p className='subtitle4-regular truncate'>{props.brief.title}</p>
        </>
      )}

      <div>{props?.work_plan?.negotiation_message && <img src={newOffer} className='w-10 h-10' alt='nuevo' />}</div>

      <div className='flex justify-center'>
        {props.status !== 'available' && (
          <Pillbox className={`py-0.5 ${setPillboxColor(props.status).bgColor}`}>{setPillboxColor(props.status).title}</Pillbox>
        )}
      </div>
      <p className='md:block hidden text-center'>{dayjs(props.created_at).format('DD/MM/YYYY')}</p>
      <div className='md:block hidden'>
        {props.status === 'available' && (
          <p className='text-blue cursor-pointer flex items-center' onClick={() => true && props.declineOffer()}>
            <FiIcons name='blue_skull' className='mr-2' /> Rechazar
          </p>
        )}
      </div>
      {props.project.offer_expiration_date > today && (
        <span className='microcopy lg:block hidden'>Expira en {days + 1 > 9 ? days + 1 : `0${days + 1}`} días</span>
      )}
    </Box>
  )
}

export default FreelancerOfferCard
