import routes from '../../constants/routes'
import useNewClient from '../../customHooks/useNewClient'
import EmailVerification from '../../components/WebSite/ClientSuscriptionForm/EmailVerificationV2'
import Banner from '../../assets/images/brand/sign-up-banner.svg'
import BannerBackground from '../../assets/images/brand/sign-up-banner-background.svg'
import FindieIcon from '../../assets/images/brand/findie-icon-negative.svg'
import { useAuthRedirect } from '../../customHooks/useAuthRedirect'

const DesktopBanner = () => {
  return (
    <div className='relative h-48 lg:h-screen'>
      <div className='absolute w-full h-full lg:h-screen'>
        <div className='flex w-full h-full lg:h-screen'>
          <div className='m-4 lg:m-10 flex-1 relative'>
            <div className='absolute w-full h-full bg-black opacity-80'></div>
            <img src={BannerBackground} alt='banner background' className='h-full w-full item object-cover' />
            <img
              src={FindieIcon}
              alt='findie icon'
              className=' absolute top-1/2 left-1/2 h-32 lg:h-52 transform -translate-x-1/2 -translate-y-1/2 object-contain'
            />
          </div>
        </div>
      </div>
      <img src={Banner} alt='banner' className=' object-top lg:object-center w-full h-full lg:h-screen object-cover' />
    </div>
  )
}

const CreateAccount = () => {
  const { emailProps } = useNewClient()
  const redirect = useAuthRedirect()

  return (
    <div className='flex  items-start h-screen lg:flex-row flex-col'>
      <div className='w-full lg:w-6/12 xl:w-5/12'>
        <DesktopBanner />
      </div>
      <div className='flex-1  min-h-min h-full items-start flex justify-center p-5 mx-auto max-w-7xl flex-grow lg:px-10 lg:py-36 overflow-y-scroll'>
        <EmailVerification
          {...emailProps}
          redirectTo={routes.web_site.lite_client_suscription_form}
          asLiteForm
        ></EmailVerification>
      </div>
    </div>
  )
}

export default CreateAccount
