import { FC } from 'react'
import FindieIsotype from '../../../shared/Brand/Isotype/FindieIsotype'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useMediaQuery } from '@material-ui/core'

const UserProfileContainer: FC = (props) => {
  const isMobile = useMediaQuery('(max-width: 430px)')

  return (
    <>
      {isMobile ? (
        <div style={{ gridTemplateColumns: '1fr', gridTemplateRows: '85px 1fr' }} className='md:hidden grid min-screen-height'>
          <div className='shadow-black-005'>
            <FindieIsotype variant='contained-black' className='mx-auto md:mt-12 mt-4' />
          </div>

          <div className='px-4 h-full w-full'>{props.children}</div>
        </div>
      ) : (
        <div style={{ gridTemplateColumns: '135px 1fr' }} className='md:grid hidden min-screen-height'>
          <div className='shadow-black-005'>
            <Link to={routes.web_site.home}>
              <FindieIsotype variant='contained-black' className='mx-auto md:mt-12 mt-4' />
            </Link>
          </div>

          <div className='lg:pl-32 md:pl-24 pl-4 h-full'>{props.children}</div>
        </div>
      )}
    </>
  )
}

export default UserProfileContainer
