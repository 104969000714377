import { FC } from 'react'
import Box from '@material-ui/core/Box/Box'

type TStepLayout = {
  className?: string
}

const NewProjectStepLayout: FC<TStepLayout> = (props) => {
  return (
    <>
      <Box height='70vh' className={`border border-gray-200 mt-2 lg:p-8 p-4 ${props.className ?? ''}`}>
        {props.children}
      </Box>
    </>
  )
}

export default NewProjectStepLayout
