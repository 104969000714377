import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
//English Imports
//Website
import globalEN from './locales/WebSite/en/global.json'
import headerEN from './locales/WebSite/en/header.json'
import homeEN from './locales/WebSite/en/home.json'
import aboutUsEN from './locales/WebSite/en/aboutUs.json'
import howWorksEN from './locales/WebSite/en/howWorks.json'
import freelancerSuscriptionFormEN from './locales/WebSite/en/freelancerSuscriptionForm.json'
import ourClientsEN from './locales/WebSite/en/ourClients.json'
import ourFreelancerEN from './locales/WebSite/en/ourFreelancer.json'
import footerEN from './locales/WebSite/en/footer.json'
import faqEN from './locales/WebSite/en/faq.json'
import termEN from './locales/WebSite/en/termsConditions.json'
import freelancerProfileEN from './locales/WebSite/en/freelancerProfile.json'
import clientSubscriptionFormEN from './locales/WebSite/en/clientSubscriptionForm.json'
//User profile
import UPGlobalEN from './locales/ClientUserProfile/en/global.json'
import UPHomeEN from './locales/ClientUserProfile/en/home.json'
import UPRightSideBarEn from './locales/ClientUserProfile/en/rightSideBar.json'
import UPSideBarEn from './locales/ClientUserProfile/en/sideBar.json'
import UPLoginEn from './locales/ClientUserProfile/en/login.json'
import UPnewProjectEn from './locales/ClientUserProfile/en/upNewProject.json'
import UPaccountSettingsEN from './locales/ClientUserProfile/en/accountSettings.json'
import UPupDateBriefEN from './locales/ClientUserProfile/en/upDateBrief.json'
import UPbriefFormEN from './locales/ClientUserProfile/en/briefForm.json'
import UPclientProjectDetailsEN from './locales/ClientUserProfile/en/clientProjectDetails.json'
import UPmyFreeLancerEN from './locales/ClientUserProfile/en/myFreeLancers.json'
import UPclientHelpEN from './locales/ClientUserProfile/en/clientHelp.json'

//Spanish Imports
//Website
import globalES from './locales/WebSite/es/global.json'
import headerES from './locales/WebSite/es/header.json'
import homeES from './locales/WebSite/es/home.json'
import aboutUsES from './locales/WebSite/es/aboutUs.json'
import howWorksES from './locales/WebSite/es/howWorks.json'
import freelancerSuscriptionFormES from './locales/WebSite/es/freelancerSuscriptionForm.json'
import ourClientsES from './locales/WebSite/es/ourClients.json'
import ourFreelancerES from './locales/WebSite/es/ourFreelancer.json'
import footerES from './locales/WebSite/es/footer.json'
import faqES from './locales/WebSite/es/faq.json'
import termES from './locales/WebSite/es/termsConditions.json'
import freelancerProfileES from './locales/WebSite/es/freelancerProfile.json'
import clientSubscriptionFormES from './locales/WebSite/es/clientSubscriptionForm.json'
//User profile
import UPGlobalES from './locales/ClientUserProfile/es/global.json'
import UPHomeES from './locales/ClientUserProfile/es/home.json'
import UPRightSideBarEs from './locales/ClientUserProfile/es/rightSideBar.json'
import UPSideBarEs from './locales/ClientUserProfile/es/sideBar.json'
import UPLoginEs from './locales/ClientUserProfile/es/login.json'
import UPnewProjectEs from './locales/ClientUserProfile/es/upNewProject.json'
import UPaccountSettingsES from './locales/ClientUserProfile/es/accountSettings.json'
import UPupDateBriefES from './locales/ClientUserProfile/es/upDateBrief.json'
import UPbriefFormES from './locales/ClientUserProfile/es/briefForm.json'
import UPclientProjectDetailsES from './locales/ClientUserProfile/es/clientProjectDetails.json'
import UPmyFreeLancerES from './locales/ClientUserProfile/es/myFreeLancers.json'
import UPclientHelpES from './locales/ClientUserProfile/es/clientHelp.json'

const enDictionary = {
  //Website
  ...globalEN,
  ...faqEN,
  ...termEN,
  ...freelancerProfileEN,
  ...clientSubscriptionFormEN,
  ...headerEN,
  ...homeEN,
  ...aboutUsEN,
  ...howWorksEN,
  ...freelancerSuscriptionFormEN,
  ...ourClientsEN,
  ...ourFreelancerEN,
  ...footerEN,
  //Client user profile
  ...UPGlobalEN,
  ...UPHomeEN,
  ...UPRightSideBarEn,
  ...UPSideBarEn,
  ...UPLoginEn,
  ...UPnewProjectEn,
  ...UPaccountSettingsEN,
  ...UPupDateBriefEN,
  ...UPbriefFormEN,
  ...UPclientProjectDetailsEN,
  ...UPmyFreeLancerEN,
  ...UPclientHelpEN,
}
const esDictionary = {
  //Website
  ...globalES,
  ...faqES,
  ...termES,
  ...freelancerProfileES,
  ...clientSubscriptionFormES,
  ...headerES,
  ...homeES,
  ...aboutUsES,
  ...howWorksES,
  ...freelancerSuscriptionFormES,
  ...ourClientsES,
  ...ourFreelancerES,
  ...footerES,
  //Client user profile
  ...UPGlobalES,
  ...UPHomeES,
  ...UPRightSideBarEs,
  ...UPSideBarEs,
  ...UPLoginEs,
  ...UPnewProjectEs,
  ...UPaccountSettingsES,
  ...UPupDateBriefES,
  ...UPbriefFormES,
  ...UPclientProjectDetailsES,
  ...UPmyFreeLancerES,
  ...UPclientHelpES,
}
const resources = {
  en: {
    translation: enDictionary,
  },
  es: {
    translation: esDictionary,
  },
}
let navLang = 'en'
if (navigator.language.includes('es')) {
  navLang = 'es'
}
i18n.use(initReactI18next).init({
  resources,
  lng: navLang,
  interpolation: {
    escapeValue: false,
  },
})
export default i18n
