import { FC, useContext } from 'react'
import { CurrentViewContext } from '../../context/WebSite/CurrentViewContext'
import { Box } from '@material-ui/core'
import SingleAvatarProfile from './SingleAvatarProfile'
import { IFreelancer } from '../../models/IFreelancer'
import PillBoxSkills from '../PillBoxSkills/PillBoxSkills'
import FindieIsotype from '../Brand/Isotype/FindieIsotype'
import { capitalizeFirstLetter } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import PictureLayout from './PictureLayout'

type TWebAvatarProfile = {
  noSkills?: boolean
  featuredicon?: boolean
  limitSkill?: number
  asFreelancerGallery?: boolean
}

const WebAvatarProfile: FC<IFreelancer & TWebAvatarProfile> = (props) => {
  const { view } = useContext(CurrentViewContext)
  const { t } = useTranslation()
  return (
    <>
      {/* desktop */}
      <div className='lg:block hidden'>
        <div className={`relative ${props.asFreelancerGallery ? '' : 'square-porfolio-avatar'} `}>
          <img
            className='square-porfolio-avatar object-cover'
            src={!props.portfolio_avatar || props.portfolio_avatar === '' ? props.category.image : props.portfolio_avatar}
          />
          {props.featuredicon && <FindieIsotype variant='golden' className='absolute -bottom-4 right-2' />}
        </div>
        <Box className='mt-1.5' display='grid' gridTemplateColumns='2.8rem 1fr' alignItems='start'>
          <PictureLayout className='mt-2' avatar={props.avatar.url} />
          <div className='mt-1'>
            <p className={`subtitle5-medium ${view.textColor}`}>{props.name}</p>
            <p className={`body2-regular -mt-1 ${view.textColor}`}>{t(props.college_degree)}</p>
            {!props.noSkills && <PillBoxSkills limitSkill={props.limitSkill} skills={props.skills} />}
          </div>
        </Box>
      </div>
      {/* tablet/mobile */}
      <div className='lg:hidden grid grid-cols-12 gap-4 md:mt-0 mt-4'>
        <div className='md:col-span-12 col-span-6 relative'>
          <SingleAvatarProfile
            portfolioImage={props.portfolio_avatar}
            avatarImage={props.avatar.url}
            categoryImage={props.category.image}
            asSquare
            withoutRemoveIcon
          />
          {props.featuredicon && <FindieIsotype variant='golden' className='absolute bottom-1 right-2' />}
        </div>
        <div className='md:col-span-12 col-span-6'>
          <p className={`subtitle3-22 md:mt-0 mt-0 ${view.textColor}`}>
            {props.name} {capitalizeFirstLetter(props.lastName)}.
          </p>
          <p className={`body2-regular ${view.textColor}`}>{props.college_degree}</p>
          {!props.noSkills && <PillBoxSkills limitSkill={props.limitSkill} skills={props.skills} />}
        </div>
      </div>
    </>
  )
}

export default WebAvatarProfile
