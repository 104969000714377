import { FiIcons } from '../../../../../../assets/UIkit/Icons/FiIcons'
import SelectField from '../../../../../../assets/UIkit/Forms/SelectField'
import { IWorkPlanVersion } from '../../../../../../models/IWorkPlan'
import { UseFormReturn } from 'react-hook-form'

type TBreakdownVersions = {
  versions: IWorkPlanVersion[]
  form: UseFormReturn<any>
}

const BreakdownVersions = (props: TBreakdownVersions) => {
  return (
    <div className='flex md:items-center justify-between items-start md:flex-row flex-col md:gap-6 gap-4 lg:w-10/12 w-full'>
      <div className='flex items-center gap-2'>
        <h4>Desglose costo</h4>
        <FiIcons name='ArrowRight' className='svg-black mt-1' />
      </div>

      <div className='flex border-b-2 border-white items-center gap-4'>
        <div className='h-6 w-6 bg-blue rounded-full' />
        <SelectField
          name='selected_version'
          variant='transparent'
          className=''
          inputProps={{
            className: 'w-48 subtitle4-regular -ml-4',
          }}
          selectOptions={props.versions.map((version) => ({
            label: version.name,
            value: version.name,
          }))}
          form={props.form}
        />
      </div>
    </div>
  )
}

export default BreakdownVersions
