import { IOffer } from '../models/IOffer'
import { IProduct } from '../models/IProduct'
import { IWorkPlanVersion } from '../models/IWorkPlan'
import { ticketTaxCalculator } from '../utils/CalculationsHelper'
import { taxCalculator } from '../utils/CalculationsHelper'
import { formatDecimal, operation } from '../utils/helpers'

type TotalPriceParams = {
  offer: IOffer | undefined
  selectedProducts: IProduct[]
  selectedWorkPlanVersion: IWorkPlanVersion | undefined
}

const useTotalWorkPlanPrice = (params: TotalPriceParams) => {
  const { offer, selectedProducts, selectedWorkPlanVersion } = params

  const subTotal = formatDecimal(selectedWorkPlanVersion?.price.total ?? 0)

  const selectedProductsPrice = () => {
    if (!offer) return 0
    if (offer.project.with_currency) {
      return selectedProducts.reduce((acc, product) => {
        return operation(acc, '+', product.currency_price)
      }, 0)
    }
    return selectedProducts.reduce((acc, product) => {
      const price = product.price_type === 'uf' ? product.price * offer.project.uf ?? 0 : product.price
      return operation(acc, '+', price)
    }, 0)
  }

  const searchPrice = () => {
    if (!offer) return 0

    if (offer.project.with_currency) return offer.project.plan.currency_price
    if (offer.project.plan.price_type === 'uf') return operation(offer.project.plan.price, '*', offer.project.uf ?? 0)
    if (offer.project.plan.price_type === 'percent') return 0
    return offer.project.plan.price
  }

  const projectPrice = () => {
    if (!offer || !selectedWorkPlanVersion) return { siiTax: 0, platformTax: 0, total: 0, afterFee: 0 }

    const subtotal = selectedWorkPlanVersion.price.total
    const findieCalcs = taxCalculator(subtotal, offer.project.plan.fee)
    const subtotalWhitProducts = operation(findieCalcs.withTax, '+', selectedProductsPrice())
    const subtotalWithSearch = operation(subtotalWhitProducts, '+', searchPrice())

    const priceTaxes = ['bill', 'none'].includes(offer.project.price.sii.type)
      ? taxCalculator(subtotalWithSearch, offer.project.price.sii.tax)
      : ticketTaxCalculator(subtotalWithSearch, offer.project.price.sii.tax, offer.project.price.sii.ticket_constant)

    const subtotalWithPlatformMethod = taxCalculator(priceTaxes.withTax, offer.project.price.payment_method.fee)
    const projectTotal = subtotalWithPlatformMethod.withTax

    return {
      afterFee: findieCalcs.tax,
      siiTax: priceTaxes.tax,
      total: projectTotal,
    }
  }

  return {
    freelancerServices: subTotal,
    platformFee: formatDecimal(operation(projectPrice().afterFee, '+', searchPrice())),
    products: formatDecimal(selectedProductsPrice()),
    siiTax: formatDecimal(projectPrice().siiTax),
    total: formatDecimal(projectPrice().total),
  }
}

export default useTotalWorkPlanPrice
