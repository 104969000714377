import { FC } from 'react'

export type TRoadMap = {
  title: string
  content: string
  step: number
  backgroundColor: string
  isNotLastStep: boolean
}
type TRoadMapLayout = {
  roadmap: TRoadMap[]
  textColor: string
  className?: string
  bgColor?: string
}

const RoadMapLayout: FC<TRoadMapLayout> = (props) => {
  const { bgColor = 'transparent' } = props

  return (
    <>
      <div
        className={`lg:block hidden p-4 ${props.className ?? ''}`}
        style={{ maxWidth: 940, backgroundColor: `var(--${bgColor})` }}
      >
        <div className='flex justify-between gap-2 mt-8'>
          {props.roadmap.map((iter: any) => {
            return (
              <div key={iter.title} className='w-48'>
                <div className='total-center relative mb-4 md:w-24 md:h-24 w-16 h-16 rounded-full mx-auto'>
                  <div
                    className='w-full h-full rounded-full absolute top-0 left-0 bg-blur'
                    style={{ backgroundColor: iter.backgroundColor }}
                  />
                  <h2 className={`text-center text-white relative z-10`}>{iter.step}</h2>
                </div>
                <p className={`subtitle1-medium text-center ${props.textColor}`}>{iter.title}</p>
                <p className={`body2-regular mt-2 text-center w-44 mx-auto ${props.textColor} break-words`}>{iter.content}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className={`lg:hidden block ${props.className ?? ''}`} style={{ backgroundColor: `var(--${bgColor})` }}>
        {props.roadmap.map((iter) => {
          return (
            <div key={iter.title} className='grid grid-cols-12 mt-8'>
              <div className='md:col-span-2 total-center md:w-24 md:h-24 w-16 h-16 relative mb-4 mx-auto'>
                <div
                  className='w-full h-full absolute top-0 left-0 rounded-full bg-blur'
                  style={{ backgroundColor: iter.backgroundColor }}
                />
                <h2 className={`text-center text-white relative z-10`}>{iter.step}</h2>
              </div>

              <div className='md:col-span-9 col-span-12 md:pl-4'>
                <p className={`subtitle1-medium ${props.textColor}`}>{iter.title}</p>
                <p className={`body2-regular mt-2 ${props.textColor}`}>{iter.content}</p>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default RoadMapLayout
