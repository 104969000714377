import { FC, useEffect } from 'react'
import { TProjectSections } from '../Project'
import { formatDecimal, operation } from '../../../utils/helpers'

import { Button } from '@material-ui/core'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { TPaymentsSection } from './ProjectPayments'
import dayjs from 'dayjs'

const ClientPayments: FC<TProjectSections & TPaymentsSection> = (props) => {
  const { data: project } = props.project

  const paymentStatus = () => {
    if (!project) return { totalPayments: 0, debs: 0 }

    const totalPayments = project.payments
      .filter(
        (payment) =>
          payment.entity === 'client' &&
          (payment.entity_id === project._id || payment.entity_id === project.client._id) &&
          payment.amount !== 10 // Ignorar los pagos iguales a 10
      )
      .reduce((acum, payment) => acum + payment.amount, 0)

    const debs = operation(project.price.total, '-', totalPayments)

    return { totalPayments: formatDecimal(totalPayments), debs }
  }

  useEffect(() => {
    if (paymentStatus().debs > 0) {
      props.setDebs(paymentStatus().debs)
    }
  }, [paymentStatus().debs])

  if (!project) return null
  return (
    <>
      <p className='body1-medium'>Cliente</p>

      <div className={`grid grid-cols-12 mt-2 gap-4`}>
        <div className='col-span-7'>
          <p className='buttontext1-semibold'>
            Valor acordado: <span className='buttontext1-medium'>{formatDecimal(project.price.total)}</span>
          </p>

          {project.payments
            .filter(
              (payment) =>
                payment.entity === 'client' && (payment.entity_id === project._id || payment.entity_id === project.client._id)
            )
            .map((payment) => {
              if (payment.amount > 10) {
                return (
                  <div key={payment._id} className='grid grid-cols-12 mt-2'>
                    <p className='col-span-4 self-center body2-regular'>{payment.title}</p>
                    <p className='col-span-3 self-center body2-regular text-right'>{formatDecimal(payment.amount)}</p>
                    <p className='col-span-3 self-center body2-regular text-right'>
                      {dayjs(payment.created_at).format('DD/MM/YYYY')}
                    </p>
                    {project.project_status === 'in_progress' && (
                      <div className='col-span-2 flex justify-end'>
                        <FiIcons
                          name='edit'
                          className='mr-2 cursor-pointer'
                          onClick={() => props.dialogToggle({ dialogAction: 'update', dialogData: payment })}
                        />
                        <FiIcons
                          name='skull'
                          className='cursor-pointer'
                          onClick={() => props.dialogToggle({ dialogAction: 'delete', dialogData: payment })}
                        />
                      </div>
                    )}
                  </div>
                )
              }
            })}

          {paymentStatus().debs !== 0 && (
            <Button
              variant='outlined'
              className='mt-4'
              color='primary'
              onClick={() => props.dialogToggle({ dialogAction: 'create', dialogData: { entity_id: project.client._id } })}
              disabled={props.isDisabledFields}
            >
              Agregar pago
            </Button>
          )}
        </div>

        <div className='col-span-5'>
          <div className='flex justify-between bg-light-gray px-2 py-2'>
            <p className='buttontext1-semibold'>Total pagado</p>
            <p className='body2-medium'>{paymentStatus().totalPayments}</p>
          </div>

          <div
            className={`flex justify-between p-2 ${
              paymentStatus().debs === 0 ? 'text-white bg-soft-green' : 'text-red bg-light-gray'
            }`}
          >
            <span className='body2-medium'>{paymentStatus().debs !== 0 ? 'Debe' : 'Pagado'}</span>
            <span className='body2-medium'>{paymentStatus().debs !== 0 && formatDecimal(paymentStatus().debs)}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientPayments
