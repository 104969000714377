import { useMutation, useQuery } from 'react-query'
import endpoints from '../../constants/endpoints'
import { apiClient } from '../../utils/ApiClient'

export const useHomeGalleryQuery = (filters?: any) => {
  const queryParams = filters ? JSON.stringify(filters) : ''
  const plansQuery = useQuery([`all_${endpoints.homeGallery}`, filters], () => apiClient.getHomeGalleryFiltered(queryParams), {
    select: (data) => data.data,
  })
  return plansQuery
}

export const usePostHomeGalleryMutation = () => {
  const mutation = useMutation(apiClient.postHomeGallery)
  return mutation
}

export const usePostHomeGalleryDnDMutation = () => {
  const mutation = useMutation(apiClient.homeGalleryDnD)
  return mutation
}

export const usePutHomeGalleryMutation = () => {
  const mutation = useMutation(apiClient.putHomeGallery)
  return mutation
}

export const useDeleteHomeGalleryMutation = () => {
  const mutation = useMutation(apiClient.deleteHomeGallery)
  return mutation
}
