import { Link, NavLink } from 'react-router-dom'
import routes from '../../constants/routes'

import clsx from 'clsx'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import { Box, Drawer, List, ListItem, Collapse, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import freelancersIcon from '../../assets/images/cpanel/freelancers/freelancers-icon.svg'
import findieIcon from '../../assets/images/brand/findie-icon.svg'
import clientsIcon from '../../assets/images/cpanel/clients/clients-icon.svg'
import assetsIcon from '../../assets/images/cpanel/assets/assets-icon.svg'
// import boardIcon from '../../assets/images/cpanel/board/board-icon.svg'
import projectIcon from '../../assets/images/cpanel/projects/project-icon.png'

type TdrawerLinks = {
  title: string
  link?: string
}
type TDrawerApp = {
  open: boolean
  setOpen: (e: boolean) => void
}

const freelancerLinks: TdrawerLinks[] = [
  { title: 'Registro', link: `${routes.cpanel.freelancers.register}` },
  { title: 'Postulantes', link: `${routes.cpanel.freelancers.postulations}` },
  { title: 'Freelancers Findie', link: `${routes.cpanel.freelancers.findie}?page=1` },
]
const clientLinks: TdrawerLinks[] = [
  { title: 'Registro', link: `${routes.cpanel.clients.register}` },
  { title: 'Postulantes', link: `${routes.cpanel.clients.postulations}` },
  { title: 'Clientes Findie', link: `${routes.cpanel.clients.findie}` },
]

const projectLinks: TdrawerLinks[] = [{ title: 'Listado', link: `${routes.cpanel.projects.list}` }]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 220,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: 220,
      borderRight: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      borderRight: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 62,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
)

const DrawerApp: React.FC<TDrawerApp> = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <Box display='grid' gridTemplateRows='64px 1fr 60px' gridTemplateColumns={`${props.open ? '100%' : 62}`} height='100vh'>
        <div className={`border-b border-gray-300 ${classes.toolbar}`}>
          <IconButton onClick={() => props.setOpen(false)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon className='text-black' /> : <ChevronLeftIcon className='text-black' />}
          </IconButton>
        </div>
        <Box className='overflow-y-auto overflow-x-hidden py-6'>
          <span onClick={() => props.setOpen(true)} className='buttontext4-medium flex items-center ml-2 cursor-pointer'>
            <img src={freelancersIcon} alt='freelancer-icon' className='mr-2' />
            Freelancers
          </span>
          <Collapse in={props.open}>
            <List>
              {props.open &&
                freelancerLinks.map((iter: TdrawerLinks) => (
                  <ListItem key={iter.title} button>
                    <NavLink
                      to={iter.link ?? ''}
                      activeClassName={iter.title === 'Freelancers Findie' ? '' : 'border-b border-black'}
                      className='buttontext2-regular text-black w-full mx-2'
                    >
                      {iter.title}
                    </NavLink>
                  </ListItem>
                ))}
              <ListItem button>
                <NavLink
                  to={`${routes.cpanel.freelancers.findie}?page=1`}
                  activeClassName='border-b border-black'
                  className='buttontext2-regular text-black pl-4 w-full mx-2'
                >
                  Quick actions
                </NavLink>
              </ListItem>
              <ListItem button>
                <NavLink
                  to={routes.cpanel.freelancers.findie_featured}
                  activeClassName='border-b border-black'
                  className='buttontext2-regular text-black pl-4 w-full mx-2'
                >
                  Destacados
                </NavLink>
              </ListItem>
              <ListItem button>
                <NavLink
                  to={routes.cpanel.freelancers.home_gallery}
                  activeClassName='border-b border-black'
                  className='buttontext2-regular text-black pl-4 w-full mx-2'
                >
                  Home Gallery
                </NavLink>
              </ListItem>
              <ListItem button>
                <NavLink
                  to={routes.cpanel.freelancers.deleted}
                  activeClassName='border-b border-black'
                  className='buttontext2-regular text-black pl-4 w-full mx-2'
                >
                  Eliminados
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
          <span onClick={() => props.setOpen(true)} className='buttontext4-medium flex items-center ml-2 cursor-pointer'>
            <img src={clientsIcon} alt='freelancer-icon' className='mr-2' />
            Clientes
          </span>
          <Collapse in={props.open}>
            <List>
              {props.open &&
                clientLinks.map((iter: TdrawerLinks) => (
                  <ListItem key={iter.title} button>
                    <NavLink
                      to={iter.link ?? ''}
                      activeClassName='border-b border-black'
                      className='buttontext2-regular text-black w-full mx-2'
                    >
                      {iter.title}
                    </NavLink>
                  </ListItem>
                ))}
              <ListItem button>
                <NavLink
                  to={routes.cpanel.clients.deleted}
                  activeClassName='border-b border-black'
                  className='buttontext2-regular text-black w-full mx-2'
                >
                  Eliminados
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
          <span
            onClick={() => props.setOpen(true)}
            className='buttontext4-medium flex items-center ml-2 cursor-pointer text-black'
          >
            <img src={projectIcon} alt='freelancer-icon' className='mr-2' />
            Proyectos
          </span>
          <Collapse in={props.open}>
            <List>
              {props.open &&
                projectLinks.map((iter: TdrawerLinks) => (
                  <ListItem key={iter.title} button>
                    <NavLink
                      to={iter.link ?? ''}
                      activeClassName='border-b border-black'
                      className='buttontext2-regular text-black w-full mx-2'
                    >
                      {iter.title}
                    </NavLink>
                  </ListItem>
                ))}
            </List>
          </Collapse>
          <Link to={`${routes.cpanel.assets.index}`}>
            <span className='buttontext4-medium flex items-center ml-2 cursor-pointer text-black'>
              <img src={assetsIcon} alt='freelancer-icon' className='mr-2' />
              Assets
            </span>
          </Link>
          <List>
            <ListItem button>
              <NavLink
                to={`${routes.cpanel.assets.index}`}
                activeClassName='border-b border-black'
                className='buttontext2-regular text-black w-full mx-2'
              >
                Quick actions
              </NavLink>
            </ListItem>
          </List>
          {/* <Link to={`${routes.cpanel.board}`}>
            <span className='buttontext4-medium flex items-center ml-2 cursor-pointer text-black'>
              <img src={boardIcon} alt='freelancer-icon' className='mr-2' />
              Board
            </span>
          </Link> */}
        </Box>
        <Box className='total-center'>
          <img src={findieIcon} className='w-12 h-12' alt='freelancer-icon' />
        </Box>
      </Box>
    </Drawer>
  )
}

export default DrawerApp
