const routes = {
  auth: {
    login: '/login',
    forgot_password: '/olvide-contrasena',
    recover_password: '/recuperar-contrasena',
  },
  web_site: {
    home: '/',
    about_us: '/sobre-findie',
    how_works: '/como-funciona',
    why_findie: '/porque-findie',
    our_freelancers: '/freelancers',
    our_clients: '/clientes',
    freelancers_profiles: '/perfiles-freelancers',
    freelancer_profile: '/perfil',
    faqs: '/preguntas-frecuentes',
    terms_conditions: '/aspectos-legales',
    calculator: '/calculadora',
    payment: '/pago-recibido',
    bookStore: '/tienda-libros',
    createAccount: '/registrarse',
    client_suscription_form: {
      index: '/inscripcion-cliente',
      step_zero: '/inscripcion-cliente/paso/0',
      form_steps: '/inscripcion-cliente/paso',
      step_six: '/inscripcion-cliente/paso/6',
      step_seven: '/inscripcion-cliente/paso/7',
    },
    lite_client_suscription_form: '/formulario-cliente',
    freelancer_suscription_form: {
      index: '/inscripcion-freelancer',
      step_zero: '/inscripcion-freelancer/paso/0',
      form_steps: '/inscripcion-freelancer/paso',
      step_five: '/inscripcion-freelancer/paso/5',
      step_six: '/inscripcion-freelancer/paso/6',
    },
  },
  cpanel: {
    index: '/cpanel',
    profile: '/cpanel/perfil',
    dashboard: '/cpanel/dashboard',
    freelancers: {
      index: '/cpanel/freelancers',
      register: '/cpanel/freelancers/registro',
      postulations: '/cpanel/freelancers/postulantes',
      findie: '/cpanel/freelancers/freelancers-findie',
      findie_featured: '/cpanel/freelancers/freelancers-findie-destacados',
      home_gallery: '/cpanel/freelancers/galeria-home',
      deleted: '/cpanel/freelancers/descartados',
    },
    clients: {
      index: '/cpanel/clientes',
      register: '/cpanel/clientes/registro',
      postulations: '/cpanel/clientes/postulantes',
      findie: '/cpanel/clientes/clientes-findie',
      deleted: '/cpanel/clientes/descartados',
    },
    assets: {
      index: '/cpanel/assets',
      categories: '/cpanel/assets/categorias',
      skills: '/cpanel/assets/habilidades',
      plans: '/cpanel/assets/planes',
      payment_methods: '/cpanel/assets/metodos-pago',
      products: '/cpanel/assets/productos',
      concepts: '/cpanel/assets/conceptos',
    },
    project: {
      new: '/proyectos/nuevo-proyecto',
      detail: '/proyectos/detalle',
    },
    projects: {
      list: '/cpanel/proyectos',
      detail: '/cpanel/proyectos/detalle',
    },
    simulator: '/cpanel/simulador-proyecto',
    board: '/cpanel/board',
  },
  clientUserProfile: {
    index: '/perfil-cliente',
    home: '/perfil-cliente/inicio',
    profile: {
      index: '/perfil-cliente/perfil',
      personalData: '/perfil-cliente/perfil/datos-personales',
      settings: '/perfil-cliente/perfil/ajustes',
      billing: '/perfil-cliente/perfil/facturacion',
    },
    projects: {
      index: '/perfil-cliente/proyectos',
      new: '/perfil-cliente/proyectos/nuevo',
      start: '/perfil-cliente/proyectos/nuevo/1',
      options: '/perfil-cliente/proyectos/opciones',
      list: '/perfil-cliente/proyectos/lista',
      historical: '/perfil-cliente/proyectos/historico',
      detail: `/perfil-cliente/proyectos/detalle`,
      myFreelancers: '/perfil-cliente/proyectos/mis-freelancers',
    },
    book: '/perfil-cliente/book',
    help: '/perfil-cliente/ayuda',
  },
  freelancerUserProfile: {
    index: '/perfil-freelancer',
    home: '/perfil-freelancer/inicio',
    profile: {
      index: '/perfil-freelancer/perfil',
      personalData: '/perfil-freelancer/perfil/datos-personales',
      settings: '/perfil-freelancer/perfil/ajustes',
      billing: '/perfil-freelancer/perfil/facturacion',
      plan: '/perfil-freelancer/perfil/plan',
    },
    projects: {
      index: '/perfil-freelancer/proyectos',
      options: '/perfil-freelancer/proyectos/opciones',
      list: '/perfil-freelancer/proyectos/lista',
      historical: '/perfil-freelancer/proyectos/historico',
      detail: `/perfil-freelancer/proyectos/detalle`,
      offers: {
        index: '/perfil-freelancer/proyectos/ofertas',
        list: '/perfil-freelancer/proyectos/ofertas/listado',
        detail: '/perfil-freelancer/proyectos/ofertas/detalle',
      },
    },
    book: {
      index: '/perfil-freelancer/book',
      bio: '/perfil-freelancer/book/bio',
      experiences: '/perfil-freelancer/book/xp-destacadas',
      education: '/perfil-freelancer/book/educacion',
      porfolio: '/perfil-freelancer/book/portafolio',
      clients: '/perfil-freelancer/book/clientes',
    },
    help: '/perfil-freelancer/ayuda',
  },
  uikit: '/uikit',
  page_not_fount: '/*',
}

export default routes
