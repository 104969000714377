import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import usePagination from '../../../customHooks/usePagination'
import { IClient } from '../../../models/IClient'
import { useClientsSorted, usePutClientMutation } from '../../../customHooks/request/clientsQuery'
import routes from '../../../constants/routes'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import useOpenDialog from '../../../customHooks/useOpenDialog'
import { useSetLoader } from '../../../customHooks/useSetLoader'
import useFilterByWord from '../../../customHooks/useFilterByWord'

import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction } from '@material-ui/core'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import CustomPagination from '../../../assets/UIkit/CustomPagination'
import AppDialog from '../../../assets/UIkit/AppDialog'
import useGroupAlphabetList from '../../../customHooks/useGroupAlphabetList'
import { paginationLimit } from '../../../utils/configs'
import FiButton from '../../../assets/UIkit/FiButton'
import { TUserType } from '../../../models/IUser'
import { created_at } from '../../../utils/helpers'
import { useCreateUser } from '../../../customHooks/request/userQuery'

const limit = paginationLimit

const ClientsFindieList: React.FC = () => {
  const pagination = usePagination()
  const { dialog, recuestAction, toogleDialog, setRecuestAction } = useOpenDialog()
  const { word, renderInput } = useFilterByWord()

  const clientsQuery = useClientsSorted({
    filters: {
      $and: [
        { $or: [{ client_status: 'available' }, { client_status: 'suspended' }] },
        {
          $or: [
            { name: word ? { $regex: word, $options: 'i' } : undefined },
            { lastName: word ? { $regex: word, $options: 'i' } : undefined },
          ],
        },
      ],
      page: pagination.page,
      limit,
    },
  })
  const clients = useGroupAlphabetList<IClient>(clientsQuery)
  const putClientMutation = usePutClientMutation('refresh')
  const createUserProfile = useCreateUser()
  useRequestAlert(putClientMutation, undefined, afterMutation)
  useRequestAlert(createUserProfile, undefined, afterMutation)
  useSetLoader(clientsQuery)

  const openDialog = (freelancer: IClient, action: 'suspend' | 'delete' | 'user_profile') => {
    setRecuestAction(action)
    toogleDialog(freelancer)
  }

  const mutateClient = () => {
    const client_status = dialog.data.client_status === 'suspended' ? 'available' : 'suspended'
    const dispatch: { [key: string]: () => void } = {
      user_profile: () => setUserProfile(),
      suspend: () => putClientMutation.mutate({ body: { client_status }, _id: dialog.data._id }),
      delete: () => putClientMutation.mutate({ body: { client_status: 'deleted' }, _id: dialog.data._id }),
    }
    dispatch[recuestAction] && dispatch[recuestAction]()
  }

  const setUserProfile = () => {
    const body = {
      created_at,
      name: dialog.data.name,
      last_name: dialog.data.lastName,
      email: dialog.data.email.toLowerCase().trim(),
      phone: dialog.data.phone,
      password: `UP-${dialog.data.email.toLowerCase().trim()}`,
      user_type: 'client' as TUserType,
      is_disabled: false,
      profile_id: dialog.data._id,
    }
    createUserProfile.mutate({ body })
  }

  async function afterMutation() {
    await clientsQuery.refetch()
    toogleDialog()
  }

  useEffect(() => {
    clientsQuery.refetch()
  }, [word])

  return (
    <>
      <h3>Clientes Findie</h3>
      <Grid container className='mt-4'>
        <Grid item xs={8}>
          {renderInput()}
        </Grid>
      </Grid>

      {clients.map((structure) => {
        return (
          <div key={structure.letter}>
            {structure.list.length > 0 && (
              <>
                <p className='subtitle2-bold mt-4'>{structure.letter}</p>
                <List className='mt-2 border-t border-r border-l border-gray-300 py-0'>
                  {structure.list.map((client: IClient) => {
                    return (
                      <div key={client._id} className='border-b border-gray-300'>
                        <ListItem button>
                          <Link to={`${routes.cpanel.clients.findie}/${client._id}`}>
                            <Box className='flex items-center'>
                              <span className='buttontext4-regular'>
                                {client?.name}, {client?.lastName} - {client?.companyName}
                              </span>
                            </Box>
                          </Link>
                          <ListItemSecondaryAction>
                            {!client.has_user_profile && (
                              <FiButton className='mr-2' size='small' onClick={() => openDialog(client, 'user_profile')}>
                                Add UP
                              </FiButton>
                            )}
                            <IconButton size='small' onClick={() => openDialog(client, 'suspend')}>
                              <FiIcons name='suspended' className={`${client.client_status === 'suspended' && 'svg-red'}`} />
                            </IconButton>
                            <IconButton edge='end' onClick={() => openDialog(client, 'delete')}>
                              <FiIcons name='skull' className='svg-soft-gray' />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    )
                  })}
                </List>
              </>
            )}
          </div>
        )
      })}

      {clientsQuery.isSuccess && (
        <div className='flex justify-center'>
          <CustomPagination
            pages={clientsQuery.data.metadata.pages}
            page={pagination.page}
            onChange={(e, value) => pagination.handlePage(value)}
            position={'center'}
            className='mt-4'
          />
        </div>
      )}

      <AppDialog open={dialog.isOpen} title='Suspender Freelancer' handleClose={toogleDialog}>
        {recuestAction === 'suspend' && (
          <span className='subtitle4-medium'>
            {dialog?.data?.freelancer_status === 'suspended'
              ? `Estás seguro que deseas quitar el status de "suspendido(a) a ${dialog?.data?.name}`
              : `¿Estás seguro que deseas suspender a ${dialog?.data?.name}?. Si lo haces, el cliente no tendrá la posibilidad de
              acceder a la plataforma.`}
          </span>
        )}
        {recuestAction === 'delete' && (
          <span className='subtitle4-medium'>
            {`¿Estás seguro que deseas enviar a ${dialog?.data?.name} a la sección de descartados?`}
          </span>
        )}
        {recuestAction === 'user_profile' && (
          <span className='subtitle4-medium'>
            ¿Estas seguro que deseas habilitar el user profile de {dialog.data?.name} {dialog.data?.lastName}?
          </span>
        )}

        <Divider className='my-6' />

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-4' onClick={toogleDialog}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={mutateClient}>
            Aceptar
          </Button>
        </Box>
      </AppDialog>
    </>
  )
}

export default ClientsFindieList
