import { useState } from 'react'

export type TAction = '' | 'post' | 'put' | 'delete' | 'suspend'

const useDialogData = <T>() => {
  const [isOpen, setisOpen] = useState<boolean>(false)
  const [data, setData] = useState<T>()
  const [action, setAction] = useState<TAction>('')

  const toggle = (modalAction?: TAction, modaldata?: T) => {
    setisOpen(!isOpen)
    setData(modaldata ?? undefined)
    setAction(modalAction ?? '')
  }

  return { isOpen, data, action, toggle }
}

export default useDialogData
