import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import {
  useFreelancerEvaluationMutation,
  useFreelancerQuery,
  useRemovePortfolioPdf,
} from '../../../../customHooks/request/freelancersQuery'
import routes from '../../../../constants/routes'
import useOpenDialog from '../../../../customHooks/useOpenDialog'

import FreelancersForm from '../../../../shared/FreelancersForm/FreelancersForm'

import { Box, Button, Divider, IconButton, Step, StepLabel, Stepper } from '@material-ui/core'
import AppDialog from '../../../../assets/UIkit/AppDialog'
import { freelancerSteps } from '../../../../constants/Cpanel/FreelancersConstant'
import { FiIcons } from '../../../../assets/UIkit/Icons/FiIcons'

type TActiveStep = {
  step: number
  stepName: 'step_one' | 'step_two' | 'step_three'
}

const defaultActiveStep: TActiveStep = { step: 1, stepName: 'step_one' }

const FreelancerEvaluation: React.FC = () => {
  const history = useHistory()
  const params = useParams<{ _id: string }>()
  const { dialog, recuestAction, toogleDialog, setRecuestAction } = useOpenDialog()
  const form = useForm()
  const freelancerQuery = useFreelancerQuery(params._id)
  const updateFreelancerProcessMutation = useFreelancerEvaluationMutation()
  const availableFreelancerMutation = useFreelancerEvaluationMutation()
  const removePortfolioMutation = useRemovePortfolioPdf()
  useRequestAlert(updateFreelancerProcessMutation)
  useRequestAlert(availableFreelancerMutation, undefined, afterMutation)
  const [activeStep, setActiveStep] = useState<TActiveStep>(defaultActiveStep)

  const handleNext = () => {
    if (activeStep.step === 3) {
      openDialog('edit')
      return
    }
    const newStep = activeStep.step + 1
    const restStepData = getStepName(newStep)
    const updatedReviewStep = {
      postulation_status: restStepData.stepName,
      email: freelancerQuery.data?.email ?? '',
      was_invited: freelancerQuery.data?.was_invited,
      name: freelancerQuery.data?.name ?? '',
      lastName: freelancerQuery.data?.lastName ?? '',
    }
    setActiveStep({
      ...activeStep,
      step: newStep,
      stepName: getStepName(newStep).stepName,
    })
    updateFreelancerProcessMutation.mutate({ body: updatedReviewStep, id: params._id })
  }

  const handleBack = () => {
    if (activeStep.step === 3) {
      setActiveStep(defaultActiveStep)
      updateFreelancerProcessMutation.mutate({
        body: {
          postulation_status: 'step_one',
          email: freelancerQuery.data?.email ?? '',
          was_invited: freelancerQuery.data?.was_invited,
          name: freelancerQuery.data?.name ?? '',
          lastName: freelancerQuery.data?.lastName ?? '',
        },
        id: params._id,
      })
      return
    }
    const newStep = activeStep.step - 1
    const restStepData = getStepName(newStep)
    const updatedReviewStep = {
      postulation_status: restStepData.stepName,
      email: freelancerQuery.data?.email ?? '',
      was_invited: freelancerQuery.data?.was_invited,
      name: freelancerQuery.data?.name ?? '',
      lastName: freelancerQuery.data?.lastName ?? '',
    }
    setActiveStep({
      ...activeStep,
      step: newStep,
      stepName: restStepData.stepName,
    })
    updateFreelancerProcessMutation.mutate({ body: updatedReviewStep, id: params._id })
  }

  const getStepName = (stepNumber: number) => {
    const stepName: any = {
      1: { stepName: 'step_one' },
      2: { stepName: 'step_two' },
      3: { stepName: 'step_three' },
    }
    return stepName[stepNumber]
  }

  const openDialog = (action: 'post' | 'edit' | 'delete') => {
    setRecuestAction(action)
    toogleDialog()
  }

  const availableOrDelete = () => {
    if (recuestAction === 'edit') {
      availableFreelancerMutation.mutate(
        {
          body: {
            freelancer_status: 'suspended',
            was_invited: freelancerQuery.data?.was_invited,
            email: freelancerQuery.data?.email ?? '',
            name: freelancerQuery.data?.name ?? '',
            lastName: freelancerQuery.data?.lastName ?? '',
          },
          id: params._id,
        },
        {
          onSuccess: () => removePortfolioMutation.mutate({ _id: freelancerQuery?.data?._id ?? '' }),
        }
      )
      return
    }

    availableFreelancerMutation.mutate(
      {
        body: {
          freelancer_status: 'rejected',
          was_invited: freelancerQuery.data?.was_invited,
          email: freelancerQuery.data?.email ?? '',
          name: freelancerQuery.data?.name ?? '',
          lastName: freelancerQuery.data?.lastName ?? '',
        },
        id: params._id,
      },
      {
        onSuccess: () => {
          removePortfolioMutation.mutate({ _id: freelancerQuery?.data?._id ?? '' })
          history.push(routes.cpanel.freelancers.postulations)
        },
      }
    )
  }

  const featurePostulation = () => {
    if (freelancerQuery.isSuccess)
      updateFreelancerProcessMutation.mutate(
        { body: { is_featured_postulation: !freelancerQuery.data.is_featured_postulation }, id: params._id },
        {
          onSuccess: () => freelancerQuery.refetch(),
        }
      )
  }

  function afterMutation() {
    toogleDialog()
    history.push(`${routes.cpanel.freelancers.postulations}`)
  }

  useEffect(() => {
    if (freelancerQuery.isSuccess) {
      const evaluationStep: { [key: string]: () => void } = {
        step_one: () => setActiveStep({ step: 1, stepName: 'step_one' }),
        step_two: () => setActiveStep({ step: 2, stepName: 'step_two' }),
        step_three: () => setActiveStep({ step: 3, stepName: 'step_three' }),
      }
      evaluationStep[freelancerQuery.data.postulation_status] && evaluationStep[freelancerQuery.data.postulation_status]()
    }
  }, [freelancerQuery.isSuccess])

  if (!freelancerQuery.data) return null
  return (
    <>
      <div className='flex justify-between'>
        <div>
          <h3>Información del freelancer</h3>
          <p className='subtitle4-medium text-blue mt-2'>{freelancerQuery.data.was_invited ? 'Freelancer invitado' : ''}</p>
        </div>
        <div className='flex items-center'>
          <h5>Destacar</h5>
          <IconButton edge='end' onClick={featurePostulation}>
            <FiIcons name='star' className={`${freelancerQuery.data?.is_featured_postulation && 'svg-orange'}`} />
          </IconButton>
        </div>
      </div>
      <div>
        <FreelancersForm freelancer={freelancerQuery} form={form} isDisabledFields asPostulation />
      </div>

      <h3 className='mt-16'>Evaluación</h3>
      <div className='w-full'>
        <Stepper activeStep={activeStep.step}>
          {freelancerSteps.map((step) => {
            return (
              <Step key={step.title}>
                <StepLabel icon={activeStep.step >= step.number ? step.activeIcon : step.icon}>
                  <p className='microcopy2'>{step.title}</p>
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box display='flex' justifyContent='space-between' mt={2}>
          <Button color='secondary' onClick={() => openDialog('delete')}>
            Eliminar
          </Button>
          <Box>
            <Button disabled={activeStep.step === 1} onClick={handleBack} className='mr-2'>
              {activeStep.step === 3 ? 'Reset' : 'Atrás'}
            </Button>
            <Button variant='contained' color='primary' onClick={handleNext} className='mr-2'>
              {activeStep.step === 3 ? 'Registrar' : 'Siguiente'}
            </Button>
          </Box>
        </Box>
      </div>

      <AppDialog
        open={dialog.isOpen}
        title={`${recuestAction === 'edit' ? 'Editar' : 'Eliminar'} freelancer`}
        handleClose={toogleDialog}
      >
        {recuestAction === 'edit' ? (
          <span className='subtitle4-medium'>
            ¿Estas seguro que deseas convertir a {`${freelancerQuery?.data?.name}`} en freelancer findie?
          </span>
        ) : (
          <>
            <p className='subtitle4-medium'>
              ¿Estas seguro que deseas enviar a {`${freelancerQuery?.data?.name}`} a la sección de eliminados?
            </p>
            <p className='subtitle4-medium mt-2'>Le enviaremos un correo con la mala noticia 😢</p>
          </>
        )}

        <Divider className='mt-2' />
        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-2' onClick={toogleDialog}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={availableOrDelete}>
            Aceptar
          </Button>
        </Box>
      </AppDialog>
    </>
  )
}

export default FreelancerEvaluation
