import { IFormStep } from '../../../models/IFormStep'

import FormStepOptionsContent from '../../../shared/FormStepOptionsContent/FormStepOptionsContent'

import { useTranslation } from 'react-i18next'

const ClientStepTwo: React.FC<IFormStep> = (props) => {
  const { t } = useTranslation()
  let NewWhenProjectStarts: any[] = t('GLOBAL.WHEN_PROJECT_STARTS', { returnObjects: true })
  return (
    <>
      <FormStepOptionsContent
        name='when_start'
        title={t('GLOBAL.WHEN_START')}
        currentStep='2'
        options={NewWhenProjectStarts}
        {...props}
      />
    </>
  )

  // <FormSkillsStep {...props} theme='blue' currentStep='2' userType='client' />
}

export default ClientStepTwo
