import { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import routes from '../../constants/routes'
import FindieGifIsotype from '../../shared/Brand/Isotype/FindieGifIsotype'
import { TSidebarOptions } from './ClientUserProfileLayout'

type TSidebarUserProfile = {
  sidebarOptions: TSidebarOptions[]
}
const SidebarUserProfile: FC<TSidebarUserProfile> = (props) => {
  return (
    <div className='flex flex-col justify-between py-14 shadow-black-005'>
      <Link to={routes.web_site.home}>
        <FindieGifIsotype className='w-14 mx-auto' variant='contained-black' />
      </Link>
      <div>
        {props.sidebarOptions.map((option: TSidebarOptions) => {
          return (
            <div className='px-8' key={option.title}>
              <NavLink
                className={`total-center flex-col cursor-pointer mt-14 pb-2`}
                activeClassName='border-b-blue'
                to={option.to}
              >
                <FiIcons name={option.icon} />
                <p className='buttontext2-regular text-center'>{option.title}</p>
              </NavLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SidebarUserProfile
