import { FC, useState } from 'react'
import { IBrief } from '../../../models/IBrief'
import OfferBrief from '../OfferBrief'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'

export type TClientProps = {
  company_size: string
  country: string
}

const useStyles = makeStyles({
  root: {
    border: 0,
    boxShadow: '0px 13.8974px 34.7436px var(--black-005)',
    backgroundColor: 'transparent !important',
  },
  backdrop: {
    backgroundColor: 'transparent !important',
  },
})

const BriefDrawer: FC<IBrief & TClientProps & { isOpen?: boolean }> = (props) => {
  const classes = useStyles()
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isOpen ?? false)

  const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    )
      return

    setIsDrawerOpen(state)
  }

  return (
    <>
      <div className='cursor-pointer' onClick={toggleDrawer(true)}>
        {props.children}
      </div>

      <Drawer
        classes={{ root: classes.root }}
        ModalProps={{ BackdropProps: { className: classes.backdrop } }}
        anchor={'right'}
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div className='bg-ice-blue lg:flex block items-center h-small-screen brief-drawer'>
          <div className='relative w-full'>
            <div className='flex justify-between items-center md:px-6 px-4 mb-4'>
              <p className='subtitle4-regular text-light-black'>Brief</p>
              <FiIcons name='circle_close_black' className=' cursor-pointer' onClick={toggleDrawer(false)} />
            </div>
            <div>
              <OfferBrief brief={props} />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default BriefDrawer
