import { useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { UseQueryResult } from 'react-query'
import { IFreelancer } from '../../models/IFreelancer'

import { Box, Button, Divider, Grid } from '@material-ui/core'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { usePlansQuery } from '../../customHooks/request/plansQuery'
import { IPlan } from '../../models/IPlan'
import { usePutFreelancerPlanMutation } from '../../customHooks/request/freelancersQuery'
import useRequestAlert from '../../customHooks/useRequestAlert'
import useDialogData from '../../customHooks/useDialogData'
import AppDialog from '../../assets/UIkit/AppDialog'

type TFreelancerFormPlan = {
  freelancer?: UseQueryResult<IFreelancer, unknown>
  isDisabledFields?: boolean
}

const FreelancerFormPlan: React.FC<TFreelancerFormPlan> = (props) => {
  const form = useForm()
  const dialog = useDialogData<IPlan>()
  const plansQuery = usePlansQuery({ entity: 'freelancer', is_available: true })
  const updatePlanMutation = usePutFreelancerPlanMutation()
  useRequestAlert(updatePlanMutation)

  const updatePlan = () => {
    updatePlanMutation.mutate(
      { body: { planId: form.watch('plan') }, _id: props.freelancer?.data?._id ?? '' },
      {
        onSuccess: () => {
          form.reset()
          dialog.toggle()
          props.freelancer && props.freelancer.refetch()
        },
      }
    )
  }

  useEffect(() => {
    if (!form.watch('plan') || !plansQuery.isSuccess) return

    const newPlan = plansQuery.data.find((plan: IPlan) => plan._id === form.watch('plan'))
    dialog.toggle('put', newPlan)
  }, [form.watch('plan'), plansQuery.isSuccess])

  if (!props?.freelancer?.data) return null
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider className='mt-4' />
          <p className='body1-medium'>Plan Findie Histórico</p>
          <p className='body2-regular'>
            El plan Findie histórico contiene los datos del plan al momento en que se le fue asignado al freelancer.
          </p>
          <div className='grid grid-cols-12 gap-4 mt-4'>
            <div className='col-span-6 mt-4'>
              <p className='subtitle4-medium'>
                Nombre: <span className='body2-regular'>{props.freelancer?.data?.plan?.name}</span>
              </p>
              <p className='subtitle4-medium'>
                Costo: <span className='body2-regular'>${props.freelancer?.data?.plan?.price}</span>
              </p>
              <p className='subtitle4-medium'>
                Fee: <span className='body2-regular'>{props.freelancer?.data?.plan?.fee}%</span>
              </p>
            </div>
            <div className='col-span-6'>
              {plansQuery.isSuccess && (
                <SelectField
                  name='plan'
                  label='Actualizar plan'
                  inputProps={{ disabled: props.isDisabledFields }}
                  form={form}
                  selectOptions={plansQuery.data.map((plan: IPlan) => {
                    return { value: plan._id, label: plan.name }
                  })}
                />
              )}
            </div>
          </div>

          <Divider className='mt-4' />
        </Grid>
      </Grid>

      <AppDialog open={dialog.isOpen} title={`Actualizar plan`} handleClose={dialog.toggle}>
        <p className='body1-medium'>¿Estas seguro que deseas actualizar el plan?</p>
        <div className='col-span-6 mt-4'>
          <p className='subtitle4-medium'>
            Nombre: <span className='body2-regular'>{dialog?.data?.name}</span>
          </p>
          <p className='subtitle4-medium'>
            Costo: <span className='body2-regular'>CLP {dialog?.data?.price}</span>
          </p>
          <p className='subtitle4-medium'>
            Fee: <span className='body2-regular'>UF {dialog?.data?.fee}</span>
          </p>
        </div>

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-4' onClick={() => dialog.toggle()}>
            Cancelar
          </Button>
          <Button onClick={updatePlan} variant='contained' color='primary'>
            Aceptar
          </Button>
        </Box>
      </AppDialog>
    </Box>
  )
}

export default FreelancerFormPlan
