import { useState, useEffect } from 'react'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { useDropzone } from 'react-dropzone'
import { UseQueryResult } from 'react-query'
import { IOffer } from '../../../models/IOffer'
import { loadFiles } from '../../../utils/helpers'
import { useRemoveImageToWorkPlan, useUploadImageToWorkPlan } from '../../../customHooks/request/workPlanQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { IconButton } from '@material-ui/core'
import { IWorkPlanImage } from '../../../models/IWorkPlan'
import scrollTop from '../../../assets/images/web/scroll-top.svg'
import SwipeableViews from 'react-swipeable-views'

type TWorkPlanImages = {
  offerQuery: UseQueryResult<IOffer, unknown>
  asClient?: boolean
}

const WorkPlanImages = (props: TWorkPlanImages) => {
  const { data: offerQuery, refetch: refetchOffer } = props.offerQuery
  const { getInputProps, acceptedFiles, open } = useDropzone({
    accept: '.png, .jpg, .jpeg, .gif',
    noClick: true,
    noKeyboard: true,
    multiple: false,
  })

  const uploadImage = useUploadImageToWorkPlan()
  const removeImage = useRemoveImageToWorkPlan()
  useRequestAlert(uploadImage)
  useRequestAlert(removeImage)

  const [selectedPosition, setSelectedPosition] = useState(0)
  const [galleryIndex, setGalleryIndex] = useState(0)

  const selectImage = (position: number) => {
    setSelectedPosition(position)
    open()
  }

  const handleImage = async () => {
    if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return

    const body = {
      images: offerQuery.work_plan.images,
      image: {
        name: acceptedFiles[0].name,
        url: await loadFiles(acceptedFiles[0]),
        position: selectedPosition,
      },
    }
    uploadImage.mutate({ body, id: offerQuery.work_plan._id }, { onSuccess: () => refetchOffer() })
  }

  const handleRemoveImage = async (image: IWorkPlanImage) => {
    if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return

    const body = {
      images: offerQuery.work_plan.images,
      image,
    }
    removeImage.mutate({ body, id: offerQuery.work_plan._id }, { onSuccess: () => refetchOffer() })
  }

  useEffect(() => {
    if (acceptedFiles.length) handleImage()
  }, [acceptedFiles])

  if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return null
  if (props.asClient) {
    return (
      <div className='overflow-hidden'>
        {!props.asClient && <p className='col-span-12 mb-2 text-blue microcopy'>Imágenes referenciales (opcional)</p>}

        <SwipeableViews index={galleryIndex}>
          <div className='grid grid-cols-12 gap-3'>
            {offerQuery.work_plan.images
              .sort((a: IWorkPlanImage, b: IWorkPlanImage) => a.position - b.position)
              .slice(0, 2)
              .map((image) => {
                return (
                  <div key={image.position} className='col-span-6'>
                    <img src={image.url} />
                  </div>
                )
              })}
          </div>

          <div className='grid grid-cols-12 gap-3'>
            {offerQuery.work_plan.images
              .sort((a: IWorkPlanImage, b: IWorkPlanImage) => a.position - b.position)
              .slice(-2)
              .map((image) => {
                return (
                  <div key={image.position} className='col-span-6'>
                    <img src={image.url} />
                  </div>
                )
              })}
          </div>
        </SwipeableViews>
        {offerQuery.work_plan.images.length > 2 && (
          <img
            className={`cursor-pointer w-20 mt-4 block ml-auto transition-all duration-300 ${
              galleryIndex ? 'rotated-left' : 'rotated-right'
            }`}
            src={scrollTop}
            alt='scroll-top'
            onClick={() => (galleryIndex ? setGalleryIndex(0) : setGalleryIndex(1))}
          />
        )}
      </div>
    )
  }
  return (
    <div className='grid grid-cols-12 gap-2'>
      <p className='col-span-12 mb-2 text-blue microcopy'>Imágenes referenciales (PNG o GIF • Máximo 5 MB)</p>

      <div className='xl:col-span-6 col-span-12'>
        {[1, 2].map((item) => {
          const currentImage = offerQuery.work_plan.images.find((image) => image.position === item)

          if (currentImage) {
            return (
              <div key={item} className='mt-2 relative'>
                <img src={currentImage.url} />
                {offerQuery.work_plan.can_edit && (
                  <IconButton className='absolute top-1 right-1' onClick={() => handleRemoveImage(currentImage)}>
                    <HighlightOffIcon className='text-red' />
                  </IconButton>
                )}
              </div>
            )
          }
          if (!offerQuery.work_plan.can_edit) return null
          return (
            <div key={item} className='col-span-6 bg-ice-blue p-32 total-center mt-2'>
              <input {...getInputProps()} />
              <FiIcons className='cursor-pointer' name='circleAdd' onClick={() => selectImage(item)} />
            </div>
          )
        })}
      </div>
      <div className='xl:col-span-6 col-span-12'>
        {[3, 4].map((item) => {
          const currentImage = offerQuery.work_plan.images.find((image) => image.position === item)

          if (currentImage) {
            return (
              <div key={item} className='mt-2 relative'>
                <img src={currentImage.url} />
                {offerQuery.work_plan.can_edit && (
                  <IconButton className='absolute top-1 right-1' onClick={() => handleRemoveImage(currentImage)}>
                    <HighlightOffIcon className='text-red' />
                  </IconButton>
                )}
              </div>
            )
          }
          if (!offerQuery.work_plan.can_edit) return null
          return (
            <div key={item} className='col-span-6 bg-ice-blue p-32 total-center mt-2'>
              <input {...getInputProps()} />
              <FiIcons className='cursor-pointer' name='circleAdd' onClick={() => selectImage(item)} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WorkPlanImages
