import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { IOffer } from '../../../../models/IOffer'
import { useUpdateWorkPlan } from '../../../../customHooks/request/workPlanQuery'
import { IWorkPlanVersion, TVersion } from '../../../../models/IWorkPlan'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import FreelancerVersion from './FreelancerVersion'
import ClientVersion from './ClientVersion'

type TWorkPlanVersion = {
  isDisabledFields: boolean
  offerQuery: UseQueryResult<IOffer, unknown>
  asClient?: boolean
  asCpanel?: boolean
}

const versions: TVersion[] = ['Full', 'Standard', 'Basic']

const WorkPlanVersion = (props: TWorkPlanVersion) => {
  const form = useForm()

  const { data: offerQuery, refetch: refetchOffer } = props.offerQuery
  const selectClientVersion = useUpdateWorkPlan()
  const removeVersion = useUpdateWorkPlan()
  useRequestAlert(
    selectClientVersion,
    undefined,
    undefined,
    undefined,
    `Seleccionaste una versión, haz click en "Eligir candidato".`,
    undefined,
    9000
  )
  useRequestAlert(removeVersion, undefined, undefined, undefined, 'none', undefined, 9000)

  const [selectedVersion, setSelectedVersion] = useState<TVersion>('none')
  const [negotiationVersion, setNegotiationVersion] = useState<TVersion>('none')

  const hasVersionSelected =
    offerQuery && offerQuery.work_plan ? offerQuery.work_plan.versions.some((thisVersion) => thisVersion.is_selected) : false
  const hasStandardVersion =
    offerQuery && offerQuery.work_plan
      ? offerQuery.work_plan.versions.some((thisVersion) => thisVersion.name === 'Standard')
      : false

  const setVersionsValues = (workPlanVersion: IWorkPlanVersion, versionName: TVersion) => {
    Object.entries(workPlanVersion).forEach(([key, value]) => {
      if (['iterations', 'deliverables'].includes(key)) return form.setValue(`${key}-${versionName}`, value)
      if (key === 'price') {
        form.setValue(`subtotal-${versionName}`, value.subtotal)
        form.setValue(`sii-${versionName}`, JSON.stringify(value.sii))
        form.setValue(`payment_method-${versionName}`, value.payment_method ? JSON.stringify(value.payment_method) : '')
        form.setValue(`after_fee-${versionName}`, props.asClient ? value.total : value.after_fee)
      }
    })
  }

  const handleVersion = (currentVersion: TVersion) => {
    form.clearErrors()
    setSelectedVersion(currentVersion)
  }

  const selectWorkPlanVersion = async (params: { version: TVersion; isSelected?: boolean }) => {
    if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return

    const versions = offerQuery.work_plan.versions.map((thisVersion) => {
      if (thisVersion.name === params.version) return { ...thisVersion, is_selected: params.isSelected } as IWorkPlanVersion
      return { ...thisVersion, is_selected: false }
    })

    const mutationParams = { body: { versions, negotiationVersion: negotiationVersion }, id: offerQuery.work_plan._id }
    const onSuccessMutation = { onSuccess: () => refetchOffer() }

    if (params.isSelected) return selectClientVersion.mutate(mutationParams, onSuccessMutation)
    removeVersion.mutate(mutationParams, onSuccessMutation)
  }

  useEffect(() => {
    if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return

    if (offerQuery.work_plan.versions && offerQuery.work_plan.versions.length) {
      offerQuery.work_plan.versions.forEach((workPlanVersion) => {
        setVersionsValues(workPlanVersion, workPlanVersion.name)
      })
    }

    if (offerQuery.work_plan.negotiation_version !== 'none') {
      setNegotiationVersion(offerQuery.work_plan.negotiation_version)
      setSelectedVersion(offerQuery.work_plan.negotiation_version)
    }
  }, [offerQuery])

  useEffect(() => {
    if (props.asClient || hasStandardVersion) return
    setSelectedVersion('Standard')
  }, [])

  if (!offerQuery || (offerQuery && !offerQuery.work_plan)) return <p>hhhhh</p>
  return (
    <div>
      {props.asClient ? (
        <p className='col-span-12 mb-2 microcopy'>
          El profesional ofrece distintas alternativas de presupuesto. Elige con cuál quieres proceder.
        </p>
      ) : (
        <>
          <p className='col-span-12 mb-2 text-blue microcopy'>Entregables y costo servicio</p>

          <div className='col-span-12 grid grid-cols-12 '>
            <p className='mb-2 microcopy col-span-6'>
              Puedes crear tres versiones de valores y entregables. Full el más completo, standard la mitad y básico el más
              simple. Por default se usa el Standard.
            </p>

            <p className='subtitle3-20 text-rose-video col-span-6 text-right'>
              Presupuestar en {offerQuery.project.with_currency ? 'dólares' : 'CLP'}
            </p>
          </div>
        </>
      )}

      <div className='grid grid-cols-12 my-6 md:gap-0 gap-4'>
        {versions
          .filter((version) => {
            if (props.asClient) return true
            if (negotiationVersion === 'none') return true
            return negotiationVersion === version
          })
          .map((version: TVersion) => {
            const hasThisVersion = offerQuery.work_plan.versions.find((thisVersion) => thisVersion.name === version)
            const isSelectedVersion = version === selectedVersion

            if (props.asClient) {
              return (
                <ClientVersion
                  key={version}
                  offerQuery={offerQuery}
                  hasThisVersion={hasThisVersion}
                  is_negotiable={offerQuery.work_plan.is_negotiable}
                  negotiationVersion={negotiationVersion}
                  hasVersionSelected={hasVersionSelected}
                  version={version}
                  handleVersion={handleVersion}
                  selectWorkPlanVersion={selectWorkPlanVersion}
                  setNegotiationVersion={setNegotiationVersion}
                />
              )
            }
            return (
              <FreelancerVersion
                key={version}
                offerQuery={props.offerQuery}
                hasThisVersion={hasThisVersion}
                isSelectedVersion={isSelectedVersion}
                isDisabledFields={props.isDisabledFields}
                is_negotiable={offerQuery.work_plan.is_negotiable}
                negotiationVersion={negotiationVersion}
                form={form}
                version={version}
                hasStandardVersion={hasStandardVersion}
                handleVersion={handleVersion}
                asCpanel={props.asCpanel}
              />
            )
          })}
      </div>
    </div>
  )
}

export default WorkPlanVersion
