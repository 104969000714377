import { useForm } from 'react-hook-form'
import { IconButton, Link, useMediaQuery } from '@material-ui/core'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { IFormStep } from '../../../models/IFormStep'
import { useEffect } from 'react'

import VerificationInput from 'react-verification-input'
import {
  useNewVerificationMutation,
  useRemoveEmailMutation,
  useVerifyEmailMutation,
} from '../../../customHooks/request/emailVerificationQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import dayjs from 'dayjs'
import FiButton from '../../../assets/UIkit/FiButton'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import { useHistory } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useTranslation } from 'react-i18next'

type TEmailVerification = {
  redirectTo: string
  asLiteForm?: boolean
}

const EmailVerification: React.FC<IFormStep & TEmailVerification> = (props) => {
  const { t } = useTranslation()
  const form = useForm()
  const history = useHistory()
  const storage = props.storage
  const isMobile = useMediaQuery('(max-width: 430px)')
  const sendEmailVerification = useNewVerificationMutation()
  const removeEmailMutation = useRemoveEmailMutation()
  const verifyEmailMutation = useVerifyEmailMutation()
  useRequestAlert(sendEmailVerification, undefined, undefined, undefined, `${t('GLOBAL.EMAIL_VERIFY.WILL_SEND')}`)
  useRequestAlert(verifyEmailMutation)

  const sendEmail = (data: { email: string }) => {
    const today = dayjs()
    const expirationDate = today.add(30, 'minute')

    const created_at = today.valueOf()
    const expiration_date = expirationDate.valueOf()

    const body = {
      created_at,
      expiration_date,
      email: data.email,
    }
    sendEmailVerification.mutate(
      { body },
      {
        onSuccess: () => {
          storage.setItem({ ...storage.item, email: data.email, isEmailVerified: false })
          form.setValue('code', '')
        },
      }
    )
  }

  const editEmail = () => {
    removeEmailMutation.mutate(
      { id: storage.item.email },
      {
        onSuccess: () => {
          form.setValue('email', '')
          form.setValue('code', '')
          storage.setItem({ ...storage.item, email: '', isEmailVerified: false })
        },
      }
    )
  }

  const verifyEmail = () => {
    const body = {
      expiration_date: dayjs().valueOf(),
      email: form.watch('email'),
      code: form.watch('code'),
    }

    verifyEmailMutation.mutate(
      { body },
      {
        onSuccess: () => {
          storage.setItem({ ...storage.item, isEmailVerified: true })
          history.push(props.redirectTo)
        },
      }
    )
  }

  useEffect(() => {
    form.setValue('email', storage.item?.email)
  }, [])

  const isInVerification = Boolean(storage.item?.email)

  return (
    <div className='w-full flex flex-grow lg:h-full flex-col justify-around lg:justify-between lg:py-20'>
      <h4 className='mb-4 md:mr-56 mr-0 md:mt-0 mt-4'>{t('GLOBAL.EMAIL_VERIFY.ACCOUNT_CREATE')} </h4>

      {isInVerification ? (
        <div>
          <p className='formtext2-regular'>{t('GLOBAL.EMAIL_VERIFY.INSERT_CODE_SEND')} </p>
          <p className='formtext2-regular'>{storage.item?.email}</p>
          <div className='flex flex-col md:flex-row items-end h-24 gap-6  mt-4'>
            <div className='md:w-7/12 w-full h-24'>
              <VerificationInput
                passwordMode={true}
                length={5}
                autoFocus
                onChange={(e) => form.setValue('code', e)}
                classNames={{
                  container: '',
                  character: 'h-24 text-black total-center border border-black',
                  characterSelected: 'border border-black outline-none',
                  characterInactive: 'bg-transparent',
                }}
              />
            </div>
            <div className='mt-10'>
              <IconButton
                size={isMobile ? 'small' : 'medium'}
                onClick={verifyEmail}
                className={`ml-auto border border-black block bg-black`}
              >
                <CheckRoundedIcon className='text-white' />
              </IconButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <form className='w-full pb-4' onSubmit={form.handleSubmit(sendEmail)}>
            <div>
              <div className='flex gap-4 border-b border-black pb-2'>
                <div className='self-end' style={{ width: '90%' }}>
                  <InputField
                    name='email'
                    className='w-full'
                    variant='transparent'
                    inputProps={{
                      className: `formtext2-regular text-black bg-transparent w-full`,
                      placeholder: `${t('GLOBAL.EMAIL_VERIFY.ENTER_MAIL')}`,
                      type: 'email',
                    }}
                    options={{ required: `${t('GLOBAL.EMAIL_VERIFY.REQUERID_FIELD')}` }}
                    form={form}
                  />
                </div>

                <div style={{ width: '10%' }}>
                  <IconButton
                    size={isMobile ? 'small' : 'medium'}
                    type='submit'
                    className={`ml-auto border border-black block bg-black`}
                  >
                    <ArrowForwardIosRoundedIcon className='text-white' />
                  </IconButton>
                </div>
              </div>
              <div className='flex flex-row space-x-2'>
                <p className='buttontext2-regular'>{t('GLOBAL.EMAIL_VERIFY.ALREADY_ACCOUNT')}</p>
                <Link className='buttontext2-regular underline' color='inherit' href={routes.auth.login}>
                  {t('GLOBAL.EMAIL_VERIFY.ALREADY_ACCOUNT_LOGIN')}
                </Link>
              </div>
            </div>
          </form>
        </div>
      )}
      {isInVerification ? (
        <div className='flex flex-col md:flex-row gap-2 md:gap-8 text-lg'>
          <p className='buttontext2-regular cursor-pointer' onClick={() => sendEmail({ email: form.watch('email') })}>
            {t('GLOBAL.EMAIL_VERIFY.DID_NOT_RECEIVE')}
          </p>
          <FiButton className='rounded-full border border-black w-32' theme='secondary' variant='outlined' onClick={editEmail}>
            <p className='microcopy'>{t('GLOBAL.EMAIL_VERIFY.MAIL_EDIT')}</p>
          </FiButton>
        </div>
      ) : (
        <div className='md:mt-0  mt-16 '>
          <p className='microcopy mt-4'>
            {t('GLOBAL.EMAIL_VERIFY.CREATING_AFFIRM')} {t('GLOBAL.EMAIL_VERIFY.TERMS_AND')}
          </p>

          <div className='flex md:flex-row flex-col mt-2 gap-4 text-blue-400'>
            <Link color='inherit' href={routes.web_site.freelancer_suscription_form.step_zero}>
              <p className='buttontext2-regular cursor-pointer'>{t('GLOBAL.EMAIL_VERIFY.APPLY_AS_FREE')}</p>
            </Link>
            <FiButton
              className='rounded-full border border-blue-400 hidden md:block'
              theme='secondary'
              variant='outlined'
              asLink
              to={routes.web_site.freelancer_suscription_form.step_zero}
            >
              <p className='microcopy text-blue-400'>{t('GLOBAL.CLICK')}</p>
            </FiButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default EmailVerification
