import { useMutation } from 'react-query'
import { IPost, IPut } from '../../models/IRequest'
import { IUser } from '../../models/IUser'
import { apiClient } from '../../utils/ApiClient'

const endpoints = { users: 'users', user: (id: string) => `users/${id}` }

export const useCreateUser = () => {
  return useMutation(({ body }: IPost<Partial<IUser>>) =>
    apiClient.post<Partial<IUser>, Partial<IUser>>({ endpoint: endpoints.users, body })
  )
}

export const useUpdateUser = () => {
  return useMutation(({ body, id }: IPut<Partial<IUser>>) =>
    apiClient.put<Partial<IUser>, Partial<IUser>>({ endpoint: endpoints.user(id), body })
  )
}
