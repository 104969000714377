import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IFormStep } from '../../../models/IFormStep'

import FormStepOptionsContent from '../../../shared/FormStepOptionsContent/FormStepOptionsContent'
import { yesOrNotOptions } from '../../../constants/Cpanel/ProjectConstants'

const FreelancerStepThree: React.FC<IFormStep> = (props) => {
  const { slide } = useParams<{ slide: string }>()
  const storage = props.storage

  useEffect(() => {
    if (slide !== '3') return

    props.setIsButtonDisabled(Boolean(!storage?.item?.was_invited))
  }, [slide, storage.item])

  return (
    <FormStepOptionsContent
      name='was_invited'
      title='¿Vienes invitado por alguien?'
      currentStep='3'
      options={yesOrNotOptions}
      {...props}
    />
  )
}

export default FreelancerStepThree
