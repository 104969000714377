import { FC, useEffect } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { FieldValues, useForm } from 'react-hook-form'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { IExperience } from '../../../models/IFreelancer'
import { TFreelancerBookSections } from '../../../views/FreelancerUserProfile/FreelancerBook'

const experiencesLength = Array.from(Array(5).keys())

const FreelancerExperiences: FC<TFreelancerBookSections> = (props) => {
  const isMobile = useMediaQuery('(max-width: 430px)')
  const { data: freelancer, refetch: freelancerRefetch } = props.freelancerQuery
  const form = useForm()

  const saveChanges = (data: FieldValues) => {
    if (!freelancer) return

    const experiences = experiencesLength.reduce((acc: IExperience[], xp: number) => {
      const hasThisExperience = freelancer.experiences.some((currentXp) => currentXp.position === xp + 1)
      const newExperience = {
        position: xp + 1,
        rol: data[`${xp + 1}-rol`],
        description: data[`${xp + 1}-description`],
        link: data[`${xp + 1}-link`],
      }
      if (hasThisExperience) {
        return acc.map((currentXp) => {
          if (currentXp.position === xp + 1) return newExperience
          return currentXp
        })
      }
      return [...acc, newExperience]
    }, freelancer.experiences)
    props.updateFreelancer.mutate({ body: { experiences }, id: freelancer._id }, { onSuccess: () => freelancerRefetch() })
  }

  useEffect(() => {
    if (!freelancer) return

    freelancer.experiences.forEach((xp) => {
      form.setValue(`${xp.position}-rol`, xp.rol)
      form.setValue(`${xp.position}-description`, xp.description)
      form.setValue(`${xp.position}-link`, xp.link)
    })
  }, [freelancer])

  if (!freelancer) return null
  return (
    <form className='relative' onSubmit={form.handleSubmit(saveChanges)}>
      <button className='subtitle4-medium sticky top-0 ml-auto block cursor-pointer' type='submit'>
        Guardar
      </button>

      {experiencesLength.map((xp, index) => {
        const experience = freelancer.experiences.find((currentXp) => currentXp.position === xp + 1)
        const isIncomplete = experience
          ? (experience.rol || experience.description) && (!experience.rol || !experience.description)
          : false

        return (
          <Box display='grid' gridTemplateColumns='55px 1fr' alignItems='center'>
            <div className='border-b border-gray-300 h-full flex items-center'>
              <div className='w-10 h-10 total-center border border-black rounded-full microcopy'>{index + 1}</div>
            </div>

            <div key={xp} className='pb-0 border-b border-gray-300 mt-0 relative'>
              {index < 3 && <p className='body2-regular text-blue absolute right-4 top-2'>*</p>}
              {isIncomplete && (
                <p className='microcopy text-red -mb-2 mt-4'>
                  Esta experiencia está incompleto. Debes completarlo para que sea visible en la web
                </p>
              )}
              <Box display='grid' gridTemplateColumns='70px 1fr' className='gap-8 items-center'>
                <p className='body2-regular text-gray-300 text-blue'>Nombre:</p>

                <InputField
                  name={`${xp + 1}-rol`}
                  variant='transparent'
                  inputProps={{
                    className: 'w-full body2-regular bg-transparent',
                    placeholder: index === 0 ? '(ej: Expositor)' : '',
                  }}
                  form={form}
                />
              </Box>
              <Box display='grid' gridTemplateColumns='70px 1fr' className='gap-8 items-center -mt-1.5'>
                <p className='body2-regular text-gray-300 text-blue'>Link:</p>
                <InputField
                  name={`${xp + 1}-link`}
                  variant='transparent'
                  inputProps={{ className: 'w-full body2-regular', placeholder: index === 0 ? '(si aplica)' : '' }}
                  form={form}
                />
              </Box>
              <Box display='grid' gridTemplateColumns={isMobile ? '1fr' : '70px 1fr'} className='md:gap-8 gap-0'>
                <p className='body2-regular text-gray-300 text-blue'>Descripción:</p>

                <InputField
                  name={`${xp + 1}-description`}
                  variant='transparent'
                  textarea
                  options={{ maxLength: { value: 80, message: 'Máximo 80 caracteres.' } }}
                  textareaProps={{
                    className: 'w-full body2-regular',
                    placeholder: index === 0 ? '(ej: Autor de obra en el pabellón Chile para la Bienal de Venecia)' : '',
                    maxLength: 80,
                    rows: isMobile ? 1 : 2,
                  }}
                  form={form}
                />
              </Box>
            </div>
          </Box>
        )
      })}
    </form>
  )
}

export default FreelancerExperiences
