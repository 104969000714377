import { FC } from 'react'
import { IWebSiteSection } from '../../../models/IWebSiteSection'
import { collage, dontWaitDesktop, dontWaitTabletMobile } from '../../../constants/WebSite/AboutUsConstants'

import { Box, useMediaQuery } from '@material-ui/core'

const Collage: FC<IWebSiteSection> = () => {
  return (
    <>
      <Box maxWidth={1350} className='pb-0 mt-4 mx-auto' id=''>
        <Box className='grid'>
          {dontWaitDesktop.map((iter, index) => {
            return (
              <div className={index === 0 ? 'col-span-12' : 'col-span-6'}>
                {typeof iter === 'string' ? <img key={iter} src={iter} width='100%' /> : iter}
              </div>
            )
          })}
        </Box>
      </Box>
    </>
  )
}

export default Collage
