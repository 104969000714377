import { Route, Switch } from 'react-router-dom'
import routes from '../constants/routes'
import FreelancerBook from '../views/FreelancerUserProfile/FreelancerBook'
import FreelancerHelp from '../views/FreelancerUserProfile/FreelancerHelp'
import FreelancerHome from '../views/FreelancerUserProfile/FreelancerHome'
import FreelancerProfile from '../views/FreelancerUserProfile/FreelancerProfile'
import FreelancerProjects from '../views/FreelancerUserProfile/FreelancerProjects'

function FreelancerUserProfileRouter() {
  return (
    <Switch>
      <Route path={routes.freelancerUserProfile.home} component={FreelancerHome} />
      <Route path={routes.freelancerUserProfile.projects.index} component={FreelancerProjects} />
      <Route path={routes.freelancerUserProfile.profile.index} component={FreelancerProfile} />
      <Route path={routes.freelancerUserProfile.help} component={FreelancerHelp} />
      <Route path={routes.freelancerUserProfile.book.index} component={FreelancerBook} />
    </Switch>
  )
}

export default FreelancerUserProfileRouter
