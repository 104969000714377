import { FC, useEffect, useState } from 'react'
import { created_at } from '../../../utils/helpers'
import { useCreateWorkPlan, useUpdateWorkPlan } from '../../../customHooks/request/workPlanQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { Box } from '@material-ui/core'
import WorkPlanData from './WorkPlanData'
import WorkPlanImages from './WorkPlanImages'
import WorkPlanVersion from './WorkPlanVersion/WorkPlanVersion'
import SwipeableViews from 'react-swipeable-views'
import { UseQueryResult } from 'react-query'
import { IOffer } from '../../../models/IOffer'

export type TOfferWorkPlan = {
  offerQuery: UseQueryResult<IOffer, unknown>
  asClient?: boolean
  asCpanel?: boolean
}

const tabsStyles = 'slider-item cursor-pointer subtitle6-regular text-black-300 text-center'

const OfferWorkPlan: FC<TOfferWorkPlan> = (props) => {
  const { data: offerQuery, refetch: refetchOffer } = props.offerQuery
  const createWorkPlan = useCreateWorkPlan()
  const updateWorkPlan = useUpdateWorkPlan()
  useRequestAlert(updateWorkPlan)

  const [index, setIndex] = useState(0)
  const isDisabledFields = !offerQuery?.work_plan?.can_edit && offerQuery?.status !== 'available'

  useEffect(() => {
    if (!offerQuery || (offerQuery && offerQuery?.work_plan?._id)) return

    const body = {
      created_at,
      offer: offerQuery._id,
      project: offerQuery.project._id,
      staff: offerQuery.staff._id,
    }
    createWorkPlan.mutate({ body }, { onSuccess: () => refetchOffer() })
  }, [offerQuery])

  if (!offerQuery) return null
  return (
    <div className={props.asClient ? 'shadow-lg py-4 px-6' : ''}>
      <p className='buttontext4-regular pb-4'>
        Oferta del freelancer{' '}
        {!props.asClient && offerQuery.project.with_currency && <span className='microcopy text-blue'>(Proyecto en USD)</span>}
      </p>
      <Box display='grid' gridTemplateColumns={props.asClient ? '1fr' : '55px 1fr'} className='gap-8'>
        {props.asClient ? (
          <div className='flex gap-12 pt-2 slider hide-scroll-bar'>
            <p
              className={`subtitle4-regular ${tabsStyles} ${index === 0 ? 'border-b-blue pb-1' : ''}`}
              onClick={() => setIndex(0)}
            >
              Precio
            </p>
            <p
              className={`subtitle4-regular ${tabsStyles} ${index === 1 ? 'border-b-blue pb-1' : ''}`}
              onClick={() => setIndex(1)}
            >
              Descripción
            </p>

            {offerQuery.work_plan.images.length > 0 && (
              <p
                className={`subtitle4-regular ${tabsStyles} ${index === 2 ? 'border-b-blue pb-1' : ''}`}
                onClick={() => setIndex(2)}
              >
                Imágenes
              </p>
            )}
          </div>
        ) : (
          <div className='flex flex-col items-center py-4 gap-y-8 shadow-black-005'>
            <FiIcons className={index === 0 ? 'border-b-blue pb-1' : ''} name='dollar' onClick={() => setIndex(0)} />
            <FiIcons className={index === 1 ? 'border-b-blue pb-1' : ''} name='edit2' onClick={() => setIndex(1)} />
            <FiIcons className={index === 2 ? 'border-b-blue pb-1' : ''} name='mountain' onClick={() => setIndex(2)} />
          </div>
        )}

        <SwipeableViews index={index}>
          <WorkPlanVersion
            offerQuery={props.offerQuery}
            isDisabledFields={isDisabledFields}
            asClient={props.asClient}
            asCpanel={props.asCpanel}
          />
          <WorkPlanData offerQuery={props.offerQuery} isDisabledFields={isDisabledFields} asClient={props.asClient} />
          <WorkPlanImages offerQuery={props.offerQuery} asClient={props.asClient} />
        </SwipeableViews>
      </Box>
    </div>
  )
}

export default OfferWorkPlan
