import { Route, Switch } from 'react-router-dom'
import routes from '../constants/routes'
import ClientHome from '../views/ClientUserProfile/ClientHome'

import ClientProfile from '../views/ClientUserProfile/ClientProfile'
import ClientProjects from '../views/ClientUserProfile/ClientProjects'
import Book from '../views/ClientUserProfile/Book'
import ClientHelp from '../views/ClientUserProfile/ClientHelp'

function ClientUserProfileRouter() {
  return (
    <Switch>
      <Route path={routes.clientUserProfile.home} component={ClientHome} />
      <Route path={routes.clientUserProfile.profile.index} component={ClientProfile} />
      <Route path={routes.clientUserProfile.projects.index} component={ClientProjects} />
      <Route path={routes.clientUserProfile.help} component={ClientHelp} />
      <Route path={routes.clientUserProfile.book} component={Book} />
    </Switch>
  )
}

export default ClientUserProfileRouter
