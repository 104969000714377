import { useMutation, useQueryClient } from 'react-query'
import { IAuth, IForgotPassword, IToken } from '../../models/IAuth'
import { TParams } from '../../models/IRequest'
import { IUser } from '../../models/IUser'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  login: 'auth/login',
  whoIAm: 'auth/whoIAm',
  forgotPassword: 'auth/fotgotPassword',
  recoverPassword: 'auth/recoverPassword',
}

export const useLoginMutation = () => {
  return useMutation((params: TParams<IAuth>) =>
    apiClient.mutation2<IToken, IAuth>(`${endpoints.login}`, params.method, params.body)
  )
}

export const useForgotPasswordMutation = () => {
  return useMutation((params: TParams<IAuth>) =>
    apiClient.mutation2<IForgotPassword, IAuth>(`${endpoints.forgotPassword}`, params.method, params.body)
  )
}

export const useRecoverPasswordMutation = () => {
  return useMutation((params: TParams<IAuth>) =>
    apiClient.mutation2<IForgotPassword, IAuth>(`${endpoints.recoverPassword}`, params.method, params.body, params.customHeader)
  )
}

export const useWhoIAmFetch = () => {
  const query = useQueryClient()
  const loader = useSetLoader()

  const user = async () => {
    const endpoint = `${endpoints.whoIAm}`
    loader.setIsOpen(true)
    const response = await query.fetchQuery(endpoint, () => apiClient.getOne<IUser>(endpoint))
    loader.setIsOpen(false)
    return response
  }

  return user
}
