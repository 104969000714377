import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../constants/routes'
import FreelancerProjectOptions from '../../components/FreelanceUserProfile/FreelancerProjects/FreelancerProjectOptions'
import FreelancerOffers from '../../components/FreelanceUserProfile/FreelancerProjects/FreelancerOffers/FreelancerOffers'
import FreelancerProjectList from '../../components/FreelanceUserProfile/FreelancerProjects/FreelancerProjectList'
import freelancerProjectDetail from '../../components/FreelanceUserProfile/FreelancerProjects/freelancerProjectDetail'
import FreelancerHistoricProjectList from '../../components/FreelanceUserProfile/FreelancerProjects/FreelancerHistoricProjectList'

const FreelancerProjects: FC = () => {
  return (
    <Switch>
      <Route path={`${routes.freelancerUserProfile.projects.detail}/:projectId`} component={freelancerProjectDetail} />

      <Route path={routes.freelancerUserProfile.projects.offers.index} component={FreelancerOffers} />
      <Route path={routes.freelancerUserProfile.projects.list} component={FreelancerProjectList} />
      <Route path={routes.freelancerUserProfile.projects.historical} component={FreelancerHistoricProjectList} />
      <Route exact component={FreelancerProjectOptions} />
    </Switch>
  )
}

export default FreelancerProjects
