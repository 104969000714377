import NewProjectStepLayout from './NewProjectStepLayout'
import { useTranslation } from 'react-i18next'

export const NewProjectStepCompleted = () => {
  const { t } = useTranslation()

  return (
    <>
      <NewProjectStepLayout>
        <h4 className='text-blue max-w-3xl'>
          {t('UP_NEW_PROJECT.FINISH_PROjECT')} <br /> {t('UP_NEW_PROJECT.TO_SEE_MOORE')}
        </h4>
      </NewProjectStepLayout>
    </>
  )
}
