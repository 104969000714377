export const sanitizeRedirectPath = (path: string | null) => {
  try {
    if (!path) return '/'

    const urlObject = new URL(path, window.location.origin)

    const { pathname, search, hash } = urlObject

    return pathname + search + hash
  } catch {
    return '/'
  }
}
