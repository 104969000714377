import { useMemo, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { Link, useHistory, useParams } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { useOneProjectQuery, useRemoveProject, useUpdateProject } from '../../customHooks/request/projectsQuery'
import routes from '../../constants/routes'
import { IProject, TProjectStatus } from '../../models/IProject'
import { Divider, Button, IconButton } from '@material-ui/core'

import Tabs from '../../assets/UIkit/Tabs'

import { Box } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ProjectBrief from './ProjectBrief'
import ProjectInfo from './ProjectInfo'
import ProjectStaff from './ProjectStaff'
import ProjectCandidates from './ProjectCandidates/ProjectCandidates'
import ProjectCategories from './ProjectCategories'
import ProjectCalculator from './ProjectCalculator'
import ProjectPayments from './ProjectPayments/ProjectPayments'
import FiButton from '../../assets/UIkit/FiButton'
import useRequestAlert from '../../customHooks/useRequestAlert'
import AppDialog from '../../assets/UIkit/AppDialog'
import useDialog from '../../customHooks/useDialog'
import ProjectFeedbackSection from './ProjectFeedbackSection'
import { useUpdateFreelancer } from '../../customHooks/request/freelancersQuery'

export type TProjectView = 'brief' | 'project' | 'candidates' | 'staff' | 'calculator' | 'payments' | 'feedback'
type TProject = {
  asClient?: boolean
  isDisabledFields?: boolean
}

export type TProjectSections = {
  clientId: string
  projectId: string
  project: UseQueryResult<IProject, unknown>
  isDisabledFields?: boolean
}

export type TInputProps = {
  // remove
  name: string
  label?: string
  type?: string
  pholder?: string
  className?: string
  inputClassName?: string
  form?: UseFormReturn<any>
  isDisabled?: boolean
  step?: string
}

const Project: React.FC<TProject> = (props) => {
  const params = useParams<{ projectId: string; clientId: string }>()
  const navigate = useHistory().push

  const projectQuery = useOneProjectQuery(params.projectId)
  const project = projectQuery.data
  const dialog = useDialog<IProject, TProjectStatus>()

  const updateProject = useUpdateProject()
  const updateFreelancer = useUpdateFreelancer()
  const removeProject = useRemoveProject()
  useRequestAlert(removeProject)
  useRequestAlert(updateProject)

  const [projectView, setProjectView] = useState<TProjectView>('brief')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const tabs = useMemo(() => {
    if (!projectQuery.data) return []

    return [
      { id: 1, text: `Brief`, isSelected: projectView === 'brief', action: () => setProjectView('brief') },
      { id: 2, text: `Proyecto`, isSelected: projectView === 'project', action: () => setProjectView('project') },
      { id: 3, text: `Candidatos`, isSelected: projectView === 'candidates', action: () => setProjectView('candidates') },
      {
        id: 4,
        text: `Freelancer`,
        isSelected: projectView === 'staff',
        action: () => setProjectView('staff'),
      },
      {
        id: 5,
        text: `Billing`,
        isSelected: projectView === 'calculator',
        action: () => setProjectView('calculator'),
      },
      {
        id: 6,
        text: `Pagos`,
        isSelected: projectView === 'payments',
        action: () => setProjectView('payments'),
      },
      {
        id: 7,
        text: `Feedback`,
        isSelected: projectView === 'feedback',
        action: () => setProjectView('feedback'),
      },
    ]
  }, [projectQuery.data, projectView])

  const setGoBackPath = () => {
    if (props.asClient) return `${routes.cpanel.clients.findie}/${params.clientId}/proyectos`
    return `${routes.cpanel.projects.list}`
  }

  const updateStatus = () => {
    if (!project) return

    updateProject.mutate(
      { body: { project_status: dialog.action }, id: params.projectId },
      {
        onSuccess: async () => {
          const current_projects = project.staff[0].freelancer.current_projects.filter((currentProject) => {
            return currentProject !== project._id
          })
          await updateFreelancer.mutate({ body: { current_projects }, id: project.staff[0].freelancer._id })
          projectQuery.refetch()
          dialog.toggle()
        },
      }
    )
  }

  const removeCurrentProject = () => {
    if (!project) return
    removeProject.mutate({ id: project._id }, { onSuccess: () => navigate(setGoBackPath()) })
  }

  const defaultProps = {
    clientId: params.clientId,
    projectId: params.projectId,
    project: projectQuery,
    isDisabledFields: projectQuery?.data?.project_status === 'cancelled' || projectQuery?.data?.project_status === 'finished',
  }

  return (
    <>
      <div className='flex items-center justify-between'>
        <div>
          {projectQuery.isSuccess && projectQuery.data.project_status === 'in_progress' && (
            <h5 className='text-blue mt-4'>Proyecto en curso</h5>
          )}
          {projectQuery.isSuccess && projectQuery.data.project_status === 'cancelled' && (
            <h5 className='text-red mt-4'>Proyecto cancelado</h5>
          )}
          {projectQuery.isSuccess && projectQuery.data.project_status === 'finished' && (
            <h5 className='text-soft-green mt-4'>Proyecto terminado</h5>
          )}
        </div>
        <div>
          {projectQuery.isSuccess && projectQuery.data.project_status === 'in_progress' && (
            <div>
              <FiButton className='mr-4' onClick={() => dialog.toggle({ dialogAction: 'finished' })}>
                Finalizar
              </FiButton>
              <FiButton variant='outlined' theme='danger' onClick={() => dialog.toggle({ dialogAction: 'cancelled' })}>
                Cancelar
              </FiButton>
            </div>
          )}
          {project && (!project.staff.length || !project.staff[0]?.freelancer) && (
            <FiButton variant='outlined' theme='danger' onClick={() => setIsModalOpen(true)}>
              Eliminar proyecto
            </FiButton>
          )}
        </div>
      </div>

      <div className='flex justify-between items-center'>
        {projectQuery.isSuccess && <Tabs tabs={tabs} variant='default' />}
        <Box className='flex justify-end mt-6'>
          <Link to={setGoBackPath()} className='body1-medium flex items-center cursor-pointer'>
            <ArrowBackIcon /> Volver
          </Link>
        </Box>
      </div>

      {project && projectView === 'brief' && <ProjectBrief {...defaultProps} />}
      {project && projectView === 'project' && (
        <>
          <ProjectInfo {...defaultProps} />
          <ProjectCategories {...defaultProps} />
        </>
      )}
      {project && projectView === 'candidates' && <ProjectCandidates {...defaultProps} />}
      {project && projectView === 'staff' && <ProjectStaff {...defaultProps} />}
      {project && projectView === 'calculator' && <ProjectCalculator {...defaultProps} />}
      {project && projectView === 'payments' && <ProjectPayments {...defaultProps} />}
      {project && projectView === 'feedback' && <ProjectFeedbackSection {...defaultProps} />}

      <AppDialog open={dialog.isOpen} title='Acciones del proyecto' handleClose={dialog.toggle}>
        <span className='subtitle4-medium mb-2 block'>
          ¿Estas seguro que deseas {dialog.action === 'finished' ? 'Finalizar' : 'Cancelar'} este proyecto?
        </span>

        <Divider className='mt-4' />

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-4' onClick={() => dialog.toggle()}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={updateStatus}>
            Aceptar
          </Button>
        </Box>
      </AppDialog>

      <AppDialog open={isModalOpen} title='eliminar ' handleClose={() => setIsModalOpen(false)}>
        <span className='subtitle4-medium'>¿Estás seguro que deseas eliminar este proyecto? No podrás revertir esta acción.</span>
        <Divider className='my-3' />

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-2' onClick={() => setIsModalOpen(false)}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={removeCurrentProject}>
            Aceptar
          </Button>
        </Box>
      </AppDialog>
    </>
  )
}

export default Project
