import { useMemo } from 'react'
import useCategories from '../../customHooks/useCategories'
import { ICategory } from '../../models/ICategory'
import { IFormStep } from '../../models/IFormStep'

import FormStepOptionsContent from '../FormStepOptionsContent/FormStepOptionsContent'
import { useTranslation } from 'react-i18next'

const FormCategoryStep: React.FC<IFormStep & { userType: 'client' | 'freelancer' }> = (props) => {
  const categories = useCategories()
  const { t } = useTranslation()
  const categoriesList = useMemo(() => {
    if (!categories.isSuccess) return []

    return categories.data
      .filter((category: ICategory) => category.is_available && !category.is_other_category)
      .map((category: ICategory) => {
        return { value: category._id, label: t(category.name), isOtherCategory: category.is_other_category }
      })
  }, [categories.isSuccess])

  return (
    <>
      <FormStepOptionsContent
        name='category'
        title={
          props.userType.includes('client')
            ? t('clientSubscriptionForm.FormCategoryStep.clientStep')
            : '¿En qué área quieres trabajar?'
        }
        currentStep={props.currentStep}
        options={categoriesList}
        categoriesQuery={categories}
        {...props}
        asCategories
      />
    </>
  )
}

export default FormCategoryStep
