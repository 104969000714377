//Roadmap view
export const homeRoadMap = [
  {
    title: 'Cuéntanos tu necesidad',
    content:
      'Trabajaremos contigo para entender tus necesidades y objetivos. Podrás elegir entre que tu o Findie haga la búsqueda de candidatos.',
    step: 1,
    isNotLastStep: true,
  },
  {
    title: 'Candidatos selectos',
    content: 'Todos nuestros freelancers han sido preseleccionados asegurándote un alto nivel de calidad y profesionalismo.',
    step: 2,
    isNotLastStep: true,
  },
  {
    title: 'Arma un equipo',
    content:
      'Alimentemos a dos pajaritos con un panecillo, desde Findie puedes formar un equipo multidisciplinario para un mismo proyecto.',
    step: 3,
    isNotLastStep: false,
  },
]
//Featured freelancers view
export const sliderSettings = (slides: number) => {
  return {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    swipe: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: slides > 5 ? 5 : slides,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slides > 4 ? 4 : slides,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }
}
export const sliderSettingsMobile = () => {
  return {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
}
//Why chooce us view
export const whyUsTab = [
  { title: 'Soy cliente', value: 'client' },
  { title: 'Soy freelancer', value: 'freelancer' },
]

export const clientTable = [
  {
    plan: 'Candidatos pre-seleccionados',
    findie: 'check',
    job: 'unCheck',
    others: 'unCheck',
  },
  {
    plan: 'Garantía de calidad',
    findie: 'check',
    job: 'unCheck',
    others: 'unCheck',
  },
  {
    plan: 'Tiempo en encontrar un candidato idóneo',
    findie: '0-3 semanas',
    job: '1-4 meses',
    others: '1-4 meses',
  },
  {
    plan: 'Costos de término de contrato',
    findie: 'ninguno',
    job: 'muy altos',
    others: 'ninguno',
  },
  {
    plan: 'Armar equipos multidisciplinarios',
    findie: 'rápido',
    job: 'lento',
    others: 'pocas lo tienen',
  },
  {
    plan: 'Depósito inicial reembolsable',
    findie: 'check',
    job: 'unCheck',
    others: 'solo el 1% ofrece',
  },
]

export const freelancerTable = [
  {
    plan: 'Protección al freelancer',
    findie: 'check',
    job: 'unCheck',
    others: 'unCheck',
  },
  {
    plan: 'Visibilidad alta',
    findie: 'check',
    job: 'unCheck',
    others: 'unCheck',
  },
  {
    plan: 'Llegar a públicos/nichos nuevos',
    findie: 'check',
    job: 'unCheck',
    others: 'sí, pero más lento',
  },
  {
    plan: 'Tratar con clientes serios',
    findie: 'check',
    job: 'no siempre',
    others: 'unCheck',
  },
  {
    plan: 'Pertenecer a comunidad insigne',
    findie: 'check',
    job: 'unCheck',
    others: 'muy pocas',
  },
  {
    plan: 'Freelancer se encarga del cobro',
    findie: 'unCheck',
    job: 'check',
    others: 'unCheck',
  },
]
