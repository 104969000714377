import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import routes from '../../constants/routes'
import { usePostBlogMutation } from '../../customHooks/request/blogsQuery'
import { IBlog } from '../../models/IBlog'
import { Box } from '@material-ui/core'

import FindieGifIsotype from '../../shared/Brand/Isotype/FindieGifIsotype'
import corfo from '../../assets/images/web/corfo.svg'
import { useTranslation } from 'react-i18next'
import { CURRENT_YEAR, created_at } from '../../utils/helpers'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'

const Footer: React.FC = () => {
  const form = useForm()
  const { t } = useTranslation()

  const email = usePostBlogMutation()

  const [emailMessage, setEmailMessage] = useState<string>('')

  const createEmail = () => {
    if (!form.watch('email')) return setEmailMessage('Agrega un email!!')

    const body: Partial<IBlog> = { email: form.watch('email'), createdAt: created_at }
    email.mutate(body, {
      onSuccess: () => {
        setEmailMessage('Envío exitoso :-)')
        setTimeout(() => {
          form.reset()
        }, 4000)
      },
      onError: (err: any) => setEmailMessage(err.json.error),
    })
  }

  useEffect(() => {
    setEmailMessage('')
  }, [form.watch('email')])

  return (
    <div className='bg-black'>
      <Box maxWidth='1440px' className='mx-auto grid grid-cols-12 relative  md:py-16 py-10 lg:px-12 md:px-40 px-0 z-40'>
        <div className='lg:col-span-6 col-span-12 border-b md:border-transparent border-white md:pb-0 pb-10 md:px-0 px-8'>
          <p className='microcopy text-white uppercase' style={{ letterSpacing: '1px' }}>
            {t('FOOTER.newsletter')}
          </p>
          <p className='subtitle4-regular text-white lg:w-7/12 md:w-10/12 w-full mt-4'>{t('FOOTER.newsText')}</p>

          <div className='grid grid-cols-12 border border-white rounded-3xl px-4 h-9 mt-8 lg:w-9/12 md:w-10/12 w-full'>
            <input
              className='lg:col-span-10 col-span-9 buttontext2-regular border-none focus:outline-none bg-transparent text-white pl-4'
              type='email'
              placeholder='E-mail'
              {...form.register('email')}
            />
            <div
              className='lg:col-span-2 col-span-3 flex items-center justify-end mr-4 h-full cursor-pointer'
              onClick={createEmail}
            >
              <FiIcons name='ArrowRight' />
            </div>
          </div>
          <p className={`microcopy ml-8 ${email.isSuccess ? 'text-white' : 'text-red'}`}>{emailMessage}</p>
        </div>

        <div className='lg:col-span-4 col-span-12 md:px-0 px-8 flex lg:gap-32 md:gap-40 gap-8 md:flex-row flex-col lg:mt-0 md:mt-20 mt-10 border-b md:border-transparent border-white md:pb-0 pb-10'>
          <div>
            <p className='microcopy text-white uppercase' style={{ letterSpacing: '1px' }}>
              {t('FOOTER.aboutFindie')}
            </p>
            <Link to={routes.web_site.about_us} className='buttontext2-regular mt-6 block text-white hover:italic-style'>
              {t('FOOTER.aboutUs')}
            </Link>
            <Link to={routes.web_site.how_works} className='buttontext2-regular block text-white hover:italic-style mt-2'>
              {t('FOOTER.howItWorks')}
            </Link>
            <Link to={routes.web_site.our_clients} className='buttontext2-regular block text-white hover:italic-style mt-2'>
              {t('FOOTER.clients')}
            </Link>
            <Link to={routes.web_site.our_freelancers} className='buttontext2-regular block text-white hover:italic-style mt-2'>
              Freelancers
            </Link>
          </div>

          <div>
            <p className='microcopy text-white uppercase' style={{ letterSpacing: '1px' }}>
              {t('FOOTER.help')}
            </p>
            <Link to={routes.web_site.faqs} className='buttontext2-regular mt-6 block text-white hover:italic-style'>
              FAQ
            </Link>
            <Link to={routes.web_site.terms_conditions} className='buttontext2-regular block text-white hover:italic-style mt-2'>
              {t('FOOTER.terms')}
            </Link>
            <Link to={routes.web_site.terms_conditions} className='buttontext2-regular block text-white hover:italic-style mt-2'>
              {t('FOOTER.privacyPolicy')}
            </Link>
          </div>
        </div>

        <div className='col-span-12 md:hidden md:px-0 px-8 flex justify-around py-5 border-b md:border-transparent border-white'>
          <a
            href='https://www.linkedin.com/company/findieonline/'
            className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
          >
            <FiIcons name='Linkedin' />
          </a>
          <a
            href='https://instagram.com/findie.insta/'
            className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
          >
            <FiIcons name='Instagram' />
          </a>
          <a
            href='https://open.spotify.com/user/kyg7yipjennf1fj2bpn67aeyf?si=661e7f6de4ed433e'
            className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
          >
            <FiIcons name='Spotify' />
          </a>
          <a
            href='mailto:contacto@findie.cl'
            className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
          >
            <FiIcons name='Email' />
          </a>
        </div>

        <div className='lg:col-span-2 col-span-12 flex lg:items-start items-center lg:mt-0 md:mt-16 mt-10 lg:justify-end justify-center lg:flex-row flex-col'>
          <FindieGifIsotype variant='contained-white' className='footer-isotype' />
          <div className='lg:hidden md:flex hidden justify-between mt-6'>
            <div className='flex items-center gap-8'>
              <a
                href='https://www.linkedin.com/company/findieonline/'
                className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
              >
                <FiIcons className='w-6' name='Linkedin' />
              </a>
              <a
                href='https://instagram.com/findie.insta/'
                className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
              >
                <FiIcons className='w-6' name='Instagram' />
              </a>
              <a
                href='https://open.spotify.com/user/kyg7yipjennf1fj2bpn67aeyf?si=661e7f6de4ed433e'
                className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
              >
                <FiIcons className='w-6' name='Spotify' />
              </a>
              <a
                href='mailto:contacto@findie.cl'
                className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
              >
                <FiIcons className='w-6' name='Email' />
              </a>
            </div>
          </div>
        </div>

        <div className='lg:justify-between justify-center lg:mt-16 md:mt-16 mt-10 col-span-12 flex'>
          <div className='items-end gap-4 lg:flex hidden'>
            <a
              href='https://www.linkedin.com/company/findieonline/'
              className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
            >
              <FiIcons name='Linkedin' />
            </a>
            <a
              href='https://instagram.com/findie.insta/'
              className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
            >
              <FiIcons name='Instagram' />
            </a>
            <a
              href='https://open.spotify.com/user/kyg7yipjennf1fj2bpn67aeyf?si=661e7f6de4ed433e'
              className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
            >
              <FiIcons name='Spotify' />
            </a>
            <a
              href='mailto:contacto@findie.cl'
              className='buttontext2-regular text-white block hover:italic-style mt-2 md:text-right text-left'
            >
              <FiIcons name='Email' />
            </a>
            <p className='microcopy text-white md:text-left text-center'>© Findie SpA {CURRENT_YEAR}</p>
          </div>

          <div>
            <img src={corfo} />
            <p className='microcopy text-white lg:mt-0 mt-8 lg:text-left text-center lg:hidden block'>
              © Findie SpA {CURRENT_YEAR}
            </p>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default Footer
