import { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { UseQueryResult } from 'react-query'
import { useHistory } from 'react-router'
import routes from '../../../../../../constants/routes'
import { useSelectProjectCandidate } from '../../../../../../customHooks/request/projectsQuery'
import useRequestAlert from '../../../../../../customHooks/useRequestAlert'
import { IProject } from '../../../../../../models/IProject'
import { created_at, operation } from '../../../../../../utils/helpers'
import { useProductsQuery } from '../../../../../../customHooks/request/productQuery'
import { IProduct } from '../../../../../../models/IProduct'
import { IWorkPlanVersion } from '../../../../../../models/IWorkPlan'
import useDialog from '../../../../../../customHooks/useDialog'
import useChooseCandidateContext from '../../../../../../customHooks/useChooseCandidateContext'
import { useOneOfferQuery } from '../../../../../../customHooks/request/offersQuery'
import { useForm } from 'react-hook-form'
import { FiIcons } from '../../../../../../assets/UIkit/Icons/FiIcons'
import ProductsList from './ProductsList'
import BreakDownDetail from './BreakDownDetail'
import BreakdownActions from './BreakdownActions'
import AppDialog from '../../../../../../assets/UIkit/AppDialog'
import BreakdownVersions from './BreakdownVersions'

type TProjectBreakDown = {
  projectQuery: UseQueryResult<IProject, unknown>
  params: { offerId: string; projectId: string }
}
export type TSection = 'breakdown' | 'payment_methods'
export type TMobileActions = 'products'

const useStyles = makeStyles((theme) => ({
  myBox: {
    height: '70svh',
    display: 'grid',
    gridTemplateColumns: '268px 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '220px 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  product: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '0px',
      display: 'none',
    },
  },
}))

const ProjectBreakDown = (props: TProjectBreakDown) => {
  const { params, projectQuery } = props
  const form = useForm()
  const classes = useStyles()
  const navigate = useHistory().push
  const dialog = useChooseCandidateContext()
  const sections = useDialog<null, TSection>({ defaultAction: 'breakdown' })
  const mobileActions = useDialog<null, TMobileActions>()

  const { data: project, refetch: projectRefetch } = projectQuery
  const { data: productsQuery } = useProductsQuery()
  const oneOfferQuery = useOneOfferQuery({ id: params.offerId, enabled: false })
  const { data: offerQuery } = oneOfferQuery
  const selectCandidate = useSelectProjectCandidate()
  useRequestAlert(selectCandidate)

  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([])
  // const [totalProjectPrice, setTotalProjectPrice] = useState<number>(0) //Acá el total del proyecto para los métodos de pago
  const [selectedWorkPlanVersion, setSelectedWorkPlanVersion] = useState<IWorkPlanVersion | undefined>()
  const selectedVersion = form.watch('selected_version')

  const selectedProductsPrice = () => {
    if (!offerQuery) return 0
    if (offerQuery.project.with_currency) {
      return selectedProducts.reduce((acc, product) => {
        return operation(acc, '+', product.currency_price)
      }, 0)
    }
    return selectedProducts.reduce((acc, product) => {
      const price = product.price_type === 'uf' ? product.price * offerQuery.project.uf ?? 0 : product.price
      return operation(acc, '+', price)
    }, 0)
  }

  const selectOffer = () => {
    if (!offerQuery || !project) return
    selectCandidate.mutate(
      {
        body: {
          ...offerQuery,
          work_plan: {
            ...offerQuery.work_plan,
            versions: offerQuery.work_plan.versions.map((version) => {
              if (version.name === selectedVersion) return { ...version, is_selected: true }
              return version
            }),
          },
          products: {
            list: project?.price.products.list.length ? project?.price.products.list : selectedProducts,
            total: project?.price.products.total > 0 ? project?.price.products.total : selectedProductsPrice(),
          },
          firstPayment: {
            title: 'Primer pago',
            created_at,
            amount: 0, //Baruc coloca acá el pago que se hace en la plataforma
            platform_name: 'paypal', //Baruc coloca acá nombre de la plataforma
            entity: 'client',
            entity_id: project.client._id,
            project: project._id,
          },
        },
        id: params.projectId,
      },
      {
        onSuccess: async () => {
          dialog.toggle({ dialogAction: 'finish_process' })
          setTimeout(async () => {
            await projectRefetch()
            navigate(`${routes.clientUserProfile.projects.detail}/${params.projectId}/actividad`)
          }, 3000)
        },
      }
    )
  }

  useEffect(() => {
    if (offerQuery) {
      const selectedWorkPlan = offerQuery.work_plan.versions.find((version) => version.name === dialog.data)
      setSelectedWorkPlanVersion(selectedWorkPlan)
      form.setValue('selected_version', selectedWorkPlan?.name ?? '')
    }
  }, [offerQuery])

  useEffect(() => {
    if (selectedVersion && offerQuery) {
      const selectedWorkPlan = offerQuery.work_plan.versions.find((version) => version.name === selectedVersion)
      setSelectedWorkPlanVersion(selectedWorkPlan)
    }
  }, [selectedVersion, offerQuery])

  if (!offerQuery || !productsQuery || !project || !selectedWorkPlanVersion) return null
  return (
    <>
      <Box className={`lg:gap-4 gap-2 -mt-12 mb-12 relative z-50 ${classes.myBox}`}>
        <div className={`py-8 px-4 overflow-auto md:block hidden shadow-lg bg-white ${classes.product}`}>
          {sections.action === 'breakdown' && project.price?.products.total === 0 && (
            <ProductsList
              withCurrency={offerQuery.project.with_currency}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          )}
          {sections.action === 'payment_methods' && <h1>Aviso de métodos de pago</h1>}
        </div>

        <div className='bg-ice-blue md:p-8 p-4 shadow-lg h-full'>
          {['breakdown'].includes(sections.action ?? '') && (
            <>
              <BreakdownVersions form={form} versions={offerQuery.work_plan.versions} />

              <BreakDownDetail
                project={project}
                offerId={params.offerId}
                selectedProducts={selectedProducts}
                selectedWorkPlanVersion={selectedWorkPlanVersion}
                // setTotalProjectPrice={setTotalProjectPrice}  DESCOMENTA BARUC
              />

              <BreakdownActions
                currentSection={sections.action as TSection}
                selectOffer={selectOffer}
                mobileActions={(dialogAction: TMobileActions) => mobileActions.toggle({ dialogAction })}
                // selectOffer={() => sections.toggle({ dialogAction: 'payment_methods' })}   DESCOMENTA BARUC
              />
            </>
          )}

          {sections.action === 'payment_methods' && (
            <h1>Metodos de pago: Dentro del componente llamas a la función "selectOffer" </h1>
          )}

          <FiIcons
            name='circle_close_black'
            className='absolute -top-0 -right-0 cursor-pointer'
            onClick={() => dialog.toggle({ dialogAction: 'work_plan' })}
          />
        </div>
      </Box>

      <AppDialog
        open={mobileActions.isOpen && mobileActions.action === 'products' && project.price?.products.total === 0}
        maxWidth='sm'
        title=''
        containerClassName='gradient-box gradient-color'
        onClose={() => mobileActions.toggle()}
        noHeader
      >
        <div className='relative overflow-x-hidden'>
          <ProductsList
            withCurrency={offerQuery.project.with_currency}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <FiIcons
            name='circle_close_black'
            className='absolute -top-2 -right-2 cursor-pointer'
            onClick={() => mobileActions.toggle()}
          />
        </div>
      </AppDialog>
    </>
  )
}

export default ProjectBreakDown
