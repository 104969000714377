import { siiTaxesValues } from '../../utils/CalculationsHelper'

export const CALENDLY_URL = 'https://calendly.com/findie/holacliente'

// TODO Add SII api
export const siiTax = [
  { name: 'Factura', type: 'bill', tax: siiTaxesValues('bill').tax, ticket_constant: siiTaxesValues('bill').constant },
  { name: 'Boleta', type: 'ticket', tax: siiTaxesValues('ticket').tax, ticket_constant: siiTaxesValues('ticket').constant },
]

export const clientSiiTax = [
  { name: 'Factura', type: 'bill', tax: siiTaxesValues('bill').tax, ticket_constant: siiTaxesValues('bill').constant },
  { name: 'Boleta', type: 'bill', tax: siiTaxesValues('bill').tax, ticket_constant: siiTaxesValues('bill').constant },
]

export const yesOrNotOptions = [
  { label: 'Si', value: 'true' },
  { label: 'No', value: 'false' },
]

export const bankList = [
  { _id: 1, name: 'Banco de Chile-Edwards-Citibank' },
  { _id: 2, name: 'Banco Internacional' },
  { _id: 3, name: 'Banco Estado' },
  { _id: 4, name: 'Scotiabank' },
  { _id: 5, name: 'BCI - TBANC' },
  { _id: 6, name: 'Corpbanca' },
  { _id: 7, name: 'Bice' },
  { _id: 8, name: 'HSBC' },
  { _id: 9, name: 'Santander' },
  { _id: 10, name: 'Banco ITAU' },
  { _id: 11, name: 'Banco Security' },
  { _id: 12, name: 'Banco Falabella' },
  { _id: 13, name: 'Banco Consorcio' },
  { _id: 14, name: 'Banco Ripley' },
  { _id: 15, name: 'Scotiabank Azul' },
  { _id: 16, name: 'Banco Coopeuch' },
  { _id: 17, name: 'BBVA' },
]

export const internationalBankList = [
  { _id: 1, name: 'Paypal' },
  { _id: 2, name: 'Payoneer' },
  { _id: 3, name: 'Mercado pago' },
]

export const accountType = [
  { _id: 1, name: 'Corriente' },
  { _id: 2, name: 'Ahorro' },
  { _id: 3, name: 'Vista' },
  { _id: 4, name: 'Rut' },
  { _id: 1, name: 'Paypal' },
  { _id: 2, name: 'Payoneer' },
  { _id: 3, name: 'Mercado pago' },
]

//Old structure
export const billingPlan = [
  'Default: 100% entrega',
  '50% al inicio / 50% entrega',
  '25% al inicio / 75% entrega',
  '25% al inicio / 25% mitad del proyecto / 50% entrega',
]

export const meetingsDays = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie']

export const paymentMethod = [
  { method: 'Transferencia bancaria', fee: 0 },
  { method: 'Tarjeta de crédito', fee: 0 },
  { method: 'Paypal', fee: 3.42 },
  { method: 'MercadoPago', fee: 2.75 },
]

export const freelancerTaxes = [
  { name: 'Factura', value: 'bill' },
  { name: 'Boleta', value: 'ticket' },
]

export const budgetList = [
  { value: '$ 50.000 - 250.000', label: '$ 50.000 - 250.000' },
  { value: '$ 250.000 - 750.000', label: '$ 250.000 - 750.000' },
  { value: '$ 750.000 - 1.500.000', label: '$ 750.000 - 1.500.000' },
  { value: '$ 1.500.000 - 3.500.000', label: '$ 1.500.000 - 3.500.000' },
  { value: '$ 3.500.000 o +', label: '$ 3.500.000 o +' },
  { value: 'A criterio del profesional', label: 'A criterio del profesional' },
]

export const currencyBudgetList = [
  { value: 'US$ 50 - 250', label: 'US$ 50 - 250' },
  { value: 'US$ 250 - 750', label: 'US$ 250 - 750' },
  { value: 'US$ 750 - 1.500', label: 'US$ 750 - 1.500' },
  { value: 'US$ 1.500 - 3.500', label: 'US$ 1.500 - 3.500' },
  { value: 'US$ 3.500 o +', label: 'US$ 3.500 o +' },
  { value: "At the professional's discretion", label: "At the professional's discretion" },
]

export const companySizeOptions = [
  { value: 'Microempresa (1 a 9 trabajadores)', label: 'Microempresa (1 a 9 trabajadores)' },
  { value: 'Pequeña (10 a 49 trabajadores)', label: 'Pequeña (10 a 49 trabajadores)' },
  { value: 'Mediana (50 a 199 trabajadores)', label: 'Mediana (50 a 199 trabajadores)' },
  { value: 'Gran empresa (200 o más)', label: 'Gran empresa (200 o más)' },
]

export const whenProjectStarts = [
  {
    label: `Lo antes posible`,
    value: 'Lo antes posible',
  },
  {
    label: `Dentro de 1 semana`,
    value: 'Dentro de 1 semana',
  },
  {
    label: `Tengo tiempo`,
    value: 'Tengo tiempo',
  },
]

export const industryOptions = [
  { value: 'AFP', label: 'AFP' },
  { value: 'Agropecuario/Silvícola', label: 'Agropecuario/Silvícola' },
  { value: 'Alimentos', label: 'Alimentos' },
  { value: 'Aeronáutica', label: 'Aeronáutica' },
  { value: 'Armamento', label: 'Armamento' },
  { value: 'Automotriz/Autopartes', label: 'Automotriz/Autopartes' },
  { value: 'Banca', label: 'Banca' },
  { value: 'Bebidas/Licores', label: 'Bebidas/Licores' },
  { value: 'Bienes de consumo', label: 'Bienes de consumo' },
  { value: 'Cementos', label: 'Cementos' },
  { value: 'Comercio', label: 'Comercio' },
  { value: 'Comunicaciones', label: 'Comunicaciones' },
  { value: 'Construcción', label: 'Construcción' },
  { value: 'Educación', label: 'Educación' },
  { value: 'Electrónica', label: 'Electrónica' },
  { value: 'Energía Eléctrica', label: 'Energía Eléctrica' },
  { value: 'Entretención', label: 'Entretención' },
  { value: 'Ferrocarriles', label: 'Ferrocarriles' },
  { value: 'Financieros/Empresariales', label: 'Financieros/Empresariales' },
  { value: 'Forestal/Papel', label: 'Forestal/Papel' },
  { value: 'Hotelería/Restauración/Turismo', label: 'Hotelería/Restauración/Turismo' },
  { value: 'Isapre', label: 'Isapre' },
  { value: 'Manufacturera', label: 'Manufacturera' },
  { value: 'Máquinas/Equipos', label: 'Máquinas/Equipos' },
  { value: 'Medios', label: 'Medios' },
  { value: 'Minería', label: 'Minería' },
  { value: 'Mobiliario', label: 'Mobiliario' },
  { value: 'Multisector', label: 'Multisector' },
  { value: 'Naviera', label: 'Naviera' },
  { value: 'Pesca', label: 'Pesca' },
  { value: 'Petróleo/Gas', label: 'Petróleo/Gas' },
  { value: 'Petroquímica', label: 'Petroquímica' },
  { value: 'Química/Farmacia', label: 'Química/Farmacia' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Ropa y Accesorios', label: 'Ropa y Accesorios' },
  { value: 'Sanitarias', label: 'Sanitarias' },
  { value: 'Sector Público', label: 'Sector Público' },
  { value: 'Seguros', label: 'Seguros' },
  { value: 'Servicios de Salud', label: 'Servicios de Salud' },
  { value: 'Servicios Financieros', label: 'Servicios Financieros' },
  { value: 'Servicios Públicos', label: 'Servicios Públicos' },
  { value: 'Sidelurgia/Metalurgia', label: 'Sidelurgia/Metalurgia' },
  { value: 'Software/TI', label: 'Software/TI' },
  { value: 'Telecomunicaciones', label: 'Telecomunicaciones' },
  { value: 'Textiles', label: 'Textiles' },
  { value: 'Transporte/Logística', label: 'Transporte/Logística' },
  { value: 'Vivienda/Inmobiliarios', label: 'Vivienda/Inmobiliarios' },
  { value: 'Otro', label: 'Otro' },
]
