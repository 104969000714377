import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useClientsSorted } from '../../../customHooks/request/clientsQuery'
import { IClient } from '../../../models/IClient'
import usePagination from '../../../customHooks/usePagination'
import routes from '../../../constants/routes'
import { useSetLoader } from '../../../customHooks/useSetLoader'
import useFilterByWord from '../../../customHooks/useFilterByWord'

import { Box, Grid, List, ListItem } from '@material-ui/core'
import CustomPagination from '../../../assets/UIkit/CustomPagination'
import useGroupAlphabetList from '../../../customHooks/useGroupAlphabetList'
import { paginationLimit } from '../../../utils/configs'
import dayjs from 'dayjs'

const limit = paginationLimit

const ClientsPostulationList: React.FC = () => {
  const pagination = usePagination()
  const { word, renderInput } = useFilterByWord()
  const clientsQuery = useClientsSorted({
    filters: {
      $or: [
        { name: word ? { $regex: word, $options: 'i' } : undefined },
        { lastName: word ? { $regex: word, $options: 'i' } : undefined },
      ],
      client_status: 'not_available',
      page: pagination.page,
      limit,
    },
  })
  const clients = useGroupAlphabetList<IClient>(clientsQuery)

  useSetLoader(clientsQuery)

  useEffect(() => {
    clientsQuery.refetch()
  }, [word])

  return (
    <>
      <h3>Clientes a postular</h3>
      <Grid container className='mt-4'>
        <Grid item lg={8} md={8}>
          {renderInput()}
          {clientsQuery.isSuccess && clientsQuery.data.data.length === 0 && word && (
            <span className='subtitle3-regular text-red block'>No se encontraron resultados de la búsqueda</span>
          )}
        </Grid>
        <Grid item lg={4} md={4} className='flex justify-end items-end'></Grid>
      </Grid>

      {clients.map((structure) => {
        return (
          <div key={structure.letter}>
            {structure.list.length > 0 && (
              <>
                <p className='subtitle2-bold mt-4'>{structure.letter}</p>
                <List>
                  {structure.list.map((client: IClient) => {
                    return (
                      <ListItem dense button key={client._id} className='p-0'>
                        <Link className='w-full' to={`${routes.cpanel.clients.postulations}/${client._id}`}>
                          <Box
                            display='grid'
                            gridTemplateColumns='1fr 20% 20% 20%'
                            className='gap-4 py-4 px-2 border-t border-gray-300'
                          >
                            <p className='subtitle4-regular truncate'>
                              {client?.name}, {client?.lastName}
                            </p>
                            <p className='subtitle4-regular truncate'>{client?.address?.country}</p>
                            <p className='subtitle4-regular truncate'>{client?.companyName}</p>
                            <p className='subtitle4-regular truncate'>{dayjs(client.createdAt).format('DD-MM-YYYY')}</p>
                          </Box>
                        </Link>
                      </ListItem>
                    )
                  })}
                </List>
              </>
            )}
          </div>
        )
      })}

      {clientsQuery.isSuccess && (
        <div className='flex justify-center'>
          <CustomPagination
            pages={clientsQuery.data.metadata.pages}
            page={pagination.page}
            onChange={(e, value) => pagination.handlePage(value)}
            position={'center'}
            className='mt-4'
          />
        </div>
      )}
    </>
  )
}

export default ClientsPostulationList
