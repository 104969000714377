import { FC, useEffect, useState } from 'react'
import { sectionTabs, TEntity, TSectionTab, TTabValue } from '../../../constants/WebSite/HowWorksConstants'
import SectionStructure from '../../../layout/WebSite/SectionStructure'
import { IWebSiteSection } from '../../../models/IWebSiteSection'

import { WhyChooseFindieTable } from '../../../shared/WhyChooseFindieTable/WhyChooseFindieTable'
import { useTranslation } from 'react-i18next'
import RoadMapLayout, { TRoadMap } from '../../../shared/RoadMapLayout/RoadMapLayout'
import ClientPrices from '../WhyFindie/ClientPrices'

export type TWhyChooseFindieTable = {
  textColor: string
  selectedTab: 'client' | 'freelancer' | string
  table: any
  theme: 'white' | 'transparent'
  entityTab: TEntity
}

const HowWorksSections: FC<IWebSiteSection> = (props) => {
  const { t, i18n } = useTranslation()
  const newClientTable = t('HOWWORKS.HowWorksSections.clientTable', { returnObjects: true })
  const newFreelancerTable = t('HOWWORKS.HowWorksSections.freelancerTable', { returnObjects: true })
  const clientHowRoadmap: any[] = t('OurClients.HowClientsWorks.howWorksMap', { returnObjects: true })
  const freelancerHowRoadmap: any[] = t('OurClients.HowClientsWorks.howFreelancersWorksMap', { returnObjects: true })
  const clientPrices: any[] = t('OurClients.ClientPrices.plansPrices', { returnObjects: true })
  const freelancersPrices: any[] = t('OurClients.ClientPrices.freelancersPrices', { returnObjects: true })

  const [selectedSection, setSelectedSection] = useState<TTabValue>('why')
  const [entityTab, setEntityTab] = useState<TEntity>('client')
  const [entityTable, setEntityTable] = useState(newClientTable)
  const [howWorksSection, setHowWorksSection] = useState<TRoadMap[]>(clientHowRoadmap)
  const [whatList, setWhatList] = useState(clientPrices)

  const selectSectionTab = (type: TEntity) => {
    setEntityTab(type)
    setHowWorksSection(type === 'client' ? clientHowRoadmap : freelancerHowRoadmap)
    setWhatList(type === 'client' ? clientPrices : freelancersPrices)
    setEntityTable(type === 'client' ? newClientTable : newFreelancerTable)
  }

  const entityDefaultProps: TWhyChooseFindieTable = {
    theme: 'transparent',
    textColor: props.view.textColor,
    selectedTab: entityTab,
    table: entityTable,
    entityTab,
  }

  const handleEntity = (entity: TEntity) => {
    const checkbox = document.getElementById('myCheckbox')

    if (checkbox) {
      selectSectionTab(entity)
      checkbox.click()
    }
  }

  useEffect(() => {
    i18n.on('languageChanged', () => {
      selectSectionTab(entityTab)
    })
  }, [])

  useEffect(() => {
    setHowWorksSection(entityTab === 'client' ? clientHowRoadmap : freelancerHowRoadmap)
    setWhatList(entityTab === 'client' ? clientPrices : freelancersPrices)
    setEntityTable(entityTab === 'client' ? newClientTable : newFreelancerTable)
  }, [i18n.language])

  return (
    <SectionStructure className='md:mt-10 mt-20' id='freelancer_selection'>
      <div className='grid grid-cols-12 items-center mt-6 mb-12 gap-y-4'>
        <h4
          className='cursor-pointer md:text-center text-left lg:col-span-4 col-span-6 h-8 lg:order-1 order-2'
          onClick={() => handleEntity('client')}
        >
          {t('HOWWORKS.HowWorksSections.imHiring')}
        </h4>
        <div className='flex lg:col-span-4 col-span-12 justify-center lg:order-2 order-1'>
          <div className='switch'>
            <div className='switch_box box_1'>
              <input
                type='checkbox'
                onChange={(e) => selectSectionTab(e.target.checked ? 'freelancer' : 'client')}
                id='myCheckbox'
                className='switch'
              />
            </div>
          </div>
        </div>
        <h4
          className='cursor-pointer h-8 md:text-center text-right lg:col-span-4 col-span-6 lg:order-3 order-3'
          onClick={() => handleEntity('freelancer')}
        >
          {t('HOWWORKS.HowWorksSections.imFreelancer')}
        </h4>
      </div>

      <div className='flex justify-between mt-6'>
        {sectionTabs.map((tab: TSectionTab) => {
          const selectedStyles = selectedSection === tab.value ? 'border-b border-white pb-2' : ''

          return (
            <p className={`subtitle2-bold cursor-pointer ${selectedStyles}`} onClick={() => setSelectedSection(tab.value)}>
              {t('HOWWORKS.HowWorksSections.' + tab.label)}
            </p>
          )
        })}
      </div>

      {selectedSection === 'why' && <WhyChooseFindieTable {...entityDefaultProps} />}

      {selectedSection === 'how' && (
        <RoadMapLayout className='mt-16' textColor={props.view.textColor} roadmap={howWorksSection} />
      )}
      {selectedSection === 'what' && <ClientPrices prices={whatList} />}
    </SectionStructure>
  )
}

export default HowWorksSections
