import { useMutation, useQuery } from 'react-query'
import { IProduct } from '../../models/IProduct'
import { IMutation, IResponse } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = { products: (filters: string) => `products${filters}` }

export const useProductsQuery = (filters?: any) => {
  const endpoint = endpoints.products(filters ? `?filters=${JSON.stringify(filters)}` : '')
  const plansQuery = useQuery([endpoint], () => apiClient.get<IResponse<IProduct>>({ endpoint }), {
    select: (data) => data,
  })
  useSetLoader(plansQuery)

  return plansQuery
}

export const useProductMutation = () => {
  return useMutation(({ body, method, id }: IMutation<IProduct>) =>
    apiClient.mutation<IProduct, IProduct>({ endpoint: endpoints.products(id ? `/${id}` : ''), body, id, method })
  )
}
