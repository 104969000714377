import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { IClient } from '../../models/IClient'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import InputField from '../../assets/UIkit/Forms/InputField'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type TClientsForm = {
  form: UseFormReturn<any>
  client?: IClient | undefined
  isDisabledFields?: boolean
  variant?: 'default' | 'outlined'
  asCpanel?: boolean
}

const ClientsForm: React.FC<TClientsForm> = (props) => {
  const { t } = useTranslation()
  const industry_Options: any = t('GLOBAL.INDUSTRY_OPTIONS', { returnObjects: true })
  const companySize_Options: any = t('clientSubscriptionForm.COMPANY_SIZES', { returnObjects: true })

  useEffect(() => {
    if (!props.client) return
    const fieldList = Object.entries(props.client)
    fieldList.forEach(([key, value]) => {
      if (key === 'createdAt') {
        props.form.setValue(key, dayjs(value).format('DD/MM/YYYY'))
        return
      }
      if (key === 'favorite_freelancer') {
        props.form.setValue('favoriteFreelancer', `${value?.name ?? ''} ${value?.lastName ?? ''}`)
        return
      }
      if (key === 'address') {
        props.form.setValue('address.country', value?.country)
        props.form.setValue('address.city', value?.city)
        return
      }
      props.form.setValue(key, value)
    })
  }, [props.client])

  return (
    <div className='grid grid-cols-12 gap-4 mt-4'>
      {props.asCpanel && (
        <div className='col-span-12 grid grid-cols-12 gap-4 mt-4'>
          {props.form.watch('createdAt') && (
            <p className='md:col-span-4 col-span-6 subtitle1-medium'>
              {t('ACCOUNT_FORM.ENTRY_DATE')}: {props.form.watch('createdAt')}
            </p>
          )}
          <div className='md:col-span-4 col-span-6'>
            {props.client?.contact_preference && (
              <p className='subtitle1-medium'>
                {t('ACCOUNT_FORM.CONTACT')}: {props.client.contact_preference}
              </p>
            )}
          </div>
          {/* <div className='md:col-span-4 col-span-6 flex items-start'>
            <InputField
              name='is_foreign'
              id='is_foreign'
              inputProps={{
                className: 'no-height -mt-2',
                type: 'checkbox',
                disabled: props.isDisabledFields,
              }}
              form={props.form}
            />
            <label htmlFor='is_foreign' className='subtitle1-medium ml-1 mb-1 cursor-pointer'>
              ¿Es extranjero?
            </label>
          </div> */}
        </div>
      )}
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='companyName'
          label={`${t('ACCOUNT_FORM.COMP_NAME.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.COMP_NAME.PLACEHOLDER')}`,
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <SelectField
          name='industry'
          label={`${t('ACCOUNT_FORM.INDUSTRY.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
          selectOptions={industry_Options}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <SelectField
          name='company_size'
          label={`${t('ACCOUNT_FORM.COMP_SIZE.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
          selectOptions={companySize_Options}
        />
      </div>
      <div className='md:col-span-4 col-span-12'>
        <InputField
          name='webSite'
          label={`${t('ACCOUNT_FORM.WEB.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.WEB.PLACEHOLDER')}`,
          }}
          form={props.form}
        />
      </div>

      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='name'
          label={`${t('ACCOUNT_FORM.NAME.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.NAME.PLACEHOLDER')}`,
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='lastName'
          label={`${t('ACCOUNT_FORM.L_NAME.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.L_NAME.PLACEHOLDER')}`,
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='job_title'
          label={`${t('ACCOUNT_FORM.JOB.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.JOB.PLACEHOLDER')}`,
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>

      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='address.city'
          label={`${t('ACCOUNT_FORM.C_T.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.C_T.PLACEHOLDER')}`,
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='address.country'
          label={`${t('ACCOUNT_FORM.CON_3.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.CON_3.PLACEHOLDER')}`,
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='email'
          label={`${t('GLOBAL.EMAIL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.EMAIL.PLACEHOLDER')}`,
            type: 'email',
          }}
          options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
          form={props.form}
        />
      </div>

      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='phone'
          label={`${t('ACCOUNT_FORM.PHONE.LABEL')}`}
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: `${t('ACCOUNT_FORM.PHONE.PLACEHOLDER')}`,
            type: 'number',
          }}
          form={props.form}
        />
      </div>

      {props.asCpanel && (
        <>
          {!props.form.watch('createdAt') && (
            <div className='md:col-span-4 col-span-6'>
              <InputField
                name='createdAt'
                label={`${t('ACCOUNT_FORM.ENTRY_DATE')}`}
                labelClassName='text-gray-500'
                variant={props.variant ?? 'outlined'}
                inputProps={{ className: 'w-full', disabled: props.isDisabledFields, type: 'date' }}
                options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
                form={props.form}
              />
            </div>
          )}
          <div className='md:col-span-4 col-span-6'>
            <InputField
              name='invitation_ticket'
              label={`${t('ACCOUNT_FORM.OFFER_TICKET.LABEL')}`}
              labelClassName='text-gray-500'
              variant={props.variant ?? 'outlined'}
              inputProps={{
                className: 'w-full',
                disabled: props.isDisabledFields,
                placeholder: `${t('ACCOUNT_FORM.OFFER_TICKET.PLACEHOLDER')}`,
              }}
              form={props.form}
            />
          </div>
          {props?.client?.favorite_freelancer?._id && (
            <div className='md:col-span-4 col-span-6'>
              <InputField
                name='favoriteFreelancer'
                label={`${t('ACCOUNT_FORM.SELECTED_FREE_L.LABEL')}`}
                labelClassName='text-gray-500'
                variant={props.variant ?? 'outlined'}
                inputProps={{
                  className: 'w-full',
                  disabled: true,
                }}
                form={props.form}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ClientsForm
