import useAuth from '../../customHooks/useAuth'
import routes from '../../constants/routes'
import { TView } from '../../context/WebSite/CurrentViewContext'
import { Link } from 'react-router-dom'

import { IconButton } from '@material-ui/core'
import FiButton from '../../assets/UIkit/FiButton'
import FindieLogo from '../../shared/Brand/Logo/FindieLogo'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import useUser from '../../customHooks/useUser'
import { SetStateAction } from 'react'

type TMobileMenu = {
  view: TView
  toogleComponent: () => void
  animation: { [key: string]: string }
  togleAnimation: () => void
  setIsVerificationModalOpen: (value: SetStateAction<boolean>) => void
}

const MobileMenu: React.FC<TMobileMenu> = (props) => {
  const auth = useAuth()
  const { user, isUserLogged } = useUser()
  const hireButtonStyles = props.view.textColor.includes('text-black') ? 'bg-black' : 'bg-white'
  const postButtonStyles = props.view.textColor.includes('text-black') ? 'border border-black' : 'border border-white'
  const { t } = useTranslation()

  const userProfilePath = () => {
    if (!user) return ''
    return user.user_type === 'client' ? routes.clientUserProfile.home : routes.freelancerUserProfile.home
  }

  return (
    <div
      className={`fixed w-screen z-50 h-small-screen top-0 left-0 ${props.view.bgColor}`}
      onAnimationEnd={props.togleAnimation}
      style={props.animation}
    >
      <div className='flex justify-between'>
        <Link
          to={routes.web_site.home}
          className='absolute lg:top-11 lg:left-11 md:top-12 md:left-12 top-7 left-7'
          onClick={props.toogleComponent}
        >
          <FindieLogo
            className='md:w-32 md:h-8 w-20 h-5'
            color={`${props.view.textColor.includes('black') ? 'black' : 'white'}`}
          />
        </Link>
        <IconButton className={`absolute md:top-6 md:right-6 top-2 right-2`} onClick={props.toogleComponent}>
          <CloseIcon fontSize='large' className={`${props.view.textColor}`} />
        </IconButton>
      </div>

      <div className='md:mt-56 mt-36 md:pl-'>
        <Link to={routes.web_site.about_us}>
          <p
            className={`buttontext1-medium ws-family-medium text-center block md:mt-12 mt-8 ${props.view.textColor}`}
            onClick={props.toogleComponent}
          >
            {t('HEADER.aboutUs')}
          </p>
        </Link>
        <Link to={routes.web_site.why_findie}>
          <p
            className={`buttontext1-medium ws-family-medium text-center block md:mt-12 mt-8 ${props.view.textColor}`}
            onClick={props.toogleComponent}
          >
            {t('HEADER.whyFindie')}
          </p>
        </Link>
        <Link to={routes.web_site.freelancers_profiles}>
          <p
            className={`buttontext1-medium ws-family-medium text-center block md:mt-12 mt-8 ${props.view.textColor}`}
            onClick={props.toogleComponent}
          >
            {t('HEADER.talents')}
          </p>
        </Link>
        <Link to={routes.web_site.our_freelancers}>
          <p
            className={`buttontext1-medium ws-family-medium text-center block md:mt-12 mt-8 ${props.view.textColor}`}
            onClick={props.toogleComponent}
          >
            {t('HEADER.applyHere')}
          </p>
        </Link>
      </div>

      <div className='absolute w-full md:bottom-28 bottom-16 total-center flex-col px-4'>
        <Link to={routes.web_site.createAccount} className='w-full'>
          <FiButton
            className={`mt-4 md:w-5/12 w-full py-2 ${props.view.textColor} ${postButtonStyles}`}
            variant='outlined'
            theme='secondary'
            asLink
            to={routes.web_site.createAccount}
          >
            <p className='buttontext1-medium'>{t('Crear cuenta')}</p>
          </FiButton>
        </Link>
        <FiButton
          className={`mt-4 md:w-5/12 w-full py-2 ${props.view.textColor2} ${hireButtonStyles}`}
          asLink
          to={routes.web_site.client_suscription_form.step_zero}
        >
          <p className='buttontext1-medium'>{t('Buscar talentos')}</p>
        </FiButton>
        {isUserLogged ? (
          <>
            <FiButton className={`mt-4 md:w-5/12 w-full ${props.view.textColor}`} variant='default' onClick={auth.logout}>
              <p className='buttontext1-medium'>{t('Cerrar sesión')}</p>
            </FiButton>
            {['super_admin', 'admin'].includes(user?.user_type ?? '') && (
              <Link to={routes.cpanel.freelancers.register} className='flex justify-end mt-4'>
                <p className={`buttontext1-medium cursor-pointer text-right text-blue hover:italic-style text-black`}>
                  Back office
                </p>
              </Link>
            )}
            {['freelancer', 'client'].includes(user?.user_type ?? '') && (
              <Link to={userProfilePath()} className='flex justify-end mt-4'>
                <p className={`buttontext1-medium cursor-pointer text-right text-blue hover:italic-style text-black`}>
                  User profile
                </p>
              </Link>
            )}
          </>
        ) : (
          <FiButton className={`mt-4 md:w-5/12 w-full ${props.view.textColor}`} variant='default' asLink to={routes.auth.login}>
            {t('Iniciar sesión')}
          </FiButton>
        )}
      </div>
    </div>
  )
}

export default MobileMenu
