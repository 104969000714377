import { FC } from 'react'
import { Box } from '@material-ui/core'

type TUserProfileDialog = {
  className?: string
  minHeight?: string
}

const UserProfileDialog: FC<TUserProfileDialog> = (props) => {
  return (
    <Box
      height={props.minHeight ?? '60vh'}
      className={`bg-ice-blue total-center flex-col col-span-12 rounded-md shadow-md ${props.className}`}
    >
      {props.children}
    </Box>
  )
}

export default UserProfileDialog
