import React, { FC, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import ProjectFeeCalcs from './SimulatorCalcs'

import calculatorImg from '../../../../assets/images/cpanel/projects/calculator.svg'

const ProjectSimulator: FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    )
      return

    setIsDrawerOpen(state)
  }

  return (
    <>
      <img className='cursor-pointer w-11 h-11' src={calculatorImg} onClick={toggleDrawer(true)} />
      <Drawer variant='persistent' anchor={'bottom'} open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <ProjectFeeCalcs asCpanel handleDrawer={toggleDrawer} />
      </Drawer>
    </>
  )
}

export default ProjectSimulator
