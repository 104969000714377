import { IWebSiteSection } from '../../../models/IWebSiteSection'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const FreelancersPrices: FC<IWebSiteSection> = (props) => {
  const { t } = useTranslation()
  let freelancersPrices: any[] = t('OurFreelancers.freelancersPlans', { returnObjects: true })
  let freelancersStudents: any[] = t('OurFreelancers.freelancersStudents', { returnObjects: true })

  return (
    <div className={`py-24 lg:px-64 md:px-12 px-0 bg-white mt-8 `} id='freelancer_price'>
      <div className={`lg:px-0 md:px-12 px-7`}>
        <p className={`subtitle2-bold ${props.view.textColor}`}>{t('OurFreelancers.FreelancersPrices.howItWorks')}</p>

        <p className={`body1-medium mt-12 ${props.view.textColor}`}>{t('OurFreelancers.FreelancersPrices.title')}</p>
      </div>

      <p className='body1-medium text-black bg-gray-026 md:pl-4 pl-7 mt-8 py-1'>Individual</p>
      <div className='grid grid-cols-12 mt-0 px-4'>
        {freelancersPrices.map((price, index) => {
          return (
            <div key={price.plan} className='md:col-span-4 col-span-12'>
              <div className='md:block hidden'>
                <h5
                  className={`text-center whitespace-pre-wrap pt-8 ${index + 1 === freelancersPrices.length ? 'text-blue' : ''}`}
                >
                  {price.plan}
                </h5>
                <h1 className='text-center'>{price.fee}</h1>
                <p className='subtitle6-medium text-gray-400 text-center'>{price.planType}</p>
              </div>
              <div className='md:hidden flex justify-between mt-6'>
                <div>
                  <h5 className={`text-center lg:px-20 md:px-8 ${index + 1 === freelancersPrices.length ? 'text-blue' : ''}`}>
                    {price.plan}
                  </h5>
                  <p className='subtitle6-medium text-gray-400'>{price.planType}</p>
                </div>
                <h1 className='text-center'>{price.fee}</h1>
              </div>
            </div>
          )
        })}
      </div>

      <div className='relative flex md:flex-row flex-col bg-gray-026 mt-8 py-1'>
        <p className='body1-medium text-strong-black md:pl-4 pl-7 mr-8'>{t('OurFreelancers.FreelancersPrices.studio')}</p>
        <p className='body1-medium text-strong-black md:pl-4 pl-7'>{t('OurFreelancers.FreelancersPrices.subtitle')}</p>
        <span className='microcopy text-white px-2 py-0 rounded-full bg-black absolute -top-4 md:-left-2 left-2'>
          {t('OurFreelancers.FreelancersPrices.new')}
        </span>
      </div>
      <div className='grid grid-cols-12 mt-0 px-4'>
        {freelancersStudents.map((price, index) => {
          return (
            <div key={price.plan} className='md:col-span-4 col-span-12'>
              <div className='md:block hidden'>
                <h5
                  className={`text-center whitespace-pre-wrap pt-8 ${index + 1 === freelancersPrices.length ? 'text-blue' : ''}`}
                >
                  {price.plan}
                </h5>
                <h1 className='text-center'>{price.fee}</h1>
                <p className='subtitle6-medium text-gray-400 text-center'>{price.planType}</p>
              </div>
              <div className='md:hidden flex justify-between mt-6'>
                <div>
                  <h5 className={`text-center lg:px-20 md:px-8 ${index + 1 === freelancersPrices.length ? 'text-blue' : ''}`}>
                    {price.plan}
                  </h5>
                  <p className='subtitle6-medium text-gray-400'>{price.planType}</p>
                </div>
                <h1 className='text-center'>{price.fee}</h1>
              </div>
            </div>
          )
        })}
      </div>

      <p className={`mt-8 microcopy md:ml-0 ml-7 ${props.view.textColor}`}>{t('OurFreelancers.FreelancersPrices.textFooter')}</p>
    </div>
  )
}

export default FreelancersPrices
