import { Box } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import FiButton from '../../../assets/UIkit/FiButton'
import {
  usePostHomeGalleryDnDMutation,
  usePutHomeGalleryMutation,
  useDeleteHomeGalleryMutation,
  useHomeGalleryQuery,
} from '../../../customHooks/request/homeGalleryQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import { IPorfolioFileDetail } from '../../../models/IFreelancer'

const FreelancerHomeGallery: FC = () => {
  const { data: galleryQuery, isSuccess: isGalleryQuerySuccess } = useHomeGalleryQuery()
  const galleryMutation = usePostHomeGalleryDnDMutation()
  const putHomeGalleryDnDMutation = usePutHomeGalleryMutation()
  const deleteHomeGalleryMutation = useDeleteHomeGalleryMutation()
  useRequestAlert(galleryMutation, undefined, undefined, afterDndErrorMutate)
  useRequestAlert(putHomeGalleryDnDMutation)
  useRequestAlert(deleteHomeGalleryMutation)
  const [galleryList, setGalleryList] = useState<IPorfolioFileDetail[]>([])
  const [beforeDragAndDrop, setBeforeDragAndDrop] = useState<IPorfolioFileDetail[]>([])

  const onDragEnd = (result: any) => {
    const { source, destination } = result
    if (!destination) return
    if (source.index === destination.index && source.droppableId === destination.droppableId) return

    const list = [...galleryList]
    const [removed] = list.splice(source.index, 1)
    list.splice(destination.index, 0, removed)
    const listEdited = list.map((image: IPorfolioFileDetail, index: number) => {
      return { ...image, position: index + 1 }
    })
    setBeforeDragAndDrop(galleryList)
    setGalleryList(listEdited)
    galleryMutation.mutate(listEdited)
  }

  function afterDndErrorMutate() {
    setGalleryList(beforeDragAndDrop)
  }

  const setEnableImage = (imageToEdit: IPorfolioFileDetail) => {
    const galleryListEdited = galleryList.map((image) => {
      if (image._id === imageToEdit._id) return { ...image, is_available: !image.is_available }
      return image
    })
    putHomeGalleryDnDMutation.mutate(
      { body: { is_available: !imageToEdit.is_available } as IPorfolioFileDetail, _id: imageToEdit._id ?? '' },
      {
        onSuccess: () => {
          setGalleryList(galleryListEdited)
        },
      }
    )
  }

  const removeImage = (imageToEdit: IPorfolioFileDetail) => {
    const galleryAfterRemoveItem = galleryList
      .filter((image) => image._id !== imageToEdit._id)
      .map((image, index) => {
        return { ...image, position: index + 1 }
      })
    deleteHomeGalleryMutation.mutate(imageToEdit._id ?? '', {
      onSuccess: async () => {
        setGalleryList(galleryAfterRemoveItem)
      },
    })
  }

  useEffect(() => {
    if (!galleryQuery) return

    const gallerySorted: IPorfolioFileDetail[] = galleryQuery.sort(
      (a: IPorfolioFileDetail, b: IPorfolioFileDetail) => a.position - b.position
    )
    setGalleryList(gallerySorted)
  }, [isGalleryQuerySuccess])

  return (
    <div>
      <h3>Home gallery</h3>
      <p className='buttontext4-regular mt-2 mb-6'>
        Las imágenes por default al ser ingresadas llegan con el estatus de "Inhabilitada" y no se podrán ver en la galeria de
        home hasta que se le cambie su estatus.
        <br />
        <br />
        Usa drag and drop para cambiar el orden en el que quieres que se muestren en el home.
      </p>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='gallery_images'>
          {(droppableProvided: any) => (
            <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {galleryList.map((image: IPorfolioFileDetail, index: number) => {
                return (
                  <Draggable key={image.position} draggableId={String(image.position)} index={index}>
                    {(draggableProvided: any) => (
                      <Box
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.dragHandleProps}
                        display='grid'
                        gridTemplateColumns='30px 110px 1fr 1fr'
                        className={`mt-2 border rounded-xl p-2 ${image.is_available ? 'border-green-400' : 'border-red-400'}`}
                      >
                        <p className='subtitle4-regular'>{image.position}.</p>
                        <img className='w-24 h-24' src={image.url} />
                        <div>
                          <p className='subtitle4-regular'>Nombre: {image.freelancer_name}</p>
                          <p className='subtitle4-regular'>Especialidad: {image.college_degree}</p>
                        </div>
                        <div className='self-center justify-self-end mr-4'>
                          <FiButton variant='outlined' className='mr-6' onClick={() => setEnableImage(image)}>
                            {image.is_available ? 'Suspender' : 'Habilitar'}
                          </FiButton>
                          <FiButton onClick={() => removeImage(image)}>Eliminar</FiButton>
                        </div>
                      </Box>
                    )}
                  </Draggable>
                )
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default FreelancerHomeGallery
