import {
  useDeleteFreelancerMutation,
  usePutFreelancerMutation,
  useHandleFeatureFreelancerMutation,
} from '../../customHooks/request/freelancersQuery'
import { IFreelancer } from '../../models/IFreelancer'
import useRequestAlert from '../../customHooks/useRequestAlert'
import useOpenDialog, { TRequestAction } from '../../customHooks/useOpenDialog'
import { useHistory } from 'react-router-dom'
import { IMetadata } from '../../models/IMetadata'
import routes from '../../constants/routes'
import { UseQueryResult } from 'react-query'

import AppDialog from '../../assets/UIkit/AppDialog'

import { Box, Button, Divider, IconButton } from '@material-ui/core'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import FindieIsotype from '../Brand/Isotype/FindieIsotype'
import FiButton from '../../assets/UIkit/FiButton'
import { useCreateUser, useUpdateUser } from '../../customHooks/request/userQuery'
import { TUserType } from '../../models/IUser'
import FreelancerAvailabilityPillBox from '../PillBoxStatus/FreelancerAvailabilityPillBox'
import { created_at } from '../../utils/helpers'

type TFreelancerShortCuts = {
  freelancer: IFreelancer
  freelancerDetail?: boolean
  page?: number
  freelancersQuery: UseQueryResult<{ data: IFreelancer[]; metadata: IMetadata }, unknown> | UseQueryResult<IFreelancer, unknown>
}

const FreelancerShortCuts: React.FC<TFreelancerShortCuts> = (props) => {
  const history = useHistory()
  const { dialog, recuestAction, toogleDialog, setRecuestAction } = useOpenDialog()
  const putFreelancerMutation = usePutFreelancerMutation()
  const handleFeatureFreelancerMutation = useHandleFeatureFreelancerMutation()
  const deleteFreelancerMutation = useDeleteFreelancerMutation()
  const createUserProfile = useCreateUser()
  const updateUserProfile = useUpdateUser()
  useRequestAlert(putFreelancerMutation, undefined, afterMutate)
  useRequestAlert(handleFeatureFreelancerMutation, undefined, afterMutate)
  useRequestAlert(deleteFreelancerMutation, undefined, afterMutate)
  useRequestAlert(createUserProfile, undefined, afterMutate)
  useRequestAlert(updateUserProfile, undefined, afterMutate)

  const openDialog = (freelancer: IFreelancer, action: TRequestAction) => {
    setRecuestAction(action)
    toogleDialog(freelancer)
  }

  const handleShortCuts = () => {
    const shortCutsActions: { [key: string]: () => void } = {
      user_profile: () => setUserProfile(),
      edit: () => setFeaturedFreelancerStatus(),
      suspend: () => suspendingFreelancer(),
      hidden: () => hiddenFreelancer(),
      featured: () => featureFreelancer(),
      delete: () => deleteFreelancer(),
    }
    shortCutsActions[recuestAction] && shortCutsActions[recuestAction]()
  }

  const setUserProfile = () => {
    const body = {
      created_at,
      name: dialog.data.name,
      last_name: dialog.data.lastName,
      email: dialog.data.email.trim(),
      phone: dialog.data.phone,
      password: `UP-${dialog.data.email.trim()}`,
      user_type: 'freelancer' as TUserType,
      is_disabled: false,
      profile_id: dialog.data._id,
    }
    createUserProfile.mutate({ body })
  }

  const setFeaturedFreelancerStatus = () => {
    const body = {
      is_hidden: false,
      featured_status: {
        ...dialog.data.featured_status,
        is_featured: !dialog.data.featured_status.is_featured,
        has_feartued_icon: false,
        position_date: created_at,
      },
    }
    handleFeatureFreelancerMutation.mutate({
      body,
      _id: dialog.data._id,
      category: dialog.data.category._id,
    })
  }

  const hiddenFreelancer = () => {
    putFreelancerMutation.mutate({ body: { is_hidden: !dialog.data.is_hidden }, _id: dialog.data._id })
  }

  const suspendingFreelancer = () => {
    putFreelancerMutation.mutate(
      {
        body: {
          freelancer_status: dialog.data.freelancer_status === 'suspended' ? 'available' : 'suspended',
          featured_status: { is_featured: false, has_feartued_icon: false, position: 0, position_date: created_at },
        },
        _id: dialog.data._id,
      },
      {
        onSuccess: () =>
          updateUserProfile.mutate({
            body: { is_disabled: dialog.data.freelancer_status !== 'suspended' ? false : true },
            id: dialog.data._id,
          }),
      }
    )
  }

  const featureFreelancer = () => {
    putFreelancerMutation.mutate({
      body: {
        featured_status: { ...dialog.data.featured_status, has_feartued_icon: !dialog.data.featured_status.has_feartued_icon },
      },
      _id: dialog.data._id,
    })
  }

  const deleteFreelancer = () => {
    putFreelancerMutation.mutate({
      body: {
        freelancer_status: 'deleted',
        featured_status: { is_featured: false, has_feartued_icon: false, position: 0, position_date: created_at },
      },
      _id: dialog.data._id,
    })
    props.freelancerDetail && history.push(`${routes.cpanel.freelancers.findie}`)
  }

  function afterMutate() {
    toogleDialog()
    props.freelancersQuery.refetch()
  }

  return (
    <>
      <Box>
        {!props.freelancer.has_user_profile && (
          <FiButton className='mr-2' size='small' onClick={() => openDialog(props.freelancer, 'user_profile')}>
            Add UP
          </FiButton>
        )}
        <FreelancerAvailabilityPillBox
          isAvailableToWork={props.freelancer.is_available_to_work}
          isWorking={props.freelancer.current_projects.length > 0}
          variant='outlined'
          className='px-2 w-28'
        />
        <IconButton edge='end' onClick={() => openDialog(props.freelancer, 'edit')} disabled={props.freelancerDetail}>
          <FiIcons name='star' className={`${props.freelancer.featured_status.is_featured && 'svg-orange'}`} />
        </IconButton>
        <IconButton edge='end' onClick={() => openDialog(props.freelancer, 'featured')} disabled={props.freelancerDetail}>
          <FindieIsotype
            variant={props.freelancer.featured_status.has_feartued_icon ? 'golden' : 'outlined-black'}
            className='w-6 h-6'
          />
        </IconButton>
        <IconButton edge='end' onClick={() => openDialog(props.freelancer, 'hidden')} disabled={props.freelancerDetail}>
          <FiIcons name='eye' className={`${props.freelancer.is_hidden && 'svg-white'}`} />
        </IconButton>
        <IconButton edge='end' onClick={() => openDialog(props.freelancer, 'suspend')} disabled={props.freelancerDetail}>
          <FiIcons name='suspended' className={`${props.freelancer.freelancer_status === 'suspended' && 'svg-red'}`} />
        </IconButton>
        {!props.freelancerDetail && (
          <IconButton edge='end' onClick={() => openDialog(props.freelancer, 'delete')}>
            <FiIcons name='skull' className='svg-soft-gray' />
          </IconButton>
        )}
      </Box>

      <AppDialog open={dialog.isOpen} title='Freelancer actions' handleClose={toogleDialog}>
        {recuestAction === 'user_profile' && (
          <span className='subtitle4-medium'>
            ¿Estas seguro que deseas habilitar el user profile de {dialog.data?.name} {dialog.data?.lastName}?
          </span>
        )}
        {recuestAction === 'edit' && (
          <span className='subtitle4-medium'>
            {dialog?.data?.featured_status?.is_featured
              ? `Estás seguro que deseas quitar el status de "Destacado(a) a ${dialog?.data?.name}`
              : `¿Estás seguro que deseas destacar a ${dialog?.data?.name}?. Si lo haces, el freelancer tendrá el status de disponible
              aun cuando su estado actual sea "suspendido".`}
          </span>
        )}
        {recuestAction === 'featured' && (
          <span className='subtitle4-medium'>
            {dialog?.data?.featured_status?.has_feartued_icon
              ? `Estás seguro que deseas quitar el sello destacado a ${dialog?.data?.name}`
              : `¿Estás seguro que deseas agregar el sello destacado a ${dialog?.data?.name}?.`}
          </span>
        )}
        {recuestAction === 'hidden' && (
          <span className='subtitle4-medium'>
            {dialog?.data?.featured_status?.is_hidden
              ? `Estás seguro que deseas mostrar a ${dialog?.data?.name}`
              : `¿Estás seguro que deseas esconder a ${dialog?.data?.name}?. Si lo haces, el freelancer no será visible en la web.`}
          </span>
        )}
        {recuestAction === 'suspend' && (
          <span className='subtitle4-medium'>
            {dialog?.data?.freelancer_status === 'suspended'
              ? `Estás seguro que deseas quitar el status de "suspendido(a) a ${dialog?.data?.name}`
              : `¿Estás seguro que deseas suspender a ${dialog?.data?.name}?. Si lo haces, no tendrás acceso a el en tus búsquedas, y si tiene el status de "Destacado", la perderá.`}
          </span>
        )}
        {recuestAction === 'delete' && (
          <span className='subtitle4-medium'>
            ¿Estás seguro que deseas enviar a {dialog?.data?.name} a la sección de eliminados?
          </span>
        )}
        <Divider className='my-3' />

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button variant='contained' className='mr-2' onClick={toogleDialog}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={handleShortCuts}>
            Aceptar
          </Button>
        </Box>
      </AppDialog>
    </>
  )
}

export default FreelancerShortCuts
