import { FC } from 'react'
import FiButton from '../../assets/UIkit/FiButton'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import newOffer from '../../assets/images/userProfile/new.png'
import SeñorityIcon from '../SeñorityIcon/SeñorityIcon'
import useTotalWorkPlanPrice from '../../customHooks/useTotalWorkPlanPrice'
import { IOffer } from '../../models/IOffer'
import { TVersion } from '../../models/IWorkPlan'
import { Link } from 'react-router-dom'
import { cn } from '../../utils/helpers'

type TTableCandidateCard = {
  candidatePath: string
  className?: string
  avatarClassName?: string
}

const TableCandidateCard: FC<IOffer & TTableCandidateCard> = (props) => {
  const hasNegotiation = props.work_plan && props.work_plan.versions.some((version) => version.is_negotiated_version)

  return (
    <div className={`relative pt-3 md:pb-3 pb-8 grid grid-cols-12 lg:gap-6 md:gap-4 gap-2 ${props.className ?? ''}`}>
      <div className='lg:col-span-4 md:col-span-7 col-span-12 order-1 relative grid grid-cols-12 gap-8 items-center'>
        <Link className='lg:col-span-8 md:col-span-6 col-span-8' to={props.candidatePath}>
          <div className='table-candidate-card-grid items-center gap-4 w-full'>
            <img
              className={`${props.avatarClassName ?? ''}  w-16 h-16 rounded-full object-cover`}
              src={props.freelancer.portfolio_avatar ?? props.freelancer.category.image}
              alt='avatar'
            />
            <div className='overflow-hidden'>
              <p className='subtitle3-20 -mt-1.5'>
                {props.freelancer.name} {props.freelancer.lastName.slice(0, 1).toUpperCase()}.
              </p>
              <p className='body2-regular truncate'>{props.freelancer.college_degree}</p>
            </div>
          </div>
        </Link>

        <div className='lg:col-span-4 md:col-span-6 col-span-4 flex gap-1 items-center'>
          <SeñorityIcon className='w-6' experienceLevel={props.freelancer.experience_level} />
          <FiIcons className={cn('opacity-0', { 'opacity-1': props.is_favorite })} name='smallStar' />
        </div>
        {hasNegotiation && <img src={newOffer} className='absolute -top-2 -left-4 w-8 h-8' alt='nuevo' />}
      </div>

      <div className='lg:col-span-6 col-span-12 lg:order-2 md:order-3 order-2 flex md:flex-row flex-col lg:pl-0 pl-20 items-center lg:gap-0 md:gap-4 gap-0'>
        <div className='w-full'>
          <WorkPlanTotal offer={props as IOffer} workPlanName='Basic' />
        </div>
        <div className='w-full'>
          <WorkPlanTotal offer={props as IOffer} workPlanName='Standard' />
        </div>
        <div className='w-full'>
          <WorkPlanTotal offer={props as IOffer} workPlanName='Full' />
        </div>
      </div>

      <Link
        className='lg:col-span-2 md:col-span-5 col-span-12 lg:order-3 md:order-2 order-3 flex items-center justify-end md:pl-0 pl-8 md:mt-0 mt-3'
        to={props.candidatePath}
      >
        <FiButton className='rounded-none lg:w-fit w-full' theme='secondary'>
          <span className='body2-regular py-1'>Ir a oferta</span>
        </FiButton>
      </Link>
    </div>
  )
}

export default TableCandidateCard

type TWorkPlanTotal = {
  offer: IOffer
  workPlanName: TVersion
}

const WorkPlanTotal = (props: TWorkPlanTotal) => {
  function selectedWorkPlan(workPlanName: TVersion) {
    return props.offer.work_plan.versions.find((version) => version.name === workPlanName)
  }

  const { total } = useTotalWorkPlanPrice({
    offer: props.offer,
    selectedProducts: [],
    selectedWorkPlanVersion: selectedWorkPlan(props.workPlanName),
  })

  const hasNoTotal = total === '0'

  return (
    <div className='flex lg:flex-col flex-row gap-x-2 lg:items-start items-center lg:justify-end justify-start'>
      <p className={cn(`subtitle6-regular lg:order-1 order-2 opacity-1`, { 'opacity-0': hasNoTotal })}>{total}</p>
      <p className={`${hasNoTotal ? 'text-gray-300' : 'text-blue'} lg:order-2 order-1 microcopy`}>{props.workPlanName}</p>
    </div>
  )
}
