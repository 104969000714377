import { IWebSiteSection } from '../../../models/IWebSiteSection'

import WebSiteStructure from '../../../layout/WebSite/WebSiteStructure'

import SectionStructure from '../../../layout/WebSite/SectionStructure'
import FreelancersGallery from '../../../shared/FreelancersGallery/FreelancersGallery'
import { useTranslation } from 'react-i18next'

const FeaturedFreelancers: React.FC<IWebSiteSection> = (props) => {
  const { t } = useTranslation()
  return (
    <div className='py-24 relative z-30' id='featured_freelancers'>
      <WebSiteStructure className='py-0'>
        <SectionStructure className='pb-4 pt-0'>
          <div className='mt-8'>
            <p className={`subtitle2-bold md:block hidden ${props.view.textColor}`}>
              {t('CONOCE A NUESTROS')} {t('TALENTS')}
            </p>
            <p className={`subtitle2-bold md:hidden block ${props.view.textColor}`}>
              {t('CONOCE A NUESTROS')} <br /> {t('TALENTS')}
            </p>
          </div>
        </SectionStructure>
      </WebSiteStructure>

      <FreelancersGallery view={props.view} />
    </div>
  )
}

export default FeaturedFreelancers
