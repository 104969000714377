import { Dispatch, FC, useEffect, useState } from 'react'
import { TProjectSections } from '../Project'
import { SubmitHandler, useForm } from 'react-hook-form'
import { cleanCLP, formatDecimal, getTimeStamp } from '../../../utils/helpers'

import { Box, Button, Divider } from '@material-ui/core'
import { IPayment } from '../../../models/IPayment'
import AppDialog from '../../../assets/UIkit/AppDialog'
import { PaymentsActions } from '../ProjectDialogactions'
import useDialog from '../../../customHooks/useDialog'
import FreelancerPayments from './FreelancerPayments'
import ClientPayments from './ClientPayments'
import { usePaymentMutation } from '../../../customHooks/request/paymentsQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'
import UserProfileDialog from '../../UserProfileDialog/UserProfileDialog'
import dayjs from 'dayjs'

export type TDialogAction = 'create' | 'update' | 'delete'
export type TPaymentsSection = {
  setDebs: Dispatch<React.SetStateAction<number>>
  dialogToggle: ({ dialogData, dialogAction }?: any) => void
}

const defaultValues = { title: '', amount: '', created_at: '' }

const tabStyles = 'subtitle4-regular cursor-pointer py-2'

const ProjectPayments: FC<TProjectSections> = (props) => {
  const form = useForm({ defaultValues })
  const { data: project, refetch: refetchProject } = props.project
  const paymentMutation = usePaymentMutation()
  useRequestAlert(paymentMutation)
  const dialog = useDialog<Partial<IPayment & { email: string }>, TDialogAction>()
  const [debs, setDebs] = useState<number>(0)
  const [view, setView] = useState<'client' | 'freelancer'>('client')

  const saveOrEditOrDelete: SubmitHandler<IPayment> = (data) => {
    const body = {
      ...data,
      created_at: getTimeStamp(data.created_at),
      amount: cleanCLP(data.amount),
      entity: view,
      entity_id: dialog.data?.entity_id ?? '',
      email: dialog.data?.email,
      project: props.projectId,
    }

    const dispatch: Record<TDialogAction, () => void> = {
      create: () => paymentMutation.mutate({ body, method: 'POST' }, { onSuccess: () => afterMutation() }),
      update: () =>
        paymentMutation.mutate({ body, method: 'PUT', id: dialog.data?._id ?? '' }, { onSuccess: () => afterMutation() }),
      delete: () =>
        paymentMutation.mutate(
          { method: 'DELETE', id: `${dialog.data?._id ?? ''}/${props.projectId}` },
          { onSuccess: () => afterMutation() }
        ),
    }
    dialog.action ? dispatch[dialog.action]() : dispatch.create()
  }

  const afterMutation = () => {
    refetchProject()
    closeDialog()
  }

  const closeDialog = () => {
    dialog.toggle()
    form.reset(defaultValues)
    form.clearErrors()
  }

  useEffect(() => {
    if (dialog?.data && dialog.action === 'update') {
      form.setValue('title', dialog.data.title as string)
      form.setValue('amount', String(dialog.data.amount))
      form.setValue('created_at', dayjs(dialog.data.created_at).format('YYYY-MM-DD'))
    }
  }, [dialog.action])

  if (!project) return null
  if (project.project_status === 'not_approved') {
    return (
      <UserProfileDialog>
        <h4 className='text-blue'>Es necesario que el proyecto sea aprobado.</h4>
        <h4 className='text-center mt-4 text-blue'>
          Cuando un candidato es seleccionado en la pestaña "Candidatos" o hecho en el user profile, podrás ver los cálculos del
          proyecto en forma automática
        </h4>
      </UserProfileDialog>
    )
  }
  return (
    <>
      <Box display='grid' gridTemplateColumns='150px 1fr' className='gap-6 mt-8'>
        <div>
          <p
            className={`${tabStyles} ${view === 'client' ? 'subtitle4-medium border-b border-blue-700 text-blue' : ''}`}
            onClick={() => setView('client')}
          >
            Cliente
          </p>
          <p
            className={`${tabStyles} ${view === 'freelancer' ? 'subtitle4-medium border-b-magenta text-magenta' : ''}`}
            onClick={() => setView('freelancer')}
          >
            Freelancer
          </p>
        </div>

        <div>
          <p className='body1-medium'>
            Total proyecto: {project.with_currency ? 'USD' : '$'}
            {formatDecimal(project.price.total)}
          </p>

          <div className={`border  p-4 rounded-lg ${view === 'client' ? 'border-blue-700' : 'border-magenta'}`}>
            {view === 'client' && <ClientPayments {...props} setDebs={setDebs} dialogToggle={dialog.toggle} />}
            {view === 'freelancer' && <FreelancerPayments {...props} setDebs={setDebs} dialogToggle={dialog.toggle} />}
          </div>

          <AppDialog
            open={dialog.isOpen}
            title={`Pagos de ${view === 'client' ? 'clientes' : 'freelancers'}`}
            handleClose={dialog.toggle}
          >
            <form onSubmit={form.handleSubmit(saveOrEditOrDelete)}>
              <PaymentsActions requestAction={dialog.action} dialogData={dialog.data} form={form} debs={debs} />

              <Divider className='mt-4' />
              <Box display='flex' justifyContent='flex-end' mt={2}>
                <Button variant='contained' className='mr-4' onClick={closeDialog}>
                  Cancelar
                </Button>
                <Button variant='contained' color='primary' type='submit'>
                  Aceptar
                </Button>
              </Box>
            </form>
          </AppDialog>
        </div>
      </Box>
    </>
  )
}

export default ProjectPayments
