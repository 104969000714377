import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { IFreelancer } from '../../models/IFreelancer'

import FormUploadButton from '../FormUploadButton/FormUploadButton'

import { Box, Grid } from '@material-ui/core'

type TFreelancersFormFiles = {
  form: UseFormReturn<any>
  freelancer?: IFreelancer | undefined
  isDisabledFields?: boolean
  asFreelancerFindie?: boolean
  asPostulation?: boolean
}

const FreelancersFormFiles: React.FC<TFreelancersFormFiles> = (props) => {
  const [cvFiles, setCvFiles] = useState({ fileToDownloadName: '', fileToDownload: '' })
  const [portfolioFiles, setPortfolioFiles] = useState({ fileToDownloadName: '', fileToDownload: '' })

  useEffect(() => {
    if (!props.freelancer) return

    Object.entries(props.freelancer).forEach(([key, value]) => {
      if (!['cv', 'portfolio'].includes(key)) return

      if (key === 'cv') return setCvFiles({ fileToDownloadName: value.file_name, fileToDownload: value.url })
      if (key === 'portfolio') return setPortfolioFiles({ fileToDownloadName: value.file_name, fileToDownload: value.url })
      props.form.setValue(key, value)
    })
  }, [props.freelancer])

  if (!props?.freelancer) return null
  return (
    <Box>
      <Grid container spacing={2} className='mt-2'>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <FormUploadButton
                className='mr-20'
                form={props.form}
                label='Curriculum'
                fileUrl='cv.url'
                fileName='cv.file_name'
                accept='.pdf'
                isDisabled={props.isDisabledFields}
                asPostulation={props.asPostulation}
                freelancerFiles={cvFiles}
                setFreelancerFiles={setCvFiles}
              />
            </Grid>
            <Grid item xs={4}>
              {(props.asFreelancerFindie || props.asPostulation) && props.freelancer.portfolio.file_name && (
                <div>
                  <FormUploadButton
                    className='mr-20'
                    form={props.form}
                    label='Portafolio'
                    fileUrl='portfolio.url'
                    fileName='portfolio.file_name'
                    accept='.pdf'
                    isDisabled={props.isDisabledFields}
                    asPostulation={props.asPostulation}
                    freelancerFiles={portfolioFiles}
                    setFreelancerFiles={setPortfolioFiles}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FreelancersFormFiles
