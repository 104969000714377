import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import useSwitchOnOff from '../../../customHooks/useSwitchOnOff'
import { IUser, TAvatarStyle } from '../../../models/IUser'
import { useUpdateUser } from '../../../customHooks/request/userQuery'
import useRequestAlert from '../../../customHooks/useRequestAlert'

import LayoutAvatar from '../../../shared/LayoutAvatar/LayoutAvatar'

import { Box, Button } from '@material-ui/core'
import InputField from '../../../assets/UIkit/Forms/InputField'
import useUser from '../../../customHooks/useUser'
import useLocalStorage from '../../../customHooks/useLocalStorage'
import { useHistory } from 'react-router-dom'
import routes from '../../../constants/routes'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { IconButton } from '@material-ui/core'

const avatarDots: { bgColor: string; value: TAvatarStyle }[] = [
  { bgColor: 'bg-yellow', value: 'yellow' },
  { bgColor: 'bg-strong-rose', value: 'strong-rose' },
  { bgColor: 'bg-magenta', value: 'magenta' },
  { bgColor: 'bg-cyan', value: 'cyan' },
  { bgColor: 'bg-golden', value: 'golden' },
]

const AdminProfileInfo: React.FC = () => {
  const { user } = useUser()
  const storage = useLocalStorage('locale', '')
  const navigate = useHistory().push
  const form = useForm({
    defaultValues: {
      name: user?.name ?? '',
      last_name: user?.last_name ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      password: '',
      repeat_password: '',
    },
  })
  const putAdminMutation = useUpdateUser()
  useRequestAlert(putAdminMutation)
  const [selectedAvatar, setSelectedAvatar] = useState<TAvatarStyle>(user?.avatar_style ?? 'yellow')
  const [passwordMessage, setPasswordMessage] = useState<string>('')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const formField = form.watch

  const editAdminProfile: SubmitHandler<IUser> = (data) => {
    const body = { ...data, avatar_style: selectedAvatar }
    putAdminMutation.mutate(
      { body, id: user?._id ?? '' },
      {
        onSuccess: () => {
          if (formField('password')) {
            setPasswordMessage('Como cambiaste la contraseña, es necesario que inicies sesión nuevamente.')
            storage.removeItem()
            setTimeout(() => {
              navigate(routes.auth.login)
            }, 4000)
          }
        },
      }
    )
  }

  return (
    <form onSubmit={form.handleSubmit(editAdminProfile)}>
      <p className='microcopy'>Perfil y cuenta</p>

      <div className='grid grid-cols-12 mt-4 gap-12'>
        <div className='lg:col-span-3 md:col-span-12 flex flex-col justify-center items-center'>
          <LayoutAvatar size='large' variant={selectedAvatar} name={user?.name ?? ''} lastName={user?.last_name ?? ''} />
          <div className='flex flex-wrap mt-4'>
            {avatarDots.map((dot, index) => {
              return (
                <div
                  key={dot.value + index}
                  className={`${dot.bgColor} ${index === avatarDots.length - 1 ? 'mr-0' : 'mr-2'} w-6 rounded-3xl cursor-pointer`}
                  style={{ height: 21 }}
                  onClick={() => setSelectedAvatar(dot.value)}
                />
              )
            })}
          </div>
        </div>
        <div className='lg:col-span-7 md:col-span-8 grid grid-cols-12 gap-4'>
          <p className='col-span-12 microcopy text-blue -mb-8 h-8'>{passwordMessage}</p>
          <div className='col-span-6'>
            <InputField
              name='name'
              label='Nombre'
              options={{ required: 'Este campo es requerido' }}
              inputProps={{ className: 'w-full', placeholder: 'Ingresa tu nombre' }}
              form={form}
            />
          </div>
          <div className='col-span-6'>
            <InputField
              name='last_name'
              label='Apellido'
              options={{ required: 'Este campo es requerido' }}
              inputProps={{ className: 'w-full', placeholder: 'Ingresa tu apellido' }}
              form={form}
            />
          </div>
          <div className='col-span-6'>
            <InputField
              name='email'
              label='Email'
              options={{ required: 'Este campo es requerido' }}
              inputProps={{
                className: 'w-full',
                placeholder: 'Ingresa tu email',
              }}
              form={form}
            />
          </div>
          <div className='col-span-6'>
            <InputField
              name='phone'
              label='Teléfono'
              options={{ required: 'Este campo es requerido' }}
              inputProps={{ className: 'w-full', placeholder: 'Ingresa tu teléfono' }}
              form={form}
            />
          </div>
          <div className='col-span-6 relative'>
            <InputField
              name='password'
              label='Contraseña'
              inputProps={{
                className: 'w-full',
                placeholder: 'Ingresa tu nueva contraseña',
                type: isShowPassword ? 'text' : 'password',
              }}
              options={{
                required: 'Este campo es requerido',
                validate: () => {
                  if (formField('repeat_password') !== formField('password')) {
                    return 'Las contraseñas deben ser iguales'
                  }
                },
              }}
              form={form}
            />
            {isShowPassword ? (
              <IconButton
                className='absolute z-10 p-0'
                style={{ top: 'calc(50% - 4px)', right: '3%' }}
                onClick={() => setIsShowPassword(false)}
              >
                <VisibilityOffIcon className='text-gray-300' />
              </IconButton>
            ) : (
              <IconButton
                className='absolute z-10 p-0'
                style={{ top: 'calc(50% - 4px)', right: '3%' }}
                onClick={() => setIsShowPassword(true)}
              >
                <VisibilityIcon className='text-gray-300' />
              </IconButton>
            )}
          </div>
          <div className='col-span-6 relative'>
            <InputField
              name='repeat_password'
              label='Confirmar contraseña'
              inputProps={{
                type: isShowPassword ? 'text' : 'password',
                className: 'w-full',
                placeholder: 'Conforma tu contraseña',
              }}
              options={{
                required: 'Este campo es requerido',
                validate: () => {
                  if (formField('repeat_password') !== formField('password')) {
                    return 'Las contraseñas deben ser iguales'
                  }
                },
              }}
              form={form}
            />
            {isShowPassword ? (
              <IconButton
                className='absolute z-10 p-0'
                style={{ top: 'calc(50% - 4px)', right: '3%' }}
                onClick={() => setIsShowPassword(false)}
              >
                <VisibilityOffIcon className='text-gray-300' />
              </IconButton>
            ) : (
              <IconButton
                className='absolute z-10 p-0'
                style={{ top: 'calc(50% - 4px)', right: '3%' }}
                onClick={() => setIsShowPassword(true)}
              >
                <VisibilityIcon className='text-gray-300' />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      <Box display='flex' justifyContent='flex-end' position='absolute' bottom={50} right={50}>
        <Button variant='contained' color='primary' type='submit'>
          Guardar cambios
        </Button>
      </Box>
    </form>
  )
}

export default AdminProfileInfo
