import { useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import endpoints from '../../constants/endpoints'
import { IResponse } from '../../models/IRequest'
import { ISkill } from '../../models/ISkill'
import { apiClient } from '../../utils/ApiClient'

const endpoints2 = {
  skills: (queryParams: string) => `skills?${queryParams}`,
}

export const useSkillListQuery = (props?: { filters: unknown; enabled?: boolean }) => {
  const endpoint = endpoints2.skills(props?.filters ? `filters=${JSON.stringify(props.filters)}` : '')
  const skillsQuery = useQuery([endpoint, props?.filters], () => apiClient.get<IResponse<ISkill>>({ endpoint }), {
    select: (data) => data.data,
    enabled: props?.enabled === undefined ? true : props.enabled,
  })
  return skillsQuery
}

//Old structure.......................................
export const useSkillsQuery = (filters: { category: string | undefined }, enabled?: boolean) => {
  const skillsQuery = useQuery(
    [`all_${endpoints.skills}`, filters],
    () => apiClient.getSkillsByCategory(JSON.stringify(filters)),
    {
      select: (data) => data.data,
      keepPreviousData: false,
      cacheTime: 0,
      enabled: enabled ?? true,
    }
  )
  return skillsQuery
}

export const useSkillQuery = (id: string) => {
  const skillQuery = useQuery(`one_${endpoints.skills}`, () => apiClient.getSkill(id))
  return skillQuery
}

export const usePostSkillMutation = () => {
  const mutation = useMutation(`post_${endpoints.skill}`, apiClient.postSkill)
  return mutation
}

export const usePutSkillMutation = () => {
  const mutation = useMutation(`put_${endpoints.skill}`, apiClient.putSkill)
  return mutation
}

export const useDeleteSkillMutation = () => {
  const mutation = useMutation(`delete_${endpoints.skill}`, apiClient.deleteSkill)
  return mutation
}

export const useRefreshSkills = (mutation: any) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    mutation.isSuccess && queryClient.refetchQueries(`all_${endpoints.skill}`)
  }, [mutation.isSuccess])
}
