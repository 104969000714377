import { TView } from '../../context/WebSite/CurrentViewContext'
import TextSlider from './TextSlider'

type TProductsSlider = {
  view?: TView
  className?: string
}

const ProductsSlider = (props: TProductsSlider) => {
  return (
    <TextSlider
      {...props}
      text={`Logo e Identidad • Logotipos • Papelería corporativa • Identidad gráfica para RRSS • Tarjeta de visita • Manual de marca • Tipografía • Señalética        Web y App • Diseño UX • Diseño UI • Diseño sitios web • Rediseño sitios web • Diseño blog • Tema para Wordpress • Landing page • Iconos o botones • Banners • Header sitio web • Diseño App        Publicidad y negocios • Afiches • Gigantografías • Pendón • Newsletter • Carátula de podcast • Cartones de RRSS • Plantilla PowerPoint • Plantilla Word • Folletería • Infografía        Ilustración y animación • Ilustración o gráficos • Ilustración editorial • Ilustración infantil • Ilustración de moda • Ilustración para sitio web • Personaje o mascota • Pictogramas • Patrones • Animación 2D • Animación 3D • Animación de dibujos • Animación stop motion • Animación videojuegos • Motion graphics        Packaging • Etiqueta vino • Etiqueta cerveza • Etiqueta producto • Etiqueta alimentos • Packaging de alimentos • Packaging de productos • Caja        Editorial • Diagramación libro • Diseño interior libro • Diseño publicación digital • Diagramación periódico • Portada libro • Portada revista • Portada eBook • Infografía • Tipografía • Tarjetas • Invitaciones`}
    />
  )
}

export default ProductsSlider
