import { FC } from 'react'
import HelpActivity from '../../components/ClientUserProfile/ClientHelp/HelpActivity'
import HelpNewProject from '../../components/ClientUserProfile/ClientHelp/HelpNewProject'
import HelpPayment from '../../components/ClientUserProfile/ClientHelp/HelpPayment'
import HelpProjectStates from '../../components/ClientUserProfile/ClientHelp/HelpProjectStates'
import HelpSOS from '../../components/ClientUserProfile/ClientHelp/HelpSOS'

import HelpUserProfile from '../../shared/HelpUserProfile/HelpUserProfile'
import HelpTermsAndConditions from '../../components/ClientUserProfile/ClientHelp/HelpTermsAndConditions'
import { useTranslation } from 'react-i18next'

const ClientHelp: FC = () => {
  const { t } = useTranslation()
  const helpList = [
    {
      topic: `${t('CLIENT_HELP.HELP_LIST.CREATE_PRO')}`,
      content: <HelpNewProject />,
    },
    {
      topic: `${t('CLIENT_HELP.HELP_LIST.PROJECT_STATUS')}`,
      content: <HelpProjectStates />,
    },
    {
      topic: `${t('CLIENT_HELP.HELP_LIST.ACTIVITY')}`,
      content: <HelpActivity />,
    },
    {
      topic: `${t('CLIENT_HELP.HELP_LIST.PAYMENTS')}`,
      content: <HelpPayment />,
    },
    {
      topic: `${t('CLIENT_HELP.HELP_LIST.HELP')}`,
      content: <HelpSOS />,
    },
    {
      topic: `${t('CLIENT_HELP.HELP_LIST.TERMS_CONDITIONS')}`,
      content: <HelpTermsAndConditions />,
    },
  ]

  return <HelpUserProfile helpList={helpList} />
}

export default ClientHelp
