import { FC } from 'react'
import FiButton from '../../../assets/UIkit/FiButton'
import { IFreelancer } from '../../../models/IFreelancer'
import FindieIsotype from '../../Brand/Isotype/FindieIsotype'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import { withStyles, Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

type TCandiateCard = {
  isSelected: boolean
  selectCandidate: (candidate: IFreelancer) => void
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const CandidateCard: FC<IFreelancer & TCandiateCard> = (props) => {
  const { isSelected, selectCandidate, ...candidate } = props

  return (
    <div className='relative shadow-md rounded-xl p-4'>
      <img className='w-40 h-40 mx-auto mt-2' src={props.avatar.url} alt='avatar' />
      {props.featured_status.has_feartued_icon && <FindieIsotype variant='golden' className='absolute top-4 right-2' />}
      <div className='mt-1'>
        <div className='flex items-center justify-between'>
          <p className='subtitle5-medium flex items-center'>
            <span className={`h2 ${props.is_available_to_work ? 'text-soft-green' : 'text-red'}`}>•</span>
            {props.name} {props.lastName}
          </p>
          {props.current_projects.length > 0 && (
            <LightTooltip placement='top' title={`${props.name} está trabajando en otro proyecto.`}>
              <WorkOutlineIcon className='text-blue mb-1' />
            </LightTooltip>
          )}
        </div>
        <p className='body2-regular -mt-4 ml-4'>{props.college_degree}</p>
      </div>
      <FiButton
        className='w-full mt-4'
        theme={props.isSelected ? 'danger' : 'secondary'}
        onClick={() => props.selectCandidate(candidate)}
      >
        {props.isSelected ? 'Remover' : 'Selccionar'}
      </FiButton>
      <FiButton
        className='w-full mt-1'
        variant='outlined'
        theme='secondary'
        onClick={() => window.open(`${window.location.origin}/perfil/${props._id}`)}
      >
        Ver perfil
      </FiButton>
    </div>
  )
}

export default CandidateCard
