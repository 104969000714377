import Pillbox from '../Pillbox/Pillbox'

type TFreelancerAvailabilityPillBox = {
  isAvailableToWork: boolean
  isWorking: boolean
  variant?: 'contained' | 'outlined'
  className?: string
}

const FreelancerAvailabilityPillBox = (props: TFreelancerAvailabilityPillBox) => {
  const { variant = 'contained' } = props

  const setProjectStatus = () => {
    if (props.isWorking) {
      return { title: 'En proyecto', styles: variant === 'contained' ? 'bg-blue' : 'border-blue text-blue' }
    }
    if (props.isAvailableToWork) {
      return { title: 'Disponible', styles: variant === 'contained' ? 'bg-soft-green' : 'border-soft-green text-soft-green' }
    }
    return { title: 'No disponible', styles: variant === 'contained' ? 'bg-red' : 'border-red text-red' }
  }

  return (
    <Pillbox className={`py-0 inline-block ${props.className} ${setProjectStatus().styles}`}>{setProjectStatus().title}</Pillbox>
  )
}

export default FreelancerAvailabilityPillBox
