import { FC } from 'react'
import Box from '@material-ui/core/Box'
import { NavLink, Route, Switch } from 'react-router-dom'
import routes from '../../constants/routes'
import useUser from '../../customHooks/useUser'
import FreelancerBio from '../../components/FreelanceUserProfile/FreelancerBook/FreelancerBio'
import { useOneFreelancerQuery, useUpdateFreelancer } from '../../customHooks/request/freelancersQuery'
import FreelancerAvatar from '../../components/FreelanceUserProfile/FreelancerBook/FreelancerAvatar'
import useRequestAlert from '../../customHooks/useRequestAlert'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { IFreelancer } from '../../models/IFreelancer'
import { IPut } from '../../models/IRequest'
import FreelancerExperiences from '../../components/FreelanceUserProfile/FreelancerBook/FreelancerExperiences'
import FreelancerEducation from '../../components/FreelanceUserProfile/FreelancerBook/FreelancerEducation'
// import FreelancerClients from '../../components/FreelanceUserProfile/FreelancerBook/FreelancerClients'
import FreelancerPorfolio from '../../components/FreelanceUserProfile/FreelancerBook/FreelancerPorfolio'
import { useMediaQuery } from '@material-ui/core'
import { projectDetailColumns } from '../../utils/helpers'

export type TFreelancerBookSections = {
  freelancerQuery: UseQueryResult<IFreelancer, unknown>
  updateFreelancer: UseMutationResult<IFreelancer, unknown, IPut<Partial<IFreelancer>>, unknown>
}

const FreelancerBook: FC = () => {
  const { user } = useUser()
  const isMobile = useMediaQuery('(max-width: 430px)')
  const isDesktop = useMediaQuery('(min-width: 825px)')
  const freelancerQuery = useOneFreelancerQuery({ id: user?.profile_id ?? '', enabled: Boolean(user?.profile_id) })
  const updateFreelancer = useUpdateFreelancer()
  useRequestAlert(updateFreelancer)

  const defaultProps = { freelancerQuery, updateFreelancer }

  if (!freelancerQuery.isSuccess) return null
  return (
    <div>
      <Box display='grid' gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)} className='gap-8'>
        <div className='border-t border-black pt-2'>
          <p className='subtitle6-regular text-black'>Freelancer</p>
        </div>

        <div className='flex gap-12 pt-2 hide-scroll-bar slider'>
          <div className='slider-item subtitle6-regular'>
            <NavLink
              to={routes.freelancerUserProfile.book.bio}
              className='text-black'
              activeClassName='subtitle6-medium border-b border-black'
            >
              Bio
            </NavLink>
          </div>
          <div className='subtitle6-regular slider-item'>
            <NavLink
              to={routes.freelancerUserProfile.book.experiences}
              className='text-black'
              activeClassName='subtitle6-medium border-b border-black'
            >
              XP destacadas
            </NavLink>
          </div>
          <div className='subtitle6-regular slider-item'>
            <NavLink
              to={routes.freelancerUserProfile.book.education}
              className='text-black'
              activeClassName='subtitle6-medium border-b border-black'
            >
              Educación
            </NavLink>
          </div>
          <div className='subtitle6-regular slider-item'>
            <NavLink
              to={routes.freelancerUserProfile.book.porfolio}
              className='text-black'
              activeClassName='subtitle6-medium border-b border-black'
            >
              Portafolio
            </NavLink>
          </div>
          <div className='subtitle6-regular slider-item'>
            {/* <NavLink
              to={routes.freelancerUserProfile.book.clients}
              className='text-black'
              activeClassName='subtitle6-medium border-b border-black'
            >
              Clientes
            </NavLink> */}
          </div>
        </div>
      </Box>

      <Box display='grid' gridTemplateColumns={projectDetailColumns(isDesktop, isMobile)} className='mt-6 gap-8'>
        <div>
          <FreelancerAvatar {...defaultProps} />
        </div>
        <div>
          <Switch>
            <Route path={routes.freelancerUserProfile.book.bio}>
              <FreelancerBio {...defaultProps} />
            </Route>
            <Route path={routes.freelancerUserProfile.book.experiences}>
              <FreelancerExperiences {...defaultProps} />
            </Route>
            <Route path={routes.freelancerUserProfile.book.education}>
              <FreelancerEducation {...defaultProps} />
            </Route>
            <Route path={routes.freelancerUserProfile.book.porfolio}>
              <FreelancerPorfolio {...defaultProps} />
            </Route>
            {/* <Route path={routes.freelancerUserProfile.book.clients}>
              <FreelancerClients {...defaultProps} />
            </Route> */}
          </Switch>
        </div>
      </Box>
    </div>
  )
}

export default FreelancerBook
