import { useContext } from 'react'
import routes from '../../constants/routes'
import { CurrentViewContext } from '../../context/WebSite/CurrentViewContext'
import { Link, useHistory } from 'react-router-dom'
import WebSiteStructure from './WebSiteStructure'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../../shared/Language/LanguageSelector'

const Header: React.FC = () => {
  const { view } = useContext(CurrentViewContext)
  const {
    location: { pathname },
  } = useHistory()
  const { t } = useTranslation()

  return (
    <WebSiteStructure>
      <div className={`flex ${view.textColor}`}>
        <Link to={routes.web_site.about_us} className='lg:block hidden mr-11 hover:italic-style'>
          <p
            className={`${pathname === routes.web_site.about_us ? 'buttontext2-semibold' : 'buttontext2-medium'} ${
              view.textColor
            }`}
          >
            {t('HEADER.aboutUs')}
          </p>
        </Link>
        <Link to={routes.web_site.why_findie} className='lg:block hidden mr-11 hover:italic-style'>
          <p
            className={`${pathname === routes.web_site.why_findie ? 'buttontext2-semibold' : 'buttontext2-medium'} ${
              view.textColor
            }`}
          >
            {t('HEADER.whyFindie')}
          </p>
        </Link>
        <Link to={routes.web_site.freelancers_profiles} className='lg:block hidden mr-11 hover:italic-style'>
          <p
            className={`${pathname === routes.web_site.freelancers_profiles ? 'buttontext2-semibold' : 'buttontext2-medium'} ${
              view.textColor
            }`}
          >
            {t('HEADER.talents')}
          </p>
        </Link>
        <Link to={routes.web_site.our_freelancers} className='lg:block hidden mr-11 hover:italic-style'>
          <p
            className={`${pathname === routes.web_site.our_freelancers ? 'buttontext2-semibold' : 'buttontext2-medium'} ${
              view.textColor
            }`}
          >
            {t('HEADER.applyHere')}
          </p>
        </Link>
        {/* <Link to={routes.web_site.bookStore} className='lg:block hidden mr-11 hover:italic-style'>
          <p className={`buttontext2-medium ${view.textColor}`}>The Good</p>
        </Link> */}
        <div className='lg:block hidden mr-11 hover:italic-style'>
          <LanguageSelector svgColor={view.textColor} />
        </div>
      </div>
    </WebSiteStructure>
  )
}

export default Header
