import { Box } from '@material-ui/core'
import { FC } from 'react'
import { IProject } from '../../models/IProject'
import Pillbox from '../Pillbox/Pillbox'
import { created_at as today, projectStatusDict } from '../../utils/helpers'
import CheckIcon from '@material-ui/icons/Check'
import useWorkPlanVersion from '../../customHooks/useWorkPlanVersion'
import { useFreelancerIsDone } from '../../customHooks/request/projectsQuery'
import useRequestAlert from '../../customHooks/useRequestAlert'
import useUser from '../../customHooks/useUser'

type TProjectActivity = {
  entity: 'freelancer' | 'client'
}

const setPillboxColor = (status: string) => {
  const dispatch: Record<string, string> = {
    in_progress: 'bg-cyan',
    paused: 'bg-strong-rose',
  }
  return dispatch[status] ? dispatch[status] : dispatch.in_progress
}

const ProjectActivity: FC<TProjectActivity & IProject> = (props) => {
  const { user, isFinishProjectButton, setIsFinishProjectButton } = useUser()

  const freelancerIsDoneMutation = useFreelancerIsDone()
  useRequestAlert(
    freelancerIsDoneMutation,
    undefined,
    undefined,
    undefined,
    'Le notificaremos al Cliente que terminaste el proyecto.',
    undefined,
    10000
  )

  const hasFirstPayment = props.payments.some((payment) => payment.entity === 'client')
  const isProjectKickOff = today >= props.staff[0].work_plan.start_date
  const selectedWorkPlanVersion = useWorkPlanVersion(props.staff[0].work_plan)

  const hasLastPayment = () => {
    if (!selectedWorkPlanVersion) return false

    const paymentsAmount = props.payments
      .filter((payment) => payment.entity === props.entity)
      .reduce((acum, payment) => {
        return (acum = acum + payment.amount)
      }, 0)

    return props.entity === 'client'
      ? paymentsAmount >= props.price.total
      : paymentsAmount >= selectedWorkPlanVersion.price.after_fee
  }

  const sendFreelancerIsDone = () => {
    if (!user) return

    const body = {
      freelancerName: `${user.name} ${user.last_name}`,
      projectTitle: props.brief.title,
      projectId: props._id,
      clientId: props.client._id,
    }
    freelancerIsDoneMutation.mutate({ body }, { onSuccess: () => setIsFinishProjectButton(false) })
  }

  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='col-span-12 flex justify-between items-center'>
        <div>
          <Pillbox className={`py-1 ${setPillboxColor(props.project_status)}`}>{projectStatusDict(props.project_status)}</Pillbox>
        </div>{' '}
        {props.entity === 'freelancer' &&
          props.project_status === 'in_progress' &&
          isProjectKickOff &&
          !hasLastPayment() &&
          isFinishProjectButton && (
            <span className='button-container'>
              <button
                className='custom-button py-3 px-6 custom-button-bg buttontext4-medium transition-all duration-300 ease-in'
                onClick={sendFreelancerIsDone}
              >
                Entregar proyecto
              </button>
            </span>
          )}
      </div>

      <div className='lg:col-span-6 col-span-12'>
        <ActivityItem title='Depósito inicial' isActive={hasFirstPayment} />
        <ActivityItem title='Kick off' isActive={isProjectKickOff} />
        <ActivityItem title='Pago final' isActive={hasLastPayment()} />
      </div>
    </div>
  )
}

export default ProjectActivity

const ActivityItem: FC<{ isActive: boolean; title: string }> = (props) => {
  return (
    <Box display='grid' gridTemplateColumns='65px 1fr' alignItems='center' className='mt-6'>
      <div className={`w-10 h-10 rounded-full total-center ${props.isActive ? 'bg-blue' : 'border border-black'}`}>
        <CheckIcon className='text-white' />
      </div>
      <p className='buttontext2-medium'>{props.title}</p>
    </Box>
  )
}
