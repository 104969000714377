import { FC } from 'react'
import { TView } from '../../context/WebSite/CurrentViewContext'

type TSpecialText = {
  styles: TView
  noMarginTop?: boolean
  containerClassName?: string
  textClassName?: string
  bgColor?: string
}

const SpecialText: FC<TSpecialText> = (props) => {
  const { bgColor = 'transparent' } = props

  return (
    <div
      style={{ backgroundColor: `var(--${bgColor})` }}
      className={`relative w-full z-30 py-4 md:mt-0 md:my-12 my-8 lg:px-0 md:px-2 px-4 ${props.containerClassName ?? ''} ${
        props.noMarginTop ? 'mt-0' : 'mt-32'
      } ${props.styles.textColor}`}
    >
      <p
        className={`formtext1-regular 2xl:w-7/12 md:w-9/12 w-full  ${props.textClassName ?? ''}`}
        style={{ textAlign: 'center' }}
      >
        {props.children}
      </p>
    </div>
  )
}

export default SpecialText
