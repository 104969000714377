import { Dispatch, SetStateAction } from 'react'
import AppDialog from '../../assets/UIkit/AppDialog'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import card from '../../assets/images/userProfile/card.svg'
import { Button } from '@material-ui/core'
import FiButton from '../../assets/UIkit/FiButton'

type TWelcomeModal = {
  title: string
  subtitle: string
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
} & (
  | { withCallToAction: false }
  | { withCallToAction: true; callToAction: () => void; callToActionText: string; closeText: string }
)

const WelcomeModal = (props: TWelcomeModal) => {
  const { withCallToAction } = props

  const closeModal = () => {
    props.setIsModalOpen(false)
    props.onClose && props.onClose()
  }

  return (
    <AppDialog
      open={props.isModalOpen}
      maxWidth='sm'
      title=''
      containerClassName='gradient-box gradient-color'
      onClose={() => props.setIsModalOpen(false)}
      noHeader
    >
      <div className='grid grid-cols-12 gap-4 md:p-4 p-0 relative overflow-hidden'>
        <article className='col-span-8'>
          <h4>{props.title}</h4>
          <p className='subtitle4-regular mt-8 mb-16'>{props.subtitle}</p>
        </article>

        <img className='col-span-4 md:w-8/12 w-full md:mb-0 mb-8 self-end justify-self-center' src={card} alt='sobre' />

        <FiIcons name='circle_close_black' className='absolute -top-2 -right-2 cursor-pointer' onClick={closeModal} />
        {withCallToAction ? (
          <div className='col-span-12 flex w-full justify-evenly'>
            <FiButton
              onClick={() => {
                closeModal()
                props.callToAction()
              }}
              theme='secondary'
              variant='contained'
              size='large'
            >
              {props.callToActionText}
            </FiButton>
            <FiButton onClick={closeModal} theme='light' size='large'>
              {props.closeText}
            </FiButton>
          </div>
        ) : null}
      </div>
    </AppDialog>
  )
}

export default WelcomeModal
