import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useCreateProjectClient } from '../../../customHooks/request/clientsQuery'
import routes from '../../../constants/routes'
import { IFormStep, IStorage } from '../../../models/IFormStep'
import FiButton from '../../../assets/UIkit/FiButton'
import InputField from '../../../assets/UIkit/Forms/InputField'
import SpecialSelect from '../../../shared/SpecialSelect.tsx/SpecialSelect'
import { IFreelancer } from '../../../models/IFreelancer'
import { useTranslation } from 'react-i18next'
import { created_at } from '../../../utils/helpers'
import { useSetLoader } from '../../../customHooks/useSetLoader'
import UserProfileContainer from './UserProfileContainer'
import { useWhoIAmFetch } from '../../../customHooks/request/authQuery'
import useUser from '../../../customHooks/useUser'
import FormError from '../../../assets/UIkit/Forms/FormError'

type TClientStepFive = {
  clientStorage: IStorage
  freelancerId: string
}

const ClientStepFive: React.FC<IFormStep & TClientStepFive> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const clientStorage = props.clientStorage
  const fetchWhoIAm = useWhoIAmFetch()
  const { setIsUserLogged, setUser } = useUser()
  const loader = useSetLoader()

  const formDefaultValues = {
    companyName: clientStorage.item.companyName ?? '',
    job_title: clientStorage.item.job_title ?? '',
    webSite: clientStorage.item.webSite ?? '',
    industry: clientStorage.item.industry ?? '',
    company_size: clientStorage.item.industry ?? [],
    is_foreign: '',
  }
  const form = useForm({ defaultValues: formDefaultValues })

  const createProjectClient = useCreateProjectClient()

  const [selectedIndustry, setSelectedIndustry] = useState<string>('')
  const [selectedCompanySize, setSelectedCompanySize] = useState<string>('')
  const projectStorage = props.storage.item

  const required = t('clientSubscriptionForm.stepFive.FormStepContainer.inputEmail.required')
  const placeholderCompanySize = t('clientSubscriptionForm.stepFive.FormStepContainer.inputCompanySize.placeholder')
  const placeholderJobTitle = t('clientSubscriptionForm.stepFive.FormStepContainer.inputJobTitle.placeholder')
  const placeholderIndustry = t('clientSubscriptionForm.stepFive.FormStepContainer.inputIndustry.placeholder')
  const placeholderCompanyName = t('clientSubscriptionForm.stepFive.FormStepContainer.inputCompanyName.placeholder')
  const payInChile = t('clientSubscriptionForm.stepFive.EXP_WORK_IN_CHILE')
  const companySize_Options: any = t('clientSubscriptionForm.COMPANY_SIZES', { returnObjects: true })
  const industry_Options: any = t('GLOBAL.INDUSTRY_OPTIONS', { returnObjects: true })

  const handleColorValidation = (
    field: 'companyName' | 'job_title' | 'webSite' | 'industry' | 'company_size',
    requiredStatus?: 'not_required'
  ) => {
    if (requiredStatus) return ''
    return form.watch(field) ? 'client-success-validation' : 'client-error-validation'
  }

  const createClient: SubmitHandler<FieldValues> = (client) => {
    if (!client.is_foreign) return form.setError('is_foreign', { message: payInChile })
    if (!selectedCompanySize) return form.setError('company_size', { message: required })

    const body: any = {
      project: {
        createdAt: created_at,
        products: projectStorage.products,
        when_start: projectStorage.when_start,
        description: projectStorage.description,
      },
      client: {
        createdAt: created_at,
        name: clientStorage.item.name,
        lastName: clientStorage.item.lastName,
        email: projectStorage.email,
        phone: clientStorage.item.phone,
        companyName: client.companyName,
        job_title: client.job_title,
        company_size: client.company_size,
        is_foreign: !JSON.parse(client.is_foreign),
        industry: client.industry,
        address: { country: clientStorage.item.address.country },
        favorite_freelancer: props.freelancerId === 'paso' ? undefined : (props.freelancerId as unknown as IFreelancer),
      },
    }

    loader.setIsOpen(true)
    createProjectClient.mutate(
      { body },
      {
        onSuccess: async (resp) => {
          window.localStorage.setItem('locale', JSON.stringify(resp.token))
          const { response: userLogged } = await fetchWhoIAm()
          setUser(userLogged)
          setIsUserLogged(true)

          clientStorage.removeItem()
          props.storage.removeItem()
          loader.setIsOpen(false)
          history.push(`${routes.clientUserProfile.projects.detail}/${resp.projectId}/brief`)
        },
        onError: (error: any) => {
          snackbar.enqueueSnackbar(error.json.error, { variant: 'error' })
          loader.setIsOpen(false)
        },
      }
    )
  }

  useEffect(() => {
    form.clearErrors()
  }, [selectedCompanySize])

  useEffect(() => {
    let inputFields = { ...clientStorage.item }
    Object.entries(form.getValues()).forEach(([key, value]) => {
      inputFields = { ...inputFields, [key]: value }
    })
    clientStorage.setItem(inputFields)
  }, [
    form.watch('companyName'),
    form.watch('job_title'),
    form.watch('webSite'),
    form.watch('industry'),
    form.watch('company_size'),
  ])

  return (
    <UserProfileContainer>
      <div className='relative lg:w-6/12 md:w-10/12 w-full h-full flex items-center'>
        <div>
          <h2 className='xl:mb-16 lg:mb-16  md:mb-12 mb-6 text-black'>
            {t('clientSubscriptionForm.stepFive.FormStepContainer.title')}
          </h2>
          <form onSubmit={form.handleSubmit(createClient)}>
            <InputField
              name={`companyName`}
              className='md:mt-8 mt-3'
              inputProps={{
                className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                  'companyName'
                )}`,
                placeholder: placeholderCompanyName,
              }}
              options={{ required: required }}
              form={form}
            />
            <InputField
              name={`job_title`}
              className='md:mt-8 mt-3'
              inputProps={{
                className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                  'job_title',
                  'not_required'
                )}`,
                placeholder: placeholderJobTitle,
              }}
              form={form}
            />
            <SpecialSelect
              options={industry_Options}
              title={placeholderIndustry}
              name='industry'
              form={form}
              selectedOption={selectedIndustry}
              setSelectedOption={setSelectedIndustry}
            />
            <SpecialSelect
              options={companySize_Options}
              title={placeholderCompanySize}
              name='company_size'
              form={form}
              selectedOption={selectedCompanySize}
              setSelectedOption={setSelectedCompanySize}
            />
            <FormError name='company_size' errors={form.formState?.errors} />

            <div className='flex md:flex-row flex-col md:gap-8 gap-2 items-center md:mt-4 mt-6'>
              <p className='buttontext4-regular'>
                {t('clientSubscriptionForm.stepFive.TAXES_IN_CHILE')}
                <span className='text-blue'>*</span>
              </p>
              <div className='flex items-center gap-4'>
                <div className='flex items-center gap-2'>
                  <InputField
                    id='is_foreign_true'
                    name='is_foreign'
                    className='md:mt-2 mt-1'
                    inputProps={{
                      className: 'no-height',
                      type: 'radio',
                      value: 'true',
                    }}
                    showErrorMessage={false}
                    form={form}
                  />
                  <label htmlFor='is_foreign_true' className='buttontext4-regular cursor-pointer block'>
                    {t('GLOBAL.YES')}
                  </label>
                </div>
                <div className='flex items-center gap-2'>
                  <InputField
                    id='is_foreign_false'
                    name='is_foreign'
                    className='md:mt-2 mt-1'
                    inputProps={{
                      className: 'no-height',
                      type: 'radio',
                      value: 'false',
                    }}
                    showErrorMessage={false}
                    form={form}
                  />
                  <label htmlFor='is_foreign_false' className='buttontext4-regular cursor-pointer block'>
                    No
                  </label>
                </div>
              </div>
            </div>
            <FormError name='is_foreign' errors={form?.formState?.errors} />

            <div className='w-full  text-right md:mt-4 mt-8'>
              <FiButton theme='light' type='submit' className='px-6 py-1'>
                <p className='buttontext4-medium'>{t('GLOBAL.SAVE')}</p>
              </FiButton>
            </div>
          </form>
        </div>
      </div>
    </UserProfileContainer>
  )
}

export default ClientStepFive
