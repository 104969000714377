import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'

type TProductPill = {
  title: string
  className?: string
  handleClick?: () => void
  handleRemove?: () => void
}

const PillBoxWithActions = (props: TProductPill) => {
  return (
    <div className='rounded-full border-blue items-center pl-2 pr-1 flex gap-2'>
      <p className='microcopy'>{props.title}</p>
      {props.handleClick && (
        <div className='py-1'>
          <FiIcons className='cursor-pointer mr-0.5' name='circle_add_blue' onClick={props.handleClick} />
        </div>
      )}
      {props.handleRemove && (
        <div>
          <FiIcons className='cursor-pointer' name='circle_close_blue' onClick={props.handleRemove} />
        </div>
      )}
    </div>
  )
}

export default PillBoxWithActions
