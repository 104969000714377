import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

import InputField from '../../assets/UIkit/Forms/InputField'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { IClient } from '../../models/IClient'
import { IFreelancer } from '../../models/IFreelancer'

type TBankDataForm = {
  form: UseFormReturn<any>
  role?: IClient | IFreelancer | undefined
  isDisabledFields?: boolean
  variant?: 'default' | 'outlined'
}

const bankList = [
  { _id: 1, name: 'Banco de Chile-Edwards-Citibank' },
  { _id: 2, name: 'Banco Internacional' },
  { _id: 3, name: 'Banco Estado' },
  { _id: 4, name: 'Scotiabank' },
  { _id: 5, name: 'BCI - TBANC' },
  { _id: 6, name: 'Corpbanca' },
  { _id: 7, name: 'Bice' },
  { _id: 8, name: 'HSBC' },
  { _id: 9, name: 'Santander' },
  { _id: 10, name: 'Banco ITAU' },
  { _id: 11, name: 'Banco Security' },
  { _id: 12, name: 'Banco Falabella' },
  { _id: 13, name: 'Banco Consorcio' },
  { _id: 14, name: 'Banco Ripley' },
  { _id: 15, name: 'Scotiabank Azul' },
  { _id: 16, name: 'Banco Coopeuch' },
  { _id: 17, name: 'BBVA' },
]
const internationalBankList = [
  { _id: 1, name: 'Paypal' },
  { _id: 2, name: 'Payoneer' },
  { _id: 3, name: 'Mercado pago' },
]

const accountType = [
  { _id: 1, name: 'Corriente' },
  { _id: 2, name: 'Ahorro' },
  { _id: 3, name: 'Vista' },
  { _id: 4, name: 'Rut' },
]

const BankDataForm: React.FC<TBankDataForm> = (props) => {
  useEffect(() => {
    if (!props.role) return

    props.form.setValue('bank_detail.name', props.role?.bank_detail?.name)
    props.form.setValue('bank_detail.dni', props.role?.bank_detail?.dni)
    props.form.setValue('bank_detail.email', props.role?.bank_detail?.email)
    props.form.setValue('bank_detail.bank_name', props.role?.bank_detail?.bank_name)
    props.form.setValue('bank_detail.account_type', props.role?.bank_detail?.account_type)
    props.form.setValue('bank_detail.account_number', props.role?.bank_detail?.account_number)
    props.form.setValue('bank_detail.international_bank_name', props.role?.bank_detail?.international_bank_name)
  }, [props.role])

  return (
    <div className='grid grid-cols-12 gap-4 mt-3'>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='bank_detail.name'
          label='Nombre'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu nombre' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='bank_detail.dni'
          label='Cédula de identidad'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa cédula' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='bank_detail.email'
          label='Email'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu email', type: 'email' }}
          options={{ required: 'Este campo es requerido' }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <SelectField
          name='bank_detail.bank_name'
          label='Banco'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
          form={props.form}
          selectOptions={bankList.map((bank) => {
            return { value: bank._id, label: bank.name }
          })}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <SelectField
          name='bank_detail.account_type'
          label='Tipo de cuenta'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
          form={props.form}
          selectOptions={accountType.map((bank) => {
            return { value: bank.name, label: bank.name }
          })}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <InputField
          name='bank_detail.account_number'
          label='Numero de cuenta'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{
            className: 'w-full',
            disabled: props.isDisabledFields,
            placeholder: 'Ingresa tu numero de cuenta',
          }}
          form={props.form}
        />
      </div>
      <div className='md:col-span-4 col-span-6'>
        <SelectField
          name='bank_detail.international_bank_name'
          label='Plataforma de pago'
          labelClassName='text-gray-500'
          variant={props.variant ?? 'outlined'}
          inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
          form={props.form}
          selectOptions={internationalBankList.map((bank) => {
            return { value: bank._id, label: bank.name }
          })}
        />
      </div>
    </div>
  )
}

export default BankDataForm
