import { FC } from 'react'

type TSectionItem = {
  title: string
  isActive: boolean
  direction?: 'row' | 'col'
  className?: string
  onClick?: () => void
}

const SectionItem: FC<TSectionItem> = (props) => {
  const { direction = 'col' } = props

  return (
    <div
      className={`flex items-center cursor-pointer ${direction === 'col' ? 'flex-col ' : 'flex-row gap-2'} ${props.className}`}
      onClick={props.onClick}
    >
      <h4 className={`text-center whitespace-pre-wrap ${direction === 'col' ? 'order-1' : 'order-2'}`}>{props.title}</h4>
      <div
        className={`w-10 h-10 rounded-full total-center border border-black  ${
          direction === 'col' ? 'order-2 mt-4' : 'order-1'
        } ${props.isActive ? 'bg-blue' : ''}`}
      />
    </div>
  )
}

export default SectionItem
