import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../constants/routes'
import ClientProjectOptions from '../../components/ClientUserProfile/ClientProject/ClientProjectOptions'
import ClientProjectList from '../../components/ClientUserProfile/ClientProject/ClientProjectList'
import ClientNewProject from '../../components/ClientUserProfile/ClientProject/ClientNewProject/ClientNewProject'
import ClientHistoricProjectList from '../../components/ClientUserProfile/ClientProject/ClientHistoricProjectList'
import ClientProjectDetail from '../../components/ClientUserProfile/ClientProject/ClientProjectDetail'
import ClientMyFreelancers from '../../components/ClientUserProfile/ClientProject/ClientMyFreelancers'

const ClientProjects: FC = () => {
  return (
    <Switch>
      <Route path={`${routes.clientUserProfile.projects.detail}/:projectId`} component={ClientProjectDetail} />
      <Route path={`${routes.clientUserProfile.projects.new}/:slide`} component={ClientNewProject} />
      <Route path={routes.clientUserProfile.projects.list} component={ClientProjectList} />
      <Route path={routes.clientUserProfile.projects.historical} component={ClientHistoricProjectList} />
      <Route path={routes.clientUserProfile.projects.myFreelancers} component={ClientMyFreelancers} />
      <Route exact={true} component={ClientProjectOptions} />
    </Switch>
  )
}

export default ClientProjects
