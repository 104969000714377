import chile from '../../assets/images/web/about-us/chile.svg'
import tetri from '../../assets/images/web/about-us/tetri.svg'
import girls from '../../assets/images/web/about-us/girls.svg'
import mountain from '../../assets/images/web/about-us/mountain.svg'
import perfectWork from '../../assets/images/web/about-us/perfect-work.svg'
import blueFace from '../../assets/images/web/about-us/blue-face.svg'
import blackCat from '../../assets/images/web/about-us/black-cat.svg'
import pocker from '../../assets/images/web/about-us/pocker.svg'
import windows from '../../assets/images/web/about-us/windows.svg'
import time from '../../assets/images/web/about-us/time.svg'
import findie from '../../assets/images/web/about-us/findie.svg'
import blackWhite from '../../assets/images/web/about-us/black-white.svg'
import smallFindie from '../../assets/images/web/about-us/small-findie.svg'
import smallBlackWhite from '../../assets/images/web/about-us/small-black-white.svg'

import { Box } from '@material-ui/core'
import FindieGifIsotype from '../../shared/Brand/Isotype/FindieGifIsotype'
import PostulationBanner from '../../shared/PostulationBanner/PostulationBanner'

export const collage = [chile, tetri, girls, mountain, perfectWork, blueFace, blackCat, pocker, windows, time]

export const dontWaitDesktop = [
  <PostulationBanner title='Si esto te hace sentido:' bgColor='web-bg-white' textColor='text-black' freelancerAsTransparent />,
  smallFindie,
  smallBlackWhite,
]

export const dontWaitTabletMobile = [
  <PostulationBanner title='Si esto te hace sentido:' bgColor='web-bg-white' textColor='text-black' freelancerAsTransparent />,
  smallFindie,
  smallBlackWhite,
]
