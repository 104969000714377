import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import routes from '../constants/routes'
import { IAuth } from '../models/IAuth'
import { IRequestError } from '../models/IRequest'
import { useLoginMutation, useWhoIAmFetch } from './request/authQuery'
import { useOneClientQuery } from './request/clientsQuery'
import { useOneFreelancerQuery } from './request/freelancersQuery'
import useLocalStorage from './useLocalStorage'
import { useSetLoader } from './useSetLoader'
import useUser from './useUser'
import { useAuthRedirect } from './useAuthRedirect'

interface LocationState {
  from: { pathname: string }
}

const useAuth = () => {
  const redirect = useAuthRedirect()
  const storage = useLocalStorage('locale', undefined)
  const navigate = useHistory().push
  const location = useLocation<LocationState>()
  const { pathname } = useLocation()
  const { user, isUserLogged, setIsUserLogged, setAuthError, setUser, setFreelancerProfile, setClientProfile } = useUser()
  const loginMutation = useLoginMutation()
  const fetchWhoIAm = useWhoIAmFetch()
  const { refetch: refetchFreelancer } = useOneFreelancerQuery({
    id: user?.profile_id ?? '',
    enabled: false,
  })
  const { refetch: refetchClient } = useOneClientQuery({ id: user?.profile_id ?? '', enabled: false })
  const loader = useSetLoader()

  const isPathnamePage =
    pathname.includes('cpanel') || pathname.includes('perfil-client') || pathname.includes('perfil-freelancer')

  const login = (data: IAuth) => {
    isPathnamePage && loader.setIsOpen(true)
    loginMutation.mutate(
      { body: { email: data.email.toLowerCase().trim(), password: data.password.trim() }, method: 'POST' },
      {
        onSuccess: (resp) => {
          storage.setItem(resp.access_token)
          loader.setIsOpen(false)
          if (redirect.redirectPath?.length > 3) {
            redirect.routeRedirect()
          }
        },
        onError: () => {
          setAuthError('Error: nombre de usuario o contraseña no válida.')
          loader.setIsOpen(false)
        },
      }
    )
  }

  const sendWhoIAm = async () => {
    if (!storage.item) {
      removeCredential()
      const isPlatformPath =
        pathname.includes('cpanel') || pathname.includes('perfil-freelancer') || pathname.includes('perfil-cliente')
      navigate(isPlatformPath ? routes.auth.login : pathname)
      return
    }

    try {
      const { response: userLogged } = await fetchWhoIAm()
      if (userLogged.is_disabled) {
        removeCredential(`Hola ${userLogged.name}, hay un problema con tu cuenta. Contacta a un administrador para solucionarlo.`)
        return
      }

      setUser(userLogged)
      setIsUserLogged(true)
      const dispatch = {
        freelancer: async () => {
          goToView(routes.freelancerUserProfile.home)
        },
        client: async () => {
          goToView(routes.clientUserProfile.home)
        },
        admin: () => goToView(routes.cpanel.freelancers.register),
        super_admin: () => goToView(routes.cpanel.freelancers.register),
      }
      userLogged.user_type && dispatch[userLogged.user_type]()
    } catch (error) {
      loader.setIsOpen(false)
      if (!error) return
      setAuthError((error as IRequestError).message as string)
    }
  }

  const goToView = (defaultPath: string) => {
    const hasPreviousUrl = location?.state?.from?.pathname
    if (pathname === '/login' && !hasPreviousUrl) return navigate(defaultPath)
    if (hasPreviousUrl) return navigate(hasPreviousUrl)
    return navigate(pathname)
  }

  const logout = () => {
    removeCredential()
    navigate(routes.auth.login)
  }

  const removeCredential = (errorMessage?: string) => {
    errorMessage && setAuthError(errorMessage ?? '')
    setUser(undefined)
    setClientProfile(undefined)
    setFreelancerProfile(undefined)
    setIsUserLogged(false)
    storage.removeItem()
  }

  const isUserAllowed = (userType: string[] | undefined) => {
    if (!user?.user_type || !isUserLogged) return false
    return userType ? userType.includes(user.user_type) : false
  }

  const serUserProfile = async () => {
    if (!user) return
    if (['super_admin', 'admin'].includes(user.user_type)) return
    if (user.user_type === 'freelancer') {
      const { data: freelancer } = await refetchFreelancer()
      setFreelancerProfile(freelancer)
      return
    }
    const { data: client } = await refetchClient()
    setClientProfile(client)
  }

  useEffect(() => {
    serUserProfile()
  }, [user])

  useEffect(() => {
    !isUserLogged && sendWhoIAm()
  }, [storage.item])

  return { login, logout, isUserAllowed }
}

export default useAuth
