import { FC, useEffect } from 'react'
import useUser from '../../../../customHooks/useUser'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import useDialog from '../../../../customHooks/useDialog'
import AppDialog from '../../../../assets/UIkit/AppDialog'
import { Box, Divider } from '@material-ui/core'
import { IOffer } from '../../../../models/IOffer'
import FiButton from '../../../../assets/UIkit/FiButton'
import { useOfferListQuery, useOffersMutation } from '../../../../customHooks/request/offersQuery'
import FreelancerOfferCard from './FreelancerOfferCard'
import { Link } from 'react-router-dom'
import routes from '../../../../constants/routes'
import TitleWithUnderline from '../../../../shared/TitleWithUnderline/TitleWithUnderline'
import UserProfileDialog from '../../../../shared/UserProfileDialog/UserProfileDialog'

const FreelancerOffersList: FC = () => {
  const { user } = useUser()
  const dialog = useDialog<IOffer>()
  const { data: offerQuery, refetch: refetchOffer } = useOfferListQuery({
    filters: {
      freelancer: user?.profile_id ?? '',
      $or: [{ is_available_to_freelancer: { $exists: false } }, { is_available_to_freelancer: true }],
    },
    enabled: Boolean(user?.profile_id),
  })
  const updateOffer = useOffersMutation()
  useRequestAlert(updateOffer, undefined, afterMutation)

  function afterMutation() {
    refetchOffer()
    dialog.toggle()
  }

  const declineOffer = () => {
    updateOffer.mutate({ body: { status: 'declined' }, id: dialog?.data?._id, method: 'PUT' })
  }

  useEffect(() => {
    if (!user) return
    refetchOffer()
  }, [user])

  return (
    <>
      <Link to={routes.freelancerUserProfile.projects.options}>
        <TitleWithUnderline className='mb-6' title='Proyectos' />
      </Link>

      {offerQuery && (
        <>
          {offerQuery.length > 0 ? (
            offerQuery.map((offer) => {
              if (!offer.project) return null
              return (
                <FreelancerOfferCard
                  key={offer._id}
                  {...offer}
                  refetchOffer={refetchOffer}
                  declineOffer={() => dialog.toggle({ dialogData: offer })}
                />
              )
            })
          ) : (
            <UserProfileDialog>
              <h4 className='text-blue text-center'>¡Mmmm! aún no tienes ofertas.</h4>
            </UserProfileDialog>
          )}
        </>
      )}

      <AppDialog open={dialog.isOpen} title='Rechazar oferta'>
        <p className='formtext4-medium mb-4'>
          ¿Estás seguro que deseas rechazar la oferta para el proyecto: "{dialog.data?.brief.title}"
        </p>

        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Divider />
          <FiButton variant='outlined' theme='secondary' className='mr-4' onClick={dialog.toggle}>
            Cancelar
          </FiButton>
          <FiButton variant='contained' theme='secondary' onClick={declineOffer}>
            Aceptar
          </FiButton>
        </Box>
      </AppDialog>
    </>
  )
}

export default FreelancerOffersList
