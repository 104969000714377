import { FC } from 'react'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'

type TFeedbackScore = {
  score: number
  className?: string
  readOnly?: boolean
  onClick?: (score: number) => void
}

const FeedbackScore: FC<TFeedbackScore> = (props) => {
  return (
    <div className={`flex items-center gap-2 ${props.className}`}>
      {Array.from(Array(5).keys()).map((star) => {
        return (
          <span
            key={star}
            className={props.readOnly ? '' : 'cursor-pointer'}
            onClick={() => props.onClick && props.onClick(star + 1)}
          >
            <FiIcons name='score_star' className={star + 1 <= props.score ? 'svg-blue' : ''} />
          </span>
        )
      })}
    </div>
  )
}

export default FeedbackScore
