import { useEffect } from 'react'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'

import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import OurFreelancersIntro from '../../components/WebSite/OurFreelancers/OurFreelancersIntro'
import FreelancersPrices from '../../components/WebSite/OurFreelancers/FreelancersPrices'
import SpecialText from '../../shared/SpecialText/SpecialText'
import FindieAtributes from '../../components/WebSite/OurFreelancers/FindieAtributes'
import FreelancersGallery from '../../shared/FreelancersGallery/FreelancersGallery'
import FiButton from '../../assets/UIkit/FiButton'
import routes from '../../constants/routes'
import { useTranslation } from 'react-i18next'
import SectionStructure from '../../layout/WebSite/SectionStructure'

const OurFreelancers: React.FC = () => {
  const { view, setView } = useWebSiteLayout()
  const { t } = useTranslation()
  useEffect(() => {
    setView({
      path: 'our_freelancers',
      bgColor: 'web-bg-light-orange2',
      textColor: 'text-black',
      textColor2: 'text-light-orange2',
    })
  }, [])

  return (
    <div className='relative'>
      <WebSiteStructure>
        <div id='our_freelancers_intro'>
          <OurFreelancersIntro view={view} />
          <SpecialText styles={view}>{t('OurFreelancers.SpecialText')}</SpecialText>
          <FindieAtributes />
        </div>

        <SectionStructure className='pb-4 pt-0'>
          <div className='mt-8'>
            <p className={`subtitle2-bold md:block hidden ${view.textColor}`}>{t('OurFreelancers.bePartOf')}</p>
          </div>
        </SectionStructure>
      </WebSiteStructure>
      <FreelancersGallery view={view} />

      <FreelancersPrices view={view} />
      <div className='bg-white pb-8 px-3'>
        <h2 className='md:text-center text-left'>{t('OurFreelancers.title')}</h2>
        <div className='flex md:justify-center justify-start mt-4'>
          <FiButton className='px-4 py-2' asLink to={routes.web_site.freelancer_suscription_form.step_zero}>
            <p className='buttontext1-medium'>{t('Postula como freelancer')}</p>
          </FiButton>
        </div>
      </div>
    </div>
  )
}

export default OurFreelancers
