import { FC } from 'react'
import usePagination from '../../../customHooks/usePagination'
import useGroupAlphabetList from '../../../customHooks/useGroupAlphabetList'
import { useClientsQuery } from '../../../customHooks/request/clientsQuery'
import DeletedEntityList from '../../../shared/DeletedEntityList/DeletedEntityList'
import { IClient } from '../../../models/IClient'
import { paginationLimit } from '../../../utils/configs'

const limit = paginationLimit

const ClientDeleted: FC = () => {
  const pagination = usePagination()
  const clientQuery = useClientsQuery({
    $or: [{ client_status: 'rejected' }, { client_status: 'deleted' }],
    page: pagination.page,
    limit,
  })
  const freelancers = useGroupAlphabetList<IClient>(clientQuery)

  return (
    <DeletedEntityList
      entityName='Clientes'
      entityList={freelancers}
      pagination={pagination}
      pages={clientQuery.data?.metadata.pages ?? 0}
    />
  )
}

export default ClientDeleted
