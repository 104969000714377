import { useMutation, useQuery } from 'react-query'
import { IPost, IPut } from '../../models/IRequest'
import {
  ICreateWorkPlanBody,
  IUpdatePlatformMethod,
  IUpdateWorkPlanBody,
  IUploadImageToWorkPlanBody,
  IWorkPlan,
} from '../../models/IWorkPlan'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  workPlan: (params?: string) => `workPlans${params ?? ''}`,
  uploadWorkPlanImage: (id: string) => `workPlans/upload_images/${id}`,
  removeWorkPlanImage: (id: string) => `workPlans/remove_image/${id}`,
  updatePlatformMethod: (id: string) => `workPlans/update_platform_method/${id}`,
}

export const useOneWorkPlan = (id: string) => {
  const endpoint = endpoints.workPlan(`/${id}`)
  const workPlan = useQuery(endpoint, () => apiClient.get<IWorkPlan>({ endpoint }), { select: (data) => data })
  useSetLoader(workPlan)

  return workPlan
}

export const useCreateWorkPlan = () => {
  const endpoint = endpoints.workPlan()
  return useMutation(({ body }: IPost<ICreateWorkPlanBody>) => apiClient.post<IWorkPlan, ICreateWorkPlanBody>({ endpoint, body }))
}

export const useUpdateWorkPlan = () => {
  return useMutation(({ body, id }: IPut<Partial<IUpdateWorkPlanBody>>) =>
    apiClient.put<IWorkPlan, Partial<IUpdateWorkPlanBody>>({ endpoint: endpoints.workPlan(`/${id}`), body })
  )
}

export const useUploadImageToWorkPlan = () => {
  return useMutation(({ body, id }: IPut<IUploadImageToWorkPlanBody>) =>
    apiClient.put<IWorkPlan, IUploadImageToWorkPlanBody>({ endpoint: endpoints.uploadWorkPlanImage(id), body })
  )
}

export const useRemoveImageToWorkPlan = () => {
  return useMutation(({ body, id }: IPut<IUploadImageToWorkPlanBody>) =>
    apiClient.put<IWorkPlan, IUploadImageToWorkPlanBody>({ endpoint: endpoints.removeWorkPlanImage(id), body })
  )
}

export const useUpdatePlatformMethod = () => {
  return useMutation(({ body = undefined, id }: IPut<IUpdatePlatformMethod | undefined>) =>
    apiClient.put<IWorkPlan, IUpdatePlatformMethod | undefined>({ endpoint: endpoints.updatePlatformMethod(id), body })
  )
}
