import { Dispatch, SetStateAction } from 'react'
import { ICategory } from '../../models/ICategory'
import { useTranslation } from 'react-i18next'

export type TCategoryTabs = {
  selectCategory: ICategory | undefined
  categories: ICategory[]
  textColor: string
  className?: string
  setSelectCategory: Dispatch<SetStateAction<ICategory | undefined>>
}

export const CategoryTabs = (props: TCategoryTabs) => {
  const { t } = useTranslation()

  return (
    <div className={`lg:px-4 md:px-12 px-2 border-t border-b border-gray-200 py-2 mb-12 md:w-full w-80 ${props.className ?? ''}`}>
      <div className='slider hide-scroll-bar md:gap-12 gap-4'>
        {props.categories
          .filter((category: ICategory) => !category.is_other_category && category.is_available)
          .map((category: ICategory) => {
            const isSelectedStyle = category._id === props.selectCategory?._id ? 'subtitle4-medium' : 'subtitle4-regular'

            return (
              <p
                key={category._id}
                className={`slider-item cursor-pointer ${props.textColor} ${isSelectedStyle}`}
                onClick={() => props.setSelectCategory(category)}
              >
                {t(category.name)}
              </p>
            )
          })}
      </div>
    </div>
  )
}
