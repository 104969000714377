import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IFormStepStructure } from '../../../../models/IFormStep'
import SectionItem from './SectionItem'
import NewProjectStepLayout from './NewProjectStepLayout'
import { cleanString } from '../../../../utils/helpers'
import { useTranslation } from 'react-i18next'

export const NewProjectStepStartDate: FC<IFormStepStructure> = (props) => {
  const { t } = useTranslation()
  const { slide } = useParams<{ slide: string }>()
  const storage = props.storage
  const { Title } = props

  useEffect(() => {
    slide === `${props.position}` && props.setIsButtonDisabled(Boolean(!storage?.item?.when_start))
  }, [slide, storage.item])

  let newHistoryOptions: any[] = t('GLOBAL.WHEN_PROJECT_STARTS', { returnObjects: true })

  return (
    <>
      <Title>{t('GLOBAL.WHEN_START')}</Title>

      <NewProjectStepLayout className='total-center md:gap-8 gap-4'>
        {newHistoryOptions.map((iter) => {
          return (
            <SectionItem
              key={iter.label}
              title={iter.label}
              isActive={cleanString(storage?.item?.when_start ?? '') === cleanString(iter.value)}
              onClick={() => storage.setItem({ ...storage.item, when_start: iter.value })}
            />
          )
        })}
      </NewProjectStepLayout>
    </>
  )
}

export default NewProjectStepStartDate
