import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'

import FormContainer from '../../../shared/FormContainer/FormContainer'
import FiButton from '../../../assets/UIkit/FiButton'
import FindieGifIsotype from '../../../shared/Brand/Isotype/FindieGifIsotype'
import degrade from '../../../assets/images/web/Forms/client-form.png'
import { useTranslation } from 'react-i18next'

const ClientStepZero: React.FC = () => {
  const year = new Date().getFullYear()
  const { t } = useTranslation()

  return (
    <FormContainer background={degrade}>
      <div className='2xl:w-1/2 md:w-8/12 w-11/12 text-center'>
        <Link to={routes.web_site.home} className='flex justify-center'>
          <FindieGifIsotype className='xl:w-32 lg:w-32 md:w-28 w-20' />
        </Link>
        <h1 className='formtext3-medium lg:pt-20 md:pt-14 pt-8'>{t('clientSubscriptionForm.stepZero.title')}</h1>
        <h4 className='lg:pt-14 md:pt-3.5 mt-6 pt-2'>{t('clientSubscriptionForm.stepZero.paragraph')}</h4>
        <FiButton
          theme='primary'
          className='lg:my-14 md:my-9 my-6 py-1.5 px-16 inline-block'
          asLink
          to={`${routes.web_site.client_suscription_form.form_steps}/1`}
        >
          <span className='buttontext4-regular'> {t('clientSubscriptionForm.stepZero.start')}</span>
        </FiButton>

        <p className='microcopy 2xl:1/4 lg:w-2/5 w-11/12 mx-auto'>
          {t('clientSubscriptionForm.stepZero.disclaimer')}{' '}
          <Link className='underline' to={routes.web_site.terms_conditions} target='_blank'>
            {t('clientSubscriptionForm.stepZero.terms')}
          </Link>{' '}
          {t('clientSubscriptionForm.stepZero.connector')}{' '}
          <Link className='underline' to={routes.web_site.terms_conditions} target='_blank'>
            {t('clientSubscriptionForm.stepZero.privacy')}
          </Link>
        </p>
        <p className='microcopy mt-0 text-center'>® Findie SpA {year}</p>
      </div>
    </FormContainer>
  )
}

export default ClientStepZero
