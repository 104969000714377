import { useQuery, useMutation } from 'react-query'
import { ICandidateBody, IFreelancerIsDoneBody, INewProjectBody, IProject } from '../../models/IProject'
import { IDelete, IPaginatedResponse, IPost, IPut } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  newProject: 'projects/new',
  freelancerIsDone: 'projects/freelancer-is-done',
  projects: (params: string) => `projects${params}`,
  removeProject: (id: string) => `projects/remove-one/${id}`,
  projectsWithOffers: (params: string) => `projects/with-offer/${params}`,
  freelancerProjects: (params: string) => `projects/freelancer${params}`,
  selectCandidate: (id: string) => `projects/select_candidate/${id}`,
  recalculateProjectPrice: (id: string) => `projects/recalculate/${id}`,
}

export const useProjectListQuery = ({ filters, enabled = true }: { filters?: unknown; enabled?: boolean }) => {
  const endpoint = endpoints.projects(`?filters=${JSON.stringify(filters)}`)
  const projectsQuery = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IProject>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled,
  })
  useSetLoader(projectsQuery)
  return projectsQuery
}

export const useProjectListWithOfferQuery = ({ filters, enabled = true }: { filters?: unknown; enabled?: boolean }) => {
  const endpoint = endpoints.projectsWithOffers(`?filters=${JSON.stringify(filters)}`)
  const projectsQuery = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IProject>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled,
  })
  useSetLoader(projectsQuery)
  return projectsQuery
}

export const useFreelancerProjectsQuery = ({ filters, enabled = true }: { filters?: unknown; enabled?: boolean }) => {
  const endpoint = endpoints.freelancerProjects(`?filters=${JSON.stringify(filters)}`)
  const projectsQuery = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IProject>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled,
  })
  useSetLoader(projectsQuery)
  return projectsQuery
}

export const useOneProjectQuery = (id: string) => {
  const endpoint = endpoints.projects(`/${id}`)

  const projectQuery = useQuery(endpoint, () => apiClient.get<IProject>({ endpoint }))
  useSetLoader(projectQuery)
  return projectQuery
}

export const useCreateProject = () => {
  const endpoint = endpoints.newProject
  return useMutation(({ body }: IPost<INewProjectBody>) => apiClient.post<IProject, INewProjectBody>({ endpoint, body }))
}

export const useFreelancerIsDone = () => {
  const endpoint = endpoints.freelancerIsDone
  return useMutation(({ body }: IPost<IFreelancerIsDoneBody>) =>
    apiClient.post<IProject, IFreelancerIsDoneBody>({ endpoint, body })
  )
}

export const useUpdateProject = () => {
  return useMutation(({ body, id }: IPut<Partial<IProject>>) =>
    apiClient.put<IProject, Partial<IProject>>({ endpoint: endpoints.projects(`/${id}`), body })
  )
}

export const useSelectProjectCandidate = () => {
  return useMutation(({ body, id }: IPut<ICandidateBody>) =>
    apiClient.put<IProject, ICandidateBody>({ endpoint: endpoints.selectCandidate(id), body })
  )
}

export const useRecalculateProjectPrice = () => {
  return useMutation(({ body, id }: IPut<ICandidateBody>) =>
    apiClient.put<IProject, ICandidateBody>({ endpoint: endpoints.recalculateProjectPrice(id), body })
  )
}

export const useRemoveProject = () => {
  return useMutation(({ id }: IDelete) => apiClient.delete<IProject>({ endpoint: endpoints.removeProject(id) }))
}

export const useUFQuery = () => {
  return useQuery(`uf`, apiClient.getUF, {
    select: (data) => data.serie[0].valor,
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
  })
}
