import EmailVerification from '../ClientSuscriptionForm/EmailVerification'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { IFormStep } from '../../../models/IFormStep'
import { cn } from '../../../utils/helpers'

type TEmailVerificationModal = {
  emailProps: IFormStep
  redirectTo: string
  asLiteForm?: boolean
  setIsModalOpen: () => void
}

const EmailVerificationModal = (props: TEmailVerificationModal) => {
  return (
    <div className={cn('pt-8 relative overflow-hidden')}>
      <EmailVerification {...props.emailProps} redirectTo={props.redirectTo} asLiteForm={props.asLiteForm} />

      {props.setIsModalOpen && (
        <FiIcons name='circle_close_black' className='absolute -top-2 -right-2 cursor-pointer' onClick={props.setIsModalOpen} />
      )}
    </div>
  )
}

export default EmailVerificationModal
