import { useEffect, FC, useMemo } from 'react'

import { Button, Divider, Grid } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { usePlansQuery } from '../../customHooks/request/plansQuery'
import { useUpdateProject } from '../../customHooks/request/projectsQuery'
import useRequestAlert from '../../customHooks/useRequestAlert'
import ClpField from '../../assets/UIkit/Forms/ClpField'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { clientSiiTax } from '../../constants/Cpanel/ProjectConstants'
import { IPlan } from '../../models/IPlan'
import { TProjectSections } from './Project'
import FiButton from '../../assets/UIkit/FiButton'
import { formatDecimal, getTimeStamp } from '../../utils/helpers'
import useUf from '../../customHooks/useUf'
import InputField from '../../assets/UIkit/Forms/InputField'
import dayjs from 'dayjs'
import { useProjectFeesQuery } from '../../customHooks/request/PaymentMethodsQuery'
import { IProjectPrice } from '../../models/IProject'

const ProjectInfo: FC<TProjectSections> = (props) => {
  const form = useForm()
  const { data: todayUf } = useUf()
  const { data: project, refetch: refetchProject } = props.project
  const { data: plansQuery } = usePlansQuery({ entity: 'client', is_available: true })
  const { data: paymentMethodsQuery } = useProjectFeesQuery()
  const updateProject = useUpdateProject()
  useRequestAlert(updateProject)

  const disableFields = () => {
    if (!project) return true
    return props.isDisabledFields || project.price.total > 0 || project.has_offers
  }

  const paymentMethods = useMemo(() => {
    if (!paymentMethodsQuery || !project) return []

    return paymentMethodsQuery.data.map((method) => ({ method: method.name, fee: method.fee }))
  }, [paymentMethodsQuery, project])

  const updateProjectInfo = (body: any) => {
    if (!project) return

    const plan = body.plan ? JSON.parse(body.plan as unknown as string) : project.plan
    const payment_method = body.price.payment_method
      ? JSON.parse(body.price.payment_method as unknown as string)
      : project.price.payment_method
    const currentSii = project.with_currency ? { name: 'none', type: 'none', tax: 0, ticket_constant: 0 } : project.price.sii
    const sii = body.price.sii ? JSON.parse(body.price.sii) : currentSii
    const uf = project.uf ?? todayUf
    const offer_expiration_date = getTimeStamp(body.offer_expiration_date)

    updateProject.mutate(
      {
        body: { ...body, plan, uf, offer_expiration_date, price: { ...body.price, payment_method, sii } },
        id: project?._id ?? '',
      },
      {
        onSuccess: () => {
          form.setValue('price.payment_method', '')
          form.setValue('price.sii', '')
          refetchProject()
        },
      }
    )
  }

  const updateCurrencyType = () => {
    if (!project) return

    const sii = { name: 'none', type: 'none', tax: 0, ticket_constant: 0 }
    const payment_method = { method: 'none', fee: 0 }
    const with_currency = !project.with_currency

    updateProject.mutate(
      {
        body: { with_currency, price: { payment_method, sii } as unknown as IProjectPrice },
        id: project?._id ?? '',
      },
      {
        onSuccess: () => {
          form.setValue('price.payment_method', '')
          form.setValue('price.sii', '')
          refetchProject()
        },
      }
    )
  }

  useEffect(() => {
    if (!project || !paymentMethodsQuery) return

    form.setValue('price.subtotal', formatDecimal(project.price.subtotal))

    project.offer_expiration_date &&
      form.setValue('offer_expiration_date', dayjs(project.offer_expiration_date).format('YYYY-MM-DD'))
  }, [project, paymentMethodsQuery])

  if (!project) return null
  return (
    <form onSubmit={form.handleSubmit(updateProjectInfo)}>
      <div className='flex justify-between mt-4'>
        <h5>Información del proyecto</h5>
        <div>
          <FiButton variant='contained' theme='secondary' type='submit' disabled={disableFields()}>
            Guardar
          </FiButton>
        </div>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <ClpField
            name='price.subtotal'
            label='Costo del proyecto'
            inputProps={{ className: 'w-full', placeholder: '$0.000', step: 'any', type: 'number', disabled: true }}
            form={form}
          />
        </Grid>

        <Grid item xs={4}>
          <SelectField
            name='price.payment_method'
            label={`Metodos de pago ${
              project.price.payment_method && project.price.payment_method.method !== 'none'
                ? `(${project.price.payment_method?.method})`
                : ''
            }`}
            inputProps={{ className: 'w-full', disabled: disableFields() }}
            selectOptions={paymentMethods.map((payment) => {
              return { value: JSON.stringify(payment), label: payment.method }
            })}
            form={form}
          />
        </Grid>
        <Grid item xs={4}>
          {!project.with_currency && (
            <SelectField
              name='price.sii'
              label={`Sii ${
                project.price.sii && project.price.sii.name !== 'none' ? `(Seleccionado: ${project.price.sii.name})` : ''
              }`}
              inputProps={{ disabled: disableFields(), className: 'w-full' }}
              selectOptions={clientSiiTax.map((sii) => {
                return { value: JSON.stringify(sii), label: sii.name }
              })}
              form={form}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <p className='body1-medium mt-2'>Datos de la oferta</p>

          <p className='microcopy'>Fecha de expiración de la oferta</p>
          <p className='microcopy text-blue'>
            {project.offer_expiration_date
              ? dayjs(project.offer_expiration_date).format('DD-MM-YYYY')
              : 'Aun sin fecha de expiración'}
          </p>
        </Grid>
        <Grid item xs={4}>
          <p className='body1-medium mt-2'>Moneda</p>
          <p className='body2-regular'>
            El proyecto usa <span className='text-red-400'>{project.with_currency ? '"USD"' : '"pesos"'}</span> como moneda de
            pagos.
          </p>
          <Button
            type='button'
            color='primary'
            variant='contained'
            className='mt-1'
            onClick={updateCurrencyType}
            disabled={disableFields()}
          >
            {project.with_currency ? 'Quiero cambiar a  pesos' : 'Quiero cambiar a USD'}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <p className='body1-medium mt-2'>Plan Findie Histórico</p>
        <p className='body2-regular'>
          El plan Findie histórico contiene los datos del plan al momento en que se le fue asignado al proyecto.
        </p>
        <div className='grid grid-cols-12 gap-4 mt-4'>
          <div className='col-span-6 mt-4'>
            <p className='subtitle4-medium'>
              Nombre: <span className='body2-regular'>{project?.plan?.name}</span>
            </p>
            <p className='subtitle4-medium'>
              Costo: <span className='body2-regular'>${project?.plan?.price}</span>
            </p>
            <p className='subtitle4-medium'>
              Fee: <span className='body2-regular'>{project?.plan?.fee}%</span>
            </p>
            <p className='subtitle4-medium'>
              Uf: <span className='body2-regular'>{formatDecimal(project?.uf)}</span>
            </p>
          </div>
          <div className='col-span-6'>
            {plansQuery && (
              <SelectField
                name='plan'
                label='Plan'
                selectOptions={plansQuery.map((plan: IPlan) => {
                  return { value: JSON.stringify(plan), label: plan.name }
                })}
                inputProps={{ className: 'w-full', disabled: disableFields() }}
                form={form}
              />
            )}
          </div>
        </div>
      </Grid>
      <Divider className='my-4' />
    </form>
  )
}

export default ProjectInfo
