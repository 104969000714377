import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useEffect } from 'react'

import { Box } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useFreelancerQuery, usePutFreelancerMutation } from '../../../../customHooks/request/freelancersQuery'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import { useSetLoader } from '../../../../customHooks/useSetLoader'
import routes from '../../../../constants/routes'
import FreelancerShortCuts from '../../../../shared/FreelancerShortCuts/FreelancerShortCuts'
import Tabs from '../../../../assets/UIkit/Tabs'
import FreelancerBilling from './FreelancerBilling'
import FreelancerTickets from './FreelancerTickets'
import FreelancerPersonalData from './FreelancerPersonalData'
import FreelancerInterviewInfo from './FreelancerInterviewInfo'
import FreelancerCpanelProfile from './FreelancerCpanelProfile/FreelancerCpanelProfile'

const FreelancerFindieDetail: React.FC = () => {
  const history = useHistory()
  const params = useParams<{ _id: string }>()
  const freelancerQuery = useFreelancerQuery(params._id)
  const queryClient = useQueryClient()
  const putFreelancerMutation = usePutFreelancerMutation()
  useRequestAlert(putFreelancerMutation, undefined, afterFreelancerMutation)
  useSetLoader(freelancerQuery)
  useSetLoader(queryClient)

  const tabs = [
    {
      id: 7,
      text: `Perfil`,
      isSelected: true,
      action: () => tabAction('mas-informacion'),
    },
    {
      id: 1,
      text: `Datos Personales`,
      isSelected: false,
      action: () => tabAction('datos-personales'),
    },
    {
      id: 2,
      text: `Facturación`,
      isSelected: false,
      action: () => tabAction('facturacion'),
    },
    {
      id: 5,
      text: `Ticket`,
      isSelected: false,
      action: () => tabAction('tickets'),
    },
    {
      id: 8,
      text: `Entrevista`,
      isSelected: false,
      action: () => tabAction('info-entrevista'),
    },
  ]

  const tabAction = (path: string) => {
    history.push(`${routes.cpanel.freelancers.findie}/${params._id}/${path}`)
  }

  function afterFreelancerMutation() {
    freelancerQuery.refetch()
  }

  const componentsProps = {
    freelancerId: params._id,
    freelancer: freelancerQuery,
    isInputsDisabled: false,
    putFreelancerMutation: putFreelancerMutation,
    afterFreelancerMutation: afterFreelancerMutation,
  }

  return (
    <>
      <h5 className='cursor-pointer text-right' onClick={() => window.history.back()}>
        <ArrowBackIcon /> Volver
      </h5>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <h3>
          {freelancerQuery?.data?.name} {freelancerQuery?.data?.lastName}
        </h3>

        {freelancerQuery.isSuccess && (
          <FreelancerShortCuts freelancer={freelancerQuery.data} freelancersQuery={freelancerQuery} freelancerDetail />
        )}
      </Box>

      <Tabs tabs={tabs} />

      <Switch>
        <Route path={`${routes.cpanel.freelancers.findie}/:id/facturacion`}>
          <FreelancerBilling {...componentsProps} />
        </Route>
        <Route path={`${routes.cpanel.freelancers.findie}/:id/tickets`}>
          <FreelancerTickets {...componentsProps} />
        </Route>
        <Route path={`${routes.cpanel.freelancers.findie}/:id/datos-personales`}>
          <FreelancerPersonalData {...componentsProps} />
        </Route>
        <Route path={`${routes.cpanel.freelancers.findie}/:id/info-entrevista`}>
          <FreelancerInterviewInfo {...componentsProps} />
        </Route>
        <Route exact={true}>
          <FreelancerCpanelProfile {...componentsProps} />
        </Route>
      </Switch>
    </>
  )
}

export default FreelancerFindieDetail
