import { FC, useContext } from 'react'
import { CurrentViewContext } from '../../context/WebSite/CurrentViewContext'
import { Link, useLocation } from 'react-router-dom'
import routes from '../../constants/routes'
import useAnimation from '../../customHooks/useAnimation'
import useUser from '../../customHooks/useUser'

import FindieLogo from '../../shared/Brand/Logo/FindieLogo'
import LanguageSelector from '../../shared/Language/LanguageSelector'
import MobileMenu from './MobileMenu'

import { Collapse, IconButton } from '@material-ui/core'
import FiButton from '../../assets/UIkit/FiButton'
import MenuIcon from '@material-ui/icons/Menu'
import useAuth from '../../customHooks/useAuth'
import { useTranslation } from 'react-i18next'
import { CategoriesContext } from '../../context/Cpanel/CategoriesContext'
import AppDialog from '../../assets/UIkit/AppDialog'
import useNewClient from '../../customHooks/useNewClient'
import EmailVerificationModal from '../../components/WebSite/HomePage/EmailVerificationModal'

type TLateralMenu = {
  open: boolean
}

const LateralMenu: FC<TLateralMenu> = (props) => {
  const { view } = useContext(CurrentViewContext)
  const { categoriesQuery } = useContext(CategoriesContext)
  const { user, isUserLogged } = useUser()
  const { logout } = useAuth()
  const { isComponentOpen, animation, toogleComponent, togleAnimation } = useAnimation()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { emailProps, isVerificationModalOpen, setIsVerificationModalOpen } = useNewClient()

  const userProfilePath = () => {
    if (!user) return ''
    return user.user_type === 'client' ? routes.clientUserProfile.home : routes.freelancerUserProfile.home
  }

  return (
    <>
      <Link to={routes.web_site.home} className='fixed z-50 lg:top-11 lg:left-11 md:top-12 md:left-12 top-7 left-7'>
        <FindieLogo className='md:w-32 md:h-8 w-20 h-5' color={`${view.textColor.includes('text-black') ? 'black' : 'white'}`} />
      </Link>

      {!pathname.includes('perfil') && (
        <span className={`buttontext2-regular rotated-text ${view.textColor}`}>{t('HEADER.paragraph')} ®</span>
      )}

      <div className='fixed lg:flex z-50 hidden justify-end flex-col top-11 right-11'>
        {categoriesQuery && (
          <>
            {isUserLogged ? (
              <FiButton onClick={logout} className={`mt-4 py-0 `}>
                <p className={`buttontext2-semibold text-white`} style={{ marginBottom: 1 }}>
                  {t('Cerrar sesión')}
                </p>
              </FiButton>
            ) : (
              <FiButton asLink to={routes.auth.login} className={`mt-4 py-0 `}>
                <p className={`buttontext2-semibold text-white`} style={{ marginBottom: 1 }}>
                  {t('Iniciar sesión')}
                </p>
              </FiButton>
            )}
            <Link to={routes.web_site.createAccount}>
              <FiButton
                className={`mt-4 py-0 border ${view.textColor.includes('text-black') ? 'border-black' : 'border-white'}`}
                variant='outlined'
                theme='secondary'
                asLink
                to={routes.web_site.createAccount}
              >
                <p className={`buttontext2-semibold ${view.textColor}`} style={{ marginBottom: 1 }}>
                  {t('Crear cuenta')}
                </p>
              </FiButton>
            </Link>
          </>
        )}

        <Collapse className='mt-14' in={props.open}>
          <Link to={routes.web_site.about_us}>
            <p className={`buttontext2-medium cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>
              {t('HEADER.aboutUs')}
            </p>
          </Link>
          <Link to={routes.web_site.why_findie}>
            <p className={`buttontext2-medium cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>
              {t('HEADER.whyFindie')}
            </p>
          </Link>
          <Link to={routes.web_site.freelancers_profiles}>
            <p className={`buttontext2-medium cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>
              {t('HEADER.talents')}
            </p>
          </Link>
          <Link to={routes.web_site.our_freelancers}>
            <p className={`buttontext2-medium cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>
              {t('HEADER.applyHere')}
            </p>
          </Link>
          {/* <Link to={routes.web_site.bookStore}>
            <p className={`buttontext2-medium cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>The Good</p>
          </Link> */}
        </Collapse>
      </div>
      <div className={`${view.textColor} fixed z-50 md:top-10 md:right-20 top-6 right-20 lg:hidden block`}>
        <LanguageSelector svgColor={view.textColor} />
      </div>
      <IconButton className={`fixed z-50 md:top-6 md:right-6 top-2 right-2 lg:hidden block`} onClick={toogleComponent}>
        <MenuIcon fontSize='large' className={`${view.textColor}`} />
      </IconButton>

      <div className='fixed lg:flex hidden justify-end flex-col bottom-16 right-11'>
        {/* <p className={`buttontext2-regular cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>Board</p> */}
        <Link to={routes.web_site.faqs}>
          <p className={`buttontext2-regular cursor-pointer text-right mt-4 hover:italic-style ${view.textColor}`}>FAQ</p>
        </Link>

        {isUserLogged && ['super_admin', 'admin'].includes(user?.user_type ?? '') && (
          <Link to={routes.cpanel.freelancers.register} className='flex justify-end mt-4'>
            <p className={`buttontext2-medium cursor-pointer text-right text-blue hover:italic-style ${view.textColor}`}>
              Back office
            </p>
          </Link>
        )}
        {isUserLogged && ['freelancer', 'client'].includes(user?.user_type ?? '') && (
          <Link to={userProfilePath()} className='flex justify-end mt-4'>
            <p className={`buttontext2-medium cursor-pointer text-right text-blue hover:italic-style ${view.textColor}`}>
              User profile
            </p>
          </Link>
        )}
      </div>

      {isComponentOpen && (
        <MobileMenu
          view={view}
          toogleComponent={toogleComponent}
          animation={animation}
          setIsVerificationModalOpen={setIsVerificationModalOpen}
          togleAnimation={togleAnimation}
        />
      )}
    </>
  )
}

export default LateralMenu
