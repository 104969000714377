import { IFormStep } from '../../models/IFormStep'

import FormStepContainer from '../FormStepContainer/FormStepContainer'
import SkillsStep from './SkillsStep'
import { useTranslation } from 'react-i18next'

const FormSkillsStep: React.FC<IFormStep & { userType: 'client' | 'freelancer' }> = (props) => {
  const { t } = useTranslation()

  return (
    <FormStepContainer>
      <h2 className={`xl:mb-16 lg:mb-16 md:mb-14 mb-12 text-black`}>
        {props.userType.includes('client')
          ? t('clientSubscriptionForm.FormSkillsStep.clientStep')
          : 'Elige tus 10 mejores habilidades'}
      </h2>
      <div className='h-96'>
        <SkillsStep {...props} />
      </div>
    </FormStepContainer>
  )
}

export default FormSkillsStep
