import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

type TVariant = 'default' | 'outlined' | 'transparent'

export type TGenericInput = {
  name: string
  label?: string
  labelClassName?: string
  showErrorMessage?: boolean
  id?: string
  textarea?: boolean
  variant?: TVariant
  form: UseFormReturn<any>
  options?: RegisterOptions
  className?: string
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  textareaProps?: DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
}

export function inputStyle(variant: TVariant) {
  if (variant === 'transparent') return ''
  if (variant === 'default') return 'border-b border-gray-300 px-2'
  return 'border border-black px-4'
}

const GenericInput: React.FC<TGenericInput> = (props) => {
  const disabledStyle = props?.inputProps?.disabled ? 'bg-black-005 border-none px-4' : inputStyle(props.variant ?? 'outlined')

  if (props.textarea)
    return (
      <textarea
        {...props.textareaProps}
        {...props.form.register(props.name, props.options)}
        className={`no-focus body2-regular disabled-placeholder ${
          props.variant === 'transparent' ? '' : 'py-2 '
        } ${disabledStyle} ${props.textareaProps?.className ?? ''}`}
        id={props.id}
      />
    )
  return (
    <input
      {...props.inputProps}
      {...props.form.register(props.name, props.options)}
      className={`md:h-10 h-8 no-focus body2-regular disabled-placeholder ${disabledStyle} ${props.inputProps?.className ?? ''}`}
      id={props.id}
    />
  )
}

export default GenericInput
