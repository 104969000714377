import { FC, useMemo } from 'react'
import useUser from '../../../customHooks/useUser'
import _ from 'lodash'

import { useProjectListQuery } from '../../../customHooks/request/projectsQuery'
import { IFreelancer } from '../../../models/IFreelancer'
import FreelancerProfileCard from '../../../shared/ProjectUserProfile/FreelancerProfileCard'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import TitleWithUnderline from '../../../shared/TitleWithUnderline/TitleWithUnderline'
import { useTranslation } from 'react-i18next'

const ClientMyFreelancers: FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { data: projectQuery } = useProjectListQuery({
    filters: {
      client: user?.profile_id ?? '',
      $or: [{ project_status: 'finished' }, { project_status: 'cancelled' }],
    },
    enabled: Boolean(user?.profile_id),
  })

  const freelancerList = useMemo(() => {
    if (!projectQuery) return []
    const list = projectQuery.data.reduce((acc: IFreelancer[], project) => {
      const freelancers = project.staff.map((staff) => staff.freelancer)
      return [...acc, ...freelancers]
    }, [])
    return _.uniqBy(list, '_id')
  }, [projectQuery])

  return (
    <>
      <Link to={routes.clientUserProfile.projects.options}>
        <TitleWithUnderline title={t('MY_FREELANCERS.BACK_MENU_PRO')} />
      </Link>
      <p className='subtitle4-regular mt-12 w-1/2'>{t('MY_FREELANCERS.FIND_FREELANCER_LIST')}</p>
      <div className='grid grid-cols-12 gap-8 lg:w-10/12 w-full mt-8'>
        {freelancerList.map((freelancer) => {
          return (
            <div
              key={freelancer._id}
              className='md:col-span-4 col-span-12 cursor-pointer mx-auto'
              onClick={() => window.open(`${window.location.origin}/perfil/${freelancer._id}`)}
            >
              <FreelancerProfileCard {...freelancer} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ClientMyFreelancers
