import { useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import endpoints from '../../constants/endpoints'
import {
  IFreelancer,
  IPortfolioFile,
  IPortfolioFileBody,
  IPortfolioLinkBody,
  IPortfolioRemoveFileBody,
} from '../../models/IFreelancer'
import { IPaginatedResponse, IPut, IReactQueryOptions } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints2 = {
  freelancers: (param: string) => `freelancers/${param}`,
  freelancerEvaluation: (param: string) => `freelancers/evaluation/${param}`,
  freelancersWithFilter: (filters: string) => `freelancers?filters=${filters}`,
  freelancersSortedWithFilter: (filters: string) => `freelancers/sorted?filters=${filters}`,
  updatePortfolioFiles: (id: string) => `freelancers/portfolio_files/${id}/`,
  updateDnDPortfolioFiles: (id: string) => `freelancers/handle_portfolio_dragAndDrop/${id}/`,
  removePortfolioFiles: (id: string) => `freelancers/remove_portfolio_file/${id}/`,
}

export const useFreelancersSorted = ({ filters, enabled }: { filters: unknown; enabled?: boolean }) => {
  const endpoint = endpoints2.freelancersSortedWithFilter(JSON.stringify(filters))
  const items = useQuery(endpoint, () => apiClient.get<IPaginatedResponse<IFreelancer>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled: enabled ?? true,
  })
  useSetLoader(items)
  return items
}

export const useFreelancersFindieQuery = ({ filters, enabled }: { filters: unknown; enabled?: boolean }) => {
  const endpoint = endpoints2.freelancersWithFilter(JSON.stringify(filters))
  const items = useQuery([endpoint, filters], () => apiClient.get<IPaginatedResponse<IFreelancer>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled: enabled ?? true,
  })
  useSetLoader(items)
  return items
}

export const useOneFreelancerQuery = ({ id, enabled = true }: { id: string; enabled: boolean }) => {
  const endpoint = endpoints2.freelancers(id)
  const item = useQuery(endpoint, () => apiClient.get<IFreelancer>({ endpoint }), {
    select: (data) => {
      return data
    },
    enabled,
  })
  useSetLoader(item)
  return item
}

export const useUpdateFreelancer = () => {
  return useMutation(({ body, id }: IPut<Partial<IFreelancer>>) =>
    apiClient.put<IFreelancer, Partial<IFreelancer>>({ endpoint: endpoints2.freelancers(id), body })
  )
}

export const useFreelancerEvaluationMutation = () => {
  return useMutation(({ body, id }: IPut<Partial<IFreelancer>>) =>
    apiClient.put<IFreelancer, Partial<IFreelancer>>({ endpoint: endpoints2.freelancerEvaluation(id), body })
  )
}

export const useUpdateDnDPortfolioFiles = () => {
  return useMutation(({ body, id }: IPut<IPortfolioFile[]>) =>
    apiClient.put<IFreelancer, IPortfolioFile[]>({ endpoint: endpoints2.updateDnDPortfolioFiles(id), body })
  )
}

export const useUpdatePortfolioFiles = () => {
  return useMutation(({ body, id }: IPut<IPortfolioFileBody | IPortfolioLinkBody>) =>
    apiClient.put<IFreelancer, IPortfolioFileBody | IPortfolioLinkBody>({ endpoint: endpoints2.updatePortfolioFiles(id), body })
  )
}

export const useRemovePortfolioFiles = () => {
  return useMutation(({ body, id }: IPut<IPortfolioRemoveFileBody>) =>
    apiClient.put<IFreelancer, IPortfolioRemoveFileBody>({ endpoint: endpoints2.removePortfolioFiles(id), body })
  )
}

//Old structure...........................................................
export const useFreelancersQuery = (filters: any) => {
  const freelancersQuery = useQuery(
    [`all_${endpoints.freelancers}`, filters],
    () => apiClient.getFreelancersFiltered(JSON.stringify(filters)),
    {
      select: (data) => {
        return { data: data.data, metadata: data.metadata }
      },
    }
  )
  return freelancersQuery
}

export const usePublicFreelancersQuery = (params: { filters?: any } & IReactQueryOptions = {}) => {
  const { filters, reactQueryOptions } = params
  const freelancersQuery = useQuery(
    [`all_${endpoints.freelancers}`, params.filters],
    () => apiClient.getPublicFreelancers(JSON.stringify(filters)),
    {
      ...reactQueryOptions,
      keepPreviousData: false,
      cacheTime: 0,
      useErrorBoundary: false,
      select: (data) => {
        return { data: data.data, metadata: data.metadata }
      },
    }
  )
  return freelancersQuery
}

export const useFreelancersFetch = () => {
  const query = useQueryClient()
  const loader = useSetLoader()

  const freelancers = async (filters: any) => {
    loader.setIsOpen(true)
    const resp = await query.fetchQuery(`fetch_${endpoints.freelancers}`, () =>
      apiClient.getFreelancersFiltered(JSON.stringify(filters))
    )
    loader.setIsOpen(false)
    return resp
  }
  return freelancers
}

export const useFreelancerProfileQuery = (id: string) => {
  const freelancerQuery = useQuery(`one_${endpoints.profile}${id}`, () => apiClient.getFreelancerProfile(id), {
    cacheTime: 0,
    keepPreviousData: false,
  })
  return freelancerQuery
}

export const useFreelancerQuery = (id: string) => {
  const freelancerQuery = useQuery(`one_${endpoints.freelancers}${id}`, () => apiClient.getFreelancer(id), {
    cacheTime: 0,
    keepPreviousData: false,
  })
  return freelancerQuery
}

export const usePostFreelancerMutation = () => {
  const mutation = useMutation(apiClient.postFreelancer)
  return mutation
}

export const usePostFreelancerSuscriptionMutation = () => {
  const mutation = useMutation(apiClient.postFreelancerSuscription)
  return mutation
}

export const usePutFreelancerMutation = (refresh?: 'refresh') => {
  const mutation = useMutation(apiClient.putFreelancer)
  refresh && useRefreshFreelancers(mutation)
  return mutation
}

export const usePutFreelancerPlanMutation = (refresh?: 'refresh') => {
  const mutation = useMutation(apiClient.putFreelancerPlan)
  refresh && useRefreshFreelancers(mutation)
  return mutation
}

export const useHandleFeatureFreelancerMutation = () => {
  const mutation = useMutation(apiClient.handleFeatureFreelancer)
  return mutation
}

export const useDragAndDropFreelancerMutation = () => {
  const mutation = useMutation(apiClient.dragAndDropFreelancer)
  return mutation
}

export const usePutPortfolioFilesMutation = () => {
  const mutation = useMutation(apiClient.putPortfolioFiles)
  return mutation
}

export const usePortfolioDragAndDropMutation = () => {
  const mutation = useMutation(apiClient.putPortfolioGragAndDrop)
  return mutation
}

export const usePutRemovePortfolioFiles = () => {
  const mutation = useMutation(apiClient.putRemovePortfolioFiles)
  return mutation
}

export const useRemovePortfolioPdf = () => {
  const mutation = useMutation(apiClient.removePortfolioPdf)
  return mutation
}

export const useDeleteFreelancerMutation = (refresh?: 'refresh') => {
  const mutation = useMutation(`delete_${endpoints.freelancers}`, apiClient.deleteFreelancer)
  refresh && useRefreshFreelancers(mutation)
  return mutation
}

export const useRefreshFreelancers = (mutation: any) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    mutation.isSuccess && queryClient.refetchQueries(`all_${endpoints.skills}`)
  }, [mutation.isSuccess])
}
