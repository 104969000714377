import { useMutation, useQuery } from 'react-query'
import { IMutation, IPaginatedResponse } from '../../models/IRequest'
import { IStaff, TBodyStaff } from '../../models/IStaff'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  staffs: (params?: string) => `staffs${params ?? ''}`,
}

export const useStaffListQuery = ({ filters, enabled = true }: { filters?: unknown; enabled: boolean }) => {
  const endpoint = endpoints.staffs(filters ? `?filters=${JSON.stringify(filters)}` : '')
  const staffQuery = useQuery(endpoint, () => apiClient.get<IPaginatedResponse<IStaff>>({ endpoint }), {
    select: (data) => {
      return { data: data.data, metadata: data.metadata }
    },
    enabled,
  })
  useSetLoader(staffQuery)

  return staffQuery
}

export const useStaffMutation = () => {
  return useMutation(({ body, method, id }: IMutation<TBodyStaff>) =>
    apiClient.mutation<IStaff, TBodyStaff>({ endpoint: endpoints.staffs(id ? `/${id}` : ''), body, id, method })
  )
}
