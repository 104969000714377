import { IFormStep } from '../../../models/IFormStep'

import FormStepContainer from '../../../shared/FormStepContainer/FormStepContainer'
import { useEffect, useState } from 'react'
import { IClientProduct } from '../../../models/IClient'
import { useParams } from 'react-router-dom'
import ProjectProductList from '../../../shared/ProductList/ProjectProductList'
import { useTranslation } from 'react-i18next'

const ClientStepOne: React.FC<IFormStep> = (props) => {
  const { t } = useTranslation()
  const { slide } = useParams<{ slide: string }>()
  const storage = props.storage

  const [selectedProducts, setSelectedProducts] = useState<IClientProduct[]>([])

  useEffect(() => {
    if (slide === '1') props.setIsButtonDisabled(Boolean(!selectedProducts.length))
  }, [storage.item.products, slide, selectedProducts])

  return (
    <>
      <FormStepContainer>
        <h2 className={`xl:mb-16 lg:mb-16 md:mb-14 mb-12 text-black`}>{t('clientSubscriptionForm.stepOne.WHICH_PRODUCT')}</h2>
        <ProjectProductList
          selectedProducts={selectedProducts}
          storage={props.storage}
          productsLimit={5}
          setSelectedProducts={setSelectedProducts}
        />
      </FormStepContainer>
    </>
  )
}

export default ClientStepOne
