import { FC } from 'react'
import SectionStructure from '../../../layout/WebSite/SectionStructure'
import FindieGifIsotype from '../../../shared/Brand/Isotype/FindieGifIsotype'
import { useTranslation } from 'react-i18next'

const FindieAtributes: FC = () => {
  const { t } = useTranslation()
  const findieAtributes1: string[] = t('OurFreelancers.FindieAtributes.findieAtributes1', { returnObjects: true })
  const findieAtributes2: string[] = t('OurFreelancers.FindieAtributes.findieAtributes2', { returnObjects: true })

  return (
    <SectionStructure className='pt-0'>
      <div className='lg:block hidden'>
        <div className='grid grid-cols-12'>
          {findieAtributes1.map((iter: string, index: number) => {
            return (
              <div
                key={iter}
                className={`${
                  index + 1 === findieAtributes1.length ? 'col-span-12' : 'col-span-6'
                } mt-6 flex justify-self-center`}
              >
                <div>
                  <FindieGifIsotype variant='default' className='atributes-isotype' />
                </div>
                <p className='body1-medium self-center'>{iter}</p>
              </div>
            )
          })}
        </div>
        <div className='flex justify-center gap-8'>
          {findieAtributes2.map((iter: string) => {
            return (
              <div key={iter} className={`mt-6 flex`}>
                <div>
                  <FindieGifIsotype variant='default' className='atributes-isotype' />
                </div>
                <p className='body1-medium self-center'>{iter}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className='lg:hidden block'>
        {findieAtributes1.map((iter: string) => {
          return (
            <div key={iter} className={`col-span-4 mt-6 flex`}>
              <div>
                <FindieGifIsotype variant='default' className='atributes-isotype' />
              </div>
              <p className='body1-medium self-center'>{iter}</p>
            </div>
          )
        })}
        {findieAtributes2.map((iter: string) => {
          return (
            <div key={iter} className={`mt-6 flex`}>
              <div>
                <FindieGifIsotype variant='default' className='atributes-isotype' />
              </div>
              <p className='body1-medium self-center'>{iter}</p>
            </div>
          )
        })}
      </div>
    </SectionStructure>
  )
}

export default FindieAtributes
