import { FC } from 'react'

import LayoutAvatar from '../../shared/LayoutAvatar/LayoutAvatar'
import useUser from '../../customHooks/useUser'
import useAuth from '../../customHooks/useAuth'
import RightMenuUserProfile from './RightMenuUserProfile'
import FreelancerCalculator from '../../components/FreelanceUserProfile/FreelancerCalculator/FreelancerCalculator'
import dayjs from 'dayjs'
import LanguageSelector from '../../shared/Language/LanguageSelector'
// import SeñorityIcon from '../../shared/SeñorityIcon/SeñorityIcon'
// import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { useTranslation } from 'react-i18next'

const HeaderUserProfile: FC = () => {
  const { user } = useUser()
  const { logout } = useAuth()
  const { t } = useTranslation()

  if (!user) return null
  return (
    <div className='flex justify-between items-center md:px-8 px-4'>
      <div className='flex gap-4 items-center relative'>
        <div className='flex items-center'>
          <LayoutAvatar className='mr-4' size='medium' variant={user.avatar_style} name={user.name} lastName={user.last_name} />
          <div className='flex items-center'>
            <h4 className='w-48'>{user.name}</h4>
            {/* {freelancerProfile && (
              <>
                <span className='w-7 h-7 mr-1'>
                  <SeñorityIcon className='w-full h-full' experienceLevel={freelancerProfile.experience_level} />
                </span>
                <FiIcons name='status' className={`w-7 h-7 ${freelancerProfile.is_available_to_work ? '' : 'svg-red'}`} />
              </>
            )} */}
          </div>
        </div>
      </div>

      <div className='xl:block hidden'>
        <p className='buttontext2-semibold text-right cursor-pointer' onClick={logout}>
          Log out
        </p>
        <p className='body2-regular lg:mt-4 mt-2 text-right'>
          {t('GLOBAL.TODAY')}
          {dayjs().format('DD/MM/YYYY')}
        </p>
        {/* <LanguageSelector svgColor='text-black' /> */}
      </div>
      <div className='xl:hidden flex items-center gap-2'>
        {user.user_type === 'freelancer' && <FreelancerCalculator />}
        <RightMenuUserProfile />
      </div>
    </div>
  )
}

export default HeaderUserProfile
