import { FC } from 'react'
import { Link } from 'react-router-dom'

type TBlueBorderCard = {
  path: string
  title: string
  className?: string
}

const BlueBorderCard: FC<TBlueBorderCard> = (props) => {
  return (
    <Link className={props.className} to={props.path}>
      <div className='total-center h-full border-2 py-16 hover:border-blue-700 transition ease-in duration-200 shadow-black-005 cursor-pointer'>
        <p className='formtext1-regular'>{props.title}</p>
      </div>
    </Link>
  )
}

export default BlueBorderCard
