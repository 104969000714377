import { Box, Collapse, useMediaQuery } from '@material-ui/core'
import { FC, useCallback, useMemo, useState } from 'react'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { IProject } from '../../models/IProject'
import { formatDecimal, operation, workPlanPrice } from '../../utils/helpers'
import dayjs from 'dayjs'

type TProjectPayments = {
  paymentEntity: 'client' | 'freelancer'
}

const ProjectPayments: FC<IProject & TProjectPayments> = (props) => {
  const isMobile = useMediaQuery('(max-width: 430px)')
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const productNames =
    props.price.products &&
    props.price.products.list.length &&
    props.price.products.list
      .map((product) => product.name)
      .toString()
      .replaceAll(',', ', ')

  const payments = useMemo(() => {
    return props.payments
      .filter((payment) => payment.entity === props.paymentEntity)
      .filter((payment) => (props.paymentEntity === 'freelancer' ? payment.entity_id === props.staff[0].freelancer._id : true))
      .sort((a, b) => a.created_at - b.created_at)
  }, [props.payments])

  const paymentsColumns = () => {
    if (isMobile) return '7% 38% 30% 25%'
    return '15% 45% 20% 20%'
  }

  const searchPrice = () => {
    if (props.with_currency) return props.plan.currency_price
    if (props.plan.price_type === 'uf') return operation(props.plan.price, '*', props.uf ?? 0)
    return props.plan.price
  }

  const totalPayments = useCallback(() => {
    if (!payments) return 0
    return payments.reduce((acum, payment) => (acum = operation(acum, '+', payment.amount)), 0)
  }, [payments])

  return (
    <div>
      {props.paymentEntity === 'client' && (
        <>
          <div className='flex justify-between'>
            <p className='microcopy text-gray-300'>Desglose costo</p>
            <p
              className='microcopy text-gray-300 total-center text-magenta cursor-pointer'
              onClick={() => setIsCollapseOpen((prev: boolean) => !prev)}
            >
              {isCollapseOpen ? 'Minimizar' : 'Maximizar'}
              <FiIcons name={isCollapseOpen ? 'chevron_up' : 'chevron_down'} className='svg-magenta w-6 h-6 ml-2' />
            </p>
          </div>
          <div className='border-magenta mt-2'>
            <Collapse in={isCollapseOpen} collapsedSize='58.4px'>
              <div className='px-2'>
                <div className='flex justify-between border-b border-blue-300 p-4'>
                  <p className='subtitle4-medium text-blue'>Total proyecto</p>
                  <p className='subtitle4-medium text-blue'>
                    {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(props.price.total)}{' '}
                  </p>
                </div>
                <div className='flex justify-between border-b border-blue-300 p-4'>
                  <p className='subtitle4-regular text-blue'>Costo proyecto</p>
                  <p className='subtitle4-regular text-blue'>
                    {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(props.price.subtotal)}{' '}
                  </p>
                </div>
                <div className='flex justify-between border-b border-blue-300 p-4'>
                  <p className='subtitle4-regular text-blue'>Platform service</p>
                  <p className='subtitle4-regular text-blue'>
                    {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(operation(props.price.findie_fee, '+', searchPrice()))}{' '}
                  </p>
                </div>
                {!props.with_currency && (
                  <div className='flex justify-between border-b border-blue-300 p-4'>
                    <p className='subtitle4-regular text-blue'>Iva</p>
                    <p className='subtitle4-regular text-blue'>
                      {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(props.price.sii_tax)}{' '}
                    </p>
                  </div>
                )}
                {props.price.products.list.length > 0 && (
                  <div className='flex justify-between p-4'>
                    <p className='subtitle4-regular text-blue'>Adicionales: {productNames}</p>
                    <p className='subtitle4-regular text-blue'>
                      {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(props.price.products.total)}{' '}
                    </p>
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </>
      )}

      {props.paymentEntity === 'freelancer' && (
        <div className='flex justify-between border-magenta p-4'>
          <p className='subtitle4-medium text-blue'>Total a ganar</p>
          <p className='subtitle4-medium text-blue'>
            {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(workPlanPrice(props.staff[0])?.after_fee ?? 0)}{' '}
          </p>
        </div>
      )}

      <Box display='grid' gridTemplateColumns={paymentsColumns()} className='md:px-4 px-2 border-b border-gray-300 py-4 mt-6'>
        <p className='subtitle4-regular text-blue'>#</p>
        <p className='subtitle4-regular text-blue'>Descripción</p>
        <p className='subtitle4-regular text-blue'>Fecha</p>
        <p className='subtitle4-regular text-blue'>Monto</p>
      </Box>
      {payments.map((payment, index) => {
        return (
          <Box
            key={payment._id}
            display='grid'
            gridTemplateColumns={paymentsColumns()}
            className='md:px-4 px-2 border-b border-gray-300 py-4'
          >
            <p className='subtitle4-regular'>{index + 1}</p>
            <p className='subtitle4-regular truncate'>{payment.title}</p>
            <p className='subtitle4-regular'>{dayjs(payment.created_at).format('DD/MM/YYYY')}</p>
            <p className='subtitle4-regular'>
              {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(payment.amount)}
            </p>
          </Box>
        )
      })}
      <Box display='grid' gridTemplateColumns={paymentsColumns()} className='md:px-4 px-2 py-4'>
        <p className='subtitle4-regular' />
        <p className='subtitle4-regular' />
        <p className='subtitle4-medium text-blue mr-2'>Total balance</p>
        <p className='subtitle4-medium'>
          {props.with_currency ? 'USD' : 'CLP$'} {formatDecimal(totalPayments())}
        </p>
      </Box>
    </div>
  )
}

export default ProjectPayments
