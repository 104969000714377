import { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import useLocalStorage from '../../customHooks/useLocalStorage'
import SpecialSelect from '../SpecialSelect.tsx/SpecialSelect'
import UserProfileContainer from '../../components/WebSite/ClientSuscriptionForm/UserProfileContainer'
import InputField from '../../assets/UIkit/Forms/InputField'
import FiButton from '../../assets/UIkit/FiButton'
import { useCreateNewClient } from '../../customHooks/request/clientsQuery'
import routes from '../../constants/routes'
import { created_at } from '../../utils/helpers'
import { IClient } from '../../models/IClient'
import { useSnackbar } from 'notistack'
import { useWhoIAmFetch } from '../../customHooks/request/authQuery'
import useUser from '../../customHooks/useUser'
import { useSetLoader } from '../../customHooks/useSetLoader'
import FormError from '../../assets/UIkit/Forms/FormError'
import { useAuthRedirect } from '../../customHooks/useAuthRedirect'

type TField = 'name' | 'lastName' | 'address.country' | 'phone' | 'companyName' | 'company_size'

const defaultValues = {
  name: '',
  lastName: '',
  phone: '',
  'address.country': '',
  company_size: '',
  companyName: '',
  is_foreign: '',
}

const LiteClientSubscriptionForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const storage = useLocalStorage('new_client', undefined)
  const snackbar = useSnackbar()
  const { setIsUserLogged, setUser } = useUser()
  const form = useForm({ defaultValues })
  const loader = useSetLoader()
  const authRedirect = useAuthRedirect()

  const createNewClient = useCreateNewClient()
  const fetchWhoIAm = useWhoIAmFetch()

  const [selectedCompanySize, setSelectedCompanySize] = useState<string>('')

  const handleColorValidation = (field: TField, requiredStatus?: 'not_required') => {
    if (requiredStatus) return ''
    return form.watch(field) ? 'client-success-validation' : 'client-error-validation'
  }

  const required = t('clientSubscriptionForm.stepFive.FormStepContainer.inputEmail.required')
  const placeholderName = t('clientSubscriptionForm.stepFive.FormStepContainer.inputName.placeholder')
  const placeholderLastName = t('clientSubscriptionForm.stepFive.FormStepContainer.inputLastname.placeholder')
  const placeholderCountry = t('clientSubscriptionForm.stepFive.FormStepContainer.inputCountry.placeholder')
  const placeholderPhone = t('clientSubscriptionForm.stepFive.FormStepContainer.inputPhone.placeholder')
  const placeholderCompanyName = t('clientSubscriptionForm.stepFive.FormStepContainer.inputCompanyName.placeholder')
  const placeholderCompanySize = t('clientSubscriptionForm.stepFive.FormStepContainer.inputCompanySize.placeholder')
  const payInChile = t('clientSubscriptionForm.stepFive.EXP_WORK_IN_CHILE')
  const numbersOnlyAlert = t('clientSubscriptionForm.stepFive.NUMBERS_ONLY')
  const companySizeOptions: any = t('clientSubscriptionForm.COMPANY_SIZES', { returnObjects: true })

  const createClient = (data: FieldValues) => {
    if (!data.is_foreign) return form.setError('is_foreign', { message: payInChile })
    if (!selectedCompanySize) return form.setError('company_size', { message: required })

    const body = {
      ...data,
      createdAt: created_at,
      email: storage.item.email,
      is_foreign: !JSON.parse(data.is_foreign),
    } as Partial<IClient>

    loader.setIsOpen(true)
    createNewClient.mutate(
      { body },
      {
        onSuccess: async (resp) => {
          window.localStorage.setItem('locale', JSON.stringify(resp.token))
          const { response: userLogged } = await fetchWhoIAm()
          setUser(userLogged)
          setIsUserLogged(true)

          storage.removeItem()
          loader.setIsOpen(false)
          if (authRedirect.redirectPath?.length > 3) {
            authRedirect.routeRedirect()
          } else {
            history.push(routes.clientUserProfile.home)
          }
        },
        onError: (error: any) => {
          snackbar.enqueueSnackbar(error.json.error, { variant: 'error' })
          loader.setIsOpen(false)
        },
      }
    )
  }

  useEffect(() => {
    form.clearErrors()
  }, [selectedCompanySize])

  if (!storage || (storage && !storage.item?.email)) return <Redirect to='/*' />
  return (
    <UserProfileContainer>
      <form className='relative lg:w-6/12 md:w-10/12 w-full h-full flex items-center' onSubmit={form.handleSubmit(createClient)}>
        <div>
          <h2 className='xl:mb-16 lg:mb-16 md:mb-12 mb-6 text-black'>
            {t('clientSubscriptionForm.stepFive.FormStepContainer.title')}
          </h2>

          <div className='md:mt-8 mt-3 grid grid-cols-12 gap-4'>
            <InputField
              name={`name`}
              className='md:col-span-6 col-span-12'
              inputProps={{
                className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                  'name'
                )}`,
                placeholder: placeholderName,
              }}
              options={{ required: required }}
              form={form}
            />
            <InputField
              name={`lastName`}
              className='md:col-span-6 col-span-12'
              inputProps={{
                className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                  'lastName'
                )}`,
                placeholder: placeholderLastName,
              }}
              options={{ required: required }}
              form={form}
            />
          </div>
          <InputField
            name={`address.country`}
            className='md:mt-8 mt-3'
            inputProps={{
              className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                'address.country'
              )}`,
              placeholder: placeholderCountry,
            }}
            options={{ required: required }}
            form={form}
          />
          <InputField
            name={`phone`}
            className='md:mt-8 mt-3'
            inputProps={{
              className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                'phone',
                'not_required'
              )}`,
              type: 'tel',
              placeholder: placeholderPhone,
            }}
            options={{
              pattern: {
                value: /^[0-9+]*$/, // Expresión regular para permitir solo números y *
                message: numbersOnlyAlert,
              },
            }}
            form={form}
          />
          <InputField
            name={`companyName`}
            className='md:mt-8 mt-3'
            inputProps={{
              className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                'companyName'
              )}`,
              placeholder: placeholderCompanyName,
            }}
            options={{ required: required }}
            form={form}
          />
          <SpecialSelect
            options={companySizeOptions}
            title={placeholderCompanySize}
            name='company_size'
            selectedOption={selectedCompanySize}
            setSelectedOption={setSelectedCompanySize}
            form={form}
          />
          <FormError name='company_size' errors={form.formState?.errors} />

          <div className='flex md:flex-row flex-col md:gap-8 gap-2 items-center md:mt-4 mt-6'>
            <p className='buttontext4-regular'>
              {t('clientSubscriptionForm.stepFive.TAXES_IN_CHILE')}
              <span className='text-blue'>*</span>
            </p>
            <div className='flex items-center gap-4'>
              <div className='flex items-center gap-2'>
                <InputField
                  id='is_foreign_true'
                  name='is_foreign'
                  className='md:mt-2 mt-1'
                  inputProps={{
                    className: 'no-height',
                    type: 'radio',
                    value: 'true',
                  }}
                  showErrorMessage={false}
                  form={form}
                />
                <label htmlFor='is_foreign_true' className='buttontext4-regular cursor-pointer block'>
                  {t('GLOBAL.YES')}
                </label>
              </div>
              <div className='flex items-center gap-2'>
                <InputField
                  id='is_foreign_false'
                  name='is_foreign'
                  className='md:mt-2 mt-1'
                  inputProps={{
                    className: 'no-height',
                    type: 'radio',
                    value: 'false',
                  }}
                  showErrorMessage={false}
                  form={form}
                />
                <label htmlFor='is_foreign_false' className='buttontext4-regular cursor-pointer block'>
                  No
                </label>
              </div>
            </div>
          </div>
          <FormError name='is_foreign' errors={form?.formState?.errors} />

          <div className='w-full  text-right mt-6'>
            <FiButton theme='light' type='submit' className='px-6 py-1'>
              <p className='buttontext4-medium'>{t('GLOBAL.SAVE')}</p>
            </FiButton>
          </div>
        </div>
      </form>
    </UserProfileContainer>
  )
}

export default LiteClientSubscriptionForm
