import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useStaffListQuery } from '../../../customHooks/request/staffQuery'
import useUser from '../../../customHooks/useUser'
import TitleWithUnderline from '../../../shared/TitleWithUnderline/TitleWithUnderline'
import ProjectItemCard from '../../../shared/ProjectUserProfile/ProjectItemCard'
import UserProfileDialog from '../../../shared/UserProfileDialog/UserProfileDialog'

const FreelancerHistoricProjectList: FC = () => {
  const { freelancerProfile } = useUser()
  const { data: staffQuery } = useStaffListQuery({
    filters: {
      freelancer: freelancerProfile?._id ?? '',
    },
    enabled: Boolean(freelancerProfile?._id),
  })

  return (
    <div>
      <Link to={routes.freelancerUserProfile.projects.options}>
        <TitleWithUnderline title='Menu Proyectos' />
      </Link>

      <div className='mt-12'>
        {staffQuery && (
          <>
            {staffQuery.data.length > 0 ? (
              staffQuery.data
                .filter((staff) => ['cancelled', 'finished'].includes(staff.project.project_status))
                .map((staff) => {
                  return <ProjectItemCard key={staff._id} {...staff.project} entity='freelancer' isHistoric />
                })
            ) : (
              <UserProfileDialog>
                <h4 className='text-blue text-center'>¡Mmmm! aún no tienes proyectos históricos.</h4>
              </UserProfileDialog>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default FreelancerHistoricProjectList
