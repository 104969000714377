import { FC, useEffect, useMemo, useState } from 'react'
import { TProjectSections } from '../Project'
import { formatDecimal, operation, workPlanPrice } from '../../../utils/helpers'

import { Button } from '@material-ui/core'
import Tabs, { TTab } from '../../../assets/UIkit/Tabs'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import { IStaff } from '../../../models/IStaff'
import { TPaymentsSection } from './ProjectPayments'
import dayjs from 'dayjs'

const FreelancerPayments: FC<TProjectSections & TPaymentsSection> = (props) => {
  const { data: project } = props.project
  const [staff, setStaff] = useState<IStaff>()

  const tabs: TTab[] = useMemo(() => {
    if (!project) return []

    setStaff(project.staff[0])
    return project.staff.map((staff: IStaff, index: number) => {
      return {
        id: index + 1,
        text: `${staff.freelancer.name} ${staff.freelancer.lastName}`,
        isSelected: index === 0,
        action: () => setStaff(staff),
      }
    })
  }, [project])

  const paymentStatus = () => {
    if (!project || !staff) return { totalPayments: '0', debs: 0 }

    const totalPayments = project.payments
      .filter((payment) => payment.entity === 'freelancer' && payment.entity_id === staff.freelancer._id)
      .reduce((acum, payment) => acum + payment.amount, 0)
    const debs = operation(workPlanPrice(staff)?.after_fee ?? 0, '-', totalPayments)
    return { totalPayments: formatDecimal(totalPayments), debs }
  }

  useEffect(() => {
    if (paymentStatus().debs > 0) {
      props.setDebs(paymentStatus().debs)
    }
  }, [paymentStatus().debs])

  if (!project || !staff) return null
  return (
    <>
      <p className='body1-medium'>Freelancer</p>

      <Tabs tabs={tabs} variant='default' />
      <div
        className={`grid grid-cols-12 mt-6 gap-4 ${
          staff.has_leave_project ? 'border border-red-400 bg-red-005 p-4 rounded-xl' : ''
        }`}
      >
        <div className='col-span-7'>
          <p className='buttontext1-semibold'>
            Valor acordado: <span className='buttontext1-medium'>{formatDecimal(workPlanPrice(staff)?.after_fee ?? 0)}</span>
          </p>

          {project.payments
            .filter((payment) => payment.entity === 'freelancer')
            .map((payment) => {
              return (
                <div key={payment._id} className='grid grid-cols-12 mt-2'>
                  <p className='col-span-4 self-center body2-regular'>{payment.title}</p>
                  <p className='col-span-3 self-center body2-regular text-right'>{formatDecimal(payment.amount)}</p>
                  <p className='col-span-3 self-center body2-regular text-right'>
                    {dayjs(payment.created_at).format('DD/MM/YYYY')}
                  </p>
                  {!staff.has_leave_project && project.project_status === 'in_progress' && (
                    <div className='col-span-2 flex justify-end'>
                      <FiIcons
                        name='edit'
                        className='mr-2 cursor-pointer'
                        onClick={() => props.dialogToggle({ dialogAction: 'update', dialogData: payment })}
                      />
                      <FiIcons
                        name='skull'
                        className='cursor-pointer'
                        onClick={() => props.dialogToggle({ dialogAction: 'delete', dialogData: payment })}
                      />
                    </div>
                  )}
                </div>
              )
            })}

          {paymentStatus().debs !== 0 && (
            <Button
              variant='outlined'
              className='mt-4'
              color='primary'
              onClick={() =>
                props.dialogToggle({
                  dialogAction: 'create',
                  dialogData: { entity_id: staff.freelancer._id, email: staff.freelancer.email },
                })
              }
              disabled={props.isDisabledFields}
            >
              Agregar pago
            </Button>
          )}
        </div>

        <div className='col-span-5'>
          <div className='flex justify-between bg-light-gray px-2 py-2'>
            <p className='buttontext1-semibold'>Total pagado</p>
            <p className='body2-medium'>{paymentStatus().totalPayments}</p>
          </div>
          {staff.has_leave_project ? (
            <p className='buttontext1-semibold text-red mt-4'>Freelance retirado del proyecto</p>
          ) : (
            <div
              className={`flex justify-between p-2 ${
                paymentStatus().debs === 0 ? 'text-white bg-soft-green' : 'text-red bg-light-gray'
              }`}
            >
              <span className='body2-medium'>{paymentStatus().debs !== 0 ? 'Debe' : 'Pagado'}</span>
              <span className='body2-medium'>{paymentStatus().debs !== 0 && formatDecimal(paymentStatus().debs)}</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FreelancerPayments
