// import { Dispatch, SetStateAction } from 'react'   DESCOMENTA BARUC

import { IProject } from '../../../../../../models/IProject'
import { IProduct } from '../../../../../../models/IProduct'
import { IWorkPlanVersion } from '../../../../../../models/IWorkPlan'
import { useOneOfferQuery } from '../../../../../../customHooks/request/offersQuery'
import HelpTooltip from '../../../../../../shared/HelpTooltip/HelpTooltip'
import { useEffect } from 'react'
import useTotalWorkPlanPrice from '../../../../../../customHooks/useTotalWorkPlanPrice'

type TBreakDownDetail = {
  project: IProject
  offerId: string
  selectedWorkPlanVersion: IWorkPlanVersion
  selectedProducts: IProduct[]
  // setTotalProjectPrice: Dispatch<SetStateAction<number>>  DESCOMENTA BARUC
}

const BreakDownDetail = (props: TBreakDownDetail) => {
  const { project } = props

  const { data: offerQuery } = useOneOfferQuery({ id: props.offerId, enabled: false })

  const { freelancerServices, platformFee, products, total, siiTax } = useTotalWorkPlanPrice({
    offer: offerQuery,
    selectedProducts: props.selectedProducts,
    selectedWorkPlanVersion: props.selectedWorkPlanVersion,
  })

  useEffect(() => {
    // props.setTotalProjectPrice(total)  DESCOMENTA BARUC
  }, [total])

  if (!project) return null
  return (
    <>
      <div className='lg:w-10/12 w-full border-b-2 border-white md:px-4 px-2 py-4 md:mt-0 mt-4'>
        <h2 className='text-blue md:text-right'>
          {project.with_currency ? 'USD' : 'CLP$'} {total}{' '}
        </h2>
      </div>

      <div className='flex justify-between lg:w-10/12 w-full md:px-4 px-2 pt-6'>
        <p className='subtitle4-regular text-blue'>Servicio freelancer</p>
        <p className='subtitle4-regular text-blue'>
          {project.with_currency ? 'USD' : 'CLP$'} {freelancerServices}{' '}
        </p>
      </div>
      <div className='flex justify-between lg:w-10/12 w-full md:px-4 px-2 pt-4'>
        <p className='subtitle4-regular text-blue flex items-center'>
          Platform fee{' '}
          <HelpTooltip
            title='Cargo por servicio al cliente y uso de la plataforma : Fee de Búsqueda exitosa + 5%'
            className='ml-2'
            placement='right-start'
          />
        </p>
        <p className='subtitle4-regular text-blue'>
          {project.with_currency ? 'USD' : 'CLP$'} {platformFee}{' '}
        </p>
      </div>
      {props.selectedProducts.length > 0 && (
        <div className='flex justify-between lg:w-10/12 w-full md:px-4 px-2 pt-4'>
          <p className='subtitle4-regular text-blue'>Adicionales</p>
          <p className='subtitle4-regular text-blue'>
            {project.with_currency ? 'USD' : 'CLP$'} {products}{' '}
          </p>
        </div>
      )}
      {!project.with_currency && project.price?.products.total === 0 && (
        <div className='flex justify-between lg:w-10/12 w-full md:px-4 px-2 pt-4'>
          <p className='subtitle4-regular'>IVA</p>
          <p className='subtitle4-regular'>
            {project.with_currency ? 'USD' : 'CLP$'} {siiTax}{' '}
          </p>
        </div>
      )}

      <div className='flex justify-end lg:w-10/12 border-t-2 border-white w-full gap-12 md:px-4 px-2 pt-4 mt-10'>
        <p className='subtitle4-medium'>Total</p>
        <p className='subtitle4-medium'>
          {project.with_currency ? 'USD' : 'CLP$'} {total}{' '}
        </p>
      </div>
    </>
  )
}

export default BreakDownDetail
