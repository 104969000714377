import { FC } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import blueWaves from '../../../assets/images/userProfile/blue-waves.svg'
import frame from '../../../assets/images/userProfile/frame.png'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import BlueWavesCard from '../../../shared/ProjectOptions/BlueWavesCard'
import BlueBorderCard from '../../../shared/ProjectOptions/BlueBorderCard'
import { useTranslation } from 'react-i18next'

const ClientProjectOptions: FC = () => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery('(min-width:825px)')

  return (
    <Box maxWidth={isDesktop ? '620px' : '100%'} className='relative pb-8'>
      <BlueBorderCard
        className='mt-4'
        title={`${t('GLOBAL.CREATE_PROJECT')}`}
        path={`${routes.clientUserProfile.projects.new}/1`}
      />

      <div className='grid grid-cols-12 gap-4 mt-4'>
        <BlueWavesCard
          className='md:col-span-6 col-span-12 justify-self-start'
          title={`${t('GLOBAL.ACTIVE_PROJECTS')}`}
          path={routes.clientUserProfile.projects.list}
        />

        <Link className='md:col-span-6 col-span-12 md:h-full h-44 pt-4' to={routes.clientUserProfile.projects.historical}>
          <div className='relative h-full cursor-pointer'>
            <p
              className='formtext1-regular z-10'
              style={{ position: 'absolute', top: '46%', left: '46%', transform: 'translate(-50%, -50%)' }}
            >
              {t('GLOBAL.SAVED_PRO')}
            </p>
            <img className='absolute w-full h-full z-0 ' src={frame} alt='marco' />
          </div>
        </Link>

        <Link className='md:col-span-6 col-span-12 md:mt-0 mt-4' to={routes.clientUserProfile.projects.myFreelancers}>
          <div className='border-border-blue-400 p-0.5 shadow-black-005 cursor-pointer'>
            <img className='w-full opacity-0' src={blueWaves} alt='olas' />
            <p className='formtext1-regular my-4 text-center'> {t('GLOBAL.MY_FREE')}</p>
            <img className='w-full opacity-0' src={blueWaves} alt='olas' />
          </div>
        </Link>
      </div>
    </Box>
  )
}

export default ClientProjectOptions
