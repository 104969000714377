import { FC, useEffect } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { FieldValues, useForm } from 'react-hook-form'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { IStudy } from '../../../models/IFreelancer'
import { TFreelancerBookSections } from '../../../views/FreelancerUserProfile/FreelancerBook'
import { max } from 'lodash'

const educationLength = Array.from(Array(10).keys())

const FreelancerEducation: FC<TFreelancerBookSections> = (props) => {
  const { data: freelancer, refetch: freelancerRefetch } = props.freelancerQuery
  const isMobile = useMediaQuery('(max-width: 430px)')
  const form = useForm()

  const saveChanges = (data: FieldValues) => {
    if (!freelancer) return

    const studies = educationLength.reduce((acc: IStudy[], study: number) => {
      const hasThisStudy = freelancer.studies.some((currentStudy) => currentStudy.position === study + 1)
      const newStudy = {
        position: study + 1,
        institution: data[`${study + 1}-institution`],
        degree: data[`${study + 1}-degree`],
        description: data[`${study + 1}-description`],
      }
      if (hasThisStudy) {
        return acc.map((currentStudy) => {
          if (currentStudy.position === study + 1) return newStudy
          return currentStudy
        })
      }
      return [...acc, newStudy]
    }, freelancer.studies)
    props.updateFreelancer.mutate(
      { body: { studies }, id: freelancer._id },
      {
        onSuccess: () => freelancerRefetch(),
      }
    )
  }

  useEffect(() => {
    if (!freelancer) return

    freelancer.studies.forEach((study) => {
      form.setValue(`${study.position}-institution`, study.institution)
      form.setValue(`${study.position}-degree`, study.degree)
      form.setValue(`${study.position}-description`, study.description)
    })
  }, [freelancer])

  if (!freelancer) return null
  return (
    <form className='relative' onSubmit={form.handleSubmit(saveChanges)}>
      <button className='subtitle4-medium sticky top-0 ml-auto block cursor-pointer' type='submit'>
        Guardar
      </button>

      {educationLength.map((study, index) => {
        const currentStudy = freelancer.studies.find((currentStudy) => currentStudy.position === study + 1)
        const isIncomplete = currentStudy
          ? (currentStudy.institution || currentStudy.degree || currentStudy.description) &&
            (!currentStudy.institution || !currentStudy.degree || !currentStudy.description)
          : false

        return (
          <Box display='grid' gridTemplateColumns='55px 1fr' alignItems='center'>
            <div className='border-b border-gray-300 h-full flex items-center'>
              <div className='w-10 h-10 total-center border border-black rounded-full microcopy'>{index + 1}</div>
            </div>

            <div key={study} className='pb-0 border-b border-gray-300 relative'>
              {index < 3 && <p className='body2-regular text-blue absolute right-4 top-2'>*</p>}

              {isIncomplete && (
                <p className='microcopy text-red -mb-2 mt-4'>
                  Este estudio está incompleto. Debes completarlo para que sea visible en la web
                </p>
              )}

              <Box display='grid' gridTemplateColumns='70px 1fr' className='gap-8 items-center'>
                <p className='body2-regular text-blue'>Institución:</p>

                <InputField
                  name={`${study + 1}-institution`}
                  variant='transparent'
                  inputProps={{
                    className: 'w-full body2-regular bg-transparent',
                    placeholder: index === 0 ? '(ej: Universidad del Diseño Gráfico Mundial)' : '',
                  }}
                  form={form}
                />
              </Box>
              <Box display='grid' gridTemplateColumns='70px 1fr' className='gap-8 items-center -mt-2'>
                <p className='body2-regular text-blue'>Grado:</p>

                <InputField
                  name={`${study + 1}-degree`}
                  variant='transparent'
                  inputProps={{
                    className: 'w-full body2-regular',
                    placeholder: index === 0 ? '(ej: Diplomado en Ilustración Editorial)' : '',
                  }}
                  form={form}
                />
              </Box>
              <Box display='grid' gridTemplateColumns={isMobile ? '1fr' : '70px 1fr'} className='md:gap-8 gap-0 mb-1'>
                <p className='body2-regular text-blue'>Descripción:</p>

                <InputField
                  name={`${study + 1}-description`}
                  variant='transparent'
                  textarea
                  options={{ maxLength: { value: 80, message: 'Máximo 80 caracteres.' } }}
                  textareaProps={{
                    className: 'w-full body2-regular',
                    placeholder: index === 0 ? '(ej: Tendencias en ilustración editorial) No más de 80 caracteres.' : '',
                    maxLength: 80,
                    rows: isMobile ? 1 : 2,
                  }}
                  form={form}
                />
              </Box>
            </div>
          </Box>
        )
      })}
    </form>
  )
}

export default FreelancerEducation
