import { FC, useRef, useState } from 'react'
import { IExperience, IFreelancer, IStudy } from '../../../models/IFreelancer'

import { Collapse } from '@material-ui/core'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import useExecuteScroll from '../../../customHooks/useExecuteScroll'
import { useTranslation } from 'react-i18next'

type TFreelancerProfileStudiesExperiences = { freelancer: IFreelancer; theme: 'white' | 'black' }
type TStudiesAndExperienceTabs = { title: string; shouldRender: boolean; type: 'experience' | 'study' | '' }
type TMoreButton = { shouldRenderMore: boolean; onClick: () => void; theme: 'white' | 'black' }

const FreelancerProfileStudiesExperiences: FC<TFreelancerProfileStudiesExperiences> = (props) => {
  const experienceScroll = useExecuteScroll({ block: 'nearest' })
  const studiesScroll = useExecuteScroll()
  const experienceRef = useRef<HTMLDivElement | null>(null)
  const studiesRef = useRef<HTMLDivElement | null>(null)
  const [shouldRenderMore, setShouldRenderMore] = useState<boolean>(false)
  const [experienceOrStudies, setExperienceOrStudies] = useState<'experience' | 'study' | ''>('experience')
  const hasXdExperience = props.freelancer.experiences.length > 0
  const hasStudies = props.freelancer.studies.length > 0
  const hasStudiesAndXdExperience = hasXdExperience || hasStudies
  const { t } = useTranslation()
  const XpHighlights = t('freelancerProfile.studiesAndExperienceTabs.XpHighlights')
  const educationTab = t('freelancerProfile.studiesAndExperienceTabs.education')
  const studiesAndExperienceTabs: TStudiesAndExperienceTabs[] = [
    { title: XpHighlights, shouldRender: hasXdExperience, type: 'experience' },
    { title: educationTab, shouldRender: hasStudies, type: 'study' },
  ]

  const openMobileColapse = (type: 'experience' | 'study' | '') => {
    setExperienceOrStudies(type)
  }

  const openMobileCollapse = (entity: 'experience' | 'study') => {
    if (entity === 'experience') {
      experienceScroll.execute()
      openMobileColapse(experienceOrStudies === 'experience' ? '' : 'experience')
      return
    }
    studiesScroll.execute()
    openMobileColapse(experienceOrStudies === 'study' ? '' : 'study')
  }

  return (
    <>
      {/* DESKTOP/TABLET */}
      <div className='md:block hidden'>
        <div className='grid grid-cols-12 gap-4 mt-16'>
          {studiesAndExperienceTabs.map((tab, index) => {
            const selectedTabStyle = experienceOrStudies === tab.type ? 'border-b subtitle6-semibold' : 'subtitle6-regular'
            return (
              <div key={tab.title} className={index === 0 ? 'col-span-4' : 'col-span-6'}>
                {tab.shouldRender && (
                  <span
                    className={` cursor-pointer pb-1 ${selectedTabStyle}`}
                    style={{
                      color: `var(--${props.theme})`,
                      borderColor: experienceOrStudies === tab.type ? `var(--${props.theme})` : '',
                    }}
                    onClick={() => setExperienceOrStudies(tab.type)}
                  >
                    {tab.title}
                  </span>
                )}
              </div>
            )
          })}
        </div>
        <Collapse in={shouldRenderMore} collapsedSize={hasStudiesAndXdExperience ? '110px' : '0px'}>
          {experienceOrStudies === 'experience' && (
            <div className='grid grid-cols-12 gap-4 mt-4'>
              {props.freelancer.experiences
                .sort((a, b) => a.position - b.position)
                .filter((experience) => experience.rol && experience.description && experience.position)
                .map((experience: IExperience, index: number) => {
                  return (
                    <div
                      key={experience.description + index}
                      className={experienceOrStudies === 'experience' ? 'col-span-4' : 'col-span-6'}
                    >
                      <p className='body2-medium flex items-center' style={{ color: `var(--${props.theme})` }}>
                        {experience.rol}{' '}
                        {experience.link && (
                          <a href={experience.link} target='_blank'>
                            <FiIcons className={`ml-2 w-4 mb-1 ${props.theme === 'white' ? 'svg-white' : ''}`} name='link' />
                          </a>
                        )}
                      </p>
                      <p className='body2-regular' style={{ color: `var(--${props.theme})` }}>
                        {experience.description}
                      </p>
                    </div>
                  )
                })}
            </div>
          )}
          {experienceOrStudies === 'study' && (
            <div className='grid grid-cols-12 gap-4 mt-4'>
              {props.freelancer.studies
                .sort((a, b) => a.position - b.position)
                .filter((study) => study.institution && study.description && study.position && study.degree)
                .map((study: IStudy, index: number) => {
                  return (
                    <div key={study.institution + index} className='col-span-6 flex'>
                      <p className='body2-medium mr-4' style={{ color: `var(--${props.theme})` }}>
                        ●
                      </p>
                      <div className=''>
                        <p className='body2-regular' style={{ color: `var(--${props.theme})` }}>
                          {study.institution}
                        </p>
                        <p className='body2-medium' style={{ color: `var(--${props.theme})` }}>
                          {study.degree}
                        </p>
                        <p className='body2-regular' style={{ color: `var(--${props.theme})` }}>
                          {study.description}
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
          )}
        </Collapse>

        {hasStudiesAndXdExperience && (
          <MoreButton
            theme={props.theme}
            shouldRenderMore={shouldRenderMore}
            onClick={() => setShouldRenderMore((prev: boolean) => !prev)}
          />
        )}
      </div>

      {/* MOBILE */}
      <div className='md:hidden block'>
        <div
          ref={experienceScroll.elementRef}
          className='flex justify-between items-center cursor-pointer md:pt-8 pt-14'
          onClick={() => openMobileCollapse('experience')}
        >
          <span
            className={` pb-1 ${experienceOrStudies === 'experience' ? 'border-b subtitle6-semibold' : 'subtitle6-medium'}`}
            style={{ color: `var(--${props.theme})`, borderColor: props.theme === 'white' ? 'white' : 'black' }}
          >
            XP destacadas
          </span>
          <div>
            {experienceOrStudies === 'experience' ? (
              <FiIcons name='chevron_up' className={`ml-2 w-5 h-3 ${props.theme === 'white' ? 'svg-white' : 'text-black'}`} />
            ) : (
              <FiIcons name='chevron_down' className={`ml-2 w-5 h-3 ${props.theme === 'white' ? 'svg-white' : 'text-black'}`} />
            )}
          </div>
        </div>
        <Collapse in={experienceOrStudies === 'experience'}>
          <div ref={experienceRef} className='grid grid-cols-12 gap-4 mt-4'>
            {props.freelancer.experiences
              .sort((a, b) => a.position - b.position)
              .filter((experience) => experience.rol && experience.description && experience.position)
              .map((experience: IExperience, index: number) => {
                return (
                  <div key={experience.description + index} className='col-span-12'>
                    <p className='body2-medium flex items-center' style={{ color: `var(--${props.theme})` }}>
                      {experience.rol}{' '}
                      {experience.link && (
                        <a href={experience.link} target='_blank'>
                          <FiIcons className={`ml-2 w-4 mb-1 ${props.theme === 'white' ? 'svg-white' : ''}`} name='link' />
                        </a>
                      )}
                    </p>
                    <p className='body2-regular' style={{ color: `var(--${props.theme})` }}>
                      {experience.description}
                    </p>
                  </div>
                )
              })}
          </div>
        </Collapse>

        <div
          ref={studiesScroll.elementRef}
          className='flex justify-between cursor-pointer mt-6'
          onClick={() => openMobileCollapse('study')}
        >
          <span
            className={`pb-1 ${experienceOrStudies === 'study' ? 'border-b subtitle6-semibold' : 'subtitle6-medium'}`}
            style={{ color: `var(--${props.theme})`, borderColor: props.theme === 'white' ? 'white' : 'black' }}
          >
            Educación formal y no formal
          </span>
          <div>
            {experienceOrStudies === 'study' ? (
              <FiIcons name='chevron_up' className={`ml-2 w-5 h-3 ${props.theme === 'white' ? 'svg-white' : 'text-black'}`} />
            ) : (
              <FiIcons name='chevron_down' className={`ml-2 w-5 h-3 ${props.theme === 'white' ? 'svg-white' : 'text-black'}`} />
            )}
          </div>
        </div>
        <Collapse in={experienceOrStudies === 'study'}>
          <div ref={studiesRef} className='grid grid-cols-12 gap-4 mt-4'>
            {props.freelancer.studies
              .sort((a, b) => a.position - b.position)
              .filter((study) => study.institution && study.description && study.position && study.degree)
              .map((study: IStudy, index: number) => {
                return (
                  <div key={study.institution + index} className='col-span-12'>
                    <p className='body2-regular' style={{ color: `var(--${props.theme})` }}>
                      {study.institution}
                    </p>
                    <p className='body2-medium' style={{ color: `var(--${props.theme})` }}>
                      {study.degree}
                    </p>
                    <p className='body2-regular' style={{ color: `var(--${props.theme})` }}>
                      {study.description}
                    </p>
                  </div>
                )
              })}
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default FreelancerProfileStudiesExperiences

const MoreButton: FC<TMoreButton> = (props) => {
  const { t } = useTranslation()
  return (
    <div className='flex justify-end items-center cursor-pointer mt-6' onClick={props.onClick}>
      <p className={`body2-medium  w-20`} style={{ color: `var(--${props.theme})` }}>
        {props.shouldRenderMore
          ? t('freelancerProfile.FreelancerProfileStudiesExperiences.minimize')
          : t('freelancerProfile.FreelancerProfileStudiesExperiences.button')}
      </p>
      {props.shouldRenderMore ? (
        <FiIcons name='chevron_up' className={`mb-1 w-8 h-8 ${props.theme === 'white' ? 'svg-white' : ''}`} />
      ) : (
        <FiIcons name={props.theme === 'white' ? 'circle_add_white' : 'circleAdd'} className='mr-1 w-8 h-8' />
      )}
    </div>
  )
}
