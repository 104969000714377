import { FC } from 'react'
import { useParams } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { useOfferListQuery } from '../../../../../customHooks/request/offersQuery'
import TableCandidateCard from '../../../../../shared/ProjectUserProfile/TableCandidateCard'
import UserProfileDialog from '../../../../../shared/UserProfileDialog/UserProfileDialog'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { cn } from '../../../../../utils/helpers'
import { FiIcons } from '../../../../../assets/UIkit/Icons/FiIcons'

const ClientProjectCandidatesList: FC = () => {
  const { t } = useTranslation()
  const params = useParams<{ projectId: string }>()
  const { data: offerQuery } = useOfferListQuery({
    filters: { project: params.projectId, is_available_to_client: true, status: { $not: { $regex: 'declined' } } },
  })

  if (!offerQuery) return null
  if (offerQuery && offerQuery.length === 0) {
    return (
      <UserProfileDialog className='mt-2'>
        <h4 className='text-blue text-center'> {t('PROJECT_DETAILS.NO_CANDIDATES')} </h4>
      </UserProfileDialog>
    )
  }
  return (
    <>
      {offerQuery[0].project.offer_expiration_date && (
        <p className='mt-2 microcopy text-blue'>
          {t('PROJECT_DETAILS.OFFERS_EXP_DATE')}: {dayjs(offerQuery[0].project.offer_expiration_date).format('DD/MM/YYYY')}
        </p>
      )}

      <div style={{ maxWidth: '70vw' }} className='mt-4 w-full'>
        <p className='microcopy text-right text-golden'>
          <FiIcons name='smallStar' /> Findie’s pick
        </p>
        {offerQuery.map((offer, index) => {
          return (
            <section
              key={offer._id}
              className={cn('border-gray-300', { 'border-t': index > 0 }, { 'border-b': index + 1 === offerQuery.length })}
            >
              <TableCandidateCard
                {...offer}
                candidatePath={`${routes.clientUserProfile.projects.detail}/${params.projectId}/candidatos/detalle/${offer._id}`}
              />
            </section>
          )
        })}
      </div>
    </>
  )
}

export default ClientProjectCandidatesList
