import { Dispatch, FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Collapse, Box } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import InputField from '../../assets/UIkit/Forms/InputField'
import { cleanString } from '../../utils/helpers'
import { ISkill } from '../../models/ISkill'
import PillBoxSkills from '../PillBoxSkills/PillBoxSkills'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { useTranslation } from 'react-i18next'

type TSkillsMultiSelect = {
  skills: ISkill[]
  selectedSkills: ISkill[]
  label?: string | ReactElement
  whitOutBorder?: boolean
  className?: string
  skillsLimit?: number
  setSelectedSkills: Dispatch<React.SetStateAction<ISkill[]>>
}

const SkillsMultiSelect: FC<TSkillsMultiSelect> = (props) => {
  const { skillsLimit = 0 } = props
  const { t } = useTranslation()
  const placeholder = t('clientSubscriptionForm.SkillsMultiSelect.placeholder')
  const form = useForm()
  const [isOpen, setIsOpen] = useState(false)
  const filterWord = form.watch('filter_word')

  const skillsList = useMemo(() => {
    const skills = props.skills.filter((skill) => !props.selectedSkills.some((selectedSkill) => selectedSkill._id === skill._id))

    if (!filterWord) return skills

    return skills.filter((skill) => cleanString(skill.name as string).includes(cleanString(filterWord)))
  }, [props.skills, props.selectedSkills, filterWord])

  const selectOption = (selectedSkill: ISkill) => {
    if (isOptionSelected(selectedSkill)) {
      const removeSkill = props.selectedSkills.filter((skill) => skill._id !== selectedSkill._id)
      props.setSelectedSkills(removeSkill)
      return
    }
    props.setSelectedSkills([...props.selectedSkills, selectedSkill])
  }

  const isOptionSelected = (selectedSkill: ISkill) => {
    return props.selectedSkills.some((skill) => skill._id === selectedSkill._id)
  }

  const removeSkill = (skillToRemove: ISkill) => {
    const removeSkill = props.selectedSkills.filter((skill) => skill._id !== skillToRemove._id)
    props.setSelectedSkills(removeSkill)
  }

  useEffect(() => {
    props.selectedSkills.length === skillsLimit && setIsOpen(false)
  }, [props.selectedSkills])

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className='relative'>
        <Box display='grid' gridTemplateColumns='1fr 30px' className={`items-center h-16 gap-8  ${props.className}`}>
          <div>
            {props.selectedSkills.length < skillsLimit && isOpen && (
              <InputField
                className='w-full'
                name='filter_word'
                variant='transparent'
                inputProps={{
                  placeholder: placeholder,
                  className: 'w-full',
                }}
                form={form}
              />
            )}
            {(props.selectedSkills.length < skillsLimit || skillsLimit === 0) && !isOpen && (
              <div className='cursor-pointer' onClick={() => setIsOpen(true)}>
                {typeof props.label === 'string' ? <p className='subtitle4-regular'>{props.label}</p> : props.label}
              </div>
            )}
            {skillsLimit !== 0 && props.selectedSkills.length === skillsLimit && (
              <p className='subtitle4-regular'>Ya tienes {props.skillsLimit} habilidades seleccionadas</p>
            )}
          </div>
          <FiIcons name='chevron_down' />
        </Box>
        <Collapse in={isOpen} className='absolute w-full top-16 bg-white'>
          <div className={`md:max-h-96 max-h-60 overflow-y-scroll px-4 py-2 ${props.whitOutBorder ? '' : 'border-blue'}`}>
            {skillsList.map((skill) => {
              return (
                <p key={skill._id} className={`subtitle4-regular mt-4 cursor-pointer`} onClick={() => selectOption(skill)}>
                  {skill.name}
                </p>
              )
            })}
          </div>
        </Collapse>
        <PillBoxSkills skills={props.selectedSkills} onRemove={removeSkill} />
      </div>
    </ClickAwayListener>
  )
}

export default SkillsMultiSelect
