import { FC } from 'react'
import Typewriter from 'typewriter-effect'

type TTypingAnimation = {
  words: string[]
}

const TypingAnimation: FC<TTypingAnimation> = (props) => {
  return (
    <Typewriter
      options={{
        strings: props.words,
        autoStart: true,
        loop: true,
        deleteSpeed: 300,
        //@ts-ignore
        pauseFor: 2000,
      }}
    />
  )
}

export default TypingAnimation

// import { FC } from 'react'
// import { makeStyles } from '@material-ui/core'

// type TTypingAnimation = {
//   words: string[]
// }

// const useStyles = makeStyles((theme) => ({
//   ul_container: {
//     overflow: 'hidden',
//     height: 80,
//     padding: 0,
//   },
//   slide_animation: {
//     listStyle: 'none',
//     position: 'relative',
//     top: 0,
//     left: 0,
//     animation: (props: { steps: number }) => `$slideDesktop ${props.steps * 3}s steps(${props.steps}) forwards infinite`,
//   },
//   type_animation: {
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     animation: 'typing 1.5s infinite alternate linear',
//   },
//   '@keyframes slideDesktop': {
//     '100%': {
//       top: (props: { steps: number }) => `-${props.steps * 80}px`,
//     },
//   },
//   '@keyframes slideTablet': {
//     '100%': {
//       top: (props: { steps: number }) => `-${props.steps * 90}px`,
//     },
//   },
//   '@keyframes slideMobile': {
//     '100%': {
//       top: (props: { steps: number }) => `-${props.steps * 50}px`,
//     },
//   },
//   '@media (max-width: 800px)': {
//     ul_container: {
//       height: 90,
//     },
//     slide_animation: {
//       animation: (props: { steps: number }) => `$slideTablet ${props.steps * 3}s steps(${props.steps}) forwards infinite`,
//     },
//   },
//   '@media (max-width: 430px)': {
//     ul_container: {
//       height: 50,
//     },
//     slide_animation: {
//       animation: (props: { steps: number }) => `$slideMobile ${props.steps * 3}s steps(${props.steps}) forwards infinite`,
//     },
//   },
// }))

// const TypingAnimation: FC<TTypingAnimation> = (props) => {
//   const classes = useStyles({ steps: props.words.length })

//   return (
//     <ul className={classes.ul_container}>
//       {props.words.map((word) => {
//         return (
//           <li className={classes.slide_animation}>
//             <h1 className={classes.type_animation}>{word}</h1>
//           </li>
//         )
//       })}
//     </ul>
//   )
// }

// export default TypingAnimation
