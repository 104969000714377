import { useState, createContext, Dispatch, SetStateAction } from 'react'
import { IClient } from '../models/IClient'
import { IFreelancer } from '../models/IFreelancer'
import { IUser } from '../models/IUser'

type TUserContextProps = {
  user: IUser | undefined
  freelancerProfile: IFreelancer | undefined
  clientProfile: IClient | undefined
  isUserLogged: boolean
  isFinishProjectButton: boolean
  authError: string
  setUser: Dispatch<SetStateAction<IUser | undefined>>
  setFreelancerProfile: Dispatch<SetStateAction<IFreelancer | undefined>>
  setClientProfile: Dispatch<SetStateAction<IClient | undefined>>
  setIsUserLogged: Dispatch<SetStateAction<boolean>>
  setIsFinishProjectButton: Dispatch<SetStateAction<boolean>>
  setAuthError: Dispatch<SetStateAction<string>>
}
const UserContext: React.Context<TUserContextProps> = createContext<TUserContextProps>({
  user: undefined,
  freelancerProfile: undefined,
  clientProfile: undefined,
  isUserLogged: false,
  isFinishProjectButton: false,
  authError: '',
  setUser: () => null,
  setFreelancerProfile: () => null,
  setClientProfile: () => null,
  setIsUserLogged: () => null,
  setIsFinishProjectButton: () => null,
  setAuthError: () => '',
})

const UserContextProvider: React.FC = (props) => {
  const [user, setUser] = useState<IUser | undefined>(undefined)
  const [freelancerProfile, setFreelancerProfile] = useState<IFreelancer | undefined>(undefined)
  const [clientProfile, setClientProfile] = useState<IClient | undefined>(undefined)
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false)
  const [authError, setAuthError] = useState<string>('')
  const [isFinishProjectButton, setIsFinishProjectButton] = useState<boolean>(true)

  return (
    <UserContext.Provider
      value={{
        user,
        isUserLogged,
        authError,
        freelancerProfile,
        clientProfile,
        isFinishProjectButton,
        setUser,
        setFreelancerProfile,
        setClientProfile,
        setIsUserLogged,
        setIsFinishProjectButton,
        setAuthError,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserContextProvider }
