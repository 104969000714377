import { FC, useEffect, useState } from 'react'

import CheckIcon from '@material-ui/icons/Check'
import { TWhyChooseFindieTable } from '../../components/WebSite/HowWorks/HowWorksSections'

export const WhyChooseFindieTable: FC<TWhyChooseFindieTable> = (props) => {
  const [table, setTable] = useState<any>([])

  useEffect(() => {
    setTable(props.table)
  }, [props.table])

  return (
    <div className='grid grid-cols-12 gap-y-12 mt-16'>
      {table.map((title: string) => {
        return (
          <div className='lg:col-span-4 md:col-span-6 col-span-12 total-center'>
            <div key={title} className=' bg-light-black md:w-52 w-full h-40 total-center flex-col'>
              <CheckIcon fontSize='large' className={props.entityTab === 'client' ? 'text-blue' : 'text-orange'} />
              <p className='subtitle3-20 mt-4 text-center whitespace-pre-line'>{title}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
