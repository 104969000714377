import { FC } from 'react'
import Pillbox from '../../../shared/Pillbox/Pillbox'
import { useTranslation } from 'react-i18next'

const HelpProjectStates: FC = () => {
  const { t } = useTranslation()

  const stateList = [
    {
      description: `${t('CLIENT_HELP.THE_PRO_HAS_BEEN')}`,
      pillbox: <Pillbox className='border-golden text-golden'> {t('CLIENT_HELP.IN_REVISION')}</Pillbox>,
    },
    {
      description: `${t('CLIENT_HELP.IT_MEANS')}`,
      pillbox: <Pillbox className='border border-black text-black'>{t('CLIENT_HELP.IN_SEARCH')}</Pillbox>,
    },
    {
      description: `${t('CLIENT_HELP.YOU_WILL_SEE')}`,
      pillbox: <Pillbox className='border-blue text-blue'>{t('CLIENT_HELP.CANDIDATES')}</Pillbox>,
    },
    {
      description: `${t('CLIENT_HELP.WHEN_CHOOSE')}`,
      pillbox: <Pillbox className='border-magenta text-magenta'>{t('CLIENT_HELP.IN_PROGRESS')}</Pillbox>,
    },
    {
      description: `${t('CLIENT_HELP.ANY_TROUBLE')}`,
      pillbox: <Pillbox className='border-light-gray text-light-gray'>{t('CLIENT_HELP.PAUSE')}</Pillbox>,
    },
    {
      description: `${t('CLIENT_HELP.ONCE_COMPLETE')}`,
      pillbox: <Pillbox className='border-2	 border-yellow-300 text-yellow-500'>{t('CLIENT_HELP.FEEDBACK')}</Pillbox>,
    },
  ]

  return (
    <div className='w-10/12'>
      <p className='body2-regular mb-6'>{t('CLIENT_HELP.YOUR_PROJECTS_ARE_ORDERED')}</p>

      {stateList.map((item) => {
        return (
          <div key={item.description} className='mt-3'>
            {item.pillbox}
            <p className='body2-regular mt-1'>{item.description}</p>
          </div>
        )
      })}
    </div>
  )
}

export default HelpProjectStates
