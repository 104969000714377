import { FC, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import routes from '../../../../constants/routes'
import TitleWithUnderline from '../../../../shared/TitleWithUnderline/TitleWithUnderline'
import useLocalStorage from '../../../../customHooks/useLocalStorage'
import { IconButton } from '@material-ui/core'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import Check from '@material-ui/icons/Check'
import Slider from 'react-slick'
import { NewProjectStepProduct } from './NewProjectStepProduct'
import { NewProjectStepStartDate } from './NewProjectStepStartDate'
import { NewProjectStepTitle } from './NewProjectStepTitle'
import { NewProjectStepDescription } from './NewProjectStepDescription'
import { NewProjectStepCompleted } from './NewProjectStepCompleted'
import { created_at, progressPercent } from '../../../../utils/helpers'
import useUser from '../../../../customHooks/useUser'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import { useCreateProject } from '../../../../customHooks/request/projectsQuery'
import { NewProjectStepInspiration } from './NewProjectStepInspiration'
import { IConcept } from '../../../../models/IConcept'
import { NewProjectStepBudget } from './NewProjectStepBudget'
import ProgressBar from '../../../../assets/UIkit/ProgressBar'
import { useTranslation } from 'react-i18next'

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 300,
  cssEase: 'ease-in',
  draggable: false,
  swipe: false,
}

const ClientNewProject: FC = () => {
  const params = useParams<{ slide: string }>()
  const { user } = useUser()
  const navigate = useHistory().push
  const createProject = useCreateProject()
  useRequestAlert(createProject)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const storage = useLocalStorage('brief', {})
  const slider1 = useRef<any>()
  const slide = Number(params.slide)

  const handleStep = (action: string) => {
    if (action.includes('prev')) {
      setIsButtonDisabled(false)
      navigate(`${routes.clientUserProfile.projects.new}/${slide - 1}`)
    }
    if (action.includes('next')) {
      navigate(`${routes.clientUserProfile.projects.new}/${slide + 1}`)
    }
  }

  const sendProject = () => {
    if (!user) return

    const project = { created_at, client: user.profile_id, with_currency: storage.item.with_currency }
    const brief = {
      ...storage.item,
      created_at,
      concepts_list: [],
    }

    createProject.mutate(
      { body: { project, brief } },
      {
        onSuccess: () => {
          storage.removeItem()
          navigate(`${routes.clientUserProfile.projects.new}/${Number(params.slide) + 1}`)
          setTimeout(() => {
            navigate(routes.clientUserProfile.projects.list)
          }, 5000)
        },
      }
    )
  }

  useEffect(() => {
    if (params.slide) {
      slider1.current.slickGoTo(Number(params.slide) - 1)
    }
  }, [params.slide])

  const defaultProps = {
    isButtonDisabled,
    storage,
    setIsButtonDisabled,
    handleStep,
  }
  const { t } = useTranslation()

  const Buttons = () => {
    return (
      <div className='flex gap-4 px-4'>
        <div>
          {Number(params.slide) !== 1 && Number(params.slide) < 7 && (
            <IconButton onClick={() => handleStep('prev')} className={`border border-black mr-1 bg-black`}>
              <ChevronLeftRoundedIcon className='text-white' />
            </IconButton>
          )}
        </div>
        <div>
          {Number(params.slide) < 6 && (
            <IconButton
              onClick={() => handleStep('next')}
              disabled={isButtonDisabled}
              className={`border border-black  ${isButtonDisabled ? 'bg-white-gray' : 'bg-black'}`}
            >
              <ChevronRightRoundedIcon className='text-white' />
            </IconButton>
          )}
          {Number(params.slide) === 6 && (
            <IconButton
              onClick={sendProject}
              disabled={isButtonDisabled}
              className={`${isButtonDisabled ? 'bg-white-gray' : 'bg-pink-500'}`}
            >
              <Check className='text-white' />
            </IconButton>
          )}
        </div>
      </div>
    )
  }

  const Title = ({ children }: { children: React.ReactNode }) => {
    return (
      <>
        <div className='flex justify-between w-full'>
          <h4>{children}</h4>
          <div className='hidden md:flex'>
            <Buttons />
          </div>
        </div>
        <ProgressBar className='' percent={progressPercent(slide - 1, 6)} />
      </>
    )
  }

  return (
    <>
      <div className='flex justify-between w-full items-center'>
        <Link to={routes.clientUserProfile.projects.options}>
          <TitleWithUnderline title={t('GLOBAL.PROJECT_MENU')} />
        </Link>
        <div className='md:hidden'>
          <Buttons />
        </div>
      </div>

      <div className='mt-4'>
        <Slider ref={(slider) => (slider1.current = slider)} {...settings}>
          <NewProjectStepStartDate {...defaultProps} position={1} Title={Title} />
          <NewProjectStepTitle {...defaultProps} position={2} Title={Title} />
          <NewProjectStepDescription {...defaultProps} position={3} Title={Title} />
          <NewProjectStepProduct {...defaultProps} position={4} Title={Title} />
          <NewProjectStepBudget {...defaultProps} position={5} Title={Title} />
          <NewProjectStepInspiration {...defaultProps} position={6} Title={Title} />
          <NewProjectStepCompleted />
        </Slider>
      </div>
    </>
  )
}

export default ClientNewProject
