import { useFirstUpdateBrief } from '../../../../customHooks/request/briefQuery'
import useRequestAlert from '../../../../customHooks/useRequestAlert'
import { UseQueryResult } from 'react-query'
import { IProject } from '../../../../models/IProject'
import { useForm } from 'react-hook-form'
import FiButton from '../../../../assets/UIkit/FiButton'
import BriefForm from '../../../../shared/ProjectUserProfile/Brief/BriefForm'
import { useState } from 'react'
import UserProfileDialog from '../../../../shared/UserProfileDialog/UserProfileDialog'
import { useHistory } from 'react-router-dom'
import routes from '../../../../constants/routes'
import WelcomeModal from '../../../../shared/WelcomeModal/WelcomeModal'
import VideoCallModal from '../../../../shared/VideoCallModal/VideoCallModal'
import { useTranslation } from 'react-i18next'

type TUpdateBrief = {
  projectQuery: UseQueryResult<IProject, unknown>
}

const UpdateBrief = (props: TUpdateBrief) => {
  const { t } = useTranslation()
  const form = useForm()
  const navigate = useHistory().push
  const { data: project, refetch: refetchProject } = props.projectQuery
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const [isBriefComplete, setIsBriefComplete] = useState(false)

  const updateBrief = useFirstUpdateBrief()
  useRequestAlert(updateBrief)

  const updateProjectBrief = (body: any) => {
    if (!project) return
    if (!form.watch('concepts_list') || !form.watch('concepts_list').length) {
      return form.setError('selected_concept', { message: `${t('UPDATE_BRIEF.MESSAGE_CHOOSE')}` })
    }

    updateBrief.mutate(
      { body, id: `${project.brief._id}/${project._id}` },
      {
        onSuccess: () => {
          setIsBriefComplete(true)
          setTimeout(async () => {
            await refetchProject()
            navigate(`${routes.clientUserProfile.projects.detail}/${project._id}/candidatos/lista`)
          }, 4000)
        },
      }
    )
  }

  if (!project) return null
  if (isBriefComplete) {
    return (
      <UserProfileDialog className='mt-2'>
        <h4 className='text-blue md:pl-8 pl-4'>{t('UPDATE_BRIEF.PRO_SEND')} </h4>
      </UserProfileDialog>
    )
  }
  return (
    <form onSubmit={form.handleSubmit(updateProjectBrief)}>
      <div className='grid grid-cols-12 gap-4'>
        <div className='md:col-span-6 col-span-12 border-t border-black pt-2 '>
          <p className='subtitle6-regular text-black'>{t('UPDATE_BRIEF.COMPLETE_BF')} </p>
        </div>

        <div className='md:col-span-6 col-span-12 flex md:mt-0 mt-2 gap-4'>
          <button
            className='lg:block hidden custom-button-no-hover ml-auto px-2 buttontext1-medium'
            type='button'
            onClick={() => setIsHelpModalOpen(true)}
          >
            {t('UPDATE_BRIEF.NEED_HELP')}
          </button>
          <button
            className='lg:hidden block w-full custom-button-no-hover ml-auto px-2 buttontext1-medium'
            type='button'
            onClick={() => setIsHelpModalOpen(true)}
          >
            {t('UPDATE_BRIEF.WANT_HELP')}
          </button>

          <FiButton className='lg:block hidden px-2' variant='outlined' theme='primary' type='submit'>
            <span className='buttontext1-medium'>{t('UPDATE_BRIEF.BUTTON_SEND_PROJECT')}</span>
          </FiButton>
          <FiButton className='lg:hidden w-full px-2 block' variant='outlined' theme='primary' type='submit'>
            <span className='buttontext1-medium '>{t('GLOBAL.SEND')}</span>
          </FiButton>
        </div>
      </div>

      <BriefForm project={project} refetchProject={refetchProject} form={form} />

      <VideoCallModal isModalOpen={isHelpModalOpen} setIsModalOpen={setIsHelpModalOpen} />

      <WelcomeModal
        isModalOpen={isModalOpen}
        title={`${t('UPDATE_BRIEF.WELCOME_MODAL.TITLE')}`}
        subtitle={`${t('UPDATE_BRIEF.WELCOME_MODAL.SUBTITLE')}`}
        withCallToAction={false}
        setIsModalOpen={setIsModalOpen}
      />
    </form>
  )
}

export default UpdateBrief
