type TPictureLayout = {
  avatar: string
  height?: number
  width?: number
  className?: string
}

const PictureLayout = (props: TPictureLayout) => {
  return (
    <img
      className={`rounded-full overflow-hidden object-cover gray-scale-filter ${props.className}`}
      style={{ width: props?.width ?? 32, height: props?.height ?? 32 }}
      src={props.avatar}
    />
  )
}

export default PictureLayout
