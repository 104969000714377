import React from 'react'
import FiButton from '../assets/UIkit/FiButton'
import routes from '../constants/routes'
import WebSiteStructure from './WebSite/WebSiteStructure'
import error500 from '../assets/images/web/error500-desktop.png'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: '', errorStatus: 500 }
  }

  static getDerivedStateFromError(error) {
    let errorMessage = 'Ups, ha ocurrido un error'
    let errorStatus = 500
    if (error?.json?.error === 'Unauthorized') {
      errorMessage = 'Tus credenciales han expirado. Debes volver a iniciar sesión'
      errorStatus = 401
      window.localStorage.removeItem('locale')
    }
    return { hasError: true, errorMessage, errorStatus }
  }

  componentDidCatch() {
    //Cath error
  }

  render() {
    if (this.state.hasError) {
      return (
        <WebSiteStructure>
          <div className={`lg:px-0 md:px-12 px-7 lg:py-24 py-16`}>
            <div className='grid grid-cols-12 lg:mt-28 md:mt-8 mt-4'>
              <div className='lg:col-span-8 md:col-span-6 col-span-12 md:flex-row flex-col'>
                <h1 className='mb-20'>{`${this.state.errorMessage}`}</h1>
                {this.state.errorStatus === 401 && (
                  <FiButton
                    className='mr-5'
                    theme='secondary'
                    asLink
                    to={routes.auth.login}
                    onClick={() => this.setState({ hasError: false })}
                  >
                    Iniciar sesión
                  </FiButton>
                )}
                {this.state.errorStatus !== 401 && (
                  <FiButton className='mr-5' theme='secondary' onClick={() => this.setState({ hasError: false })}>
                    Volver a intertarlo
                  </FiButton>
                )}
                <FiButton
                  theme='secondary'
                  variant='outlined'
                  className='mr-5'
                  asLink
                  to={routes.web_site.home}
                  onClick={() => this.setState({ hasError: false })}
                >
                  Ir al inicio
                </FiButton>
              </div>
              <img src={error500} alt='error404' className='lg:col-span-4 md:col-span-6 col-span-12' />
            </div>
          </div>
        </WebSiteStructure>
      )
    }
    return <div>{this.props.children}</div>
  }
}

export default ErrorBoundary
