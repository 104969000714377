import { evaluate } from 'decimal-eval'
import { operation } from './helpers'

const locale = 'en'
const options = { style: 'currency', currency: 'GBP', maximumFractionDigits: 2 }

export const siiTaxesValues = (type: 'bill' | 'ticket') => {
  if (type === 'bill') return { tax: 19, constant: 0 }
  return { tax: 13.75, constant: 86.25 }
}

export const formatNum = (num: number) => {
  return Intl.NumberFormat(locale, options).format(num)
}

export const taxCalculator = (price: number, taxPercent: number) => {
  const tax = +evaluate(`${price} * (${taxPercent / 100})`)
  return {
    tax,
    withTax: operation(price, '+', tax),
    withOutTax: operation(price, '-', tax),
  }
}

export const ticketTaxCalculator = (price: number, taxPercent: number, ticket_constant: number) => {
  const tax = +evaluate(`${price} * (${taxPercent} / ${ticket_constant})`)

  return {
    tax,
    withTax: operation(price, '+', tax),
    withOutTax: operation(price, '-', tax),
  }
}
