import { FC } from 'react'

import { List, ListItem } from '@material-ui/core'
import usePagination from '../../customHooks/usePagination'
import CustomPagination from '../../assets/UIkit/CustomPagination'

type TDeletedEntityList = {
  entityName: 'Freelancers' | 'Clientes'
  entityList: any[]
  pages: number
  pagination: {
    page: number
    handlePage: (value: number) => void
  }
}

const DeletedEntityList: FC<TDeletedEntityList> = (props) => {
  return (
    <>
      <h3 className='mb-6'>{props.entityName} descartados</h3>
      {props.entityList.map((structure, index) => {
        return (
          <div key={structure.letter}>
            {structure.list.length > 0 && (
              <>
                <p className={`subtitle2-bold text-red ${index !== 0 ? 'mt-4' : ''}`}>{structure.letter}</p>
                <List className='mt-2 border-t border-r border-l border-gray-300 py-0'>
                  {structure.list.map((entity: any) => {
                    return (
                      <div key={entity._id} className='border-b border-gray-300'>
                        <ListItem className='block'>
                          <div className='grid grid-cols-12'>
                            <span className='col-span-5 buttontext4-regular'>
                              {entity?.name}, {entity?.lastName}
                            </span>
                            {props.entityName === 'Freelancers' && (
                              <div className='col-span-7 flex justify-between'>
                                <span className='buttontext4-regular'>{entity?.category.name}</span>
                                <span className='buttontext4-medium'>{entity?.freelancer_status === 'deleted' ? 'X' : 'R'}</span>
                              </div>
                            )}
                            {props.entityName === 'Clientes' && (
                              <div className='col-span-7 flex justify-end'>
                                <span className='buttontext4-medium'>{entity?.client_status === 'deleted' ? 'X' : 'R'}</span>
                              </div>
                            )}
                          </div>
                        </ListItem>
                      </div>
                    )
                  })}
                </List>
              </>
            )}
          </div>
        )
      })}

      {props.entityList.length > 0 && (
        <div className='flex justify-center'>
          <CustomPagination
            pages={props.pages}
            page={props.pagination.page}
            onChange={(event, value) => props.pagination.handlePage(value)}
            position={'center'}
            className='mt-4'
          />
        </div>
      )}
    </>
  )
}

export default DeletedEntityList
