import { FC } from 'react'
import TYCImage from '../../../assets/images/userProfile/tyc-image.svg'
import { useTranslation } from 'react-i18next'

const HelpTermsAndConditions: FC = () => {
  const { t } = useTranslation()
  return (
    <div className='w-8/12'>
      <p className='body2-regular mb-8'>{t('CLIENT_HELP.ATTACH_COPY')}</p>
      <a href='https://files-findie.s3.amazonaws.com/Documents/Terminos+y+condiciones.pdf' target='_blank'>
        <img src={TYCImage} alt='tyc' />
      </a>
    </div>
  )
}

export default HelpTermsAndConditions
