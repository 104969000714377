import { Route, Switch } from 'react-router-dom'
import { useContext } from 'react'
import { LoaderContext } from '../context/LoaderContext'
import { CategoriesContextProvider } from '../context/Cpanel/CategoriesContext'
import routes from '../constants/routes'

import CpanelLayout from '../layout/Cpanel/CpanelLayout'
import ClienteSuscriptionForm from '../views/WebSite/ClientSuscriptionForm'
import FreelancerSuscriptionForm from '../views/WebSite/FreelancerSuscriptionForm'
import WebSiteLayout from '../layout/WebSite/WebSiteLayout'
import UiKitView from '../views/UiKitView'

import Loader from '../assets/UIkit/Loader'
import Login from '../pages/Login'
import PrivateRoute from './PrivateRoute'
import useAuth from '../customHooks/useAuth'
import ForgotPassword from '../pages/ForgotPassword'
import RecoverPassword from '../pages/RecoverPassword'
import ClientUserProfileLayout from '../layout/UserProfile/ClientUserProfileLayout'
import FreelancerUserProfileLayout from '../layout/UserProfile/FreelancerUserProfileLayout'
import PaymentPage from '../views/WebSite/PaymentPage'
import LiteClientSubscriptionForm from '../shared/LiteClientSubscriptionForm/LiteClientSubscriptionForm'
import CreateAccount from '../views/WebSite/CreateAccount'
import FreelancerProfile from '../views/WebSite/FreelancerProfile'

function AppRouter() {
  useAuth()
  const loader = useContext(LoaderContext)

  return (
    <>
      <Loader open={loader.isOpen} />
      <CategoriesContextProvider>
        <Switch>
          <Route exact path={`${routes.web_site.freelancer_profile}/:id`} component={FreelancerProfile} />
          <Route exact path={routes.auth.login} component={Login} />
          <Route exact path={routes.web_site.payment} component={PaymentPage} />
          <Route exact path={routes.auth.forgot_password} component={ForgotPassword} />
          <Route exact path={`${routes.auth.recover_password}/:token`} component={RecoverPassword} />
          <Route exact path={routes.uikit} component={UiKitView} />
          <Route path={routes.web_site.freelancer_suscription_form.index} component={FreelancerSuscriptionForm} />
          <Route path={`${routes.web_site.client_suscription_form.index}/:freelancerId?`} component={ClienteSuscriptionForm} />
          <Route exact path={routes.web_site.lite_client_suscription_form} component={LiteClientSubscriptionForm} />
          <Route path={routes.web_site.createAccount} component={CreateAccount} />
          <PrivateRoute
            userType={['admin', 'super_admin']}
            exact={false}
            path={routes.cpanel.index}
            component={<CpanelLayout />}
          />
          <PrivateRoute
            userType={['client']}
            exact={false}
            path={routes.clientUserProfile.index}
            component={<ClientUserProfileLayout />}
          />
          <PrivateRoute
            userType={['freelancer']}
            exact={false}
            path={routes.freelancerUserProfile.index}
            component={<FreelancerUserProfileLayout />}
          />
          <Route path={routes.web_site.home} component={WebSiteLayout} />
        </Switch>
      </CategoriesContextProvider>
    </>
  )
}

export default AppRouter
