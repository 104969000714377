import { FC, useEffect, useState } from 'react'
import ClientUserProfileRouter from '../../appRouter/ClientUserProfileRouter'
import { IconsDict } from '../../assets/UIkit/Icons/FiIcons'
import routes from '../../constants/routes'
import useUser from '../../customHooks/useUser'
import UserProfileLayout from './UserProfileLayout'
import WelcomeModal from '../../shared/WelcomeModal/WelcomeModal'
import { useUpdateUser } from '../../customHooks/request/userQuery'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export type TSidebarOptions = {
  title: string
  icon: keyof typeof IconsDict
  to: string
}

const ClientUserProfileLayout: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const sidebarOptions: TSidebarOptions[] = [
    { title: 'Home', icon: 'home', to: routes.clientUserProfile.home },
    { title: `${t('SIDE_BAR.PROJECTS')}`, icon: 'findieTransparent', to: routes.clientUserProfile.projects.index },
    { title: `${t('SIDE_BAR.CATALOGUE')}`, icon: 'book', to: routes.clientUserProfile.book },
    { title: `${t('SIDE_BAR.PROFILE')}`, icon: 'account', to: routes.clientUserProfile.profile.personalData },
  ]

  const { user } = useUser()

  const updateClient = useUpdateUser()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const updateClientData = () => {
    updateClient.mutate({ body: { is_first_render: false }, id: user?._id ?? '' })
  }

  useEffect(() => {
    if (user && user.is_first_render) setIsModalOpen(true)
  }, [user])

  return (
    <UserProfileLayout sidebarOptions={sidebarOptions}>
      <ClientUserProfileRouter />

      <WelcomeModal
        isModalOpen={isModalOpen}
        title={`${t('SIDE_BAR.WELCOME_MODAL.TITLE')}`}
        subtitle={`${t('SIDE_BAR.WELCOME_MODAL.SUBTITLE')}`}
        withCallToAction
        callToAction={() => history.push(routes.clientUserProfile.projects.start)}
        callToActionText={`${t('SIDE_BAR.WELCOME_MODAL.BUTTON_CREATE')}`}
        closeText={`${t('SIDE_BAR.WELCOME_MODAL.BUTTON_CLOSE')}`}
        setIsModalOpen={setIsModalOpen}
        onClose={updateClientData}
      />
    </UserProfileLayout>
  )
}

export default ClientUserProfileLayout
