import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { sanitizeRedirectPath } from '../utils/sanitize-redirect-path'

export const useAuthRedirect = () => {
  const location = useLocation()
  const history = useHistory()

  const [redirect, setRedirect] = useState(() => {
    const params = new URLSearchParams(location.search)
    const value = params.get('redirect')

    if (value) {
      sessionStorage.setItem('redirect', value)
    } else {
      return sessionStorage.getItem('redirect')
    }
    return value
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const value = params.get('redirect')

    if (value) {
      sessionStorage.setItem('redirect', value)
    } else {
      const savedValue = sessionStorage.getItem('redirect')
      setRedirect(savedValue)
    }
  }, [location])

  return {
    redirectPath: sanitizeRedirectPath(redirect),
    routeRedirect: () => {
      history.push(sanitizeRedirectPath(redirect))
      sessionStorage.removeItem('redirect')
    },
  }
}
