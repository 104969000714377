import { useMutation, useQuery } from 'react-query'
import { ICreateOffersBody, IOffer, ISendClientOfferBody } from '../../models/IOffer'
import { IMutation, IPost, IPut, IResponse } from '../../models/IRequest'
import { apiClient } from '../../utils/ApiClient'
import { useSetLoader } from '../useSetLoader'

const endpoints = {
  offers: (params?: string) => `offers${params ?? ''}`,
  sendClientOffer: (id: string) => `offers/offer_to_client/${id}`,
  createOffers: 'offers/many',
}

export const useOfferListQuery = ({ filters, enabled = true }: { filters?: unknown; enabled?: boolean }) => {
  const endpoint = endpoints.offers(`?filters=${JSON.stringify(filters)}`)
  const offerQuery = useQuery(endpoint, () => apiClient.get<IResponse<IOffer>>({ endpoint }), {
    select: (data) => {
      return data.data
    },
    enabled,
  })
  useSetLoader(offerQuery)

  return offerQuery
}

export const useOneOfferQuery = ({
  id,
  enabled = true,
  refetchOnWindowFocus = true,
}: {
  id: string
  enabled?: boolean
  refetchOnWindowFocus?: boolean
}) => {
  const endpoint = endpoints.offers(`/${id}`)
  const offerQuery = useQuery([endpoint, id], () => apiClient.get<IOffer>({ endpoint }), {
    select: (data) => data,
    enabled,
    refetchOnWindowFocus,
  })
  useSetLoader(offerQuery)

  return offerQuery
}

export const useCreateOffers = () => {
  const endpoint = endpoints.createOffers
  return useMutation(({ body }: IPost<ICreateOffersBody>) => apiClient.post<IOffer, ICreateOffersBody>({ endpoint, body }))
}

export const useSendClientOffer = () => {
  return useMutation(({ body, id }: IPut<ISendClientOfferBody>) =>
    apiClient.put<IOffer, ISendClientOfferBody>({ endpoint: endpoints.sendClientOffer(id), body })
  )
}

export const useOffersMutation = () => {
  return useMutation(({ body, id, method }: IMutation<Partial<IOffer>>) =>
    apiClient.mutation<IOffer, Partial<IOffer>>({ endpoint: endpoints.offers(`/${id}`), body, method })
  )
}
