import { TMobileActions, TSection } from './ProjectBreakDown'

type TBreakdownActions = {
  currentSection: TSection
  selectOffer: () => void
  mobileActions: (dialogAction: TMobileActions) => void
}

const BreakdownActions = (props: TBreakdownActions) => {
  return (
    <div>
      <div className='grid grid-cols-12 lg:w-10/12 w-full gap-4 mt-8'>
        <div className='md:col-span-6 md:hidden block col-span-12'>
          <button className='bg-magenta border-magenta py-2 w-full' onClick={() => props.mobileActions('products')}>
            <p className='buttontext2-medium text-white'>Productos</p>
          </button>
        </div>

        <div className='md:col-span-6 col-span-12'>
          <button className='bg-blue border-blue py-2 w-full' onClick={props.selectOffer}>
            <p className='buttontext2-medium text-white'>Elegir candidato</p>
          </button>
        </div>
      </div>

      <p className='buttontext2-regular text-blue mt-6'>
        Al elegir candidato aceptas la Propuesta de Trabajo del freelancer escogido.
      </p>
    </div>
  )
}

export default BreakdownActions
