export const designExpertise1 = [
  {
    area: 'Logo e Identidad',
    expertises: ['Diseño de Logos', 'Manual de Marca', 'Tarjetas de Presentación', 'Papelería', 'Tipografía y Lettering'],
  },
  {
    area: 'Web y App',
    expertises: ['Diseño Web', 'Diseño para Apps', 'Diseño UX', 'Landing Pages', 'Diseño de Iconos'],
  },
]

export const designExpertise2 = [
  {
    area: 'Arte e Ilustración',
    expertises: [
      'Ilustración',
      'Ilustración Infantil',
      'Retratos y Caricaturas',
      'Personajes y Comics',
      'Diseño de Patterns',
      'Diseño de Tatuajes',
      'Storyboards',
    ],
  },
  {
    area: 'Visual Design',
    expertises: ['Diseño de Presentaciones', 'Diseño de Infografías', 'Vectorización de Imagenes', 'Diseño de CV'],
  },
]

export const designExpertise3 = [
  {
    area: 'Packaging',
    expertises: ['Packaging y Etiquetas', 'Diseño de Libros', 'Diseño de Portadas', 'Diseño de Álbumes', 'Arte para Podcast'],
  },
  {
    area: 'Editorial e Impresiones',
    expertises: [
      'Diseño de Flyer',
      'Diseño de Brochure',
      'Diseño de Afiches',
      'Diseño de Catálogos',
      'Diseño de Menú',
      'Diseño de Invitaciones',
      'Diseño',
    ],
  },
]

export const designExpertise4 = [
  {
    area: 'Marketing',
    expertises: ['Diseño para RRSS', 'Diseño de Emails', 'Banners Web', 'Señalética'],
  },
]

export const writingExpertise1 = [
  {
    area: 'Redacción de Contenido',
    expertises: [
      'Artículos de Blog ',
      'Estrategia de Contenido ',
      'Redacción de Libros y E-books',
      'Contenido para web',
      'Redacción de guiones',
      'Redacción creativa',
      'Redacción de podcast',
      'Redacción de discursos',
      'Investigación y resúmenes',
    ],
  },
]

export const writingExpertise2 = [
  {
    area: 'Edición y Críticas',
    expertises: ['Corrección y Edición', 'Edición de Contenido', 'Edición de Libros', 'Consejos para escribir', 'Diseño'],
  },
  {
    area: 'Traducción y Transcripción',
    expertises: ['Traducción', 'Transcripción'],
  },
]

export const writingExpertise3 = [
  {
    area: 'Marketing y Negocios',
    expertises: [
      'Marca Voz y Tono',
      'Naming y Eslóganes',
      'Estudios de Casos',
      'Documentos Técnicos',
      'Descripciones de Productos',
      'Copy para Anuncios',
      'Copy para Ventas',
      'Copy para Email',
      'Copy para Redes Sociales',
      'Comunicados de prensa',
      'Redacción UX',
    ],
  },
]

export const writingExpertise4 = [
  {
    area: 'Redacción Académica',
    expertises: ['Redacción de CV', 'Cartas de presentación', 'Perfiles de LinkedIn'],
  },
]

export const developExpertise1 = [
  {
    area: 'Sitios Web',
    expertises: ['Desarrollo de Web', 'Mantenimiento Web', 'AnalyDesarrollos Personalizadostics'],
  },
  {
    area: 'Plataformas Web',
    expertises: ['WordPress', 'Shopify', 'Wix', 'Webflox'],
  },
]

export const developExpertise2 = [
  {
    area: 'Desarrollo de Apps',
    expertises: [
      'Desarrollo de Software',
      'App Móviles',
      'Aplicaciones Web',
      'Aplicación de Escritorio',
      'Desarrollo de Juegos',
      'Desarrollo IA',
      'Desarrollo de Chatbot',
    ],
  },
  {
    area: 'Soporte y Ciberseguridad',
    expertises: ['Soporte e IT', 'DevOps y Nube', 'Ciberseguridad', 'Desarrollo para Streamers', 'Conversión de Archivos'],
  },
]

export const audiovisualExpertise1 = [
  {
    area: 'Edición y Post-Producción',
    expertises: [
      'Edición de Video',
      'Efectos Visuales',
      'Arte para Videos',
      'Intro y Outro',
      'Plantillas para Videos',
      'Subtitulado',
      'Rigging',
    ],
  },
  {
    area: 'Animación',
    expertises: [
      'Animación de Personaje',
      'Explainers Animados',
      'Logotipo Animado',
      'GIFs Animados',
      'Animación Web',
      'Animación para Niños',
    ],
  },
]

export const audiovisualExpertise2 = [
  {
    area: 'Videos RRSS y Marketing',
    expertises: [
      'Publicidad y Comerciales',
      'Videos para RRSS',
      'Videos de Música',
      'Videos de Presentación',
      'Videos Corporativos',
      'Videos Inmobiliarios',
    ],
  },
  {
    area: 'Videos Explainer y Productos',
    expertises: ['Animación de Producto 3D', 'Videos E-Commerce', 'Videos de MVP', 'Videos de Libros', 'Videos de Juegos'],
  },
]

export const audiovisualExpertise3 = [
  {
    area: 'Tutoriales y Educación',
    expertises: ['Producción eLearning'],
  },
]

export const marketingExpertise1 = [
  {
    area: 'Search',
    expertises: ['Search Engine Optimization ', '(SEO)', 'Search Engine Marketing', '(SEM)', 'E-commerce SEO', 'Video SEO'],
  },
  {
    area: 'Sociales',
    expertises: ['Marketing para RRSS', 'Marketing para Influencers', 'Community Manager'],
  },
]

export const marketingExpertise2 = [
  {
    area: 'Métodos y Técnicas',
    expertises: ['Video Marketing', 'E-commerce Marketing', 'Email Marketing', 'Marketing de Afiliados', 'Relaciones Públicas'],
  },
  {
    area: 'Analíticas y Estrategia',
    expertises: ['Marketing Estratégico', 'Consultoría de Marketing', 'Análisis Web'],
  },
]
