import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'
import routes from '../constants/routes'
import useAuth from '../customHooks/useAuth'

import FiButton from '../assets/UIkit/FiButton'
import InputField from '../assets/UIkit/Forms/InputField'
import { useSetLoader } from '../customHooks/useSetLoader'
import FindieGifIsotype from '../shared/Brand/Isotype/FindieGifIsotype'
import { IAuth } from '../models/IAuth'
import useUser from '../customHooks/useUser'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useTranslation } from 'react-i18next'
import { useAuthRedirect } from '../customHooks/useAuthRedirect'
import '../assets/styles/index.scss'

const Login: React.FC = () => {
  const { login } = useAuth()
  const { authError, setAuthError } = useUser()
  const form = useForm()
  useSetLoader(login)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const { t, i18n } = useTranslation()
  useAuthRedirect()

  let forgotPassWord: any[] = t('LOGIN.FORGOT_PASSWORD', { returnObjects: true })

  const loginUser: SubmitHandler<IAuth> = async (body) => {
    login(body)
  }

  useEffect(() => {
    setAuthError('')
  }, [form.watch('email'), form.watch('password')])

  return (
    <div className='h-small-screen w-screen flex flex-col justify-center items-center'>
      <form className='lg:w-1/3 md:w-4/5 w-full px-4' onSubmit={form.handleSubmit(loginUser)}>
        <Link to={routes.web_site.home}>
          <FindieGifIsotype className='block mx-auto w-28 h-28' />
        </Link>

        <h2 className='mt-8 text-center'>{t('GLOBAL.WELL_MET')}</h2>

        <div className='h-30 mt-4 flex flex-col items-center'>
          <InputField
            name='email'
            className='lg:w-9/12 w-10/12'
            inputProps={{
              className: 'mt-4 border border-gray-300 w-full mx-auto block',
              placeholder: `${t('GLOBAL.EMAIL')}`,
              type: 'email',
            }}
            options={{ required: 'Este campo es requerido' }}
            form={form}
          />
          <div className='relative lg:w-9/12 w-10/12'>
            <InputField
              name='password'
              variant='default'
              inputProps={{
                className: 'mt-4 border border-gray-300 w-full pl-4',
                placeholder: `${t('GLOBAL.PASSWORD')}`,
                type: isShowPassword ? 'text' : 'password',
              }}
              options={{ required: 'Este campo es requerido' }}
              form={form}
            />
            {isShowPassword ? (
              <IconButton
                className='absolute z-10 p-0'
                style={{ top: 'calc(50% - 4px)', right: '3%' }}
                onClick={() => setIsShowPassword(false)}
              >
                <VisibilityOffIcon className='text-gray-300' />
              </IconButton>
            ) : (
              <IconButton
                className='absolute z-10 p-0'
                style={{ top: 'calc(50% - 4px)', right: '3%' }}
                onClick={() => setIsShowPassword(true)}
              >
                <VisibilityIcon className='text-gray-300' />
              </IconButton>
            )}
          </div>
          <p className='body2-medium text-magenta mt-2 text-center'>{authError}</p>
        </div>

        <Link to={routes.auth.forgot_password}>
          <p className='microcopy  text-center mb-20  mt-4 px-16'>
            {forgotPassWord.map((iter) => {
              if (i18n.language === 'en') {
                return (
                  <>
                    {iter.UPDATE_PW}
                    <br />
                    {iter.CLICK_2}
                    <span className='border-solid border-0 border-b border-black'>{iter.RESET}</span>
                  </>
                )
              }

              if (i18n.language === 'es') {
                return (
                  <>
                    <span className='border-solid border-0 border-b border-black'>{iter.FORGET_PS}</span>
                    {iter.WELCOME_TO}
                    <br />
                    {iter.E_MAIL}
                    <span className='border-solid border-0 border-b border-black'>{iter.CLICK_ME}</span>
                  </>
                )
              }
            })}
          </p>
        </Link>

        <FiButton type='submit' theme='secondary' variant='outlined' className='w-80 h-14 block mx-auto px-8 mt-4'>
          <p className='formtext1-medium'>{t('LOGIN.LOG')}</p>
        </FiButton>
      </form>
    </div>
  )
}

export default Login
