import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { cleanCLP, getTimeStamp } from '../../utils/helpers'
import { TProjectSections } from './Project'
import ProjectForm from './ProjectForm'
import useRequestAlert from '../../customHooks/useRequestAlert'
import { useUpdateBrief } from '../../customHooks/request/briefQuery'
import dayjs from 'dayjs'
import FiButton from '../../assets/UIkit/FiButton'

const ProjectBrief: FC<TProjectSections> = (props) => {
  const form = useForm()
  const { data: project, refetch: refetchProject } = props.project
  const updateBrief = useUpdateBrief()
  useRequestAlert(updateBrief)

  const updateProjectBrief = (data: any) => {
    const start_date = getTimeStamp(data.brief.start_date)
    const end_date = getTimeStamp(data.brief.end_date)
    const proposed_budget = cleanCLP(data.brief.proposed_budget)
    const body = { ...data.brief, start_date, end_date, proposed_budget }
    updateBrief.mutate({ body, id: project?.brief?._id ?? '' }, { onSuccess: () => refetchProject() })
  }

  useEffect(() => {
    if (!project || !project?.brief) return

    const brief = Object.entries(project.brief)
    brief.forEach(([key, value]) => {
      if (key === 'category') {
        !form.watch(key) && form.setValue('brief.category', value._id)
        !form.watch(key) && form.setValue('category_selected', JSON.stringify(value))
        return
      }
      if (key === 'start_date')
        return !form.watch(key) && value && form.setValue('brief.start_date', dayjs(value).format('YYYY-MM-DD'))
      if (key === 'end_date') return

      !form.watch(key) && form.setValue(`brief.${key}`, value)
    })
  }, [project])

  if (!project) return null
  return (
    <>
      <form className='mt-4' onSubmit={form.handleSubmit(updateProjectBrief)}>
        <div className='sticky -top-10 flex justify-end mt-4'>
          <FiButton variant='contained' theme='secondary' type='submit' disabled={project.project_status !== 'not_approved'}>
            Guardar cambios
          </FiButton>
        </div>

        {project.is_brief_incomplete && (
          <h5 className='text-red'>Proyecto creado desde el formulario del sitio web. El cliente, aun no actualiza el brief</h5>
        )}
        <ProjectForm
          className='mt-4'
          form={form}
          isDisabledFields={project.project_status !== 'not_approved'}
          withCurrency={project.with_currency}
        />
      </form>
    </>
  )
}

export default ProjectBrief
