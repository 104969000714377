import { IFormStep } from '../../../models/IFormStep'
import { Dispatch, SetStateAction } from 'react'

import FormStepInputContent from '../../../shared/FormStepInputContent/FormStepInputContent'
import { useTranslation } from 'react-i18next'
import AppDialog from '../../../assets/UIkit/AppDialog'
import routes from '../../../constants/routes'
import EmailVerificationModal from '../HomePage/EmailVerificationModal'

type TClientStepThree = {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ClientStepThree: React.FC<IFormStep & TClientStepThree> = (props) => {
  const { t } = useTranslation()

  const examples: any = t('clientSubscriptionForm.stepFour.FormStepInputContent.examples', { returnObjects: true })
  const title = t('clientSubscriptionForm.stepFour.FormStepInputContent.title')
  const placeholder = t('clientSubscriptionForm.stepFour.FormStepInputContent.placeholder')

  return (
    <>
      <FormStepInputContent
        title={title}
        name='description'
        currentStep='3'
        placeholder={placeholder}
        examples={examples}
        {...props}
      />

      <AppDialog
        open={props.isModalOpen}
        title=''
        maxWidth='xl'
        containerClassName='rounded-none'
        onClose={() => props.setIsModalOpen(false)}
        noHeader
      >
        <EmailVerificationModal
          emailProps={props}
          setIsModalOpen={() => props.setIsModalOpen(false)}
          redirectTo={`${routes.web_site.client_suscription_form.form_steps}/4`}
        />
      </AppDialog>
    </>
  )
}

export default ClientStepThree
