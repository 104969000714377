import { IFreelancer } from '../../../models/IFreelancer'

import FiButton from '../../../assets/UIkit/FiButton'
import { getFirstWord } from '../../../utils/helpers'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import FindieIsotype from '../../../shared/Brand/Isotype/FindieIsotype'
import SeñorityIcon from '../../../shared/SeñorityIcon/SeñorityIcon'
import FreelancerProfilePortfolio from './FreelancerProfilePortfolio'
import FreelancerProfileStudiesExperiences from './FreelancerProfileStudiesExperiences'
import PillBoxSkills from '../../../shared/PillBoxSkills/PillBoxSkills'
import { useTranslation } from 'react-i18next'

type TFreelancerProfileInfo = {
  freelancer: IFreelancer
  contactButtonLink?: string
  noContractButton?: boolean
  noStatus?: boolean
  asWebSite?: boolean
  theme?: 'white' | 'black'
}

const WebFreelancerProfile: React.FC<TFreelancerProfileInfo> = (props) => {
  const { t } = useTranslation()
  const { theme = 'white' } = props
  const color = theme === 'white' ? 'black' : 'white'

  return (
    <div className='py-4 px-8' style={{ backgroundColor: `var(--${theme === 'black' ? 'black-092' : 'white'})` }}>
      <div className='flex justify-between mt-8 mb-12 gap-2'>
        <div>
          <h3 className='flex items-center gap-4' style={{ color: `var(--${color})` }}>
            {props.freelancer.name} {props.freelancer.lastName.slice(0, 1).toUpperCase()}.
            {props.freelancer.featured_status.has_feartued_icon && <FindieIsotype variant='golden' />}
          </h3>

          <div className='flex md:flex-row flex-col mt-4'>
            <p className='microcopy mr-3' style={{ color: `var(--${color})` }}>
              {t(props.freelancer.college_degree)}
            </p>
            <div className='flex'>
              <p className='microcopy mr-3' style={{ color: `var(--${color})` }}>
                <FiIcons className='ml-0 mr-3 mb-1' name={theme === 'white' ? 'location' : 'location_white'} />
                {props.freelancer.address.city}, {props.freelancer.address.country}
              </p>
              <div className='flex items-center'>
                <SeñorityIcon experienceLevel={props.freelancer.experience_level} />
                <p className='microcopy ml-1 md:block hidden' style={{ color: `var(--${color})` }}>
                  : Seniority
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-end'>
          {false && (
            <p
              className={`microcopy text-right md:mb-2 mb-0 ${props.freelancer.is_available_to_work ? 'text-blue' : 'text-red'}`}
            >
              <span className='mr-2.5'>●</span>
              {props.freelancer.is_available_to_work
                ? t('freelancerProfile.webFreelancerProfile.available')
                : t('freelancerProfile.webFreelancerProfile.notAvailable')}
            </p>
          )}
        </div>
      </div>
      <FreelancerProfilePortfolio freelancer={props.freelancer} />
      <div className='grid grid-cols-12 gap-6 mt-8'>
        <p className='lg:col-span-8 md:col-span-11 col-span-12 subtitle4-regular' style={{ color: `var(--${color})` }}>
          {props.freelancer.biography}
        </p>
        <div className='lg:col-span-4 col-span-12'>
          <PillBoxSkills borderColor={color} skills={props.freelancer.skills} className='md:justify-end justify-start' />
        </div>
      </div>
      <FreelancerProfileStudiesExperiences freelancer={props.freelancer} theme={color} />
    </div>
  )
}

export default WebFreelancerProfile
