import { FC } from 'react'

import { useTranslation } from 'react-i18next'

const HelpActivity: FC = () => {
  const { t, i18n } = useTranslation()
  const activityTopics: any = t('CLIENT_HELP.ACTIVITY_TOPICS', { returnObjects: true })
  return (
    <div className='w-9/12'>
      <p className='body2-regular mb-6'>{t('CLIENT_HELP.STATE_IN_DEVELOP_TITLE')}</p>

      {activityTopics.map((topic: any, index: any) => {
        const contacLink = i18n.language === 'en' && index === 4 && (
          <a href='mailto:contacto@findie.cl' className='text-blue-500'>
            {t('CLIENT_HELP.PLEASE_CONTACT')}
          </a>
        )

        return (
          <div key={index} className='flex gap-4  mt-4'>
            <div>
              <p className='microcopy w-7 h-7 border border-black total-center rounded-full'>{index + 1}</p>
            </div>
            <p className='body2-regular whitespace-pre-wrap'>
              <span className='body2-medium'>{topic.title} </span>
              {topic.subtitle}
              {contacLink}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default HelpActivity
