import expert from '../../assets/images/brand/expert-icon.svg'
import senior from '../../assets/images/brand/senior-icon.svg'
import medium from '../../assets/images/brand/medium-icon.svg'
import junior from '../../assets/images/brand/junior-icon.svg'
import { IExperienceLevel } from '../../models/IFreelancer'

type TSeñorityIcon = {
  experienceLevel: IExperienceLevel
  className?: string
}

const SeñorityIcon = (props: TSeñorityIcon) => {
  const experienceLevelDict: Record<IExperienceLevel, string> = { junior, semi_senior: medium, senior, expert }

  return <img src={experienceLevelDict[props.experienceLevel]} alt='senority' className={`${props.className ?? ''}`} />
}

export default SeñorityIcon
