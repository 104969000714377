import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { IFormStep, IStorage } from '../../../models/IFormStep'

import { IconButton, useMediaQuery } from '@material-ui/core'
import InputField from '../../../assets/UIkit/Forms/InputField'
import { useTranslation } from 'react-i18next'
import UserProfileContainer from './UserProfileContainer'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import routes from '../../../constants/routes'

type TClientStepFour = {
  clientStorage: IStorage
}

const ClientStepFour: React.FC<IFormStep & TClientStepFour> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width: 430px)')
  const storage = props.clientStorage
  const formDefaultValues = {
    name: storage.item.name ?? '',
    lastName: storage.item.lastName ?? '',
    phone: storage.item.phone ?? '',
    'address.country': storage.item.address?.country ?? '',
  }
  const form = useForm({ defaultValues: formDefaultValues })

  const validateForm =
    Boolean(!storage.item?.name) || Boolean(!storage.item?.lastName) || Boolean(!storage.item?.address?.country)

  const handleColorValidation = (field: 'name' | 'lastName' | 'address.country' | 'phone', requiredStatus?: 'not_required') => {
    if (requiredStatus) return ''
    return form.watch(field) ? 'client-success-validation' : 'client-error-validation'
  }

  const nextPage = () => {
    history.push(`${routes.web_site.client_suscription_form.form_steps}/5`)
  }

  useEffect(() => {
    let inputFields = { ...storage.item }
    Object.entries(form.getValues()).forEach(([key, value]) => {
      inputFields = { ...inputFields, [key]: value }
    })
    storage.setItem(inputFields)
  }, [form.watch('name'), form.watch('lastName'), form.watch('phone'), form.watch('address.country')])

  useEffect(() => {
    form.setValue('name', storage.item.name)
    form.setValue('lastName', storage.item.lastName)
    form.setValue('phone', storage.item.phone)
    form.setValue('address.country', storage.item.address?.country as never)
  }, [])

  const required = t('clientSubscriptionForm.stepFive.FormStepContainer.inputEmail.required')
  const placeholderName = t('clientSubscriptionForm.stepFive.FormStepContainer.inputName.placeholder')
  const placeholderLastName = t('clientSubscriptionForm.stepFive.FormStepContainer.inputLastname.placeholder')
  const placeholderCountry = t('clientSubscriptionForm.stepFive.FormStepContainer.inputCountry.placeholder')
  const placeholderPhone = t('clientSubscriptionForm.stepFive.FormStepContainer.inputPhone.placeholder')
  const numbersOnlyAlert = t('clientSubscriptionForm.stepFive.NUMBERS_ONLY')

  return (
    <UserProfileContainer>
      <form className='relative lg:w-6/12 md:w-10/12 w-full h-full flex items-center' onSubmit={form.handleSubmit(nextPage)}>
        <div>
          <h2 className='xl:mb-16 lg:mb-16  md:mb-12 mb-6 text-black'>
            {t('clientSubscriptionForm.stepFive.FormStepContainer.title')}
          </h2>

          <div className='md:mt-8 mt-3 grid grid-cols-12 gap-4'>
            <InputField
              name={`name`}
              className='md:col-span-6 col-span-12'
              inputProps={{
                className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                  'name'
                )}`,
                placeholder: placeholderName,
              }}
              options={{ required: required }}
              form={form}
            />
            <InputField
              name={`lastName`}
              className='md:col-span-6 col-span-12'
              inputProps={{
                className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                  'lastName'
                )}`,
                placeholder: placeholderLastName,
              }}
              options={{ required: required }}
              form={form}
            />
          </div>
          <InputField
            name={`address.country`}
            className='md:mt-8 mt-3'
            inputProps={{
              className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                'address.country'
              )}`,
              placeholder: placeholderCountry,
            }}
            options={{ required: required }}
            form={form}
          />
          <InputField
            name={`phone`}
            className='md:mt-8 mt-3'
            inputProps={{
              className: `bg-transparent text-black buttontext4-regular w-full autofill-color-white ${handleColorValidation(
                'phone',
                'not_required'
              )}`,
              type: 'tel',
              placeholder: placeholderPhone,
            }}
            options={{
              pattern: {
                value: /^[0-9+]*$/, // Expresión regular para permitir solo números y *
                message: numbersOnlyAlert,
              },
            }}
            form={form}
          />
        </div>

        <IconButton
          size={isMobile ? 'small' : 'medium'}
          type='submit'
          disabled={validateForm}
          className={`absolute bottom-8 right-8 border border-black  ${validateForm ? 'bg-white-gray' : 'bg-black'}`}
        >
          <ArrowForwardIosRoundedIcon className='text-white' />
        </IconButton>
      </form>
    </UserProfileContainer>
  )
}

export default ClientStepFour
