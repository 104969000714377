import { useEffect } from 'react'
import { useWebSiteLayout } from '../../customHooks/useWebSiteLayout'

import WebSiteStructure from '../../layout/WebSite/WebSiteStructure'
import AboutUsIntro from '../../components/WebSite/AboutUs/AboutUsIntro'
import OurHistory from '../../components/WebSite/AboutUs/OurHistory'
import Collage from '../../components/WebSite/AboutUs/Collage'
import useExecuteScroll from '../../customHooks/useExecuteScroll'
import SectionStructure from '../../layout/WebSite/SectionStructure'
import { useTranslation } from 'react-i18next'

const AboutUs: React.FC = () => {
  const { view, setView } = useWebSiteLayout()
  const scroll = useExecuteScroll()
  const { t } = useTranslation()
  useEffect(() => {
    setView({ path: 'about_us', bgColor: 'web-bg-white', textColor: 'text-black', textColor2: 'text-light-rose' })
  }, [])

  return (
    <>
      <WebSiteStructure>
        <div id='about_us_intro'>
          <AboutUsIntro view={view} executeScroll={scroll.execute} />
          <SectionStructure className={`py-4 md:mt-0 md:my-12 mb-14 mt-28 ${view.textColor}`}>
            <p className='special-about-us-book w-full'>
              <span className='special-about-us'>{'{Find}: '}</span> {t('ABOUTFINDIE.AboutUs.part1')}{' '}
              <span className='special-about-us'>{'+ {Indie}: '}</span> {t('ABOUTFINDIE.AboutUs.part2')}
              <span className='special-about-us'>{t('ABOUTFINDIE.AboutUs.part3')}</span>
            </p>
            <p className='body2-regular mt-6'>{t('ABOUTFINDIE.AboutUs.part4')}</p>
            <p className='body2-regular'>{t('ABOUTFINDIE.AboutUs.part5')}</p>
          </SectionStructure>
        </div>
        <OurHistory view={view} />
        <Collage view={view} />
      </WebSiteStructure>
    </>
  )
}

export default AboutUs
