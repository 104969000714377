import { FC } from 'react'

type TPillbox = {
  className?: string
}

const Pillbox: FC<TPillbox> = (props) => {
  return (
    <span
      className={`rounded-full microcopy text-center ${props.className}`}
      style={{ color: 'var(--white)', padding: '2px 16px' }}
    >
      {props.children}
    </span>
  )
}

export default Pillbox
