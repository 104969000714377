import { useEffect, useState, useContext } from 'react'
import { Route, Switch, useHistory, useParams, useLocation } from 'react-router'
import useLocalStorage from '../../customHooks/useLocalStorage'
import routes from '../../constants/routes'

import Wizard from '../../shared/Wizard/Wizard'
import ClientStepZero from '../../components/WebSite/ClientSuscriptionForm/ClientStepZero'
import ClientStepOne from '../../components/WebSite/ClientSuscriptionForm/ClientStepOne'
import ClientStepTwo from '../../components/WebSite/ClientSuscriptionForm/ClientStepTwo'
import ClientStepThree from '../../components/WebSite/ClientSuscriptionForm/ClientStepThree'
import { created_at } from '../../utils/helpers'
import { CategoriesContext } from '../../context/Cpanel/CategoriesContext'
import ClientStepFour from '../../components/WebSite/ClientSuscriptionForm/ClientStepFour'
import ClientStepFive from '../../components/WebSite/ClientSuscriptionForm/ClientStepFive'

const ClienteSuscriptionForm: React.FC = () => {
  const param = useParams<{ freelancerId: string }>()
  const storage = useLocalStorage('project', { createdAt: created_at })
  const clientStorage = useLocalStorage('client', { createdAt: created_at })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { categoriesQuery } = useContext(CategoriesContext)
  const { pathname } = useLocation()
  const history = useHistory()

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const slide = pathname.charAt(pathname.length - 1)

  const handleStep = (step: string) => {
    history.push(`${routes.web_site.client_suscription_form.form_steps}/${step}`)
  }

  const defaultProps = {
    isButtonDisabled,
    route: routes.web_site.client_suscription_form.form_steps,
    storage,
    setIsButtonDisabled,
    handleStep,
  }

  useEffect(() => {
    history.push(`${routes.web_site.client_suscription_form.form_steps}/0`)
  }, [])

  if (!categoriesQuery) return null
  return (
    <Switch>
      <Route exact path={`${routes.web_site.client_suscription_form.step_zero}`} component={ClientStepZero} />
      <Route exact path={`${routes.web_site.client_suscription_form.form_steps}/4`}>
        <ClientStepFour {...defaultProps} clientStorage={clientStorage} />
      </Route>
      <Route exact path={`${routes.web_site.client_suscription_form.form_steps}/5`}>
        <ClientStepFive {...defaultProps} freelancerId={param.freelancerId} clientStorage={clientStorage} />
      </Route>

      <Route exact path={`${routes.web_site.client_suscription_form.form_steps}/:slide`}>
        <Wizard {...defaultProps} withRoutes onChangeNextSlide={slide === '3' ? () => setIsModalOpen(true) : undefined}>
          <ClientStepOne {...defaultProps} />
          <ClientStepTwo {...defaultProps} />
          <ClientStepThree {...defaultProps} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          <div />
        </Wizard>
      </Route>
    </Switch>
  )
}

export default ClienteSuscriptionForm
