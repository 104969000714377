import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import ClientProjectCandidatesList from './ClientProjectCandidatesList'
import ClientProjectCandidatesDetail from './ClientProjectCandidatesDetail'
import { UseQueryResult } from 'react-query'
import { IProject } from '../../../../../models/IProject'
import { ChooseCandidateContextProvider } from '../../../../../context/ChooseCandidateContext'

type TClientProjectCandidates = {
  projectQuery: UseQueryResult<IProject, unknown>
}

export const ClientProjectCandidates: FC<TClientProjectCandidates> = (props) => {
  return (
    <Switch>
      <Route path={`${routes.clientUserProfile.projects.detail}/:projectId/candidatos/detalle/:offerId`}>
        <ChooseCandidateContextProvider>
          <ClientProjectCandidatesDetail projectQuery={props.projectQuery} />
        </ChooseCandidateContextProvider>
      </Route>
      <Route
        path={`${routes.clientUserProfile.projects.detail}/:projectId/candidatos/lista`}
        component={ClientProjectCandidatesList}
      />
    </Switch>
  )
}
