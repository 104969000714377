import { FC } from 'react'
import defaultAvatar from '../../assets/images/userProfile/project-avatar.svg'
import { IFreelancer } from '../../models/IFreelancer'
import { capitalizeFirstLetter } from '../../utils/helpers'

const FreelancerProfileCard: FC<IFreelancer> = (props) => {
  return (
    <div>
      <img src={props.portfolio_avatar ?? defaultAvatar} className='w-72 h-72 object-cover' />
      <p className='subtitle5-medium mt-1'>
        {props.name} {capitalizeFirstLetter(props.lastName)}.
      </p>
      <p className='body2-regular'>{props.college_degree}</p>
    </div>
  )
}

export default FreelancerProfileCard
