import { Zoom } from '@material-ui/core'
import { FC } from 'react'
import scrollTop from '../../assets/images/web/scroll-top.svg'

type TScrollToTop = {
  open: boolean
  onClick: () => void
}

const ScrollToTop: FC<TScrollToTop> = (props) => {
  return (
    <div className='fixed md:bottom-4 bottom-20 lg:right-32 md:right-6 right-2 z-50'>
      <Zoom in={props.open}>
        <img className='cursor-pointer md:w-max w-12' src={scrollTop} alt='scroll-top' onClick={props.onClick} />
      </Zoom>
    </div>
  )
}

export default ScrollToTop
