import { FC } from 'react'

const HelpPayment: FC = () => {
  return (
    <div className='w-10/12'>
      <p className='body2-regular'>
        Nuestros datos para realizar facturas:
        <br />
        <br />
        Findie SpA
        <br />
        Rut 77.311.341-6
        <br />
        Ebro 2740 Of. 602
        <br />
        Las Condes Santiago, Chile
      </p>
    </div>
  )
}

export default HelpPayment
