import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Tooltip } from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'

import InputField from '../../assets/UIkit/Forms/InputField'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import { IClient } from '../../models/IClient'
import { accountType, bankList } from '../../constants/Cpanel/ProjectConstants'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import HelpTooltip from '../HelpTooltip/HelpTooltip'
import { useTranslation } from 'react-i18next'

type TClientBankDataForm = {
  form: UseFormReturn<any>
  client?: IClient | undefined
  isDisabledFields?: boolean
  variant?: 'default' | 'outlined'
}

const ClientBankDataForm: React.FC<TClientBankDataForm> = (props) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (!props.client) return

    props.form.setValue('bank_detail.name', props.client?.bank_detail?.name)
    props.form.setValue('bank_detail.bank_name', props.client?.bank_detail?.bank_name)
    props.form.setValue('bank_detail.account_type', props.client?.bank_detail?.account_type)
    props.form.setValue('bank_detail.account_number', props.client?.bank_detail?.account_number)

    props.form.setValue('billing.company_name', props.client?.billing?.company_name)
    props.form.setValue('billing.dni', props.client?.billing?.dni)
    props.form.setValue('billing.business_sector', props.client?.billing?.business_sector)
    props.form.setValue('billing.address', props.client?.billing?.address)
    props.form.setValue('billing.city', props.client?.billing?.city)
    props.form.setValue('billing.country', props.client?.billing?.country)
    props.form.setValue('billing.phone', props.client?.billing?.phone)
  }, [props.client])

  return (
    <>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            {t('ACCOUNT_FORM.BUSINESS_NAME.LABEL')} <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.company_name'
            labelClassName='text-gray-500'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.BUSINESS_NAME.PLACEHOLDER')}`,
            }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div>
        <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            {t('ACCOUNT_FORM.TAX_NUMBER.LABEL')} <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.dni'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.TAX_NUMBER.PLACEHOLDER')}`,
            }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div>
        <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            {t('ACCOUNT_FORM.BUSINESS_TYPE.LABEL')} <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.business_sector'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.BUSINESS_TYPE.PLACEHOLDER')}`,
            }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div>
        <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            {t('ACCOUNT_FORM.COMP_ADDRESS.LABEL')} <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.address'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.COMP_ADDRESS.PLACEHOLDER')}`,
            }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div>
        {/* <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            Ciudad <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.city'
            variant={props.variant ?? 'outlined'}
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu Ciudad' }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div> */}
        <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            {t('ACCOUNT_FORM.CON_3.LABEL')} <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.country'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.CON_3.PLACEHOLDER')}`,
            }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div>
        {/* <div className='col-span-4'>
          <p className='microcopy text-gray-500'>
            Teléfono <span className='text-blue'>*</span>
          </p>
          <InputField
            name='billing.phone'
            variant={props.variant ?? 'outlined'}
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa tu Teléfono' }}
            options={{ required: `${t('GLOBAL.REQUIRED_FIELD')}` }}
            form={props.form}
          />
        </div> */}
      </div>
      <div className='flex items-center gap-4 mt-8'>
        <p className='microcopy mt-1.5 text-blue'>{t('ACCOUNT_FORM.OPTION')}</p>
        <HelpTooltip title={`${t('ACCOUNT_FORM.OPTION_MESSAGE')}`} placement='right' />
      </div>
      <div className='grid grid-cols-12 gap-4 mt-4'>
        <div className='md:col-span-4 col-span-6'>
          <InputField
            name='bank_detail.name'
            label={`${t('ACCOUNT_FORM.NAME.LABEL')}`}
            labelClassName='text-gray-500'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.NAME.PLACEHOLDER')}`,
            }}
            form={props.form}
          />
        </div>
        <div className='md:col-span-4 col-span-6'>
          <InputField
            name='bank_detail.account_number'
            label={`${t('ACCOUNT_FORM.ACCOUNT_N.LABEL')}`}
            labelClassName='text-gray-500'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.ACCOUNT_N.PLACEHOLDER')}`,
            }}
            form={props.form}
          />
        </div>
        <div className='md:col-span-4 col-span-6'>
          <InputField
            name='bank_detail.dni'
            label={`${t('ACCOUNT_FORM.I_D.LABEL')}`}
            labelClassName='text-gray-500'
            variant={props.variant ?? 'outlined'}
            inputProps={{
              className: 'w-full',
              disabled: props.isDisabledFields,
              placeholder: `${t('ACCOUNT_FORM.I_D.PLACEHOLDER')}`,
            }}
            form={props.form}
          />
        </div>
        <div className='md:col-span-4 col-span-6'>
          <SelectField
            name='bank_detail.bank_name'
            label={`${t('ACCOUNT_FORM.BAN_K')}`}
            labelClassName='text-gray-500'
            variant={props.variant ?? 'outlined'}
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
            form={props.form}
            selectOptions={bankList.map((bank) => {
              return { value: bank.name, label: bank.name }
            })}
          />
        </div>
        <div className='md:col-span-4 col-span-6'>
          <SelectField
            name='bank_detail.account_type'
            label={`${t('ACCOUNT_FORM.ACCOUNT_T')}`}
            labelClassName='text-gray-500'
            variant={props.variant ?? 'outlined'}
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
            form={props.form}
            selectOptions={accountType.map((bank) => {
              return { value: bank.name, label: bank.name }
            })}
          />
        </div>
      </div>
    </>
  )
}

export default ClientBankDataForm
