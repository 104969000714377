import { useState, useEffect, useRef, useMemo } from 'react'
import Slider from 'react-slick'
import { useMediaQuery } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { IWebSiteSection } from '../../models/IWebSiteSection'
import { useCategoriesQuery } from '../../customHooks/request/categoriesQuery'
import { usePublicFreelancersQuery } from '../../customHooks/request/freelancersQuery'
import { ICategory } from '../../models/ICategory'
import { IFreelancer } from '../../models/IFreelancer'
import { sliderSettings, sliderSettingsMobile } from '../../constants/WebSite/HomePageConstants'
import WebAvatarProfile from '../AvatarProfile/WebAvatarProfile'
import routes from '../../constants/routes'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import { CategoryTabs } from './CategoryTabs'

const FreelancersGallery: React.FC<IWebSiteSection> = (props) => {
  const sliderRef = useRef<any>()
  const mobileSliderRef = useRef<any>()
  const mobile = useMediaQuery('(max-width:430px)')
  const tablet = useMediaQuery('(max-width:800px)')
  const categoriesQuery = useCategoriesQuery()
  const freelancersQuery = usePublicFreelancersQuery({ filters: { 'featured_status.is_featured': true } })
  const [selectCategory, setSelectCategory] = useState<ICategory>()
  const [freelancers, setFreelancers] = useState<IFreelancer[]>([])

  const categories = useMemo(() => {
    if (categoriesQuery.isSuccess) return categoriesQuery.data.filter((category: ICategory) => !category.is_other_category)
    return []
  }, [categoriesQuery.data])

  const showArrowsSlides = () => {
    if (mobile) return 1
    if (tablet) return 3
    return 5
  }

  const handleSlide = (direction: 'prev' | 'next') => {
    direction === 'prev' && sliderRef.current.slickNext()
    direction === 'next' && sliderRef.current.slickPrev()
  }

  useEffect(() => {
    if (categoriesQuery.isSuccess) {
      setSelectCategory(categoriesQuery.data[0])
    }
  }, [categoriesQuery.isSuccess])

  useEffect(() => {
    if (freelancersQuery.isSuccess && selectCategory?._id) {
      const freelancerByCategory = freelancersQuery.data.data
        .filter((freelancer: IFreelancer) => {
          return freelancer.category._id === selectCategory._id
        })
        .sort((a, b) => a.featured_status.position - b.featured_status.position)
      setFreelancers(freelancerByCategory.slice(0, 9))
    }
  }, [freelancersQuery.isSuccess, selectCategory])

  return (
    <>
      <div className='freelancer-gallery-grid'>
        <div />
        <div style={{ maxWidth: 1108 }}>
          <CategoryTabs
            categories={categories}
            selectCategory={selectCategory}
            textColor={props.view.textColor}
            className='mt-8'
            setSelectCategory={setSelectCategory}
          />
        </div>
      </div>

      {/* desktop/tablet gallery */}
      <div className='w-full px-3 mx-auto relative md:block hidden'>
        <Slider ref={(slider) => (sliderRef.current = slider)} {...sliderSettings(freelancers.length)}>
          {freelancers.map((freelancer: IFreelancer) => {
            return (
              <Link
                key={freelancer._id}
                to={`${routes.web_site.freelancer_profile}/${freelancer._id}`}
                className='flex md:justify-center justify-start md:mt-0 mt-3 mx-2'
              >
                <div className='grid grid-cols-12 gap-4 md:px-1'>
                  <div className='col-span-12'>
                    <WebAvatarProfile limitSkill={4} {...freelancer} asFreelancerGallery />
                  </div>
                </div>
              </Link>
            )
          })}
        </Slider>
        {freelancers.length > showArrowsSlides() && (
          <div className='lg:flex hidden items-center absolute top-0 right-0 h-full px-4 bg-white-04'>
            <FiIcons
              name='chevron_right'
              className={`${props.view.textColor === 'text-white' ? 'svg-white' : 'svg-black'}  cursor-pointer`}
              onClick={() => handleSlide('prev')}
            />
          </div>
        )}
      </div>

      {/* mobile gallery */}
      <div className='w-full md:hidden block'>
        <Slider ref={(slider) => (mobileSliderRef.current = slider)} {...sliderSettingsMobile()}>
          <div className='px-7 w-full'>
            {freelancers.slice(0, 3).map((freelancer: IFreelancer) => {
              return (
                <Link key={freelancer._id} to={`${routes.web_site.freelancer_profile}/${freelancer._id}`}>
                  <WebAvatarProfile key={freelancer._id} limitSkill={4} {...freelancer} />
                </Link>
              )
            })}
          </div>
          <div className='px-7 w-full'>
            {freelancers.slice(3, 6).map((freelancer: IFreelancer) => {
              return (
                <Link key={freelancer._id} to={`${routes.web_site.freelancer_profile}/${freelancer._id}`}>
                  <WebAvatarProfile limitSkill={4} {...freelancer} />
                </Link>
              )
            })}
          </div>
        </Slider>
      </div>
    </>
  )
}

export default FreelancersGallery
