import { useMemo } from 'react'
import { IWorkPlan } from '../models/IWorkPlan'

const useWorkPlanVersion = (workPlan: IWorkPlan | undefined) => {
  return useMemo(() => {
    if (!workPlan) return undefined

    const workPlanVersion = workPlan.versions.find((version) => version.is_selected)
    if (!workPlanVersion) return undefined
    return workPlanVersion
  }, [workPlan])
}

export default useWorkPlanVersion
