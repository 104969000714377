import { FC, useContext } from 'react'
import { CurrentViewContext } from '../../context/WebSite/CurrentViewContext'
import { ISkill } from '../../models/ISkill'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'

type TPillBoxSkills = {
  skills: ISkill[]
  className?: string
  limitSkill?: number
  borderColor?: 'black' | 'white'
  onRemove?: (skill: ISkill) => void
}

const PillBoxSkills: FC<TPillBoxSkills> = (props) => {
  const { view } = useContext(CurrentViewContext)
  const { t } = useTranslation()

  const borderStyles = () => {
    if (view) return view.textColor.includes('text-black') ? 'border-black text-black' : 'border-white text-white'
    if (props.borderColor) return props.borderColor === 'black' ? 'border-black text-black' : 'border-white text-white'
    return 'border-black text-black'
  }

  return (
    <div className={`flex flex-wrap mt-1 ${props.className}`}>
      {props.skills.slice(0, props.limitSkill ?? props.skills.length).map((skill: ISkill) => {
        return (
          <span
            key={skill._id}
            className={`microcopy flex items-center rounded-2xl px-2 py-0 mr-2 mt-2 border ${borderStyles()}`}
          >
            {t(skill.name)}
            {props?.onRemove && (
              <CloseIcon
                fontSize='small'
                className='text-gray ml-1 cursor-pointer'
                onClick={() => props.onRemove && props.onRemove(skill)}
              />
            )}
          </span>
        )
      })}
    </div>
  )
}

export default PillBoxSkills
