import { UseMutationResult, UseQueryResult } from 'react-query'

import { Box } from '@material-ui/core'
import FreelancerAdditionalInfo from './FreelancerAdditionalInfo'
import FreelancerStudiesAndExperiences from './FreelancerStudiesAndExperiences'
import FreelancerFindiePortfolio from './FreelancerFindiePortfolio'
import { IFreelancer } from '../../../../../models/IFreelancer'

type TFreelancerCpanelProfile = {
  freelancerId: string
  freelancer: UseQueryResult<IFreelancer, unknown>
  isInputsDisabled: boolean
  putFreelancerMutation: UseMutationResult<{ data: IFreelancer }, unknown, { body: Partial<IFreelancer>; _id: string }, unknown>
  afterFreelancerMutation: () => void
}

const FreelancerCpanelProfile: React.FC<TFreelancerCpanelProfile> = (props) => {
  const componentsProps = {
    freelancerId: props.freelancerId,
    freelancer: props.freelancer,
    isInputsDisabled: props.isInputsDisabled,
    putFreelancerMutation: props.putFreelancerMutation,
    afterFreelancerMutation: props.afterFreelancerMutation,
  }

  return (
    <Box className='mt-2'>
      <FreelancerAdditionalInfo {...componentsProps} />
      <FreelancerStudiesAndExperiences {...componentsProps} />
      <FreelancerFindiePortfolio
        freelancerId={props.freelancerId}
        freelancer={props.freelancer}
        putFreelancerMutation={props.putFreelancerMutation}
        afterFreelancerMutation={props.afterFreelancerMutation}
      />
    </Box>
  )
}

export default FreelancerCpanelProfile
