import { FC } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { operation } from '../../utils/helpers'
import { Link } from 'react-router-dom'
import routes from '../../constants/routes'
import { IProject } from '../../models/IProject'
import { FiIcons } from '../../assets/UIkit/Icons/FiIcons'
import newOffer from '../../assets/images/userProfile/new.png'
import FeedbackScore from './ProjectFeedback/FeedbackScore'
import ProjectStatusPillBox from '../PillBoxStatus/ProjectStatusPillBox'
import dayjs from 'dayjs'
import FindieIsotype from '../Brand/Isotype/FindieIsotype'

type TProjectItemCard = {
  entity: 'freelancer' | 'client'
  has_negotiation?: boolean
  isHistoric?: boolean
}

const ProjectItemCard: FC<TProjectItemCard & IProject> = (props) => {
  const isMobile = useMediaQuery('(max-width: 430px)')
  const isTablet = useMediaQuery('(max-width: 825px)')
  const freelancer = props.staff.length && props.staff[0].freelancer ? props.staff[0].freelancer : undefined
  const client = props.client.companyName
  const path = props.entity === 'client' ? routes.clientUserProfile.projects.detail : routes.freelancerUserProfile.projects.detail

  const clientFeedback = () => {
    const entityFeedback = props.entity === 'client' ? props.staff[0].client_feedback : props.staff[0].freelancer_feedback
    if (!entityFeedback?.list) return 0

    const feedbackAmount = entityFeedback.list.reduce((acum, feedback) => (acum = acum + feedback.score), 0)
    return operation(feedbackAmount, '/', entityFeedback.list.length)
  }

  return (
    <Box
      display='grid'
      height={60}
      gridTemplateColumns={isTablet ? `35px ${isMobile ? '160px' : '300px'} 45px 1fr` : '40px 1fr 55px 15% 12% 20% 15%'}
      className={`items-center w-full border-b  border-gray-200 md:px-2 px-0 ${!true ? 'bg-gray-100' : ''}`}
    >
      <div>
        <Link to={`${path}/${props._id}/actividad`}>
          <FiIcons className='mb-0.5' name='addCircle' />
        </Link>
      </div>

      <Link className='' to={`${path}/${props._id}/actividad`}>
        <p className='subtitle4-regular truncate'>{props?.brief?.title}</p>
      </Link>

      <div>
        {props.has_negotiation && !['in_progress', 'paused'].includes(props.project_status) && (
          <img src={newOffer} className='w-10 h-10' alt='nuevo' />
        )}
      </div>

      <div>
        <ProjectStatusPillBox
          className='w-24 px-1'
          projectStatus={props.project_status}
          evaluationStatus={props.evaluation_status}
        />
      </div>

      <p className='lg:block hidden text-center'>{dayjs(props.created_at).format('DD/MM/YYYY')}</p>

      {props.entity === 'client' && freelancer && (
        <div className='lg:flex hidden items-center gap-4 pl-2'>
          <img src={freelancer.portfolio_avatar} alt='avatar' className='w-8 h-8 rounded-full' />
          <p className='subtitle6-regular truncate'>
            {freelancer.name} {freelancer.lastName.slice(0, 1).toUpperCase()}.
          </p>
        </div>
      )}
      {props.entity === 'freelancer' && client && (
        <div className='lg:flex hidden items-center gap-2'>
          <span className='w-8 h-8'>
            <FindieIsotype />
          </span>
          <p className='subtitle6-regular truncate'>{client}.</p>
        </div>
      )}

      {props.isHistoric && (
        <span className='lg:block hidden pl-2'>
          <FeedbackScore score={clientFeedback()} />
        </span>
      )}
    </Box>
  )
}

export default ProjectItemCard
