import { FC } from 'react'
import { useFreelancersFindieQuery } from '../../../customHooks/request/freelancersQuery'
import { IFreelancer } from '../../../models/IFreelancer'
import usePagination from '../../../customHooks/usePagination'
import useGroupAlphabetList from '../../../customHooks/useGroupAlphabetList'
import { paginationLimit } from '../../../utils/configs'
import DeletedEntityList from '../../../shared/DeletedEntityList/DeletedEntityList'

const limit = paginationLimit

const FreelancersDeleted: FC = () => {
  const pagination = usePagination()
  const freelancersQuery = useFreelancersFindieQuery({
    filters: {
      $or: [{ freelancer_status: 'rejected' }, { freelancer_status: 'deleted' }],
      isShortResponse: true,
      page: pagination.page,
      limit,
    },
  })
  const freelancers = useGroupAlphabetList<IFreelancer>(freelancersQuery)

  return (
    <DeletedEntityList
      entityName='Freelancers'
      entityList={freelancers}
      pagination={pagination}
      pages={freelancersQuery.data?.metadata.pages ?? 0}
    />
  )
}

export default FreelancersDeleted
