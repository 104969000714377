import { FC } from 'react'

import SidebarUserProfile from './SidebarUserProfile'
import HeaderUserProfile from './HeaderUserProfile'

import { Box, useMediaQuery } from '@material-ui/core'
import BottomMenuUserProfile from './BottomMenuUserProfile'
import { TSidebarOptions } from './ClientUserProfileLayout'
import { RightSideBar } from './RightSideBar'

type TUserProfileLayout = {
  sidebarOptions: TSidebarOptions[]
}

const UserProfileLayout: FC<TUserProfileLayout> = (props) => {
  const isDesktop = useMediaQuery('(min-width: 825px)')

  return (
    <>
      {isDesktop ? (
        <div className='user-profile__container'>
          <SidebarUserProfile sidebarOptions={props.sidebarOptions} />

          <div className='user-profile__header'>
            <HeaderUserProfile />
            <div className='user-profile__main'>
              <div className='user-profile__overflow px-16 py-8'>{props.children}</div>
              <RightSideBar />
            </div>
          </div>
        </div>
      ) : (
        <div className='user-profile__container'>
          <HeaderUserProfile />
          <div className='user-profile__overflow md:px-8 px-4 md:pt-6 pt-4 pb-12'>{props.children}</div>
          <BottomMenuUserProfile sidebarOptions={props.sidebarOptions} />
        </div>
      )}
    </>
  )
}

export default UserProfileLayout
