import { useSetLoader } from './useSetLoader'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const useRequestAlert = (
  request: any,
  form?: UseFormReturn<any>,
  actionAfterSuccess?: () => void,
  actionAfterError?: () => void,
  successMessage?: string,
  errorMessage?: string,
  autoHideDuration?: number
) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  useSetLoader(request)

  useEffect(() => {
    if (request.isError) {
      actionAfterError && actionAfterError()
      if (errorMessage === 'none') return
      snackbar.enqueueSnackbar(errorMessage ?? request?.error?.json?.error ?? `${t('GLOBAL.ALERTS_SAVE_PRO.ERROR_TRY_AGAIN')}`, {
        variant: 'error',
      })
    }
    if (request.isSuccess) {
      form && form.reset()
      actionAfterSuccess && actionAfterSuccess()
      if (successMessage === 'none') return

      snackbar.enqueueSnackbar(successMessage ?? `${t('GLOBAL.ALERTS_SAVE_PRO.CHANGES_SAVED')}`, {
        variant: 'success',
        autoHideDuration: autoHideDuration ?? 5000,
      })
    }
  }, [request.isError, request.isSuccess])
}

export default useRequestAlert
