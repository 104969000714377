import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ISkill } from '../../models/ISkill'
import { ICategory } from '../../models/ICategory'
import { IFreelancer } from '../../models/IFreelancer'

import { Box, Grid } from '@material-ui/core'
import SelectField from '../../assets/UIkit/Forms/SelectField'
import InputField from '../../assets/UIkit/Forms/InputField'
import useCategoryAndSkills from '../../customHooks/useCategoryAndSkills'
import PillBoxSkills from '../PillBoxSkills/PillBoxSkills'
import ClpField from '../../assets/UIkit/Forms/ClpField'

type TFreelancersFormCategoriesAndSkills = {
  form: UseFormReturn<any>
  freelancer?: IFreelancer | undefined
  isDisabledFields?: boolean
  asPostulation?: boolean
}

const FreelancersFormCategoriesAndSkills: React.FC<TFreelancersFormCategoriesAndSkills> = (props) => {
  const { skillsList, categoriesQuery, categorySelected, selectedSkills, removeSkill, setSelectedSkills } = useCategoryAndSkills({
    form: props.form,
  })

  useEffect(() => {
    props.form.setValue('is_submitted_form', false)
  }, [])

  useEffect(() => {
    props.form.setValue('is_submitted_form', false)
    if (!props.freelancer) return
    Object.entries(props.freelancer).forEach(([key, value]) => {
      if (!['invitation_ticket', 'category', 'portfolio_link', 'skills', 'hour_cost'].includes(key)) return
      if (key === 'category') {
        props.form.setValue('category', value._id)
        props.form.setValue('category_selected', JSON.stringify(value))
        return
      }

      if (key === 'skills') {
        setSelectedSkills(value)
      }
      if (key === 'hour_cost') return props.form.setValue('hour_cost', String(value))
      props.form.setValue(key, value)
    })
  }, [props.freelancer])

  useEffect(() => {
    console.log(props.form.watch('is_submitted_form'))
    if (props.form.watch('is_submitted_form')) {
      setSelectedSkills([])
      props.form.setValue('is_submitted_form', false)
    }
  }, [props.form.watch('is_submitted_form')])

  return (
    <Box className='mt-2'>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {categoriesQuery && (
                <SelectField
                  name='category_selected'
                  label='Categoría'
                  inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Nombre de la categoría' }}
                  // options={{ required: 'Este campo es requerido' }}
                  form={props.form}
                  selectOptions={categoriesQuery
                    .filter((category: ICategory) => !category.is_other_category && category.is_available)
                    .map((category: ICategory) => {
                      return { value: JSON.stringify(category), label: category.name }
                    })}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <SelectField
                name='skills_options'
                label='Selecciona una habilidad'
                inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Nombre de la categoría' }}
                form={props.form}
                selectOptions={skillsList.map((skill: ISkill) => {
                  return { value: JSON.stringify(skill), label: skill.name }
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={8} md={10}>
          <p className='subtitle4-medium'>Habilidades seleccionadas</p>
          <div className='border border-black rounded-md px-2 h-28 overflow-auto'>
            {props.asPostulation ? (
              <PillBoxSkills skills={selectedSkills} />
            ) : (
              <PillBoxSkills skills={selectedSkills} onRemove={(skill) => removeSkill(skill)} />
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputField
            name='portfolio_link'
            label='Link'
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa el link' }}
            options={{ required: categorySelected?.is_link_required ? 'Este campo es requerido' : false }}
            form={props.form}
          />
        </Grid>
        <Grid item xs={4}>
          <InputField
            name='invitation_ticket'
            label='Ticket Invitación'
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields, placeholder: 'Ingresa el ticket' }}
            form={props.form}
          />
        </Grid>
        <Grid item xs={4}>
          <ClpField
            name='hour_cost'
            label='Valor hora'
            inputProps={{ className: 'w-full', disabled: props.isDisabledFields }}
            form={props.form}
          />
        </Grid>
        <Grid item xs={4}>
          {props.freelancer?.contact_preference && (
            <p className='microcopy'>Preferencia de contacto: {props.freelancer.contact_preference}</p>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default FreelancersFormCategoriesAndSkills
