import { Link } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { useSetLoader } from '../../../../customHooks/useSetLoader'
import usePagination from '../../../../customHooks/usePagination'
import { useFreelancersSorted } from '../../../../customHooks/request/freelancersQuery'
import { IFreelancer } from '../../../../models/IFreelancer'
import CustomPagination from '../../../../assets/UIkit/CustomPagination'
import useFilterByWord from '../../../../customHooks/useFilterByWord'

import { Box, Grid, List, ListItem } from '@material-ui/core'
import useGroupAlphabetList from '../../../../customHooks/useGroupAlphabetList'
import { FiIcons } from '../../../../assets/UIkit/Icons/FiIcons'
import { paginationLimit } from '../../../../utils/configs'
import dayjs from 'dayjs'
import FreelancerEvaluationStatusPillBox from '../../../../shared/PillBoxStatus/FreelancerEvaluationStatusPillBox'

const limit = paginationLimit

const FreelancersPostulationList: React.FC = () => {
  const pagination = usePagination()
  const { word, renderInput } = useFilterByWord()
  const freelancersQuery = useFreelancersSorted({
    filters: {
      $or: [
        { name: word ? { $regex: word, $options: 'i' } : undefined },
        { lastName: word ? { $regex: word, $options: 'i' } : undefined },
      ],
      freelancer_status: 'not_available',
      page: pagination.page,
      isShortResponse: true,
      limit,
    },
  })
  const freelancers = useGroupAlphabetList<IFreelancer>(freelancersQuery)
  useSetLoader(freelancersQuery)

  return (
    <>
      <h3>Formularios Recibidos</h3>

      <Grid container className='mt-4'>
        <Grid item xs={8}>
          <Box>
            {renderInput()}
            {freelancersQuery.isSuccess && freelancersQuery.data.data.length === 0 && word && (
              <span className='subtitle3-regular text-red block'>No se encontraron resultados de la búsqueda</span>
            )}
          </Box>
        </Grid>
      </Grid>

      {freelancers.map((structure) => {
        return (
          <div key={structure.letter}>
            {structure.list.length > 0 && (
              <>
                <p className='subtitle2-bold mt-4'>{structure.letter}</p>
                <List>
                  {structure.list.map((freelancer: IFreelancer) => {
                    return (
                      <ListItem dense button key={freelancer._id} className='p-0'>
                        <Link className='w-full' to={`${routes.cpanel.freelancers.postulations}/${freelancer._id}`}>
                          <Box
                            display='grid'
                            gridTemplateColumns='25% 12% 12% 15% 1fr 10%'
                            className='gap-4 py-4 px-2 border-t border-gray-300'
                          >
                            <p className='subtitle4-regular truncate'>
                              {freelancer?.name}, {freelancer?.lastName}
                            </p>
                            <p className='subtitle4-regular truncate'>{freelancer?.address.country}</p>
                            <p className='subtitle4-regular truncate'>{freelancer?.category.name}</p>
                            <p className='subtitle4-regular truncate'>{dayjs(freelancer.createdAt).format('DD-MM-YYYY')}</p>
                            <div>
                              <FreelancerEvaluationStatusPillBox
                                className='w-48'
                                postulationStatus={freelancer.postulation_status}
                                variant='outlined'
                              />
                            </div>
                            <div className='justify-self-end'>
                              {freelancer.is_featured_postulation && <FiIcons name='star' className='svg-orange' />}
                            </div>
                          </Box>
                        </Link>
                      </ListItem>
                    )
                  })}
                </List>
              </>
            )}
          </div>
        )
      })}

      {freelancersQuery.isSuccess && (
        <div className='flex justify-center'>
          <CustomPagination
            pages={freelancersQuery.data.metadata.pages}
            page={pagination.page}
            onChange={(e, value) => pagination.handlePage(value)}
            position={'center'}
            className='mt-4'
          />
        </div>
      )}
    </>
  )
}

export default FreelancersPostulationList
