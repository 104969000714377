import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import FreelancerUserProfileRouter from '../../appRouter/FreelancerUserProfileRouter'
import { IconsDict } from '../../assets/UIkit/Icons/FiIcons'
import routes from '../../constants/routes'
import useUser from '../../customHooks/useUser'
import UserProfileLayout from './UserProfileLayout'

export type TSidebarOptions = {
  title: string
  icon: keyof typeof IconsDict
  to: string
}

const sidebarOptions: TSidebarOptions[] = [
  { title: 'Home', icon: 'home', to: routes.freelancerUserProfile.home },
  { title: 'Proyectos', icon: 'findieTransparent', to: routes.freelancerUserProfile.projects.index },
  { title: 'Book', icon: 'book', to: routes.freelancerUserProfile.book.bio },
  { title: 'Cuenta', icon: 'account', to: routes.freelancerUserProfile.profile.personalData },
]

const FreelancerUserProfileLayout: FC = () => {
  return (
    <UserProfileLayout sidebarOptions={sidebarOptions}>
      <FreelancerUserProfileRouter />
    </UserProfileLayout>
  )
}

export default FreelancerUserProfileLayout
