import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type TLanguageSelector = {
  svgColor: string
}

const LanguageSelector: FC<TLanguageSelector> = (props) => {
  const { i18n } = useTranslation()
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [colorSvg, setColorSvg] = useState<string>('black')
  const [selectedOption, setSelectedOption] = useState<{ text: string; value: string }>()
  const [filterLanguages, setFilterLanguages] = useState<{ text: string; value: string }[]>()
  const languages = [
    { text: 'ESP', value: 'es' },
    { text: 'ENG', value: 'en' },
  ]

  const selectLng = async (lng: string) => {
    const filters = languages.filter((language) => language.value != lng)
    setFilterLanguages(filters)
    setSelectedOption(languages.find((language) => language.value == lng))
    await i18n.changeLanguage(lng)
  }
  useEffect(() => {
    setSelectedOption(languages.find((language) => language.value == i18n.language))
    const filters = languages.filter((language) => language.value != i18n.language)
    setFilterLanguages(filters)
  }, [])

  useEffect(() => {
    if (props.svgColor == 'text-white') setColorSvg(`white`)
    else setColorSvg('black')
  }, [props.svgColor])

  return (
    <div onMouseEnter={() => setShowSelect(true)} onMouseLeave={() => setShowSelect(false)}>
      <div className={`buttontext2-medium flex row items-center justify-center cursor-pointer`}>
        <div className={'mr-4'}>
          <p>{selectedOption?.text}</p>
        </div>
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='10' viewBox='0 0 20 10' fill='none'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M9.41827 8.8361L18.5221 -2.52423e-08L19.0996 0.594976L9.40958 10L0.000217412 0.590633L0.58651 0.00434139L9.41827 8.8361Z'
            fill={colorSvg}
          />
        </svg>
      </div>
      <div className={'buttontext2-medium absolute z-50 cursor-pointer'}>
        {showSelect &&
          filterLanguages?.map((lng) => {
            return (
              <p onClick={() => selectLng(lng.value as string)} className={'border-t'}>
                {lng?.text}
              </p>
            )
          })}
      </div>
    </div>
  )
}

export default LanguageSelector
