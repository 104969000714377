import { FC, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import useRequestAlert from '../../../customHooks/useRequestAlert'

import AppDialog from '../../../assets/UIkit/AppDialog'
import InputField from '../../../assets/UIkit/Forms/InputField'

import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction } from '@material-ui/core'
import { FiIcons } from '../../../assets/UIkit/Icons/FiIcons'
import useDialog from '../../../customHooks/useDialog'
import { IProduct } from '../../../models/IProduct'
import { useProductMutation, useProductsQuery } from '../../../customHooks/request/productQuery'
import { created_at } from '../../../utils/helpers'

const Plans: FC<{ mustDelete: boolean }> = (props) => {
  const dialog = useDialog<IProduct, 'update' | 'suspend' | 'delete'>()
  const createForm = useForm<IProduct>()
  const editForm = useForm<IProduct>()
  const { data: productsQuery, refetch: productRefetch } = useProductsQuery()
  const productMutation = useProductMutation()
  useRequestAlert(productMutation)

  const createPlan: SubmitHandler<IProduct> = async (data) => {
    const body = { ...data, created_at: created_at }
    productMutation.mutate(
      { body, method: 'POST' },
      {
        onSuccess: () => {
          productRefetch()
          createForm.reset()
        },
      }
    )
  }

  const handlePlan: SubmitHandler<IProduct> = (data) => {
    const id = dialog.data?._id ?? ''
    const is_available = !dialog?.data?.is_available
    const mutationOptions = { onSuccess: () => afterMutation() }
    dialog.action === 'update' && productMutation.mutate({ body: data, id, method: 'PUT' }, mutationOptions)
    dialog.action === 'suspend' && productMutation.mutate({ body: { ...data, is_available }, id, method: 'PUT' }, mutationOptions)
    dialog.action === 'delete' && productMutation.mutate({ id, method: 'DELETE' }, mutationOptions)
  }

  const afterMutation = () => {
    productRefetch()
    editForm.reset()
    dialog.toggle()
  }

  useEffect(() => {
    if (dialog.data?.name) {
      editForm.setValue('name', dialog.data.name)
      editForm.setValue('price', dialog.data.price)
      editForm.setValue('price_type', dialog.data.price_type)
      editForm.setValue('description', dialog.data.description)
    }
  }, [dialog.data])

  if (!productsQuery) return null
  return (
    <>
      <h3 className='mt-6'>Agrega un Producto</h3>
      <form onSubmit={createForm.handleSubmit(createPlan)}>
        <Grid container spacing={2} className='mt-4'>
          <Grid item xs={3}>
            <InputField
              name='name'
              label='Nombre del plan'
              inputProps={{ className: 'w-full', placeholder: 'Nombre del plan' }}
              options={{ required: 'Este campo es requerido' }}
              form={createForm}
            />
          </Grid>
          <Grid item xs={3}>
            <InputField
              name='price'
              label='Precio'
              inputProps={{
                className: 'w-full',
                placeholder: 'Agrega un precio al plan',
                type: 'number',
                step: '0.01',
              }}
              options={{ required: 'Este campo es requerido' }}
              form={createForm}
            />
          </Grid>
          <Grid item xs={3}>
            <InputField
              name='currency_price'
              label='Precio en divisa'
              inputProps={{
                className: 'w-full',
                placeholder: 'Agrega un precio al plan',
                type: 'number',
                step: '0.01',
              }}
              options={{ required: 'Este campo es requerido' }}
              form={createForm}
            />
          </Grid>
          <Grid item xs={3}>
            <p className='subtitle4-medium mr-8'>Tipo de precio</p>
            <div className='flex items-center'>
              <div className='flex items-center mr-4'>
                <InputField
                  name='price_type'
                  id='price_type_uf'
                  inputProps={{ type: 'radio', value: 'uf' }}
                  options={{ required: 'Este campo es requerido' }}
                  form={createForm}
                />
                <label htmlFor='price_type_uf' className='body2-medium cursor-pointer mb-2 ml-2'>
                  UF
                </label>
              </div>
              <div className='flex items-center mr-4'>
                <InputField
                  name='price_type'
                  id='price_type_c'
                  inputProps={{ type: 'radio', value: 'cash' }}
                  options={{ required: 'Este campo es requerido' }}
                  form={createForm}
                />
                <label htmlFor='price_type_c' className='body2-medium cursor-pointer mb-2 ml-2'>
                  Pesos
                </label>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <InputField
              name='description'
              label='Descripción'
              textarea
              textareaProps={{ className: 'w-full', placeholder: 'Agrega un comentario', rows: 2 }}
              form={createForm}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className='flex justify-end lg:pt-0 pt-2'>
          <Button type='submit' variant='contained' color='primary'>
            Guardar
          </Button>
        </Grid>
      </form>

      <h3 className='mb-1'>Productos disponibles</h3>
      <List className='border-t border-r border-l border-gray-300 py-0 mt-4'>
        {productsQuery.data.map((product: IProduct) => {
          return (
            <ListItem key={product._id} className='border-b border-gray-300'>
              <span className='subtitle3-regular'>{product.name}</span>

              <ListItemSecondaryAction>
                <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: product, dialogAction: 'update' })}>
                  <FiIcons name='edit' />
                </IconButton>
                <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: product, dialogAction: 'suspend' })}>
                  <FiIcons name='eye' className={`${product.is_available ? '' : 'svg-white'}`} />
                </IconButton>
                {props.mustDelete && (
                  <IconButton edge='end' onClick={() => dialog.toggle({ dialogData: product, dialogAction: 'delete' })}>
                    <FiIcons name='skull' />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <AppDialog
        open={dialog.isOpen}
        title={`${dialog.action === 'update' ? 'Editar' : 'Eliminar'} producto`}
        handleClose={dialog.toggle}
      >
        <form onSubmit={editForm.handleSubmit(handlePlan)}>
          {dialog.action === 'update' && (
            <>
              <span className='subtitle4-medium mb-2 block'>El nombre del plan a editar es: {dialog?.data?.name}</span>
              <InputField
                name='name'
                label='Nombre'
                inputProps={{ className: 'w-full', placeholder: 'Nombre del plan' }}
                options={{ required: 'Este campo es requerido' }}
                form={editForm}
              />
              <InputField
                name='price'
                label='Precio'
                inputProps={{ className: 'w-full', placeholder: 'Precio del plan' }}
                form={editForm}
              />
              <InputField
                name='currency_price'
                label='Precio en divisa'
                inputProps={{ className: 'w-full', placeholder: 'Precio del plan en divisas' }}
                form={editForm}
              />
              <InputField
                name='description'
                label='Descripción'
                textarea
                textareaProps={{ className: 'w-full', placeholder: 'Agrega un comentario sobre el plan', rows: 3 }}
                form={editForm}
              />
              <p className='subtitle4-medium mr-8'>Tipo de precio</p>
              <div className='flex items-center'>
                <div className='flex items-center mr-4'>
                  <InputField
                    name='price_type'
                    options={{ required: 'Este campo es requerido' }}
                    id='price_type_uf'
                    inputProps={{ type: 'radio', value: 'uf' }}
                    form={editForm}
                  />
                  <label htmlFor='price_type_uf' className='body2-medium cursor-pointer mb-2 ml-2'>
                    UF
                  </label>
                </div>
                <div className='flex items-center mr-4'>
                  <InputField
                    name='price_type'
                    options={{ required: 'Este campo es requerido' }}
                    id='price_type_c'
                    inputProps={{ type: 'radio', value: 'cash' }}
                    form={editForm}
                  />
                  <label htmlFor='price_type_c' className='body2-medium cursor-pointer mb-2 ml-2'>
                    Pesos
                  </label>
                </div>
              </div>
            </>
          )}
          {dialog.action === 'suspend' && (
            <>
              <span className='subtitle4-medium mb-4 block'>
                ¿Estas seguro que deseas {dialog?.data?.is_available ? 'suspender el plan' : 'activar el plan'}{' '}
                {dialog?.data?.name}?
              </span>
            </>
          )}
          {dialog.action === 'delete' && (
            <span className='subtitle4-medium mb-4 block'>
              ¿Estas seguro de eliminar el producto {dialog?.data?.name}? <br />
            </span>
          )}
          <Divider className='mt-4' />

          <Box display='flex' justifyContent='flex-end' mt={2}>
            <Button variant='contained' className='mr-4' onClick={() => dialog.toggle()}>
              Cancelar
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              Aceptar
            </Button>
          </Box>
        </form>
      </AppDialog>
    </>
  )
}

export default Plans
