import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { IFormStepStructure } from '../../../../models/IFormStep'
import NewProjectStepLayout from './NewProjectStepLayout'
import InputField from '../../../../assets/UIkit/Forms/InputField'
import { useTranslation } from 'react-i18next'

export const NewProjectStepInspiration: FC<IFormStepStructure> = (props) => {
  const { t } = useTranslation()
  const { slide } = useParams<{ slide: string }>()
  const storage = props.storage
  const form = useForm()
  const { Title } = props

  useEffect(() => {
    form.setValue('competition_referents', storage?.item?.competition_referents ?? '')
  }, [])

  useEffect(() => {
    if (slide === `${props.position}`) {
      props.setIsButtonDisabled(Boolean(!storage?.item?.competition_referents))
    }
  }, [slide, storage.item])

  useEffect(() => {
    storage.setItem({ ...storage.item, competition_referents: form.watch('competition_referents') })
  }, [form.watch('competition_referents')])

  return (
    <>
      <Title>{t('UP_NEW_PROJECT.INSPIRATION')}</Title>

      <NewProjectStepLayout>
        <InputField
          name='competition_referents'
          form={form}
          variant='transparent'
          className='w-full border-b-blue'
          textareaProps={{
            rows: 4,
            className: 'w-full formtext2-regular',
            placeholder: `${t('UP_NEW_PROJECT.ABOUT_INSPIRATION')}`,
          }}
          textarea
        />
      </NewProjectStepLayout>
    </>
  )
}
