import { Dispatch, SetStateAction } from 'react'
import useRequestAlert from '../../../../../../customHooks/useRequestAlert'
import { useProductsQuery } from '../../../../../../customHooks/request/productQuery'
import FiButton from '../../../../../../assets/UIkit/FiButton'
import { IProduct } from '../../../../../../models/IProduct'
import stars from '../../../../../../assets/images/userProfile/doble-stars.svg'
import { useSelectProjectCandidate } from '../../../../../../customHooks/request/projectsQuery'

type TProductsList = {
  selectedProducts: IProduct[]
  withCurrency: boolean
  className?: string
  setSelectedProducts: Dispatch<SetStateAction<IProduct[]>>
}

const ProductsList = (props: TProductsList) => {
  const { data: productsQuery } = useProductsQuery()
  const selectCandidate = useSelectProjectCandidate()
  useRequestAlert(selectCandidate)

  const isProductSelected = (currentProduct: IProduct) => {
    return props.selectedProducts.some((product) => product._id === currentProduct._id)
  }

  const handleProduct = (currentProduct: IProduct) => {
    if (isProductSelected(currentProduct)) {
      const removeProduct = props.selectedProducts.filter((product) => product._id !== currentProduct._id)
      props.setSelectedProducts(removeProduct)
      return
    }
    props.setSelectedProducts([...props.selectedProducts, currentProduct])
  }

  if (!productsQuery) return null
  return (
    <>
      <div className='flex gap-2 mb-8'>
        <div>
          <p className='subtitle4-regular'>Adicionales</p>
          <p className='subtitle4-regular'>disponibles</p>
        </div>
        <img src={stars} alt='estrellas' />
      </div>

      {productsQuery.data
        .filter((product) => product.is_available)
        .map((product) => {
          return (
            <div key={product._id} className={`p-2 mt-2`}>
              <div className='flex justify-between gap-3'>
                <div>
                  <p className='subtitle6-regular'>{product.name}</p>
                </div>
                <div className='flex items-center self-start'>
                  {props.withCurrency ? (
                    <span className={`subtitle6-medium mr-1 ${isProductSelected(product) ? 'text-gray-400' : 'text-black'}`}>
                      USD {product.currency_price}
                    </span>
                  ) : (
                    <span className={`subtitle6-medium mr-1 ${isProductSelected(product) ? 'text-gray-400' : 'text-black'}`}>
                      {product.price_type === 'cash' ? '$' : ''} {product.price}
                      {product.price_type === 'uf' ? 'UF' : ''}{' '}
                    </span>
                  )}
                </div>
              </div>

              <p className={`microcopy lg:w-9/12 10/12 ${isProductSelected(product) ? 'text-gray-400' : 'text-black'}`}>
                {product.description}
              </p>

              <FiButton
                variant='outlined'
                theme={isProductSelected(product) ? 'primary' : 'magenta'}
                className='w-full md:mt-1 mt-2 py-1'
                onClick={() => handleProduct(product)}
              >
                <p className='buttontext2-medium'>{isProductSelected(product) ? 'Eliminar' : 'Lo quiero'}</p>
              </FiButton>
            </div>
          )
        })}
    </>
  )
}

export default ProductsList
