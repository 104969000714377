import { FC } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../constants/routes'
import { useTranslation } from 'react-i18next'

const HelpSOS: FC = () => {
  const { t, i18n } = useTranslation()

  let SOSTopics: any[] = t('CLIENT_HELP.SOS_TOPICS', { returnObjects: true })
  return (
    <div className='w-10/12'>
      <p className='body2-regular mb-8'>{t('CLIENT_HELP.WHAT_IF')}</p>
      {SOSTopics.map((topic: any) => {
        return (
          <div key={topic.title} className='mt-4'>
            <p className='body2-regular whitespace-pre-wrap'>
              <span className='body2-medium'>{topic.title} </span>
            </p>
            <p className='body2-regular mt-1'>← {topic.subtitle}</p>
          </div>
        )
      })}
      {i18n.language === 'en' && (
        <>
          <p className='body2-regular mt-6'>
            {t('CLIENT_HELP.REVIEW_MORE')}
            <Link to={routes.web_site.terms_conditions} className='text-blue-500'>
              {t('CLIENT_HELP.HELP_LIST.TERMS_CONDITIONS')}
            </Link>
            {t('CLIENT_HELP.SECTION')}
          </p>
        </>
      )}
    </div>
  )
}

export default HelpSOS
